import { Member, MemberInsuranceDetail } from "src/graphql";
import { BillingStates } from "src/pages/organization/tabs/OrganizationBillingTab/utils";

interface memberNames {
  firstName: string;
  lastName: string;
  nickName?: string;
}

export const getFullName = (member?: memberNames): string => {
  if (!member) return "";
  if (member.nickName)
    return `${member.firstName} "${member.nickName}" ${member.lastName}`;
  return `${member.firstName} ${member.lastName}`;
};

export const getShortName = (member?: memberNames): string => {
  if (!member) return "";
  if (member.nickName) return member.nickName;
  return member.firstName;
};

export type BillingMissingFieldErrors = {
  primaryCIN?: string;
  primaryInsuranceCompany?: string;
  dob?: string;
  sex?: string;
  addressFields?: string;
  countryName?: string;
  stateName?: string;
};

export const validateMemberForBillingFields = (
  member: Member,
  insuranceDetails: MemberInsuranceDetail | undefined
): BillingMissingFieldErrors => {
  const errors: BillingMissingFieldErrors = {};

  if (!member.primaryCIN) errors.primaryCIN = "No primary CIN found";
  if (!insuranceDetails?.primaryInsuranceCompany)
    errors.primaryInsuranceCompany = "No primary insurance company found";
  if (!member.dob) errors.dob = "No date of birth found";
  if (!member.sex) errors.sex = "No member sex found";

  if (
    !member.contactInfo?.address ||
    !member.contactInfo?.city ||
    !member.contactInfo?.state ||
    !member.contactInfo?.country ||
    !member.contactInfo?.zip
  ) {
    errors.addressFields = "Missing address fields";
  }

  if (member.contactInfo?.country !== "US")
    errors.countryName = "Only US addresses are supported";

  if (
    member.contactInfo?.state &&
    !BillingStates.includes(member.contactInfo?.state)
  )
    errors.stateName = "Invalid US state found";

  return errors;
};
