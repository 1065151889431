import { gql } from "@apollo/client";
import { notificationFields, notificationSettingsFields } from "./types";

export const DELETE_NOTIFICATION = gql`
  mutation DeleteNotification(
    $notificationId: ID!
    ) {
    deleteNotification(
      notificationId: $notificationId
      ) {
      success
      message
      data {
        ${notificationFields}
      }
    }
  }
`;

export const MARK_NOTIFICATION_AS_READ = gql`
  mutation MarkNotificationAsRead(
    $notificationId: ID!
    ) {
    markNotificationAsRead(
      notificationId: $notificationId
      ) {
      success
      message
      data {
        ${notificationFields}
      }
    }
  }
`;

export const MARK_ALL_NOTIFICATION_AS_READ = gql`
  mutation MarkAllNotificationAsRead($organizationId:ID!) {
    markAllNotificationAsRead(organizationId:$organizationId) {
      success
      message
      data {
        ${notificationFields}
      }
    }
  }
`;

export const SEND_NOTIFICATION_MANUALLY = gql`
  mutation SendNotificationManually(
    $type: NotificationType!
    $channels: [NotificationChannel!]!
    $renderedContent: String!
    $userIds: [ID!]!
    $organizationId: ID!
    ) {
    sendNotificationManually(
      type: $type
      channels: $channels
      renderedContent: $renderedContent
      userIds: $userIds
      organizationId: $organizationId
      ) {
      success
      message
      data {
        ${notificationFields}
      }
    }
  }
`;

export const UPDATE_NOTIFICATION_SETTINGS = gql`
  mutation UpdateNotificationSettings(
    $notificationSettingsInput: NotificationSettingsInput!
    ) {
    updateNotificationSettings(
      notificationSettingsInput: $notificationSettingsInput
      ) {
      success
      message
      data {
        ${notificationSettingsFields}
      }
    }
  }
`;
