import { ActionIcon, Button, Grid } from "@mantine/core";
import { useAuthContext } from "src/hooks";
import {
  User,
  UserGroup,
  UserIdAndName,
  useMutationUpdateUserGroup,
  useQueryUsers,
} from "src/graphql";
import toast from "src/libs/toast";
import { DataTableComponent, TableHeader } from "../table";
import { TableColumn } from "react-data-table-component";
import { ArrowLeft, ArrowRight } from "react-feather";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../modal";
import { DontTranslate } from "src/libs/localization/components/DontTranslate";

type UserGroupUsersModalProps = {
  isOpen: boolean;
  selectedGroup?: UserGroup;
  onRequestClose: () => void;
};

export const UserGroupUsersModal = ({
  isOpen,
  selectedGroup,
  onRequestClose,
}: UserGroupUsersModalProps) => {
  const { selectedOrganizationId } = useAuthContext();

  const { data: usersResponse, loading: usersLoading } = useQueryUsers({
    excludeSuperAdmins: true,
    organizationId: selectedOrganizationId,
  });

  const [mutationUpdateGroup] = useMutationUpdateUserGroup(
    selectedOrganizationId
  );

  const handleAddMemberToGroup = async (memberId: string) => {
    if (!selectedGroup) return;

    const response = await mutationUpdateGroup({
      variables: {
        input: {
          _id: selectedGroup._id,
          organizationId: selectedOrganizationId,
          addUsers: [{ id: memberId }],
        },
      },
    }).then((response) => response.data?.updateUserGroup);

    if (response?.success) toast.success("Successfully added member");
    else toast.error("Failed to add member");
  };

  const handleRemoveMemberFromGroup = async (memberId: string) => {
    if (!selectedGroup) return;

    const response = await mutationUpdateGroup({
      variables: {
        input: {
          _id: selectedGroup._id,
          organizationId: selectedOrganizationId,
          removeUsers: [{ id: memberId }],
        },
      },
    }).then((response) => response.data?.updateUserGroup);

    if (response?.success) toast.success("Successfully removed member");
    else toast.error("Failed to remove member");
  };

  const groupUsersColumns: TableColumn<UserIdAndName>[] = [
    {
      name: "Name",
      cell: (user) => <DontTranslate>{user.name}</DontTranslate>,
    },
    {
      name: "Remove",
      center: true,
      width: "150px",
      cell: (user) => (
        <ActionIcon
          color="red"
          onClick={() => handleRemoveMemberFromGroup(user._id)}
        >
          <ArrowLeft size="16" />
        </ActionIcon>
      ),
    },
  ];

  const availableUsersColumns: TableColumn<User>[] = [
    {
      name: "Name",
      cell: (user) => <DontTranslate>{user.name}</DontTranslate>,
    },
    {
      name: "Add to group",
      center: true,
      width: "150px",
      cell: (member) => (
        <ActionIcon
          color="green"
          onClick={() => handleAddMemberToGroup(member._id)}
          disabled={selectedGroup?.users.some((m) => m._id === member._id)}
        >
          <ArrowRight size="16" />
        </ActionIcon>
      ),
    },
  ];

  return (
    <Modal opened={isOpen} onClose={onRequestClose} size={900}>
      <ModalHeader>Assign Users</ModalHeader>

      <ModalBody>
        <Grid>
          <Grid.Col md={6}>
            <DataTableComponent
              noDataText="All available users assigned"
              data={usersResponse?.users.data ?? []}
              columns={availableUsersColumns}
              noRowsPerPage
              highlightOnHover
              pointerOnHover
              initialized={!usersLoading}
              onRowClicked={(member) => handleRemoveMemberFromGroup(member._id)}
            />
          </Grid.Col>

          <Grid.Col md={6}>
            <TableHeader noSearch />
            <DataTableComponent
              noDataText="Please assign users to this group"
              data={selectedGroup?.users ?? []}
              columns={groupUsersColumns}
              noRowsPerPage
              highlightOnHover
              pointerOnHover
              onRowClicked={(member) => handleRemoveMemberFromGroup(member._id)}
            />
          </Grid.Col>
        </Grid>
      </ModalBody>

      <ModalFooter>
        <Button color="red" onClick={onRequestClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};
