import styled from "@emotion/styled";

export const SectionHeader = styled.div<{
  noGap?: boolean;
  noPad?: boolean;
  bgColor?: string;
}>`
  position: relative;
  width: 100%;
  padding: ${({ noPad }) => (noPad ? "0px" : "6px 10px")};
  background-color: ${({ theme, bgColor }) => bgColor ?? theme.colors.gray[1]};
  margin-bottom: ${({ noGap }) => (noGap ? "0px" : "16px")};
  font-weight: 600;
  font-size: 16px;
  display: block;
`;

export const SectionSubHeader = styled(SectionHeader)`
  min-height: 44px;
  border-top: 1px solid ${({ theme }) => theme.colors.gray[1]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[1]};
  background-color: ${({ theme }) => theme.colors.gray[0]};
  margin-bottom: ${({ noGap }) => (noGap ? "0px" : "16px")};
`;
