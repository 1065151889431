import { useLazyQuery, useMutation, useSubscription } from "@apollo/client";

import { usePagination } from "src/hooks/usePagination";
import {
  CREATED_OR_UPDATED_TEXT_MESSAGE_THREAD_EVENT,
  DELTE_TEXT_MESSAGE_THREAD,
  GET_TEXT_MESSAGE_THREAD,
  GET_TEXT_MESSAGE_THREADS,
  UPDATE_TEXT_MESSAGE_THREAD_READ,
} from ".";
import {
  GetTextMessageThreadsInput,
  GetTextMessageThreadsResponse,
  MutationDeleteTextMessageThreadArgs,
  MutationUpdateTextMessageThreadReadArgs,
  QueryTextMessageThreadArgs,
  QueryTextMessageThreadsArgs,
  SubscriptionCreatedOrUpdatedTextMessageThreadArgs,
  TextMessageThread,
  TextMessageThreadResponse,
} from "../schemaTypes";
import log from "loglevel";

// queries

export const useLazyQueryGetTextMessageThread = () =>
  useLazyQuery<
    { GetTextMessageThread: TextMessageThreadResponse },
    QueryTextMessageThreadArgs
  >(GET_TEXT_MESSAGE_THREAD);

export const usePaginatedQueryTextMessageThreads = (
  input: GetTextMessageThreadsInput
) =>
  usePagination<
    QueryTextMessageThreadsArgs,
    { response: GetTextMessageThreadsResponse },
    TextMessageThread
  >({
    document: GET_TEXT_MESSAGE_THREADS,
    variables: { input },
  });

// mutations

export const useMutationUpdateTextMessageThreadRead = () =>
  useMutation<
    { updateTextMessageThreadRead: TextMessageThreadResponse },
    MutationUpdateTextMessageThreadReadArgs
  >(UPDATE_TEXT_MESSAGE_THREAD_READ, {
    refetchQueries: [GET_TEXT_MESSAGE_THREADS],
  });

export const useMutationDeleteTextMessageThread = () =>
  useMutation<
    { deleteTextMessageThread: TextMessageThreadResponse },
    MutationDeleteTextMessageThreadArgs
  >(DELTE_TEXT_MESSAGE_THREAD, {
    refetchQueries: [GET_TEXT_MESSAGE_THREADS],
  });

export const useCreatedOrUpdatedTextMessageThreadSubscription = (
  organizationId: string
) =>
  useSubscription<
    { createdOrUpdatedTextMessageThread: TextMessageThread },
    SubscriptionCreatedOrUpdatedTextMessageThreadArgs
  >(CREATED_OR_UPDATED_TEXT_MESSAGE_THREAD_EVENT, {
    variables: { organizationId },

    onData(options) {
      if (options.data.data?.createdOrUpdatedTextMessageThread) {
        log.debug("Refetching text message threads");

        options.client.refetchQueries({
          include: [GET_TEXT_MESSAGE_THREADS],
        });
      }
    },
  });
