const CallRoutingEdgeFields = `
  _id
  condition
  conditionIndex
  sourceNodeId
  targetNodeId
`;

const CallRoutingNodeFields = `
  _id
  type
  userGroupId
  voicePromptContent
  sourceEdges {
    ${CallRoutingEdgeFields}
  }
  targetEdges {
    ${CallRoutingEdgeFields}
  }
  position {
    x
    y
  }
`;

export const CallRoutingConfigurationFields = `
  _id
  organizationId
  entryNodeId
  startNodePosition {
    x
    y
  }
  nodes {
    ${CallRoutingNodeFields}
  }
`;
