import { useMutation, useQuery } from "@apollo/client";
import {
  GetReferralTemplateResponse,
  GetReferralTemplatesResponse,
  MutationCreateReferralTemplateArgs,
  MutationRetireReferralTemplateArgs,
  MutationUpdateReferralTemplateArgs,
  QueryReferralTemplateArgs,
  QueryReferralTemplatesArgs,
} from "../schemaTypes";
import {
  CREATE_REFERRAL_TEMPLATE,
  RETIRE_REFERRAL_TEMPLATE,
  UPDATE_REFERRAL_TEMPLATE,
} from "./mutations";
import { GET_REFERRAL_TEMPLATE, GET_REFERRAL_TEMPLATES } from "./queries";

export const useQueryReferralTemplate = (
  organizationId: string,
  templateId: string,
  onboardingToken?: string,
  skip = false
) =>
  useQuery<
    { referralTemplate: GetReferralTemplateResponse },
    QueryReferralTemplateArgs
  >(GET_REFERRAL_TEMPLATE, {
    variables: {
      input: {
        organizationId,
        templateId,
      },
    },
    skip,
    ...(onboardingToken
      ? {
          context: {
            headers: {
              "Pear-Onboarding-Survey": onboardingToken,
            },
          },
        }
      : {}),
  });

export const useQueryReferralTemplates = (
  organizationId: string,
  resourceId?: string,
  skip = false
) =>
  useQuery<
    { referralTemplates: GetReferralTemplatesResponse },
    QueryReferralTemplatesArgs
  >(GET_REFERRAL_TEMPLATES, {
    variables: {
      input: {
        organizationId,
        resourceId,
      },
    },
    skip,
  });

export const useMutationCreateReferralTemplate = (
  organizationId: string,
  resourceId?: string
) =>
  useMutation<
    { createReferralTemplate: GetReferralTemplateResponse },
    MutationCreateReferralTemplateArgs
  >(CREATE_REFERRAL_TEMPLATE, {
    update: (cache, result) => {
      const createdTemplate = result.data?.createReferralTemplate.data;
      if (!createdTemplate) return;

      // org-wide
      cache.updateQuery<{ referralTemplates: GetReferralTemplatesResponse }>(
        {
          query: GET_REFERRAL_TEMPLATES,
          variables: { input: { organizationId } },
        },
        (data) => {
          if (!data) return;

          return {
            ...data,
            referralTemplates: {
              ...data.referralTemplates,
              data: [...(data.referralTemplates.data ?? []), createdTemplate],
            },
          };
        }
      );

      // resource-specific
      cache.updateQuery<{ referralTemplates: GetReferralTemplatesResponse }>(
        {
          query: GET_REFERRAL_TEMPLATES,
          variables: { input: { organizationId, resourceId } },
        },
        (data) => {
          if (!data) return;

          return {
            ...data,
            referralTemplates: {
              ...data.referralTemplates,
              data: [...(data.referralTemplates.data ?? []), createdTemplate],
            },
          };
        }
      );
    },
  });

export const useMutationUpdateReferralTemplate = () =>
  useMutation<
    { updateReferralTemplate: GetReferralTemplateResponse },
    MutationUpdateReferralTemplateArgs
  >(UPDATE_REFERRAL_TEMPLATE);

export const useMutationRetireReferralTemplate = () =>
  useMutation<
    { retireReferralTemplate: GetReferralTemplateResponse },
    MutationRetireReferralTemplateArgs
  >(RETIRE_REFERRAL_TEMPLATE, {
    refetchQueries: [GET_REFERRAL_TEMPLATES],
  });
