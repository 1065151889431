import * as Yup from "yup";
import { AnswerType } from "src/graphql";
import { SelectOption } from "src/types";
import { EditableItemState } from "src/components";
import { QuestionFormValues } from "./QuestionEditorModal";
import { dataIdFormatMessage, reservedDataIds } from "src/utils";

const EditableItemSchema = Yup.object({
  id: Yup.string().required(),
  timestamp: Yup.string().notRequired(),
  content: Yup.string().required(),
  state: Yup.mixed<EditableItemState>().oneOf(["editing", "saved"]),
});

export const QuestionFormSchema = Yup.object({
  answerOptions: Yup.array(EditableItemSchema)
    .required()
    .when("answerType", {
      is: (type: SelectOption<AnswerType>) =>
        ([AnswerType.Multi, AnswerType.MultiChoice] as string[]).includes(
          type.value
        ),
      then: (schema) =>
        schema
          .min(2, "Multiple Choice types require at least two options")
          .required("Multiple Choice types require at least two options"),
    }),
  answerType: Yup.object({
    value: Yup.mixed<AnswerType>().oneOf(Object.values(AnswerType)).required(),
    label: Yup.string().required(),
  }),
  questionText: Yup.string().required("Question Text is a required field"),
  questionTitle: Yup.string().required("Question Title is a required field"),
  dataId: Yup.string()
    .required("Data ID is a required field")
    .matches(/^[A-Z]+\d+$/, dataIdFormatMessage)
    .test({
      name: "test-data-id-for-answer-type",
      test: (value, context) => {
        if (!value) {
          return true;
        }

        const parent: QuestionFormValues = context.parent;
        const answerTypesByDataId = parent.answerTypesByDataId ?? {};
        const isSameAnswerType =
          answerTypesByDataId[value] === parent.answerType.value ||
          answerTypesByDataId[value] === undefined;

        return isSameAnswerType;
      },
      message: "Selected DataID is not valid with selected Answer Type",
    })
    .test({
      name: "test-reserved-data-id",
      test: (value, context) => {
        if (!value) return true;
        const dataIdInputValueIsInReservedDataIds =
          reservedDataIds[value] !== undefined;
        const isAllowedReadOnlyDataId =
          value === "DROQ1" &&
          context.parent.answerType.value === "READ_ONLY_TEXT";

        return !dataIdInputValueIsInReservedDataIds || isAllowedReadOnlyDataId;
      },
      message: "This Data ID is reserved and cannot be used",
    }),
});
