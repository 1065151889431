import { useState, useLayoutEffect, useEffect, useMemo } from "react";
import {
  useMantineTheme,
  Tooltip,
  Group,
  MantineSize,
  Input,
  Card,
  Box,
} from "@mantine/core";
import { merge } from "lodash";
import DataTable, { TableProps, TableStyles } from "react-data-table-component";
import { ChevronDown } from "react-feather";
import { LoaderComponent } from "../loader";
import { useResizeObserver } from "@mantine/hooks";
import styled from "@emotion/styled";

export type DataTableComponentProps<T> = Omit<
  TableProps<T>,
  "progressComponent" | "progressPending" | "noDataComponent" | "customStyles"
> & {
  /** @default true */
  pagination?: boolean;
  noDataText?: string;
  /**
   * @default false
   */
  noRowsPerPage?: boolean;
  noCardWrapper?: boolean;
  customStylesOverrides?: TableStyles;
  /**
   * Used in place of library's `progressPending` prop, for table's first load
   * @default true
   */
  initialized?: boolean;
  /**
   * Supplements `initialized` by disabling the table when a refetch is occuring;
   * this keeps data displayed in the table, but with the data grayed out and
   * non-interactable during the refresh
   * @default false
   */
  refreshingData?: boolean;
  searchBarProps?: {
    isVisible: boolean;
    placeholder: string;
    size: MantineSize;
    fieldNameToSearchOn: string;
  };
};

export const DataTableComponent = <T,>({
  noDataText,
  noRowsPerPage = false,
  className,
  style,
  noCardWrapper,
  customStylesOverrides,
  initialized = true,
  refreshingData = false,
  searchBarProps,
  data,
  pagination = true,
  ...rest
}: DataTableComponentProps<T>) => {
  const theme = useMantineTheme();
  const [filterText, setFilterText] = useState("");

  useEffect(() => {
    setFilterText("");
  }, [data]);

  const filteredData = useMemo(() => {
    return (data || []).filter((dataItem: T) => {
      if (searchBarProps?.fieldNameToSearchOn) {
        const value = (dataItem as Record<string, unknown>)[
          searchBarProps.fieldNameToSearchOn
        ] as string;

        return value
          ? value.toLowerCase().includes(filterText.toLowerCase())
          : false;
      }

      return true;
    });
  }, [data, filterText, searchBarProps?.fieldNameToSearchOn]);

  const tableComponent = (
    <DataTable
      style={{ minHeight: 300 }}
      disabled={refreshingData}
      noHeader
      pagination={pagination}
      progressPending={!initialized}
      progressComponent={<LoaderComponent />}
      noDataComponent={<NoData text={noDataText} />}
      sortIcon={
        <ChevronDown
          size={10}
          style={{ marginLeft: "0.5em", marginTop: "0.2em" }}
        />
      }
      paginationComponentOptions={{ noRowsPerPage }}
      data={filteredData}
      customStyles={merge(
        {
          /**
           * custom styles reference:
           * https://github.com/jbetancur/react-data-table-component/blob/master/src/DataTable/styles.ts
           */
          tableWrapper: {
            style: { display: "block" },
          },
          rows: {
            highlightOnHoverStyle: {
              backgroundColor: theme.colors.gray[1],
              transitionDuration: "0s",
            },
            style: {
              "&:not(:last-of-type)": {
                borderBottomColor: theme.colors.gray[1],
              },
            },
          },
          noData: {
            style: {
              minHeight: 150,
              padding: "0px 16px",
              color: theme.colors.gray[5],
            },
          },
          headRow: {
            style: {
              borderBottomColor: theme.colors.gray[1],
            },
          },
          pagination: {
            style: {
              borderTopColor: theme.colors.gray[1],
            },
            pageButtonsStyle: {
              height: 30,
              width: 30,
              transition: "0s",
              padding: 0,
              borderRadius: theme.radius.xs,

              "&:hover:not(:disabled)": {
                backgroundColor: theme.colors.gray[1],
              },

              "&:focus": {
                backgroundColor: "transparent",
              },
            },
          },
          expanderButton: {
            style: {
              borderRadius: 0,

              "&:hover:not(:disabled)": {
                backgroundColor: theme.colors.gray[2],
              },

              "&:focus": {
                backgroundColor: "transparent",
              },

              "&:disabled": {
                color: theme.colors.gray[3],
                cursor: "default",
              },
            },
          },
          expanderRow: {
            style: {
              transition: "1s",
            },
          },
        },
        customStylesOverrides
      )}
      {...rest}
    />
  );

  return (
    <>
      {searchBarProps?.isVisible && (
        <Input
          placeholder={searchBarProps.placeholder}
          size={searchBarProps.size}
          mb="xs"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFilterText(e.target.value);
          }}
          value={filterText}
          style={{
            width: "100%",
          }}
        />
      )}
      {noCardWrapper ? (
        <div className={className}>{tableComponent}</div>
      ) : (
        <Card withBorder p={0} className={className} style={{ ...style }}>
          {tableComponent}
        </Card>
      )}
    </>
  );
};

type NoDataComponentProps = {
  text?: string;
};

const NoData = ({ text }: NoDataComponentProps) =>
  text ? (
    <Group my={5} mih={150} align="center" position="center">
      {text}
    </Group>
  ) : null;

const StyledTooltipBox = styled(Box)<{ clickable: boolean }>`
  // base styles
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  // clickable cell styles
  ${({ clickable, theme }) =>
    clickable
      ? `
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    padding-right: 16px;
    cursor: ${clickable ? "pointer" : "inherit"};
    
    &:hover {
      color: ${theme.colors.black[(theme.primaryShade as number) - 1]};
    };
    &:active {
      color: ${theme.colors.black[theme.primaryShade as number]};
    };
  `
      : ""}
`;

type OverflowTooltipCellProps = {
  children: React.ReactNode;
  tooltip?: React.ReactNode;
  style?: React.CSSProperties;
  disabled?: boolean;
  onClick?: () => unknown;
};

export const OverflowTooltipCell = ({
  children,
  tooltip,
  style,
  disabled,
  onClick,
}: OverflowTooltipCellProps) => {
  const [contentOverflowing, setContentOverflowing] = useState(false);
  const [contentRef, contentRect] = useResizeObserver<HTMLDivElement>();

  useLayoutEffect(() => {
    const contentElement = contentRef.current;
    if (!contentElement) return;

    const overflowing =
      contentElement.offsetHeight < contentElement.scrollHeight ||
      contentElement.offsetWidth < contentElement.scrollWidth;

    setContentOverflowing(overflowing);
  }, [contentRef, contentRect]);

  return (
    <Tooltip
      disabled={!contentOverflowing}
      label={tooltip ?? children}
      withinPortal
      data-tag={onClick ? undefined : "allowRowEvents"}
    >
      <StyledTooltipBox
        data-tag={onClick ? undefined : "allowRowEvents"}
        onClick={disabled ? undefined : onClick}
        style={style}
        ref={contentRef}
      >
        {children}
      </StyledTooltipBox>
    </Tooltip>
  );
};
