/**
 * Transforms an array of objects into an object keyed by iterated elements' values at passed key
 */
export const arrayToKeyedObj = <
  T extends Record<string, unknown>,
  K extends keyof T
>(
  input: T[],
  keyOn: K
): Record<string, T> => {
  return input.reduce((byId, el) => {
    if (!(keyOn in el) || typeof el[keyOn] !== "string") {
      throw new Error(
        "Cannot map array to keyed object by passed property name"
      );
    }
    byId[el[keyOn] as string] = el;
    return byId;
  }, {} as Record<string, T>);
};

/**
 * Transforms an array of non-record type interfaces which contain id or _id properties to keyed obj on ids`
 */
type IdKeyed = { id: string };
type _IdKeyed = { _id: string };
export const idKeyedArrToObj = <T extends IdKeyed | _IdKeyed>(
  input: T[],
  keyOn: "id" | "_id"
): Record<string, T> => {
  if (keyOn === "id") {
    return input.reduce((byId, el) => {
      if (!("id" in el))
        throw new Error(
          "Cannot map array to keyed object by passed property name `id`"
        );
      return { ...byId, [el["id"]]: el };
    }, {});
  } else {
    return input.reduce((byId, el) => {
      if (!("_id" in el))
        throw new Error(
          "Cannot map array to keyed object by passed property name `id`"
        );
      return { ...byId, [el["_id"]]: el };
    }, {});
  }
};

/**
 * Unwraps _id values from array of objects with _id keys
 */
export const unwrapIds = <T extends { _id: string }>(values: T[]) =>
  values.map((value) => value._id);

export const arrayToListString = (array: string[]) => {
  if (array.length === 0) {
    return "";
  }
  if (array.length === 1) {
    return array[0];
  }
  const lastItem = array.pop();
  return `${array.join(", ")} & ${lastItem}`;
};
