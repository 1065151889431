import { FieldPolicy } from "@apollo/client";
import log from "loglevel";

type Resource = { _id: string };
type PaginatedResource = { page: number; total: number; data: Resource[] };

interface PaginationResponse {
  success: boolean;
  message: string;
  data?: PaginatedResource;
}

export const createInfiniteQueryFieldPolicy = (
  keyArgs: (string | string[])[]
): FieldPolicy => ({
  keyArgs,
  read: (data) => data,

  merge(
    previous: PaginationResponse | undefined,
    incoming: PaginationResponse,
    { variables, fieldName, mergeObjects }
  ): PaginationResponse {
    if (!previous?.data) {
      log.debug(fieldName, "No previous data");
      return incoming;
    }

    if (
      typeof variables?.pagination !== "object" ||
      typeof variables?.pagination.size !== "number"
    ) {
      log.debug(fieldName, "Not pagination query, invalid usage");
      return previous;
    }

    if (!incoming.data?.data) {
      log.debug(fieldName, "No incoming data, return old data");
      return previous;
    }

    const incomingDataOffset =
      (incoming.data.page - 1) * variables.pagination.size;

    const absentElementsBetweenPreviousDataAndOffset = Math.max(
      0,
      incomingDataOffset - previous.data.data.length
    );

    const [supersetData, subsetData] =
      incoming.data.data.length > previous.data.data.length - incomingDataOffset
        ? [incoming.data.data, previous.data.data.slice(incomingDataOffset)]
        : [previous.data.data.slice(incomingDataOffset), incoming.data.data];

    const mergedData = [
      ...previous.data.data.slice(0, incomingDataOffset),
      ...new Array(absentElementsBetweenPreviousDataAndOffset).fill(undefined),
      ...supersetData.map((value, idx) =>
        subsetData[incomingDataOffset + idx]
          ? mergeObjects(value, subsetData[incomingDataOffset + idx])
          : value
      ),
    ];

    return { ...incoming, data: { ...incoming.data, data: mergedData } };
  },
});
