import { gql } from "@apollo/client";

export const REVOKE_USER_INTEGRATION = gql`
  mutation Mutation($input: RevokeUserIntegrationInput!) {
    revokeUserIntegration(input: $input) {
      message
      success
    }
  }
`;
