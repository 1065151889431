import { gql } from "@apollo/client";
import { TextMessageFields } from "./types";

export const SEND_TEXT_MESSAGE = gql`
    mutation SendTextMessage($input: SendTextMessageInput!) {
        sendTextMessage(input: $input) {
            success
            message
            data{
                ${TextMessageFields}
            }
        }
    }
`;
