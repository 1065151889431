import { useMemo } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import toast from "src/libs/toast";
import { Redirect, useHistory } from "react-router-dom";
import { Button, Stack, Text, Title } from "@mantine/core";
import { FormikInput, LoaderComponent } from "src/components";
import { JWT_ERRORS_SET } from "src/constants/errors";
import {
  useLazyQueryRequestPasswordReset,
  useMutationSetPassword,
} from "src/graphql";
import {
  OneTimeSessionState,
  useOneTimeSession,
  useURLQueryParams,
} from "src/hooks";

const Schema = Yup.object({
  email: Yup.string().required(),
  password: Yup.string().min(12, "Must be 12 or more characters").required(),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords do not match!")
    .required(),
});

export const ResetPasswordForm = () => {
  const history = useHistory();
  const params = useURLQueryParams();
  const oneTimeIdToken = params.get("token");
  const email = params.get("email");

  // exchange ID token for a one-time 10m TTL session cookie
  const { sessionState, setSessionExpired } = useOneTimeSession(oneTimeIdToken);

  const initialValues = useMemo(
    () => ({
      email,
      password: "",
      confirmPassword: "",
    }),
    [email]
  );

  const [mutationSetPassword, { loading: setPasswordLoading }] =
    useMutationSetPassword();
  const [lazyQueryRequestPasswordReset, { loading: requestResetLoading }] =
    useLazyQueryRequestPasswordReset();

  const handleSubmit = async (password: string) => {
    try {
      if (!oneTimeIdToken) return;
      const res = await mutationSetPassword({
        variables: { password },
      });
      if (!res.data?.setPassword?.success)
        throw new Error(res.data?.setPassword?.message);
      toast.success("Password Reset!");
      history.push("/login");
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const err = error.message
        ? JWT_ERRORS_SET[error.message] || error.message
        : "";
      toast.error(err || "Couldn't Signup! Something went wrong.");
      setSessionExpired();
    }
  };

  const handleRequestPasswordReset = async () => {
    if (!email) return; // should never happen, page will redirect if email not set
    try {
      const res = await lazyQueryRequestPasswordReset({
        variables: { email },
      });
      if (!res.data?.requestPasswordReset?.success)
        throw new Error(res.data?.requestPasswordReset?.message);
      toast.success("Signup email sent!");
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const err = error.message
        ? JWT_ERRORS_SET[error.message] || error.message
        : "";
      toast.error(err || "Couldn't send email! Something went wrong.");
    }
  };

  if (!oneTimeIdToken || !email) return <Redirect to="/login" />;

  if (sessionState === OneTimeSessionState.Loading) {
    return <LoaderComponent />;
  }

  return (
    <>
      {/* Bad or expired token, offer to resend password reset email */}
      {sessionState === OneTimeSessionState.Expired && (
        <Stack>
          <Text align="center">
            Sorry, this reset link has expired. Request a new reset link?
          </Text>

          <Button
            loading={requestResetLoading}
            onClick={handleRequestPasswordReset}
          >
            Resend Password Reset Email
          </Button>
        </Stack>
      )}

      {/* Token's good! proceed with completing password reset. */}
      {sessionState === OneTimeSessionState.Active && (
        <>
          <Title order={5} align="center" mb="sm">
            Reset Password
          </Title>
          <Formik
            initialValues={initialValues}
            validationSchema={Schema}
            enableReinitialize={true}
            onSubmit={(values) => handleSubmit(values.password)}
          >
            {({ dirty, handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Stack spacing="sm">
                    <FormikInput
                      label="email"
                      type="email"
                      name="email"
                      value={email}
                      disabled={true}
                      required
                    />

                    <FormikInput
                      label="password"
                      type="password"
                      name="password"
                      placeholder="Must be 8 or more characters"
                      required
                    />

                    <FormikInput
                      label="confirm password"
                      type="password"
                      name="confirmPassword"
                      required
                    />

                    <Button
                      mt="sm"
                      loading={setPasswordLoading}
                      disabled={!dirty}
                      type="submit"
                    >
                      Reset Password
                    </Button>
                  </Stack>
                </form>
              );
            }}
          </Formik>
        </>
      )}
    </>
  );
};
