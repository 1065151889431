import { gql } from "@apollo/client";
import { ActivityTemplateFields } from "./types";

export const CREATE_ACTIVITY_TEMPLATE = gql`
  mutation CreateActivityTemplate($input: ActivityTemplateInput!) {
    createActivityTemplate(input: $input) {
      success
      message
      data {
        ${ActivityTemplateFields}
      }
    }
  }
`;

export const RETIRE_ACTIVITY_TEMPLATE = gql`
  mutation RetireActivityTemplate($input: ActivityTemplateIdAndOrgInput!) {
    retireActivityTemplate(input: $input) {
      success
      message
      data {
        ${ActivityTemplateFields}
      }
    }
  }
`;

export const UPDATE_ACTIVITY_TEMPLATE = gql`
  mutation UpdateActivityTemplate($input: UpdateActivityTemplateInput!) {
    updateActivityTemplate(input: $input) {
      success
      message
      data {
        ${ActivityTemplateFields}
      }
    }
  }
`;
