export const recommendingProviderFields = `
  _id
  nationalProviderIdentifier
  firstName
  lastName
  title
  faxNumber
  companyName
`;
export const providerRecommendationFields = `
  _id
  createdAt
  updatedAt
  status
  memberId
  batchId
  notes
  batch {
    _id
    createdAt
    updatedAt
    recommendingProvider {
      ${recommendingProviderFields}
    }
    templateId
    signatureRequestId
  }
`;
