import { ActionIcon, Tooltip, useMantineTheme } from "@mantine/core";
import { useState } from "react";
import { Archive } from "react-feather";
import toast from "src/libs/toast";
import { Member, MemberStatus, useMutationCloseMember } from "src/graphql";
import { CloseMemberModal } from "../close-member-modal";
import { useAuthContext } from "src/hooks";

interface MemberCloseButtonProps {
  member: Member;
  onClose?: () => void;
  iconSize?: string;
}

export const MemberCloseButton = ({
  member,
  onClose,
  iconSize,
}: MemberCloseButtonProps) => {
  const theme = useMantineTheme();
  const { selectedOrganizationId } = useAuthContext();
  const [closeMember] = useMutationCloseMember(selectedOrganizationId);

  const [closeModalIsOpen, setCloseModalIsOpen] = useState(false);

  const handleCloseMember = async (
    id: string,
    status: MemberStatus,
    reason: string
  ) => {
    try {
      const response = await closeMember({
        variables: { input: { memberId: id, status, reason } },
      });

      if (!response?.data?.closeMember?.success) {
        throw new Error(response.data?.closeMember.message);
      } else {
        toast.success(
          response.data?.closeMember?.message || "Member closed successfully!"
        );
        onClose && onClose();
      }
    } catch (error) {
      toast.error("Couldn't close member! Something went wrong.");
    }
  };

  return (
    <>
      <Tooltip label="Close Member">
        <ActionIcon
          onClick={() => setCloseModalIsOpen(true)}
          disabled={member.status !== MemberStatus.Active}
          data-testid="close-member-button"
        >
          <Archive
            size={iconSize ?? "16"}
            color={
              member.status === MemberStatus.Active
                ? theme.colors.orange[5]
                : undefined
            }
          />
        </ActionIcon>
      </Tooltip>
      {closeModalIsOpen && (
        <CloseMemberModal
          modalIsOpen={closeModalIsOpen}
          setModalIsOpen={setCloseModalIsOpen}
          closeMember={handleCloseMember}
          rowId={member._id}
        />
      )}
    </>
  );
};
