import { useMutation, useQuery } from "@apollo/client";
import {
  DefaultResponse,
  GetEhrNoteTemplateResponse,
  GetEhrNoteTemplatesResponse,
  MutationCreateEhrNoteTemplateArgs,
  MutationDeleteEhrNoteTemplateArgs,
  MutationUpdateEhrNoteTemplateArgs,
  QueryEhrNoteTemplatesArgs,
} from "../schemaTypes";
import {
  CREATE_EHR_NOTE_TEMPLATE,
  DELETE_EHR_NOTE_TEMPLATE,
  UPDATE_EHR_NOTE_TEMPLATE,
} from "./mutations";
import { GET_EHR_NOTE_TEMPLATES } from "./queries";

export const useQueryEHRNoteTemplates = (organizationId: string) =>
  useQuery<
    { ehrNoteTemplates: GetEhrNoteTemplatesResponse },
    QueryEhrNoteTemplatesArgs
  >(GET_EHR_NOTE_TEMPLATES, { variables: { organizationId } });

export const useMutationCreateEHRNoteTemplate = () =>
  useMutation<
    { createEHRNoteTemplate: GetEhrNoteTemplateResponse },
    MutationCreateEhrNoteTemplateArgs
  >(CREATE_EHR_NOTE_TEMPLATE, { refetchQueries: [GET_EHR_NOTE_TEMPLATES] });

export const useMutationUpdateEHRNoteTemplate = () =>
  useMutation<
    { updateEHRNoteTemplate: GetEhrNoteTemplateResponse },
    MutationUpdateEhrNoteTemplateArgs
  >(UPDATE_EHR_NOTE_TEMPLATE, { refetchQueries: [GET_EHR_NOTE_TEMPLATES] });

export const useMutationDeleteEHRNoteTemplate = () =>
  useMutation<
    { deleteEHRNoteTemplate: DefaultResponse },
    MutationDeleteEhrNoteTemplateArgs
  >(DELETE_EHR_NOTE_TEMPLATE, { refetchQueries: [GET_EHR_NOTE_TEMPLATES] });
