import { gql } from "@apollo/client";
import { consentFields, consentTemplateFields } from "./types";

export const CREATE_OR_UPDATE_CONSENT_TEMPLATE = gql`
  mutation CreateOrUpdateConsentTemplate($input: ConsentTemplateInput!) {
    createOrUpdateConsentTemplate(input: $input) {
      success
      message
      data {
        ${consentTemplateFields}
      }
    }
  }
`;

export const DELETE_CONSENT_TEMPLATE = gql`
  mutation DeleteConsentTemplate($consentTemplateId: ID!, $organizationId: ID!) {
    deleteConsentTemplate(consentTemplateId: $consentTemplateId, organizationId: $organizationId) {
      success
      message
      data {
        ${consentTemplateFields}
      }
    }
  }
`;

export const CREATE_OR_UPDATE_CONSENT = gql`
  mutation CreateOrUpdateConsent($input: ConsentInput!) {
    createOrUpdateConsent(input: $input) {
      success
      message
      data {
        ${consentFields}
      }
    }
  }
`;

export const REVOKE_CONSENT = gql`
  mutation RevokeConsent($consentId: ID!, $organizationId: ID!) {
    revokeConsent(consentId: $consentId, organizationId: $organizationId) {
      success
      message
      data {
        ${consentFields}
      }
    }
  }
`;
