import {
  useMutation,
  useQuery,
  useLazyQuery,
  useSubscription,
} from "@apollo/client";
import { usePagination } from "src/hooks/usePagination";
import { Optional } from "src/types";
import {
  GetPhoneCallsResponse,
  MutationUpdatePhoneCallEntryArgs,
  PhoneCall,
  PhoneCallEntryResponse,
  SignedPhoneCallRecordingUrlResponse,
  QueryPhoneCallsArgs,
  SubscriptionCreatedPhoneCallEntryArgs,
  SubscriptionUpdatedPhoneCallEntryArgs,
  VoiceGrantAccessTokenResponse,
  MutationVoiceGrantAccessTokenArgs,
  GetPhoneCallsInput,
  QuerySignedPhoneCallRecordingUrlArgs,
  DefaultResponse,
  MutationAddParticipantToConferenceCallArgs,
  QueryPhoneCallArgs,
  ClaimIncomingPhoneCallResponse,
  MutationClaimIncomingPhoneCallArgs,
} from "../schemaTypes";
import {
  UPDATE_PHONE_CALL_ENTRY,
  CREATE_VOICE_GRANT_ACCESS_TOKEN,
  ADD_PARTICIPANT_TO_CONFERENCE_CALL,
  CLAIM_INCOMING_PHONE_CALL,
} from "./mutations";
import {
  GET_PHONE_CALLS,
  GET_SIGNED_PHONE_CALL_RECORDING_URL,
  GET_PHONE_CALL,
} from "./queries";

import {
  CREATED_PHONE_CALL_ENTRY_EVENT,
  UPDATED_PHONE_CALL_ENTRY_EVENT,
} from "./subscriptions";

export const PHONE_CALLS_POLL_INTERVAL = 1000 * 10; // 10 seconds

export const usePaginatedQueryPhoneCalls = (input: GetPhoneCallsInput) =>
  usePagination<
    QueryPhoneCallsArgs,
    { response: GetPhoneCallsResponse },
    PhoneCall
  >({
    document: GET_PHONE_CALLS,
    variables: { input },
  });

export const useLazyQueryPhoneCall = () =>
  useLazyQuery<{ phoneCall: PhoneCallEntryResponse }, QueryPhoneCallArgs>(
    GET_PHONE_CALL
  );

export const useMutationCreateVoiceGrantAccessToken = () =>
  useMutation<
    { voiceGrantAccessToken: VoiceGrantAccessTokenResponse },
    MutationVoiceGrantAccessTokenArgs
  >(CREATE_VOICE_GRANT_ACCESS_TOKEN);

export const useMutationAddParticipantToConferenceCall = () =>
  useMutation<
    { addParticipantToConferenceCall: DefaultResponse },
    MutationAddParticipantToConferenceCallArgs
  >(ADD_PARTICIPANT_TO_CONFERENCE_CALL);

export const useMutationUpdatePhoneCallEntry = () =>
  useMutation<
    { updatePhoneCallEntry: PhoneCallEntryResponse },
    MutationUpdatePhoneCallEntryArgs
  >(UPDATE_PHONE_CALL_ENTRY);

export const useQueryGetSignedPhoneCallRecordingUrl = (
  organizationId: string,
  phoneCallId?: string
) =>
  useQuery<
    { signedPhoneCallRecordingUrl: SignedPhoneCallRecordingUrlResponse },
    Optional<QuerySignedPhoneCallRecordingUrlArgs, "phoneCallId">
  >(GET_SIGNED_PHONE_CALL_RECORDING_URL, {
    variables: { organizationId, phoneCallId },
    skip: !phoneCallId,
  });

export const useLazyQueryGetSignedPhoneCallRecordingUrl = () =>
  useLazyQuery<
    { signedPhoneCallRecordingUrl: SignedPhoneCallRecordingUrlResponse },
    { phoneCallId: string; organizationId: string }
  >(GET_SIGNED_PHONE_CALL_RECORDING_URL, { fetchPolicy: "network-only" });

export const useCreatedPhoneCallEntrySubscription = (organizationId: string) =>
  useSubscription<
    { createdPhoneCallEntry: PhoneCall },
    SubscriptionCreatedPhoneCallEntryArgs
  >(CREATED_PHONE_CALL_ENTRY_EVENT, {
    variables: { organizationId },

    onData(options) {
      if (options.data.data?.createdPhoneCallEntry)
        options.client.refetchQueries({
          include: [GET_PHONE_CALLS],
        });
    },
  });

export const useUpdatedPhoneCallEntrySubscription = (organizationId: string) =>
  useSubscription<
    { updatedPhoneCallEntry: PhoneCall },
    SubscriptionUpdatedPhoneCallEntryArgs
  >(UPDATED_PHONE_CALL_ENTRY_EVENT, {
    variables: { organizationId },

    onData(options) {
      if (options.data.data?.updatedPhoneCallEntry)
        options.client.refetchQueries({
          include: [GET_PHONE_CALLS],
        });
    },
  });

export const useMutationClaimIncomingPhoneCall = () =>
  useMutation<
    { claimIncomingPhoneCall: ClaimIncomingPhoneCallResponse },
    MutationClaimIncomingPhoneCallArgs
  >(CLAIM_INCOMING_PHONE_CALL);
