import { Box, Button, Select, Space, Text, Textarea } from "@mantine/core";
import * as React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../modal";
import { StyledLabel } from "../input/StyledLabel";
import { DeactivationReasons, StatusOptions } from "src/constants/member";
import { MemberStatus } from "src/graphql";
import toast from "src/libs/toast";
import { useAuthContext } from "src/hooks";

interface CloseMemberModalProps {
  modalIsOpen: boolean;
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  closeMember: (rowId: string, status: MemberStatus, reason: string) => void;
  rowId: string;
}

export const CloseMemberModal = ({
  modalIsOpen,
  setModalIsOpen,
  closeMember,
  rowId,
}: CloseMemberModalProps) => {
  const [status, setStatus] = React.useState<MemberStatus>();
  const [selectedReason, setSelectedReason] = React.useState<string | null>(
    null
  );
  const [reason, setReason] = React.useState("");
  const { selectedOrganization } = useAuthContext();

  const onReasonSelection = (value: string) => {
    if (value === "other") {
      setReason("");
    } else {
      const template = DeactivationReasons.find((r) => r.value === value);
      setReason(template?.label || "");
    }
    setSelectedReason(value);
  };

  const onReasonChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setReason(event.target.value);
  };

  const resetForm = () => {
    setSelectedReason(null);
    setReason("");
    setModalIsOpen(false);
  };

  const onSubmit = () => {
    if (!status) {
      toast.error("Select a close status!");
      return;
    }
    closeMember(rowId, status, reason);
    resetForm();
  };

  return (
    <Modal opened={modalIsOpen} onClose={() => setModalIsOpen(false)}>
      <ModalHeader>Confirm Close</ModalHeader>

      <ModalBody>
        <Text>Are you sure you want to close this member?</Text>
        <Space h="md" />
        <Box>
          <StyledLabel>Status</StyledLabel>
          <Select
            placeholder="Select Status..."
            data={StatusOptions}
            value={status}
            onChange={(newStatus) => setStatus(newStatus as MemberStatus)}
          />
          {status && selectedOrganization.episodesOfCareEnabled && (
            <Text color="orange" size="sm" mt="md">
              Episodes of care is enabled on this organization. Closing this
              member will store all the activities in an Episode of Care for
              later viewing. Reactivated members will start with a clean slate
              of Goals, Activities, Referrals, etc.
            </Text>
          )}
        </Box>
        <Space h="md" />
        <Box>
          <StyledLabel>Reason</StyledLabel>
          <Select
            placeholder="Select Reason..."
            data={DeactivationReasons}
            value={selectedReason}
            onChange={onReasonSelection}
          />
        </Box>
        {selectedReason === "other" && (
          <>
            <Space h="md" />
            <Box>
              <Textarea
                placeholder="Enter the reason for deactivation"
                value={reason}
                onChange={onReasonChange}
              />
            </Box>
          </>
        )}
        <Space h="md" />
      </ModalBody>

      <ModalFooter>
        <Button color="red" onClick={resetForm}>
          Cancel
        </Button>

        <Button onClick={onSubmit}>Confirm</Button>
      </ModalFooter>
    </Modal>
  );
};
