import { Button } from "@mantine/core";
import { Form, Formik } from "formik";
import log from "loglevel";
import toast from "src/libs/toast";
import {
  UserGroup,
  useMutationCreateUserGroup,
  useMutationUpdateUserGroup,
} from "src/graphql";
import { useAuthContext } from "src/hooks";
import * as Yup from "yup";
import { FormikInput } from "../input";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../modal";

type UserGroupFormModalProps = {
  selectedGroup?: UserGroup;
  isOpen: boolean;
  onRequestClose: () => void;
};

export const UserGroupFormModal = ({
  selectedGroup,
  isOpen,
  onRequestClose,
}: UserGroupFormModalProps) => {
  const { selectedOrganizationId } = useAuthContext();

  const [mutationCreateGroup, { loading: createPending }] =
    useMutationCreateUserGroup(selectedOrganizationId);
  const [mutationUpdateGroup, { loading: updatePending }] =
    useMutationUpdateUserGroup(selectedOrganizationId);

  const handleCreateGroup = async (values: FormValues) => {
    try {
      const { data: response } = await mutationCreateGroup({
        variables: {
          input: {
            title: values.title,
            organizationId: selectedOrganizationId,
          },
        },
      });

      if (!response?.createUserGroup.success)
        throw new Error(response?.createUserGroup.message);

      toast.success("Group updated successfully!");
      onRequestClose();
    } catch (error) {
      log.error(error);
      toast.error("Couldn't update group! Something went wrong.");
    }
  };

  const handleUpdateGroup = async (values: FormValues) => {
    try {
      if (!selectedGroup) return;

      const { data: response } = await mutationUpdateGroup({
        variables: {
          input: {
            _id: selectedGroup._id,
            organizationId: selectedOrganizationId,
            title: values.title,
          },
        },
      });

      if (!response?.updateUserGroup.success)
        throw new Error(response?.updateUserGroup.message);

      toast.success("Group updated successfully!");
      onRequestClose();
    } catch (error) {
      log.error(error);
      toast.error("Couldn't update group! Something went wrong.");
    }
  };

  return (
    <Modal opened={isOpen} onClose={onRequestClose}>
      <Formik
        initialValues={{ title: selectedGroup?.title ?? "" }}
        validationSchema={schema}
        onSubmit={selectedGroup ? handleUpdateGroup : handleCreateGroup}
      >
        {() => (
          <Form>
            <ModalHeader>
              {selectedGroup ? "Update group" : "Create group"}
            </ModalHeader>

            <ModalBody spacing="md">
              <FormikInput type="text" name="title" label="Title" />
            </ModalBody>

            <ModalFooter>
              <Button variant="outline" color="red" onClick={onRequestClose}>
                Cancel
              </Button>

              <Button type="submit" loading={updatePending || createPending}>
                {selectedGroup ? "Update" : "Create"}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const schema = Yup.object({
  title: Yup.string().required("Title for group is required"),
});

type FormValues = Yup.InferType<typeof schema>;
