import { Formik, Form } from "formik";
import { Grid, Button, Group, Checkbox } from "@mantine/core";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "src/components";
import { Note } from "src/graphql";
import { QuestionTooltip } from "../question-tooltip";
import { NoteFormFields, useNoteForm } from "./NoteForm";

interface CreateNoteModalProps {
  // Modal control
  isOpen: boolean;
  onRequestClose: () => void;

  // Functional inputs
  organizationId: string;
  userId: string;
  memberId: string | undefined;
  activityId?: string;
  existingNote?: Note | null;
  externalResourceId?: string;
  externalResourceContactId?: string;

  // Behavior flags
  multiMemberActivity?: boolean;
  createNoteForAllMembersInActivity?: boolean;

  // Callbacks
  afterUpdateSuccess?: () => void;
}

export const CreateNoteModal = ({
  isOpen,
  organizationId,
  userId,
  activityId,
  afterUpdateSuccess,
  onRequestClose,
  memberId,
  existingNote,
  externalResourceId,
  externalResourceContactId,
  multiMemberActivity = false,
  createNoteForAllMembersInActivity = false,
}: CreateNoteModalProps) => {
  const {
    formikRef,
    initialValues,
    handleSubmit,
    templateOptions,
    templatesById,
    templatesLoading,
    domainsData,
    submissionPending,
    createForAllMembersInActivity,
    setCreateForAllMembersInActivity,
  } = useNoteForm({
    organizationId,
    userId,
    activityId,
    memberId,
    existingNote,
    externalResourceId,
    externalResourceContactId,
    multiMemberActivity,
    createNoteForAllMembersInActivity,
    afterUpdateSuccess,
    onClose: onRequestClose,
  });
  return (
    <Modal opened={isOpen} onClose={onRequestClose} width="750px">
      <ModalHeader withSubHeader>
        {existingNote ? "Edit Note" : "Create New Note"}
      </ModalHeader>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        innerRef={formikRef}
        enableReinitialize={true}
      >
        {({ values, handleChange, setFieldValue }) => (
          <Form>
            <ModalBody>
              <NoteFormFields
                values={values}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                templateOptions={templateOptions}
                templatesById={templatesById}
                templatesLoading={templatesLoading}
                domainsData={(domainsData?.data ?? []).map((domain) => ({
                  label: domain.title ?? "",
                  value: domain._id ?? "",
                }))}
              />
            </ModalBody>
            <ModalFooter>
              <Grid w="100%" align="center">
                {multiMemberActivity && (
                  <Grid.Col span={8}>
                    <Checkbox
                      checked={createForAllMembersInActivity}
                      onChange={(e) =>
                        setCreateForAllMembersInActivity(e.target.checked)
                      }
                      label={
                        <>
                          Create for all members of activity
                          <QuestionTooltip
                            toolTipMessage={
                              "If checked, this will create a separate note for each member in the activity."
                            }
                          />
                        </>
                      }
                    />
                  </Grid.Col>
                )}
                <Grid.Col span={multiMemberActivity ? 4 : 12}>
                  <Group position="right">
                    <Button
                      color="red"
                      variant="outline"
                      onClick={onRequestClose}
                      disabled={submissionPending}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" loading={submissionPending}>
                      {existingNote ? "Save Changes" : "Create Note"}
                    </Button>
                  </Group>
                </Grid.Col>
              </Grid>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
