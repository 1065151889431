import { useMutation, useQuery } from "@apollo/client";
import {
  EpisodeOfCareResponse,
  EpisodesOfCareResponse,
  MutationCreateEpisodeOfCareArgs,
  MutationUpdateEpisodeOfCareArgs,
  QueryCurrentEpisodeOfCareByMemberIdArgs,
  QueryEpisodesOfCareByMemberIdArgs,
} from "../schemaTypes";
import { CREATE_EPISODE_OF_CARE, UPDATE_EPISODE_OF_CARE } from "./mutations";
import { GET_EPISODES_OF_CARE, GET_CURRENT_EPISODE_OF_CARE } from "./queries";

export const useQueryEpisodesOfCare = (memberId: string) =>
  useQuery<
    { episodesOfCareByMemberId: EpisodesOfCareResponse },
    QueryEpisodesOfCareByMemberIdArgs
  >(GET_EPISODES_OF_CARE, { variables: { memberId } });

export const useMutationCreateEpisodeOfCare = () =>
  useMutation<
    { createEpisodeOfCare: EpisodeOfCareResponse },
    MutationCreateEpisodeOfCareArgs
  >(CREATE_EPISODE_OF_CARE, {
    refetchQueries: [GET_EPISODES_OF_CARE],
  });

export const useMutationUpdateEpisodeOfCare = () =>
  useMutation<
    { updateEpisodeOfCare: EpisodeOfCareResponse },
    MutationUpdateEpisodeOfCareArgs
  >(UPDATE_EPISODE_OF_CARE, { refetchQueries: [GET_EPISODES_OF_CARE] });

export const useQueryCurrentEpisodeOfCare = (memberId: string) =>
  useQuery<
    { currentEpisodeOfCareByMemberId: EpisodeOfCareResponse },
    QueryCurrentEpisodeOfCareByMemberIdArgs
  >(GET_CURRENT_EPISODE_OF_CARE, { variables: { memberId } });
