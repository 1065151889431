import { useEffect, useRef } from "react";

/**
 * CAUTION: ALWAYS USE THE BUILTIN DOM `autoFocus` ATTRIBUTES FIRST BEFORE
 *          REACHING FOR THIS HOOK
 *
 * Auto focuses the input this ref is attached to when it mounts in the DOM;
 * there are some conditions, sometimes with library code, where the DOM's
 * built-in `autoFocus` props on input elements won't work as expected.
 * For instance, I'm adding this hook because of some more complex memoization
 * and render patterns involved in the internals of ReactFlow for the Flow
 * Graphs.
 */
export const useAutoFocusInputRef = <T extends HTMLInputElement>(
  mountCondition: boolean
) => {
  const elementRef = useRef<T>(null);

  useEffect(() => {
    let frameHandle: number;

    if (mountCondition) {
      frameHandle = requestAnimationFrame(() => {
        if (!elementRef?.current) return;

        elementRef.current.focus();
        elementRef.current.setSelectionRange(
          elementRef.current.value.length,
          elementRef.current.value.length
        );
      });
    }

    return () => {
      frameHandle && cancelAnimationFrame(frameHandle);
    };
  }, [mountCondition]);

  return elementRef;
};

export const useAutoFocusTextAreaRef = (mountCondition: boolean) => {
  const elmenentRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    let frameHandle: number;

    if (mountCondition) {
      frameHandle = requestAnimationFrame(() => {
        if (!elmenentRef.current) return;

        elmenentRef.current.focus();

        const end = elmenentRef.current.value.length;
        elmenentRef.current.selectionStart = end;
        elmenentRef.current.selectionEnd = end;
      });
    }

    return () => {
      frameHandle && cancelAnimationFrame(frameHandle);
    };
  }, [mountCondition]);

  return elmenentRef;
};
