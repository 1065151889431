import { forwardRef } from "react";
import { Flex, SelectItem, Text, Tooltip } from "@mantine/core";
import { Info } from "react-feather";

export type ItemProps = SelectItem & {
  questions?: string[];
  dataId?: string;
  answerType?: string;
};

export const DataIDOption = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, answerType, ...others }: ItemProps, ref) => (
    <Flex justify="space-between" align="center" ref={ref} {...others}>
      <Text size="sm">{label}</Text>
      <Tooltip
        withArrow
        withinPortal
        position="right"
        label={`${label} ${
          answerType ? `(${answerType})` : `(${others.dataId})`
        }`}
      >
        <Info
          size={14}
          color={others["data-selected"] ? "white" : "var(--GREEN)"}
          cursor="pointer"
        />
      </Tooltip>
    </Flex>
  )
);
