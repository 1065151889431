import { gql } from "@apollo/client";
import { ReferralFields } from "./types";

export const CREATE_REFERRAL = gql`
  mutation CreateReferral($input: CreateReferralInput!) {
    createReferral(input: $input) {
      success
      message
      data {
        ${ReferralFields}
      }
    }
  }
`;

export const UPDATE_REFERRAL_STATUS = gql`
  mutation UpdateReferralStatus($input: UpdateReferralStatusInput!) {
    updateReferralStatus(input: $input) {
      success
      message
      data {
        ${ReferralFields}
      }
    }
  }
`;

export const UPDATE_REFERRAL_STATUS_PUBLIC = gql`
  mutation UpdateReferralStatusPublic($input: UpdateReferralStatusPublicInput!) {
    updateReferralStatusPublic(input: $input) {
      success
      message
      data {
        ${ReferralFields}
      }
    }
  }
`;
