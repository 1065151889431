import * as Sentry from "@sentry/react";
import { PropsWithChildren } from "react";

import { AuthStep } from "src/components/auth/AuthFlowContainer";
import { AuthSplash } from "src/components/auth/AuthSplash";

const shouldInstrumentThisEnvironment = (): boolean => {
  const env = process.env.REACT_APP_ENV;

  // please avoid local Dx
  return env === "production" || env === "staging" || env === "sandbox";
};

export const initInstrumentation = (): void => {
  if (!shouldInstrumentThisEnvironment()) {
    return;
  }

  // Initialize Sentry
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      // sandbox api endpoint
      /^https:\/\/sandbox.api.pearsuite.com\//,
      // staging api endpoint
      /^https:\/\/staging.api.pearsuite.com\//,
      // production api endpoint
      /^https:\/\/api.pearsuite.com\//,
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: process.env.REACT_APP_ENV,
    release: process.env.REACT_APP_ENV,
  });
};

type ErrorBoundaryProps = PropsWithChildren<{
  message?: string;
}>;

export const ErrorBoundary = (props: ErrorBoundaryProps) => {
  const message =
    props.message ??
    "Sorry we are experiencing some technical difficulties - please refresh and try again";

  return (
    <Sentry.ErrorBoundary
      fallback={
        <AuthSplash pretty>
          <AuthStep>{message}</AuthStep>
        </AuthSplash>
      }
    >
      {props.children}
    </Sentry.ErrorBoundary>
  );
};
