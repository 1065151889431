import { gql } from "@apollo/client";
import {
  BillingProviderFields,
  OrganizationFields,
  InsuranceConfigurationFields,
} from "./types";

export const CREATE_ORGANIZATION = gql`
  mutation ($input: OrganizationInput!) {
    createOrganization(input: $input) {
      success
      message
    }
  }
`;
export const UPDATE_ORGANIZATION = gql`
  mutation ($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      success
      message
      data {
        ${OrganizationFields}
      }
    }
  }
`;

export const ADD_DEFAULT_ANSWERING_USER = gql`
  mutation AddDefaultOrganizationUser($organizationId: ID!, $userId: ID!) {
    addDefaultAnsweringUser(organizationId: $organizationId, userId: $userId) {
      success
      message
    }
  }
`;

export const REMOVE_DEFAULT_ANSWERING_USER = gql`
  mutation RemoveDefaultOrganizationUser($organizationId: ID!, $userId: ID!) {
    removeDefaultAnsweringUser(
      organizationId: $organizationId
      userId: $userId
    ) {
      success
      message
    }
  }
`;

export const DELETE_ONBOARDING_SURVEY = gql`
  mutation DeleteOnboardingSurvey($organizationId: ID!, $surveyId: ID!) {
    deleteOnboardingSurvey(
      organizationId: $organizationId
      surveyId: $surveyId
    ) {
      success
      message
    }
  }
`;

export const GET_ONBOARDING_LINK = gql`
  mutation GenerateOnboardingLink($input: GetOnboardingLinkInput!) {
    getOnboardingLink(input: $input) {
      success
      message
      data
    }
  }
`;

export const UPDATE_ONBOARDING_SURVEY = gql`
  mutation UpdateOnboardingSurvey($input: UpdateOnboardingSurveyInput!) {
    updateOnboardingSurvey(input: $input) {
      success
      data
    }
  }
`;

export const UPDATE_ORGANIZATION_BILLING_CONFIGURATION = gql`
    mutation UpdateOrganizationBillingConfiguration($input: BillingConfigurationInput!) {
        updateOrganizationBillingConfiguration(input: $input) {
            success
            message
            data {
                ${OrganizationFields}
            }
        }
    }
`;

export const CREATE_OR_UPDATE_BILLING_PROVIDER = gql`
  mutation CreateOrUpdateBillingProvider($id: ID, $input: BillingProviderInput!) {
    createOrUpdateBillingProvider(id: $id, input: $input) {
      success
      message
      data {
        ${BillingProviderFields}
      }
    }
  }
`;

export const DELETE_BILLING_PROVIDER = gql`
  mutation DeleteBillingProvider($id: ID!, $organizationId: ID) {
    deleteBillingProvider(id: $id, organizationId: $organizationId) {
      success
      message
    }
  }
`;

export const ENABLE_EPISODES_OF_CARE = gql`
  mutation ToggleEpisodesOfCare($organizationId: ID!) {
    toggleEpisodesOfCare(organizationId: $organizationId) {
      success
      message
    }
  }
`;

export const TOGGLE_CARE_PLANS = gql`
  mutation ToggleCarePlans($organizationId: ID!) {
    toggleCarePlans(organizationId: $organizationId) {
      success
      message
    }
  }
`;

export const DELETE_ORGANIZATION = gql`
  mutation DeleteOrganization($organizationId: ID!, $permanently: Boolean) {
    deleteOrganization(
      organizationId: $organizationId
      permanently: $permanently
    ) {
      success
      message
      data {
        ${OrganizationFields}
      }
    }
  }
`;

export const RESTORE_ORGANIZATION = gql`
  mutation RestoreOrganization($organizationId: ID!) {
    restoreOrganization(organizationId: $organizationId) {
      success
      message
      data {
        ${OrganizationFields}
      }
    }
  }
`;

export const CREATE_OR_UPDATE_ORGANIZATION_INSURANCE_BILLING_CONFIGURATION = gql`
  mutation CreateOrUpdateOrganizationInsuranceBillingConfiguration($organizationId: ID!, $input: CreateOrUpdateOrganizationBillingConfigurationInsuranceInput!) {
    createOrUpdateOrganizationInsuranceBillingConfiguration(organizationId: $organizationId, input: $input) {
      success
      message
      data {
        ${OrganizationFields}
      }
    }
  }
`;

export const CREATE_OR_UPDATE_IN_NETWORK_INSURANCE_CONFIGURATION = gql`
  mutation CreateOrUpdateInNetworkInsuranceBillingConfiguration($input: InsuranceBillingConfigurationInput!) {
    createOrUpdateInNetworkInsuranceBillingConfiguration(input: $input) {
      success
      message
      data {
        ${InsuranceConfigurationFields}
      }
    }
  }
`;

export const DELETE_INSURANCE_BILLING_CONFIGURATION = gql`
  mutation DeleteInsuranceBillingConfiguration(
    $organizationId: ID
    $insurance: String!
  ) {
    deleteInsuranceBillingConfiguration(
      organizationId: $organizationId
      insurance: $insurance
    ) {
      success
      message
    }
  }
`;

export const CREATE_OR_UPDATE_COST_CONFIGURATIONS = gql`
  mutation CreateOrUpdateCostConfigurations(
    $input: CreateOrUpdateBillingCostConfigurationInput!
  ) {
    createOrUpdateCostConfigurations(input: $input) {
      success
      message
      data {
        ${InsuranceConfigurationFields}
      }
    }
  }
`;

export const DELETE_COST_CONFIGURATION = gql`
  mutation DeleteCostConfiguration($organizationId: ID, $costId: ID!) {
    deleteCostConfiguration(organizationId: $organizationId, costId: $costId) {
      success
      message
      data {
        ${InsuranceConfigurationFields}
      }
    }
  }
`;

export const RENAME_ORGANIZATION_INSURANCE_BILLING_CONFIGURATION = gql`
  mutation RenameOrganizationInsuranceBillingConfiguration($organizationId: ID!, $id: ID!, $name: String!) {
    renameOrganizationInsuranceBillingConfiguration(organizationId: $organizationId, id: $id, name: $name) {
      success
      message
      data {
        ${OrganizationFields}
      }
    }
  }
`;

export const SWAP_ORGANIZATION_INSURANCE_BILLING_CONFIGURATION = gql`
  mutation SwapOrganizationInsuranceBillingConfiguration(
    $organizationId: ID!
    $fromId: ID!
    $toId: ID!
  ) {
    swapOrganizationInsuranceBillingConfiguration(
      organizationId: $organizationId
      fromId: $fromId
      toId: $toId
    ) {
      success
      message
    }
  }
`;

export const CREATE_DOMAIN = gql`
  mutation CreateDomain($input: CreateDomainInput!) {
    createDomain(input: $input) {
      success
      message
      data {
        _id
        title
        description
        organizationId
        goals {
          _id
        }
      }
    }
  }
`;

export const UPSERT_DOMAIN = gql`
  mutation UpsertDomain($input: DomainInput!) {
    upsertDomain(input: $input) {
      success
      message
      data {
        _id
        title
        description
        organizationId
        goals {
          _id
        }
      }
    }
  }
`;

export const UPSERT_MEMBER_DOMAIN_DESCRIPTION = gql`
  mutation UpsertDomainDescription($input: DomainDescriptionInput!) {
    upsertDomainDescription(input: $input) {
      success
      message
    }
  }
`;

export const DELETE_DOMAIN = gql`
  mutation DeleteDomain($domainId: ID!, $organizationId: ID!) {
    deleteDomain(domainId: $domainId, organizationId: $organizationId) {
      success
      message
    }
  }
`;
