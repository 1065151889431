import gql from "graphql-tag";
import { episodeOfCareFields } from "./types";

export const CREATE_EPISODE_OF_CARE = gql`
    mutation CreateEpisodeOfCare($input: EpisodeOfCareInput!) {
        createEpisodeOfCare(input: $input) {
            success
            message
            data {
                ${episodeOfCareFields}
            }
        }
    }`;

export const UPDATE_EPISODE_OF_CARE = gql`
    mutation UpdateEpisodeOfCare($input: UpdateEpisodeOfCareInput!) {
        updateEpisodeOfCare(input: $input) {
            success
            message
            data {
                ${episodeOfCareFields}
            }
        }
    }`;

export const CLOSE_EPISODE_OF_CARE = gql`
    mutation CloseEpisodeOfCare($episodeOfCareId: ID!) {
        closeEpisodeOfCare(episodeOfCareId: $episodeOfCareId) {
            success
            message
            data {
                ${episodeOfCareFields}
            }
        }
    }`;
