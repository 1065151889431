import { LockingReferenceFields } from "../Common/types";

export const GoalTemplateFields = `
  _id
  name
  organizationId
  description
  domainId
  actionItemDescriptions
  lockingReferences {
    ${LockingReferenceFields}
  }
`;
