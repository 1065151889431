import { Tooltip } from "@mantine/core";
import { Lock } from "react-feather";

type LockIconProps = {
  /**
   * Renders in white for contrast over darker backgrounds
   * @default false
   */
  lightTheme?: boolean;

  label?: string;
};

export const LockIcon = ({ lightTheme, label }: LockIconProps) => (
  <Tooltip withinPortal label={label ?? "Locked"}>
    <Lock color={lightTheme ? "white" : "var(--color-grey)"} size={16} />
  </Tooltip>
);

export const FlowLockIcon = ({
  lightTheme,
  label = "Locked by Flow Template",
}: LockIconProps) => <LockIcon lightTheme={lightTheme} label={label} />;
