import { CarePathwayTemplate } from "src/graphql";
import { PreviewContainer, PreviewHeader } from "./shared";

type CarePathwayTemplatePreviewProps = {
  carePathwayTemplate: CarePathwayTemplate;
};

export const CarePathwayTemplatePreview = ({
  carePathwayTemplate,
}: CarePathwayTemplatePreviewProps) => {
  return (
    <PreviewContainer>
      <PreviewHeader>Preview</PreviewHeader>
      {!carePathwayTemplate && "<missing template info>"}

      {carePathwayTemplate && (
        <div style={{ padding: 3 }}>
          {/* Title */}
          <div style={{ marginTop: "0.25em" }}>{carePathwayTemplate.name}</div>
        </div>
      )}
    </PreviewContainer>
  );
};
