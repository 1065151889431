import { Stack } from "@mantine/core";
import styled from "styled-components";

import {
  LoaderComponent,
  StyledLabel,
  labelsByReferenceType,
} from "src/components";
import {
  LockingReference,
  LockingReferenceType,
  TitledLockingReference,
  useQueryTitledReferences,
} from "src/graphql";
import { useAuthContext } from "src/hooks";

type LockingReferencesProps = {
  activeReferences: LockingReference[];
};

export const LockingReferences = ({
  activeReferences,
}: LockingReferencesProps) => {
  const { selectedOrganizationId } = useAuthContext();

  const { loading, data } = useQueryTitledReferences(
    selectedOrganizationId,
    activeReferences
  );

  const byType = (data?.titledReferences.data ?? []).reduce((byType, ref) => {
    if (ref.referencingType in byType) {
      byType[ref.referencingType].push(ref);
    } else {
      byType[ref.referencingType] = [ref];
    }
    return byType;
  }, {} as Record<LockingReferenceType, TitledLockingReference[]>);

  return (
    <>
      {loading && <LoaderComponent />}
      {!loading && (
        <Stack>
          {Object.entries(byType).map(([referenceType, references]) => (
            <LockingReferenceList
              key={referenceType}
              referenceType={referenceType as LockingReferenceType}
              references={references}
            />
          ))}
        </Stack>
      )}
    </>
  );
};

const StyledLockingItem = styled.div`
  padding: 10px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.05);

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

type LockingReferenceListProps = {
  references: TitledLockingReference[];
  referenceType: LockingReferenceType;
};

const LockingReferenceList = ({
  references,
  referenceType,
}: LockingReferenceListProps) => {
  const label = labelsByReferenceType[referenceType];
  return (
    <Stack spacing={0}>
      <StyledLabel strong>{label}:</StyledLabel>
      {references.map((ref, i) => (
        <StyledLockingItem key={i}>{ref.title}</StyledLockingItem>
      ))}
    </Stack>
  );
};
