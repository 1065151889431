import { gql } from "@apollo/client";
import { ExternalResourceContactFields, ExternalResourceFields } from "./types";

export const CREATE_EXTERNAL_RESOURCE = gql`
  mutation CreateExternalResource($input: ExternalResourceInput!) {
    createExternalResource(input: $input) {
      success
      message
      data {
        ${ExternalResourceFields}
      }
    }
  }
`;

export const UPDATE_EXTERNAL_RESOURCE = gql`
  mutation UpdateExternalResource($input: UpdateExternalResourceInput!) {
    updateExternalResource(input: $input) {
      success
      message
      data {
        ${ExternalResourceFields}
      }
    }
  }
`;

export const RETIRE_EXTERNAL_RESOURCE = gql`
  mutation RetireExternalResource($input: ExternalResourceIdAndOrgInput!) {
    retireExternalResource(input: $input) {
      success
      message
      data {
        ${ExternalResourceFields}
      }
    }
  }
`;

export const CREATE_EXTERNAL_RESOURCE_CONTACT = gql`
  mutation CreateExternalResourceContact($input: ExternalResourceContactInput!) {
    createExternalResourceContact(input: $input) {
      success
      message
      data {
        ${ExternalResourceContactFields}
      }
    }
  }
`;

export const UPDATE_EXTERNAL_RESOURCE_CONTACT = gql`
  mutation UpdateExternalResourceContact($input: UpdateExternalResourceContactInput!) {
    updateExternalResourceContact(input: $input) {
      success
      message
      data {
        ${ExternalResourceContactFields}
      }
    }
  }
`;

export const RETIRE_EXTERNAL_RESOURCE_CONTACT = gql`
  mutation RetireExternalResourceContact($input: RetireExternalResourceContactInput!) {
    retireExternalResourceContact(input: $input) {
      success
      message
      data {
        ${ExternalResourceContactFields}
      }
    }
  }
`;
