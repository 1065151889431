import { gql } from "@apollo/client";
import { UserFields } from "../Users/types";

export const INVITE_USER = gql`
  mutation InviteUser($input: InviteUserInput!) {
    inviteUser(input: $input) {
      success
      message
      data {
        ${UserFields}
      }
    }
  }
`;

export const EXCHANGE_ONE_TIME_ID_TOKEN = gql`
  mutation ExchangeOneTimeIdToken($oneTimeIdToken: String!) {
    exchangeOneTimeIdToken(oneTimeIdToken: $oneTimeIdToken) {
      success
      message
    }
  }
`;

export const SET_PASSWORD = gql`
  mutation SetPassword($password: String!) {
    setPassword(password: $password) {
      success
      message
    }
  }
`;
