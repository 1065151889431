import { gql } from "@apollo/client";
import { GoalFields } from "./types";

export const CREATE_GOAL = gql`
  mutation CreateGoal($input: CreateGoalInput!) {
    createGoal(input: $input) {
      success
      message
      data {
        ${GoalFields}
      }
    }
  }
`;

export const UPDATE_GOAL = gql`
  mutation UpdateGoal($input: UpdateGoalInput!) {
    updateGoal(input: $input) {
      success
      message
      data {
        ${GoalFields}
      }
    }
  }
`;

export const UPDATE_GOAL_PROGRESS = gql`
  mutation UpdateGoalProgress($input: UpdateGoalProgressInput!) {
    updateGoalProgress(input: $input) {
      success
      message
      data {
        ${GoalFields}
      }
    }
  }
`;

export const DELETE_GOAL = gql`
  mutation DeleteGoal($input: DeleteGoalInput!) {
    deleteGoal(input: $input) {
      success
      message
      data {
        ${GoalFields}
      }
    }
  }
`;

export const UPDATE_GOAL_STATUS = gql`
  mutation UpdateGoalStatus($goalId: ID!, $status: GoalStatus!, $organizationId: ID!, $memberId: ID!) {
    updateGoalStatus(goalId: $goalId, status: $status, organizationId: $organizationId, memberId: $memberId) {
      success
      message
      data {
        ${GoalFields}
      }
    }
  }
`;
