import { memberIdAndNameFields } from "../Members/types";

export const PhoneCallFields = `
    _id
    initiatingCallSid
    conferenceFriendlyName
    to
    from
    direction
    organizationId
    members{
        ${memberIdAndNameFields}
    }
    duration
    recordingSid
    voicemailRecordingSid
    disposition
    userId
    userName
    voicemail
    createdAt
    updatedAt
`;

export const ClaimSuccessFields = `
    didClaim
`;
