import gql from "graphql-tag";
import { episodeOfCareFields } from "./types";

export const GET_EPISODES_OF_CARE = gql`
    query GetEpisodesOfCare($memberId: ID!) {
        episodesOfCareByMemberId(memberId: $memberId) {
            success
            data {
                ${episodeOfCareFields}
            }
            message
            total
        }
    }`;

export const GET_CURRENT_EPISODE_OF_CARE = gql`
    query GetCurrentEpisodeOfCare($memberId: ID!) {
        currentEpisodeOfCareByMemberId(memberId: $memberId) {
            success
            data {
                ${episodeOfCareFields}
            }
            message
        }
    }`;
