import { ActionIcon } from "@mantine/core";
import { Edit2, Trash } from "react-feather";
import { Draggable, DragType } from "src/components/drag-and-drop";
import { Question } from "src/graphql";
import styled from "styled-components";

const StyledContainer = styled.div`
  box-shadow: 1.5px 1.5px 2px rgba(0, 0, 0, 0.08),
    0 1.5px 2px rgba(0, 0, 0, 0.11);
  cursor: pointer;
  text-overflow: ellipsis;
  background-color: white;
  border-radius: 3px;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
`;

const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: var(--color-pear-green);
  width: 100%;
  height: 31px;
  overflow: hidden;
  color: white;
  font-size: 14px;

  ${StyledContainer}:hover & {
    background-color: var(--color-pear-green-highlight);
  }
`;

const QuestionDataId = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0px 6px;
  position: relative;

  &::after {
    display: block;
    content: "";
    height: 1em;
    width: 2px;
    background-color: rgba(255, 255, 255, 0.3);
    position: absolute;
    top: calc(50% - 0.5em);
    right: 0px;
  }
`;

const QuestionTitle = styled.div`
  padding: 0px 6px;
  margin-right: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  align-items: stretch;
  width: 100%;
`;

const QuestionText = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding: 5px;
  font-size: 80%;

  & > *:first-child {
    margin-top: 0px;
  }

  & > *:last-child {
    margin-bottom: 0px;
  }
`;

const ButtonsContainer = styled.div`
  height: 100%;
  background-color: var(--color-pear-green-highlight);
  display: none;

  ${StyledContainer}:hover & {
    display: flex;
  }
`;

type QuestionListEntryProps = {
  question: Question;
  onRequestEdit: (questionId: string) => void;
  onRequestDelete: (questionId: string) => void;
  isLast?: boolean;
};

export const QuestionListEntry = ({
  question,
  onRequestEdit,
  onRequestDelete,
  isLast,
}: QuestionListEntryProps) => {
  const locked = !!question.lockingReferences.length;

  return (
    <>
      <Draggable type={DragType.Question} dragData={question}>
        <StyledContainer>
          <HeaderContainer>
            <QuestionDataId>
              <strong>{question.dataId}</strong>
            </QuestionDataId>

            <QuestionTitle>{question.questionTitle}</QuestionTitle>

            <ButtonsContainer>
              <ActionIcon
                variant="transparent"
                onClick={() => onRequestEdit(question._id)}
              >
                <Edit2 size={14} color="white" />
              </ActionIcon>

              <ActionIcon
                variant="transparent"
                disabled={locked}
                onClick={() => onRequestDelete(question._id)}
              >
                <Trash size={14} color={locked ? "gray" : "red"} />
              </ActionIcon>
            </ButtonsContainer>
          </HeaderContainer>

          <QuestionText
            dangerouslySetInnerHTML={{ __html: question.questionText }}
          />
        </StyledContainer>
      </Draggable>
    </>
  );
};
