import { Anchor, Center, SimpleGrid, Stack, Title } from "@mantine/core";
import { useHistory } from "react-router-dom";
import lostPerson from "src/assets/svgs/404-lost-person.svg";

export default function NotFound() {
  const history = useHistory();

  return (
    <Center h="100%" w="100%">
      <SimpleGrid cols={2}>
        <Stack spacing="xl" style={{ alignSelf: "center" }}>
          <Title
            style={{ fontSize: "6rem", lineHeight: "normal" }}
            data-testid="404"
          >
            Not found!
          </Title>

          <Stack>
            <Title order={2}>Oops, looks like you are lost</Title>

            <Anchor color="green" onClick={() => history.push("/dashboard")}>
              Go back to dashboard
            </Anchor>
          </Stack>
        </Stack>

        <img src={lostPerson} alt="Lost person holding map" height="400px" />
      </SimpleGrid>
    </Center>
  );
}
