import { Center, MantineSize } from "@mantine/core";
import * as React from "react";

interface LoaderComponentProps {
  h?: MantineSize;
  w?: MantineSize;
  style?: React.CSSProperties;
}

// inlining the Loader SVG that Mantine renders, to clear "Unexpected unit 2.25rem"
// from console warnings on FE

export const LoaderComponent = React.forwardRef<
  HTMLDivElement,
  LoaderComponentProps
>(({ h = "70px", w = "70px", style }, ref) => (
  <Center ref={ref} h="100%" style={style}>
    <svg
      viewBox="0 0 120 30"
      xmlns="http://www.w3.org/2000/svg"
      fill="#3EAE83"
      height={h}
      width={w}
      role="presentation"
    >
      <circle cx="15" cy="15" r="15">
        <animate
          attributeName="r"
          from="15"
          to="15"
          begin="0s"
          dur="0.8s"
          values="15;9;15"
          calcMode="linear"
          repeatCount="indefinite"
        ></animate>
        <animate
          attributeName="fill-opacity"
          from="1"
          to="1"
          begin="0s"
          dur="0.8s"
          values="1;.5;1"
          calcMode="linear"
          repeatCount="indefinite"
        ></animate>
      </circle>
      <circle cx="60" cy="15" r="9" fillOpacity="0.3">
        <animate
          attributeName="r"
          from="9"
          to="9"
          begin="0s"
          dur="0.8s"
          values="9;15;9"
          calcMode="linear"
          repeatCount="indefinite"
        ></animate>
        <animate
          attributeName="fill-opacity"
          from="0.5"
          to="0.5"
          begin="0s"
          dur="0.8s"
          values=".5;1;.5"
          calcMode="linear"
          repeatCount="indefinite"
        ></animate>
      </circle>
      <circle cx="105" cy="15" r="15">
        <animate
          attributeName="r"
          from="15"
          to="15"
          begin="0s"
          dur="0.8s"
          values="15;9;15"
          calcMode="linear"
          repeatCount="indefinite"
        ></animate>
        <animate
          attributeName="fill-opacity"
          from="1"
          to="1"
          begin="0s"
          dur="0.8s"
          values="1;.5;1"
          calcMode="linear"
          repeatCount="indefinite"
        ></animate>
      </circle>
    </svg>
  </Center>
));
