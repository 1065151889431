import { ActionIcon, Button, Group, Textarea } from "@mantine/core";
import React, { useRef, useState } from "react";
import { X } from "react-feather";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const SavedItem = styled.div<{ editable: boolean }>`
  padding: 5px;
  margin-left: -5px;
  margin-right: -5px;
  width: 100%;
  border-radius: 0.25rem;
  font-size: 14px;
  background-color: transparent;
  cursor: ${({ editable }) => (editable ? "pointer" : undefined)};
  position: relative;

  > .item-hover-controls {
    position: absolute;
    top: 5px;
    right: 5px;
    display: none;
  }

  &:hover {
    background-color: ${({ editable }) =>
      editable ? "rgba(0, 0, 0, 0.05)" : undefined};

    > .item-hover-controls {
      display: flex;
    }
  }
`;

export const UI_GENERATED_NOTE_ID_SLUG = "ui-id";

/**
 * Makes an empty EditableItem with timestamp and id
 * @param state Sets the state of the note; DEFAULT: 'editing'
 */
export const makeEditableListItem = (
  state: EditableItemState = "editing"
): EditableItem => {
  const timestamp = `${Date.now()}`;
  return {
    id: `${UI_GENERATED_NOTE_ID_SLUG}-${timestamp}`,
    content: "",
    state,
  };
};

export type EditableItemState = "editing" | "submitting" | "saved";
export type EditableItem = {
  id: string;
  content: string;
  state: EditableItemState;
};

interface BaseProps {
  item: EditableItem;
  onCommitItemUpdate?: (
    action: "create" | "update" | "delete" | "commit",
    item: EditableItem
  ) => void;
  /**
   * When true, will enable clicking into an item to edit.
   * @default true
   */
  editable?: boolean;
  /**
   * @default true
   */
  deleteable?: boolean;
  /**
   * When true, will render Save and Cancel buttons in edit mode.
   * @default true
   */
  renderControls?: boolean;
}

interface EditableProps extends BaseProps {
  editable: true;
  onCommitItemUpdate: NonNullable<BaseProps["onCommitItemUpdate"]>;
}

interface DeleteableProps extends BaseProps {
  deleteable: true;
  onCommitItemUpdate: NonNullable<BaseProps["onCommitItemUpdate"]>;
}

interface StaticProps extends BaseProps {
  editable?: false;
  canCreateNew?: false;
  onCommitItemUpdate?: undefined;
}

type EditableListItemProps = EditableProps | DeleteableProps | StaticProps;

export const EditableListItem = ({
  item,
  editable = true,
  deleteable = true,
  renderControls = true,
  onCommitItemUpdate: onUpdateItem,
}: EditableListItemProps) => {
  const handleSelectItem = () => {
    onUpdateItem?.("update", { ...item, state: "editing" });
  };

  const handleDeleteItem = (e: React.MouseEvent) => {
    e.stopPropagation();
    onUpdateItem?.("delete", item);
  };

  return (
    <Container>
      {item.state === "saved" && (
        <SavedItem
          editable={editable}
          onClick={editable ? handleSelectItem : undefined}
        >
          <span>{item.content}</span>
          {deleteable && (
            <div className={"item-hover-controls"}>
              <ActionIcon
                size="sm"
                color="red"
                variant="filled"
                onClick={handleDeleteItem}
              >
                <X size={14} />
              </ActionIcon>
            </div>
          )}
        </SavedItem>
      )}

      {item.state === "editing" && (
        <ItemEditor
          initialItem={item}
          renderControls={renderControls}
          onUpdateItem={(nextItem) =>
            nextItem.content.trim() === ""
              ? onUpdateItem?.("delete", nextItem)
              : onUpdateItem?.("commit", nextItem)
          }
          onCancel={() =>
            item.content.trim() === ""
              ? onUpdateItem?.("delete", item)
              : onUpdateItem?.("update", { ...item, state: "saved" })
          }
        />
      )}
    </Container>
  );
};

interface ItemEditorProps {
  initialItem: EditableItem;
  renderControls: boolean;
  onUpdateItem: (item: EditableItem) => void;
  onCancel: (item: EditableItem) => void;
}

export const ItemEditor = ({
  initialItem,
  renderControls,
  onUpdateItem,
  onCancel,
}: ItemEditorProps) => {
  const inputElementRef = useRef<HTMLTextAreaElement>(null);
  const [itemContent, setItemContent] = useState(initialItem.content);

  /**
   * Handle special keys
   * - enter:       submit
   * - shift+enter: newline
   * - esc:         cancel
   */
  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      if (e.shiftKey) setItemContent(e.currentTarget.value + "\n");
      else handleCommit();
    }
    if (e.key === "Escape") {
      e.stopPropagation();
      onCancel(initialItem);
    }
  };

  /**
   * Cancel editing when clicking away from an active item
   */
  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    !renderControls && onCancel(initialItem);
  };

  /**
   * Move cursor to end of text when element is focused for the first time
   */
  const handleFocus = () => {
    setTimeout(() => inputElementRef.current?.setSelectionRange(-1, -1), 0);
  };

  const handleCommit = () => {
    onUpdateItem({
      id: initialItem.id,
      content: itemContent,
      state: "saved",
    });
  };

  return (
    <div>
      <Textarea
        ref={inputElementRef}
        id="newItem"
        name="item"
        autoFocus
        required
        value={itemContent}
        onChange={(e) => setItemContent(e.currentTarget.value)}
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />

      {renderControls && (
        <Group position="right" style={{ marginTop: 10 }}>
          <Button compact color="red" onClick={() => onCancel(initialItem)}>
            Cancel
          </Button>

          <Button
            compact
            disabled={
              itemContent === initialItem.content || itemContent.trim() === ""
            }
            onClick={handleCommit}
          >
            Save
          </Button>
        </Group>
      )}
    </div>
  );
};

/**
 * Transforms an array of type `string[]` into an EditableItem array for use in <EditableListItem />
 */
export const makeEditableItemsArray = (input: string[]): EditableItem[] =>
  input.map((content, i) => ({
    id: `item-${i}`,
    content,
    state: "saved",
  }));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const extractErrorMessage = (error: any): string =>
  error instanceof Error && error.message
    ? error.message
    : "Unknown error, could not submit successfully";
