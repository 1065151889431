import * as Yup from "yup";
import toast from "src/libs/toast";
import {
  ActionType,
  ScheduleActivityData,
  ActivityTemplate,
} from "src/graphql";
import { SelectOption } from "src/types";
import { ActionInfo } from "../../../util";
import { ySelectOptionSchema } from "src/utils";
import { Formik, FormikProps } from "formik";
import { FormikSelect } from "src/components";
import { ActivityTemplatePreview } from "../ActivityTemplatePreview";
import { useRef } from "react";
import { SubFormCommonProps } from "../AddActionModal";
import log from "loglevel";
import { Stack } from "@mantine/core";

type ScheduleActivityActionFormProps = SubFormCommonProps & {
  templateOptions: SelectOption<string>[];
  templatesById: Record<string, ActivityTemplate>;
};

export const ScheduleActivityActionForm = ({
  templateOptions,
  templatesById,
  innerRef,
  node,
  onCreateAction,
  onDirtyStateChange,
  onValidStateChange,
}: ScheduleActivityActionFormProps) => {
  const dirtyStateRef = useRef(false);
  const validStateRef = useRef(false);

  const initialValues: WrappedFormValues = {
    activityTemplateId: null,
  };

  return (
    <Formik
      innerRef={innerRef as React.RefObject<FormikProps<WrappedFormValues>>}
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={(formValues, { setSubmitting }) => {
        if (!node) return; // nothing renders without a node selected
        try {
          setSubmitting(true);
          const actionInfo = parseFormValues(formValues);
          onCreateAction(node, actionInfo);
          // eslint-disable-next-line
        } catch (err: any) {
          log.error(err.message);
          setSubmitting(false);
          toast.error("Failed to add action; please try again");
        }
      }}
    >
      {({ values, dirty, isValid }) => {
        if (onDirtyStateChange && dirty !== dirtyStateRef.current) {
          dirtyStateRef.current = dirty;
          requestAnimationFrame(() => onDirtyStateChange(dirty));
        }
        if (onValidStateChange && isValid !== validStateRef.current) {
          validStateRef.current = isValid;
          requestAnimationFrame(() => onValidStateChange(isValid));
        }

        return (
          <Stack mt="0.75em" spacing="1em">
            <FormikSelect
              name="activityTemplateId"
              label="Activity Template"
              options={templateOptions}
              creatable
              onCreate={(query) => ({ label: query, value: `CREATE:${query}` })}
            />

            {values.activityTemplateId && (
              <div>
                <ActivityTemplatePreview
                  activityTemplate={
                    templatesById[values.activityTemplateId.value]
                  }
                />
              </div>
            )}
          </Stack>
        );
      }}
    </Formik>
  );
};

type WrappedFormValues = Omit<ScheduleActivityData, "activityTemplateId"> & {
  activityTemplateId: SelectOption<string> | null;
};

const parseFormValues = (formValues: WrappedFormValues): ActionInfo => {
  if (!formValues.activityTemplateId) {
    throw new Error("Missing Activity Template ID - check schema validator");
  }

  return {
    actionType: ActionType.ScheduleActivityData,
    actionData: {
      activityTemplateId: formValues.activityTemplateId.value,
    },
  };
};

const ValidationSchema = Yup.object({
  activityTemplateId: ySelectOptionSchema(Yup.string().required()).required(),
});
