import { gql } from "@apollo/client";
import { NoteFields } from "./types";

export const CREATE_NOTE = gql`
  mutation CreateNote($input: CreateNoteInput!) {
    createNote(input: $input) {
      success
      message
      data {
        ${NoteFields}
      }
    }
  }
`;

export const UPDATE_NOTE = gql`
  mutation UpdateNote($input: UpdateNoteInput!) {
    updateNote(input: $input) {
      success
      message
      data {
        ${NoteFields}
      }
    }
  }
`;
