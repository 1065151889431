import { Button } from "@mantine/core";

import {
  FormErrorNotice,
  ModalBody,
  ModalFooter,
  ModalSubHeader,
} from "src/components";
import { LockingReference } from "src/graphql";
import { LockingReferences } from "./LockingReferences";

type WarnModalContentProps = {
  lockedObject: string;
  activeReferences: LockingReference[];
  onConfirm: () => void;
  onCancel: () => void;
  isLoading?: boolean;
  errorMessage?: string;
};

export const WarnModalContent = ({
  lockedObject,
  activeReferences,
  onConfirm,
  onCancel,
  isLoading = false,
  errorMessage = "",
}: WarnModalContentProps) => {
  return (
    <>
      <ModalSubHeader>
        Warning! Updating this {lockedObject} will impact the following
        services:
      </ModalSubHeader>

      <ModalBody pt="30px">
        <LockingReferences activeReferences={activeReferences} />
        {errorMessage && (
          <div style={{ marginTop: 15 }}>
            <FormErrorNotice errorMessage={errorMessage} />
          </div>
        )}
      </ModalBody>

      <ModalFooter>
        <Button
          color="red"
          variant="outline"
          onClick={onCancel}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button onClick={onConfirm} loading={isLoading}>
          Confirm
        </Button>
      </ModalFooter>
    </>
  );
};
