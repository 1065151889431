import { memo } from "react";
import { Position } from "react-flow-renderer";
import styled from "styled-components";
import { useFlowBuilderContext } from "../template-editor/context";
import { StyledEdgeHandle } from "./StyledEdgeHandle";
import { useCallRoutingEditorContext } from "src/pages/organization/tabs/OrganizationCallRoutingTab/CallRoutingEditor/CallRoutingEditorContext";

const START_NODE_HEIGHT = 31;
const ARROW_BODY_WIDTH = 50;
const ARROW_POINT_WIDTH = START_NODE_HEIGHT / 2;
export const START_NODE_WIDTH = ARROW_BODY_WIDTH + ARROW_POINT_WIDTH;

const ArrowBody = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--DANGER);
  color: white;
  font-size: 14px;
  padding: 0px 10px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  width: ${ARROW_BODY_WIDTH}px;
  height: ${START_NODE_HEIGHT}px;
`;

const ArrowPoint = styled.div`
  position: absolute;
  right: -${ARROW_POINT_WIDTH - 1}px;
  top: 0;
  height: 100%;
  border-top: ${ARROW_POINT_WIDTH}px solid transparent;
  border-bottom: ${ARROW_POINT_WIDTH}px solid transparent;
  border-left: ${ARROW_POINT_WIDTH}px solid var(--DANGER);
`;

export const StartFlowNode = memo(() => {
  const { readOnly: flowReadOnly } = useFlowBuilderContext();
  const { readOnly: routingReadOnly } = useCallRoutingEditorContext();

  // undefined if rendered in Snapshot Editor context (useFlowBuilderContext undefined)
  let readOnly;
  if (flowReadOnly === undefined && routingReadOnly === undefined) {
    readOnly = true;
  } else {
    readOnly = flowReadOnly || routingReadOnly;
  }

  return (
    <div
      className={readOnly ? "nodrag" : undefined}
      style={{ position: "relative", cursor: readOnly ? "default" : "inherit" }}
    >
      <ArrowBody>Start</ArrowBody>
      <ArrowPoint>
        <StyledEdgeHandle
          type="source"
          position={Position.Right}
          isConnectable={!readOnly}
        />
      </ArrowPoint>
    </div>
  );
});
