import { gql } from "@apollo/client";

export const GET_ACL_FOR_OBJECT_TYPE = gql`
  query GetACLForObjectType($organizationId: ID!, $objectType: ObjectType!) {
    getACLForObjectType(
      organizationId: $organizationId
      objectType: $objectType
    ) {
      data {
        name
        objectId
        accessType
        subOrgs {
          orgId
          name
          accessType
        }
      }
    }
  }
`;

export const GET_SUB_ORGS_BY_PARENT_ID = gql`
  query GetSubOrgsByParentId($parentId: ID!) {
    getSubOrgsByParentId(organizationId: $parentId) {
      success
      message
      data {
        _id
        title
        createdAt
        updatedAt
      }
    }
  }
`;
