import { Call } from "@twilio/voice-sdk";
import * as Yup from "yup";
import {
  User,
  Member,
  MemberIdAndName,
  ActivityType,
  Organization,
  FilterCondition,
} from "./graphql";
import { SelectItem } from "@mantine/core";

export type AuthResponse = {
  message: string;
  success: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
};

export type ClientRequestResponse = {
  success: boolean;
  token: string;
  messages: string[];
};

export type SomeUserValues = Partial<User>;

export interface ITabs {
  id: number;
  title: string;
  disabled: boolean;
}
export interface IDomains {
  id: string;
  domains: string[];
}

export interface DomainBar {
  label?: string;
  name: string;
  "Average Score"?: number;
  "Member Score": number;
}
export interface GqlDomain {
  name: string;
  memberScore: number;
}

export interface ActivityQuestion {
  questionId: string | null;
  questionText: string;
  response: string;
}

export interface Domain {
  domainName: string;
  strengthScore: number;
  engagementScore: number;
  ActivityQuestions: ActivityQuestion[];
}
export interface MemberSnapShot {
  snapshot: GqlDomain[];
  engagementSum: number;
  strengthSum: number;
  domains: Domain[];
}

export interface Group {
  _id: string;
  title: string;
}

export interface ActivityTemplate {
  _id: string;
  title: string;
  formId: string;
  organization: string;
  description?: string;
  activityType: ActivityType;
}

export type SelectOption<T> = {
  value: T;
  label: string;
};
export type isCallIncomingType = {
  isIncoming: boolean;
  callerNumber: string;
  members?: MemberIdAndName[];
};

type TwilioWidget = {
  members?: MemberInfo[];
  phoneNumber?: string;
  expanded: boolean;
  tab: string;
  setPhoneNumber: (phoneNumber: undefined | string) => void;
  expand: (isWidgetOpen: boolean) => void;
  setTab: (widgetTab: string) => void;
};

export type IncomingCall = {
  conferenceFriendlyName: string;
  phoneCallId: string;
  twilioCall: Call;
  members?: MemberInfo[];
  phone: string;
  organization: Organization;
};

export type MemberInfo = {
  _id: Member["_id"];
  organizationId: Member["contactInfo"]["organizations"][0];
  firstName: Member["firstName"];
  lastName: Member["lastName"];
  nickName: Member["nickName"];
  phone: Member["contactInfo"]["phone"];
};
export type TwilioContextType = {
  call?: Call;
  getAccessToken: () => void;
  endOutgoingCall: () => void;
  endIncomingCall: () => void;
  acceptIncomingCall: () => void;
  declineIncomingCall: () => void;
  makeOutgoingCall: () => Promise<void>;
  lastCallOrgId: string | undefined;
  incomingCall: undefined | IncomingCall;
  isCallIncoming: boolean;
  isCallInProgress: boolean;
  isSettingDevice: boolean;
  isIncomingCallInProgress: boolean;
  widget: TwilioWidget;
  micState?: PermissionState;
  conferenceFriendlyName: string;
};

export const CustomIncomingCallParametersSchema = Yup.object({
  organizationId: Yup.string().required(),
  phoneCallId: Yup.string().required(),
});

export type CustomIncomingCallParameters = Yup.InferType<
  typeof CustomIncomingCallParametersSchema
>;

export interface Dictionary {
  [key: string]: string;
}

export interface MessageState {
  sid: string;
  author: string;
  body: string;
  timestamp: string;
  dateUpdated: string;
  type: string;
  participantSid: string;
}

export interface Message {
  state: MessageState;
}

export type Optional<T, K extends keyof T> = Omit<T, K> & Partial<T>;

export type RequiredKeys<T, K extends keyof T> = Exclude<T, K> &
  Required<Pick<T, K>>;

export enum ActivityFormForKind {
  Members = "Members",
  AllMembersAssignedToUser = "AllMembersAssignedToUser",
  Group = "Group",
  EntireOrganization = "EntireOrganization",
}

export type FilterType = {
  condition: FilterCondition;
  filter: string;
  filterValue: string;
};

export type FilterOption = {
  label: string;
  value: string;
} & (
  | {
      type: "number" | "date";
    }
  | {
      type: "select";
      options: Array<SelectItem>;
    }
);

export const FormContext = {
  Template: "Template",
  Item: "Item",
} as const;

export type FormContextType = typeof FormContext[keyof typeof FormContext];
