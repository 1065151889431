import { Grid, Title, TextInput } from "@mantine/core";
import { useFormikContext, Field } from "formik";
import { MemberProfileSettings } from "src/graphql";
import type { MemberFormValues } from "../MemberFormFormik";

interface BasicInformationProps {
  memberProfileSettings: MemberProfileSettings;
}

export const BasicMemberInfoFormSection = ({
  memberProfileSettings,
}: BasicInformationProps) => {
  const { errors, touched } = useFormikContext<MemberFormValues>();
  return (
    <>
      <Title order={2} style={{ marginBottom: 20 }}>
        Basic Info
      </Title>
      <Grid gutter="md">
        <Grid.Col md={4} sm={6}>
          <Field
            as={TextInput}
            label="First Name"
            name="firstName"
            type="text"
            placeholder="Enter First Name"
            required
            variant="filled"
            radius="md"
            error={touched.firstName && errors.firstName}
          />
        </Grid.Col>
        <Grid.Col md={4} sm={6}>
          <Field
            as={TextInput}
            label="Last Name"
            name="lastName"
            type="text"
            placeholder="Enter Last Name"
            required
            variant="filled"
            radius="md"
            error={touched.lastName && errors.lastName}
          />
        </Grid.Col>
        {!memberProfileSettings.memberPhoneticHidden && (
          <Grid.Col md={4} sm={6}>
            <Field
              as={TextInput}
              label="Name Pronunciation"
              name="phonetic"
              type="text"
              placeholder="Enter Name Pronunciation"
              variant="filled"
              radius="md"
              error={touched.phonetic && errors.phonetic}
            />
          </Grid.Col>
        )}
        <Grid.Col md={4} sm={6}>
          <Field
            as={TextInput}
            label="Phone"
            name="contactInfo.phone"
            type="tel"
            placeholder="Enter Phone"
            required
            variant="filled"
            radius="md"
            error={touched.contactInfo?.phone && errors.contactInfo?.phone}
          />
        </Grid.Col>
      </Grid>
      <Grid gutter="md" style={{ marginTop: 20 }}>
        <Grid.Col md={6}>
          <Field
            as={TextInput}
            label="Email"
            name="contactInfo.email"
            type="email"
            placeholder="Enter Email"
            variant="filled"
            radius="md"
            error={touched?.contactInfo?.email && errors.contactInfo?.email}
          />
        </Grid.Col>
      </Grid>
    </>
  );
};
