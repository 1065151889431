import { DateInput } from "@mantine/dates";
import { useField } from "formik";
import { useMemo } from "react";
import {
  extractWrapperProps,
  FormikInputBaseProps,
  FormikInputWrapper,
} from "./FormikInputWrapper";
import dayjs from "dayjs";

type FormikDateInputProps = FormikInputBaseProps<string> & {
  clearable?: boolean;
  /** Dayjs format to display input value, "MMMM D, YYYY" by default  */
  inputPreviewFormat?: string;
  // The format used for storing the date in the formik field, "YYYY-MM-DD" by default
  valueFormat?: string;
  onChangeOverride?: (value: string | undefined) => void;
};

export const FormikDateInput = (props: FormikDateInputProps) => {
  const {
    clearable = true,
    className,
    style,
    disabled,
    onChangeOverride,
    inputPreviewFormat,
    valueFormat = "YYYY-MM-DD",
    defaultValue,
    ...rest
  } = props;

  const [field, , helpers] = useField(rest);

  const valueAsDate = useMemo(
    () => (field.value ? dayjs(field.value).toDate() : undefined),
    [field.value]
  );

  const onChange = onChangeOverride
    ? (date: Date | null) =>
        onChangeOverride(date ? dayjs(date).format(valueFormat) : "")
    : (date: Date | null) => {
        helpers.setValue(date ? dayjs(date).format(valueFormat) : "");
      };
  return (
    <FormikInputWrapper {...extractWrapperProps(props)}>
      <DateInput
        {...field}
        clearable={clearable}
        valueFormat={inputPreviewFormat}
        value={valueAsDate}
        style={style}
        placeholder={rest.placeholder}
        disabled={disabled}
        className={className}
        onChange={onChange}
        popoverProps={{ withinPortal: true }}
        allowDeselect={false}
      />
    </FormikInputWrapper>
  );
};
