import React, { ComponentType } from "react";
import { NodeProps } from "react-flow-renderer";
import { FlowEditorNodeType } from "../util";
import {
  TemplateStepFlowNode,
  STEP_NODE_TITLE_HEIGHT,
} from "./TemplateStepFlowNode";
import { SnapshotStepFlowNode } from "./SnapshotStepFlowNode";
import { TemplateAnswerFlowNode } from "./TemplateAnswerFlowNode";
import { SnapshotAnswerFlowNode } from "./SnapshotAnswerFlowNode";
import { StartFlowNode } from "./StartFlowNode";

export const templateEditorNodeTypes: Record<
  FlowEditorNodeType,
  React.MemoExoticComponent<ComponentType<NodeProps>>
> = {
  stepFlowNode: TemplateStepFlowNode,
  answerFlowNode: TemplateAnswerFlowNode,
  startFlowNode: StartFlowNode,
};

export const snapshotEditorNodeTypes: Record<
  FlowEditorNodeType,
  React.MemoExoticComponent<ComponentType<NodeProps>>
> = {
  stepFlowNode: SnapshotStepFlowNode,
  answerFlowNode: SnapshotAnswerFlowNode,
  startFlowNode: StartFlowNode,
};

export {
  TemplateStepFlowNode,
  TemplateAnswerFlowNode,
  SnapshotStepFlowNode,
  SnapshotAnswerFlowNode,
  STEP_NODE_TITLE_HEIGHT,
};
