import { useMutation, useQuery } from "@apollo/client";
import { GET_GOALS, GET_USER_GOALS } from "./queries";
import {
  CREATE_GOAL,
  UPDATE_GOAL_PROGRESS,
  UPDATE_GOAL,
  DELETE_GOAL,
  UPDATE_GOAL_STATUS,
} from "./mutations";
import { GET_ACTIVITIES } from "../Activity/queries";
import {
  CreateGoalResponse,
  GetGoalResponse,
  GetGoalsResponse,
  MutationCreateGoalArgs,
  MutationDeleteGoalArgs,
  MutationUpdateGoalArgs,
  MutationUpdateGoalProgressArgs,
  MutationUpdateGoalStatusArgs,
  QueryGoalsArgs,
} from "../schemaTypes";
import log from "loglevel";

export const useQueryGoals = (
  memberId: string,
  episodeOfCareId: string | undefined
) =>
  useQuery<{
    goals: GetGoalsResponse;
  }>(GET_GOALS, { variables: { memberId, episodeOfCareId } });

export const useQueryUserGoals = (
  organizationId: string,
  userId: string,
  completed: boolean,
  after: string | undefined,
  before: string | undefined
) =>
  useQuery<{
    userGoals: GetGoalsResponse;
  }>(GET_USER_GOALS, {
    variables: { input: { organizationId, userId, completed, before, after } },
    skip: !userId,
  });

export const useMutationCreateGoal = (
  memberId: string,
  organization?: string
) =>
  useMutation<
    {
      createGoal: CreateGoalResponse;
    },
    MutationCreateGoalArgs
  >(CREATE_GOAL, {
    update: (cache, result) => {
      try {
        // update goals query cache
        const newGoals = result.data?.createGoal.data;
        if (newGoals) {
          newGoals.forEach((newGoal) =>
            cache.updateQuery<{ goals: GetGoalsResponse }, QueryGoalsArgs>(
              {
                query: GET_GOALS,
                variables: { memberId: newGoal.memberId },
              },
              (cached) =>
                cached
                  ? {
                      goals: {
                        ...cached.goals,
                        data: [...(cached.goals.data ?? []), newGoal],
                      },
                    }
                  : null
            )
          );
        }
        // TODO: update activities query cache rather than refetch
      } catch (err) {
        log.error(err);
        throw err;
      }
    },
    refetchQueries: [GET_ACTIVITIES],
  });

export const useMutationUpdateGoalProgress = () =>
  useMutation<
    { updateGoalProgress: GetGoalResponse },
    MutationUpdateGoalProgressArgs
  >(UPDATE_GOAL_PROGRESS);

export const useMutationUpdateGoal = () =>
  useMutation<{ updateGoal: GetGoalResponse }, MutationUpdateGoalArgs>(
    UPDATE_GOAL
  );

export const useMutationDeleteGoal = () =>
  useMutation<
    {
      deleteGoal: GetGoalResponse;
    },
    MutationDeleteGoalArgs
  >(DELETE_GOAL, {
    update: (cache, result) => {
      try {
        // update goals query cache
        const deletedGoal = result.data?.deleteGoal.data;
        if (deletedGoal) {
          cache.updateQuery<{ goals: GetGoalsResponse }, QueryGoalsArgs>(
            {
              query: GET_GOALS,
              variables: { memberId: deletedGoal.memberId },
            },
            (cached) =>
              cached
                ? {
                    goals: {
                      ...cached.goals,
                      data: cached.goals.data?.filter(
                        (goal) => goal._id !== deletedGoal._id
                      ),
                    },
                  }
                : null
          );
        }
        // TODO: update activities query cache rather than refetch
      } catch (err) {
        log.error(err);
        throw err;
      }
    },
  });

export const useMutationUpdateGoalStatus = () =>
  useMutation<
    {
      updateGoalStatus: GetGoalResponse;
    },
    MutationUpdateGoalStatusArgs
  >(UPDATE_GOAL_STATUS, { refetchQueries: [GET_GOALS] });
