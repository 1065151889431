import { useMutation, useQuery } from "@apollo/client";
import {
  CarePathwayTemplatesResponse,
  QueryCarePathwayTemplatesByOrganizationIdArgs,
  CarePathwayTemplateResponse,
  QueryCarePathwayTemplateByIdArgs,
  MutationCreateOrUpdateCarePathwayTemplateArgs,
  MutationMakeCarePathwayTemplateDefaultArgs,
  MutationStartCarePathwayTemplateForMemberArgs,
  CarePathwayResponse,
  MutationDeleteCarePathwayTemplateArgs,
  QueryCarePathwayTemplatesByMemberIdArgs,
  QueryCarePathwayTemplateDataByMemberIdArgs,
  MutationCopyCarePathwayTemplateArgs,
  MutationRetireCarePathwayTemplateArgs,
  MutationRemoveCarePathwayTemplateFromDefaultArgs,
  MutationUpdateCarePathwayStatusArgs,
} from "../schemaTypes";
import {
  COPY_CARE_PATHWAY_TEMPLATE,
  CREATE_OR_UPDATE_CAREPATHWAY_TEMPLATE,
  DELETE_CARE_PATHWAY_TEMPLATE,
  MAKE_CARE_PATHWAY_TEMPLATE_DEFUALT,
  REMOVE_CARE_PATHWAY_TEMPLATE_FROM_DEFUALT,
  RETIRE_CARE_PATHWAY_TEMPLATE,
  START_CARE_PATHWAY_TEMPLATE_FOR_MEMBER,
  UPDATE_CARE_PATHWAY_STATUS,
} from "./mutations";
import {
  GET_CAREPATHWAY_TEMPLATES,
  GET_CAREPATHWAY_TEMPLATES_BY_MEMBER_ID,
  GET_CAREPATHWAY_TEMPLATE_BY_ID,
  GET_CAREPATHWAY_TEMPLATE_DATA_BY_MEMBER_ID,
} from "./queries";
import { CARE_PATHWAYS_BY_MEMBER_ID } from "../CarePathway/queries";
import { GET_ACTIVITIES } from "../Activity/queries";
import { GET_BUILDER_GRAPH } from "../Builder/queries";
import { GET_ACTIVITY_TEMPLATES } from "../ActivityTemplate";

export const useQueryCarePathwayTemplates = (organizationId: string) =>
  useQuery<
    { carePathwayTemplatesByOrganizationId: CarePathwayTemplatesResponse },
    QueryCarePathwayTemplatesByOrganizationIdArgs
  >(GET_CAREPATHWAY_TEMPLATES, {
    variables: {
      organizationId,
    },
  });

export const useQueryMemberCarePathways = (memberId: string) =>
  useQuery<
    { carePathwayTemplatesByMemberId: CarePathwayTemplatesResponse },
    QueryCarePathwayTemplatesByMemberIdArgs
  >(GET_CAREPATHWAY_TEMPLATES_BY_MEMBER_ID, {
    variables: {
      memberId,
    },
  });

type QueryCarePathwayTemplateByIdOptions = {
  organizationId: string;
  carePathwayTemplateId: string;
  carePathwayId: string | undefined;
  skip?: boolean;
  onboardingToken?: string;
};

export const useQueryCarePathwayTemplateById = ({
  carePathwayTemplateId,
  carePathwayId,
  skip = false,
  onboardingToken,
  organizationId,
}: QueryCarePathwayTemplateByIdOptions) =>
  useQuery<
    { carePathwayTemplateById: CarePathwayTemplateResponse },
    QueryCarePathwayTemplateByIdArgs
  >(GET_CAREPATHWAY_TEMPLATE_BY_ID, {
    variables: {
      organizationId,
      carePathwayTemplateId,
      carePathwayId,
    },
    nextFetchPolicy: "no-cache",
    skip,
    context: onboardingToken
      ? { headers: { "Pear-Onboarding-Survey": onboardingToken } }
      : {},
  });

export const useQueryMemberCarePathwayTemplateData = (
  carePathwayId: string,
  memberId: string,
  skip?: boolean
) =>
  useQuery<
    { carePathwayTemplateDataByMemberId: CarePathwayResponse },
    QueryCarePathwayTemplateDataByMemberIdArgs
  >(GET_CAREPATHWAY_TEMPLATE_DATA_BY_MEMBER_ID, {
    variables: {
      carePathwayId,
      memberId,
    },
    skip: skip,
  });

export const useMutationCreateOrUpdateCarePathwayTemplate = (
  organizationId: string
) =>
  useMutation<
    { createOrUpdateCarePathwayTemplate: CarePathwayTemplateResponse },
    MutationCreateOrUpdateCarePathwayTemplateArgs
  >(CREATE_OR_UPDATE_CAREPATHWAY_TEMPLATE, {
    refetchQueries: [
      GET_BUILDER_GRAPH,
      GET_ACTIVITY_TEMPLATES,
      { query: GET_CAREPATHWAY_TEMPLATES, variables: { organizationId } },
    ],
  });

export const useMutationMakeCarePathwayTemplateDefault = (
  organizationId: string
) =>
  useMutation<
    { makeCarePathwayTemplateDefault: CarePathwayTemplateResponse },
    MutationMakeCarePathwayTemplateDefaultArgs
  >(MAKE_CARE_PATHWAY_TEMPLATE_DEFUALT, {
    refetchQueries: [
      { query: GET_CAREPATHWAY_TEMPLATES, variables: { organizationId } },
    ],
  });

export const useMutationRemoveCarePathwayTemplateFromDefault = (
  organizationId: string
) =>
  useMutation<
    { removeCarePathwayTemplateFromDefault: CarePathwayTemplateResponse },
    MutationRemoveCarePathwayTemplateFromDefaultArgs
  >(REMOVE_CARE_PATHWAY_TEMPLATE_FROM_DEFUALT, {
    refetchQueries: [
      { query: GET_CAREPATHWAY_TEMPLATES, variables: { organizationId } },
    ],
  });

export const useMutationStartCarePathwayTemplateForMember = () =>
  useMutation<
    { startCarePathwayTemplateForMember: CarePathwayResponse },
    MutationStartCarePathwayTemplateForMemberArgs
  >(START_CARE_PATHWAY_TEMPLATE_FOR_MEMBER, {
    refetchQueries: (result) => [
      {
        query: GET_CAREPATHWAY_TEMPLATES_BY_MEMBER_ID,
        variables: {
          memberId:
            result.data?.startCarePathwayTemplateForMember.data?.memberId,
        },
      },
      {
        query: CARE_PATHWAYS_BY_MEMBER_ID,
        variables: {
          memberId:
            result.data?.startCarePathwayTemplateForMember.data?.memberId,
        },
      },
      GET_ACTIVITIES,
    ],
  });

export const useMutationDeleteCarePathwayTemplate = (organizationId: string) =>
  useMutation<
    { deleteCarePathwayTemplate: CarePathwayTemplateResponse },
    MutationDeleteCarePathwayTemplateArgs
  >(DELETE_CARE_PATHWAY_TEMPLATE, {
    refetchQueries: [
      { query: GET_CAREPATHWAY_TEMPLATES, variables: { organizationId } },
    ],
  });

export const useMutationCopyCarePathwayTemplate = (organizationId: string) =>
  useMutation<
    { copyCarePathwayTemplate: CarePathwayTemplateResponse },
    MutationCopyCarePathwayTemplateArgs
  >(COPY_CARE_PATHWAY_TEMPLATE, {
    refetchQueries: [
      { query: GET_CAREPATHWAY_TEMPLATES, variables: { organizationId } },
    ],
  });

export const useMutationRetireCarePathwayTemplate = (organizationId: string) =>
  useMutation<
    { retireCarePathwayTemplate: CarePathwayTemplateResponse },
    MutationRetireCarePathwayTemplateArgs
  >(RETIRE_CARE_PATHWAY_TEMPLATE, {
    refetchQueries: [
      {
        query: GET_CAREPATHWAY_TEMPLATES,
        variables: { organizationId },
      },
    ],
  });

export const useMutationUpdateCarePathwayStatus = () =>
  useMutation<
    { updateCarePathwayStatus: CarePathwayResponse },
    MutationUpdateCarePathwayStatusArgs
  >(UPDATE_CARE_PATHWAY_STATUS, {
    refetchQueries: (result) => [
      {
        query: CARE_PATHWAYS_BY_MEMBER_ID,
        variables: {
          memberId: result.data?.updateCarePathwayStatus.data?.memberId,
        },
      },
    ],
  });
