import {
  Box,
  ColSpan,
  Grid,
  Group,
  Input as MantineInput,
} from "@mantine/core";
import { ChangeEvent } from "react";
import { Search } from "react-feather";
import styled from "@emotion/styled";
import { SectionHeader } from "../display";

export const Card = styled("div")`
  padding: 8px;
  margin-bottom: 4px;
  border: 1px solid lightgrey;
  border-radius: 6px;
`;

interface TableHeaderProps {
  placeholder?: string;
  actionButtons?: React.ReactNode[];
  filterText?: string;
  setFilterText?: (nextText: string) => void;
  searchColumn?: ColSpan;
  actionColumn?: ColSpan;
  noSearch?: boolean;
}

export const TableHeader = ({
  placeholder,
  actionButtons,
  filterText,
  setFilterText,
  searchColumn = 5,
  actionColumn = 7,
  noSearch,
}: TableHeaderProps) => {
  const filterInputValueProp = filterText ? { value: filterText } : {};
  return (
    <SectionHeader noGap>
      <Grid className="px-2">
        <Grid.Col md={searchColumn}>
          <Box
            style={{ alignItems: "center", display: "flex", minHeight: "36px" }}
          >
            {!noSearch && (
              <MantineInput
                style={{ flex: 1 }}
                icon={<Search size={18} />}
                placeholder={placeholder}
                type="text"
                {...filterInputValueProp}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setFilterText?.(e.target.value)
                }
              />
            )}
          </Box>
        </Grid.Col>
        {actionButtons && (
          <Grid.Col span={actionColumn}>
            <Group align="center" position="right" h="100%">
              {actionButtons.map((button, i) => (
                <div key={i}>{button}</div>
              ))}
            </Group>
          </Grid.Col>
        )}
      </Grid>
    </SectionHeader>
  );
};
