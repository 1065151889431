import { gql } from "@apollo/client";

export const SUBMIT_ASSESSMENT = gql`
  mutation SubmitAssessment($input: SubmitAssessmentInput!) {
    submitAssessment(input: $input) {
      success
      message
      stepId
    }
  }
`;
