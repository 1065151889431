import { gql } from "@apollo/client";
import { NoteFields } from "./types";

export const GET_NOTES = gql`
  query GetNotes($input: GetNotesInput!) {
    notes(input: $input) {
      success
      message
      data {
        ${NoteFields}
      }
    }
  }
`;
