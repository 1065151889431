import { useEffect } from "react";
import { useFormikContext } from "formik";
import toast from "src/libs/toast";

// When rendered as a child of a Formik component, scrolls to & focuses the
// first field that has an error during form validation
export const AutoFocusErroredField = () => {
  const { errors, isSubmitting, isValidating } = useFormikContext();

  useEffect(() => {
    if (isSubmitting && !isValidating) {
      const selector = getNestedFirstErrorSelector(errors);

      if (selector) {
        const errorElement = document.querySelector<HTMLElement>(selector);

        if (errorElement) {
          errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
          errorElement.focus({ preventScroll: true });
          toast.error("Validation Error");
        }
      }
    }
  }, [errors, isSubmitting, isValidating]);

  return null;
};

// returns full (maybe nested) selector path to first validation error
const getNestedFirstErrorSelector = (errors: Record<string, unknown>) => {
  let entries = Object.entries(errors);
  if (!entries.length) return "";

  const fields: string[] = [entries[0][0]];

  while (typeof entries[0][1] !== "string") {
    entries = Object.entries(entries[0][1] as Record<string, unknown>);
    fields.push(entries[0][0]);
  }

  return `[name="${fields.join(".")}"]`;
};
