import { Formik, FormikProps } from "formik";
import log from "loglevel";
import { useRef } from "react";
import toast from "src/libs/toast";
import * as Yup from "yup";

import { FormikSelect } from "src/components";
import { ActionInfo } from "src/components/flow-graph/util";
import { ActionType, Group } from "src/graphql";
import { SelectOption } from "src/types";
import { ySelectOptionSchema } from "src/utils";
import { SubFormCommonProps } from "../AddActionModal";
import { Stack } from "@mantine/core";
import { PreviewContainer, PreviewHeader } from "../shared";

type AddOrRemoveGroupFormProps = SubFormCommonProps & {
  groupOptions: SelectOption<string>[];
  groupsById: Record<string, Group>;
  actionType: ActionType;
};

export const AddOrRemoveGroupForm = ({
  groupOptions,
  groupsById,
  innerRef,
  node,
  onCreateAction,
  onDirtyStateChange,
  onValidStateChange,
  actionType,
}: AddOrRemoveGroupFormProps) => {
  const dirtyStateRef = useRef(false);
  const validStateRef = useRef(false);

  const initialValues: WrappedFormValues = {
    groupId: null,
  };

  return (
    <Formik
      innerRef={innerRef as React.RefObject<FormikProps<WrappedFormValues>>}
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={(formValues, { setSubmitting }) => {
        if (!node) return; // nothing renders without a node selected
        try {
          setSubmitting(true);
          const actionInfo = parseFormValues(formValues, actionType);
          log.log("formSubmit", actionInfo, formValues);
          onCreateAction(node, actionInfo);
          // eslint-disable-next-line
        } catch (err: any) {
          log.error(err.message);
          setSubmitting(false);
          toast.error("Failed to add action; please try again");
        }
      }}
    >
      {({ values, dirty, isValid }) => {
        if (onDirtyStateChange && dirty !== dirtyStateRef.current) {
          dirtyStateRef.current = dirty;
          requestAnimationFrame(() => onDirtyStateChange(dirty));
        }
        if (onValidStateChange && isValid !== validStateRef.current) {
          validStateRef.current = isValid;
          requestAnimationFrame(() => onValidStateChange(isValid));
        }

        return (
          <Stack mt="0.75em" spacing="1em">
            <FormikSelect
              placeholder="Please select a group"
              name="groupId"
              label="Group"
              options={groupOptions}
            />

            {values.groupId && (
              <div>
                <PreviewContainer>
                  <PreviewHeader>Preview</PreviewHeader>
                  <div style={{ padding: 3 }}>
                    <div style={{ marginTop: "0.25em" }}>
                      {groupsById[values.groupId.value].title}
                    </div>
                  </div>
                </PreviewContainer>
              </div>
            )}
          </Stack>
        );
      }}
    </Formik>
  );
};

type WrappedFormValues = {
  groupId: SelectOption<string> | null;
};

const parseFormValues = (
  formValues: WrappedFormValues,
  actionType: ActionType
): ActionInfo => {
  if (!formValues.groupId) {
    throw new Error("Missing group ID - check schema validator");
  }

  return {
    actionType:
      actionType === "AddMemberToGroup"
        ? ActionType.AddMemberToGroup
        : ActionType.RemoveMemberFromGroup,
    actionData: {
      groupId: formValues.groupId.value,
    },
  };
};

const ValidationSchema = Yup.object({
  groupId: ySelectOptionSchema(Yup.string().required()).required(),
});
