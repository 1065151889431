import { Route, Switch } from "react-router-dom";
import { EditRecommendationView } from "./EditRecommendationView";
import { RecommendationsListView } from "./RecommendationsListView";

export const BASE_RECOMMENDATION_ID = "new-recommendation";
const RecommendationsRoute = () => {
  return (
    <Switch>
      <Route exact path="/recommendations">
        <RecommendationsListView />
      </Route>

      <Route path="/recommendations/:recommendationId">
        <EditRecommendationView />
      </Route>
    </Switch>
  );
};

export default RecommendationsRoute;
