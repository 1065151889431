import { gql } from "@apollo/client";

export const KEEPALIVE_PING = gql`
  mutation KeepalivePing($clientId: String!) {
    keepalivePing(clientId: $clientId) {
      success
      message
    }
  }
`;
