import { gql } from "@apollo/client";
import { textMessageThreadFields } from "./types";

export const DELTE_TEXT_MESSAGE_THREAD = gql`
  mutation DeleteTextMessageThread(
    $organizationId: String!
    $participantPhone: String!
  ) {
    deleteTextMessageThread(
      organizationId: $organizationId
      participantPhone: $participantPhone
    ) {
      success
      message
      data {
        ${textMessageThreadFields}
      }
    }
  }
`;

export const UPDATE_TEXT_MESSAGE_THREAD_READ = gql`
  mutation UpdateTextMessageThreadRead(
    $read: Boolean!
    $organizationId: String!
    $participantPhone: String!
  ) {
    updateTextMessageThreadRead(
      read: $read
      organizationId: $organizationId
      participantPhone: $participantPhone
    ) {
      success
      message
      data {
        ${textMessageThreadFields}
      }
    }
  }
`;
