import { Button } from "@mantine/core";
import toast from "src/libs/toast";

import { Modal, ModalBody, ModalFooter, ModalHeader } from "src/components";
import {
  Certification,
  useMutationCreateOrUpdateCertification,
  useQueryCertificationTemplates,
  useMutationUploadFile,
} from "src/graphql";
import { FormikProps } from "formik";
import { useRef, useState } from "react";
import { CertificationForm } from "./CertificationForm";

interface CertificationModalProps {
  modalIsOpen: boolean;
  closeModal: (() => void) | undefined;
  certification?: Certification;
  organizationId: string;
  userId: string;
  onViewFile?: (psFileId: string) => void;
  uploadedFileId?: string;
  setUploadedFileId?: (fileId: string) => void;
}

export type CertificationFormValues = {
  title: string;
  description?: string;
  isActive: boolean;
  fileId: string;
  certificationTemplateId: string;
  organizationId: string;
  userId: string;
};

export const CertificationModal = ({
  modalIsOpen,
  closeModal,
  certification,
  organizationId,
  userId,
  onViewFile,
  uploadedFileId,
  setUploadedFileId,
}: CertificationModalProps) => {
  const {
    data: certificationTemplatesResponse,
    loading: certificationTemplatesLoading,
  } = useQueryCertificationTemplates(organizationId);

  const [mutationUploadFile] = useMutationUploadFile();

  const [mutationCreateOrUpdateCertification] =
    useMutationCreateOrUpdateCertification();

  const [submissionPending, setSubmissionPending] = useState(false);

  const formikRef = useRef<FormikProps<Certification>>(null);

  const certificationTemplateOptions = !certificationTemplatesLoading
    ? certificationTemplatesResponse?.certificationTemplatesByOrganizationId?.data?.map(
        (template) => ({
          value: template._id,
          label: template.name,
        })
      )
    : [{ value: "noTemplate", label: "No Template" }];

  const handleClose = () => {
    formikRef.current?.resetForm();
    closeModal && closeModal();
  };

  const handleUploadFile = async (file: File, title: string) => {
    const { data } = await mutationUploadFile({
      variables: { input: { file, organizationId, title } },
    });
    if (!data?.uploadPSFile?.success) {
      toast.error(data?.uploadPSFile?.message ?? "Failed to upload file");
      return;
    }

    formikRef.current?.setFieldValue("fileId", data.uploadPSFile?.data?._id);
    setUploadedFileId && setUploadedFileId(data.uploadPSFile?.data?._id ?? "");
  };

  const handleSubmit = async (values: CertificationFormValues) => {
    try {
      setSubmissionPending(true);
      const res = await mutationCreateOrUpdateCertification({
        variables: {
          input: {
            ...values,
            _id: certification?._id,
            organizationId,
            userId,
          },
        },
      });
      if (!res.data?.createOrUpdateCertification?.success) throw new Error();
      toast.success(
        res.data?.createOrUpdateCertification?.message ?? "Success!"
      );
      handleClose();
    } catch (error) {
      toast.error("Failed! Unable to save changes.");
    } finally {
      setSubmissionPending(false);
    }
  };

  return (
    <Modal opened={modalIsOpen} onClose={handleClose}>
      <ModalHeader>
        {certification ? "Edit" : "Create"} Certification
      </ModalHeader>
      <ModalBody spacing="md">
        <CertificationForm
          handleSubmit={handleSubmit}
          innerRef={formikRef}
          certification={certification}
          templates={certificationTemplateOptions ?? []}
          organizationId={organizationId}
          userId={userId}
          closeModal={handleClose}
          modalIsOpen={modalIsOpen}
          handleUploadFile={handleUploadFile}
          uploadedFileId={uploadedFileId}
          onViewFile={onViewFile}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          color="red"
          variant="outline"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          onClick={formikRef.current?.submitForm}
          type="button"
          disabled={submissionPending}
          loading={submissionPending}
        >
          {certification ? "Update" : "Create"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
