import { useMantineTheme } from "@mantine/core";
import { Handle, HandleProps } from "react-flow-renderer";

type StyledEdgeHandleProps = HandleProps & {
  style?: React.CSSProperties;
  handleEdgeOffset?: number;
};

export const StyledEdgeHandle = ({
  style,
  handleEdgeOffset = 0,
  ...rest
}: StyledEdgeHandleProps) => {
  const theme = useMantineTheme();

  return (
    <Handle
      {...rest}
      style={{
        ...style,
        backgroundColor: theme.colors.dark[8],
        border: "3px solid rgba(255, 255, 255, 1)",
        height: 16,
        width: 16,
        cursor: "crosshair",
        right: rest.type === "source" ? -(8 + handleEdgeOffset) : undefined,
        left: rest.type === "target" ? -(8 + handleEdgeOffset) : undefined,
      }}
    />
  );
};
