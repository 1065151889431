import { LockingReferenceFields } from "../Common/types";
import { taggedEntityFields } from "../Tag/types";

export const DataPointTemplateFields = `
    _id
    createdAt
    updatedAt
    dataId
    title
    description
    dataType
    defaultOptions
    historicalOptions
    organizationId
    tags {
        ${taggedEntityFields}
    }
    lockingReferences {
        ${LockingReferenceFields}
    }
    accessType
`;
