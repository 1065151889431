import { PhoneCallFields } from "../Twilio/types";

export const voicemailFields = `
_id
sid
call{
    ${PhoneCallFields}
}
transcription
recordingSid
listenedTo
hidden
organizationId
createdAt
updatedAt`;
