import { Grid, Title, TextInput, Select } from "@mantine/core";
import { SelectOption } from "src/types";

type EHRDetailsSectionProps = {
  ehrIDTypeOptions: SelectOption<string>[];
};
export const EHRDetailsSection = ({
  ehrIDTypeOptions,
}: EHRDetailsSectionProps) => {
  return (
    <>
      <Title order={6} style={{ marginBottom: 20 }}>
        EHR Details
      </Title>
      <Grid gutter="md">
        <Grid.Col md={6}>
          <TextInput
            label="EHR ID"
            name="ehrID"
            placeholder="Enter EHR ID"
            variant="filled"
            radius="md"
          />
        </Grid.Col>
        <Grid.Col md={6}>
          <Select
            label="EHR ID Type"
            name="ehrIDType"
            data={ehrIDTypeOptions}
            placeholder="Select EHR ID Type"
            variant="filled"
            radius="md"
          />
        </Grid.Col>
      </Grid>
    </>
  );
};
