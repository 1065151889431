import React, { createRef, useMemo, useLayoutEffect, useState } from "react";
import { Edge, Node, NodeProps } from "react-flow-renderer";
import styled from "styled-components";
import { idKeyedArrToObj } from "src/utils";
import {
  TemplateAnswerFlowNode,
  TemplateStepFlowNode,
  SnapshotStepFlowNode,
  SnapshotAnswerFlowNode,
  STEP_NODE_TITLE_HEIGHT,
} from "./flow-nodes";
import * as CallRoutingNodeComponents from "../../pages/organization/tabs/OrganizationCallRoutingTab/CallRoutingEditor/nodes";
import {
  PearNodesData,
  StepNodeData,
  AnswerNodeData,
  FlowEditorNodeType,
} from "./util";
import { CallRoutingEditorNodeType } from "src/pages/organization/tabs/OrganizationCallRoutingTab/utils";
import { StartFlowNode } from "./flow-nodes/StartFlowNode";

// toggles debug for measurement container.
// When debug is on, container will show measuring nodes on screen,
// and will not report measured nodes to parent
const debug = false;
const childNodeGutterSize = 5;
const dummyNodeProps = {} as NodeProps;

const StyledContainer = styled.div`
  ${() => (!debug ? "visibility: hidden" : "")}
  pointer-events: none;
  position: absolute;
  top: 20%;
  left: 40%;
`;

type AllNodeTypes = FlowEditorNodeType | CallRoutingEditorNodeType;

export enum FlowNodeRenderContext {
  TemplateEditor,
  SnapshotEditor,
  CallRoutingEditor,
}

export type OrderedStepNodeTuple = [
  Node<StepNodeData>,
  ...Node<AnswerNodeData>[]
];
export type NodeTuplesToMeasure = {
  tuples: Node[][];
  isDropPlacement?: boolean;
  context: FlowNodeRenderContext;
};
type NodeMeasuringContainerProps = {
  nodesToMeasure: NodeTuplesToMeasure;
  onNodesMeasured: (nodesById: Record<string, Node<PearNodesData>>) => void;
};

// Pre-Renders a set of `OrderedStepNodeTuple`s (a step node and each of it's
// associated answer nodes), takes their measurements, and passes back up
// to parent to pass to react-flow with explicit sizing.
export const NodeMeasuringContainer = ({
  nodesToMeasure,
  onNodesMeasured,
}: NodeMeasuringContainerProps) => {
  const [parentNodesMeasured, setParentNodesMeasured] = useState(false);
  const [parentNodeWidths, setParentNodeWidths] = useState<
    Record<string, number>
  >({});

  // set up state
  const [parentNodes, refsById] = useMemo(
    () => [
      nodesToMeasure.tuples.flatMap((tuple) => tuple[0]),
      nodesToMeasure.tuples
        .flat()
        .reduce(
          (byId, node) => ({ ...byId, [node.id]: createRef<HTMLDivElement>() }),
          {} as Record<string, React.RefObject<HTMLDivElement>>
        ),
    ],
    [nodesToMeasure]
  );

  // on first render, set answer node widths to parent width so we can get the
  // real render height of their content
  useLayoutEffect(() => {
    if (parentNodesMeasured) return;

    const widthsById: Record<string, number> = {};
    parentNodes.forEach((node) => {
      widthsById[node.id] = refsById[node.id].current?.offsetWidth ?? 0;
    });

    setParentNodeWidths(widthsById);
    setParentNodesMeasured(true);
  }, [parentNodes, refsById, parentNodesMeasured]);

  // once child node widths have been matched to parent, measure nodes
  // and add positioning & sizing then report back to parent component
  useLayoutEffect(() => {
    if (!parentNodesMeasured) return;
    const measuredTuplesByParentId: Record<string, Node[]> = {};

    nodesToMeasure.tuples.forEach(([parentNode, ...childNodes]) => {
      const parentNodeWidth = parentNodeWidths[parentNode.id];
      let parentNodeHeight = refsById[parentNode.id].current?.offsetHeight ?? 0;
      let nextNodeOffset = parentNodeHeight;

      const measuredChildNodes = childNodes.map((node) => {
        const nextNode = { ...node };
        const nodeHeight = refsById[nextNode.id].current?.offsetHeight ?? 0;

        // position answer node
        nextNode.position = {
          x: childNodeGutterSize,
          y: nextNodeOffset,
        };

        // size answer node
        nextNode.style = {
          width: parentNodeWidth - childNodeGutterSize * 2,
        };

        // node + bottom gutter
        nextNodeOffset += nodeHeight + childNodeGutterSize;
        parentNodeHeight += nodeHeight + childNodeGutterSize;

        return nextNode;
      });

      // size parent node to calculated sum
      parentNode.style = { height: parentNodeHeight };

      if (nodesToMeasure.isDropPlacement) {
        // subtract half of width from drop location coordinate to center node on mouse pos
        parentNode.position.x -= parentNodeWidth / 2;
        // subtract half of title height from drop location to center top of node with center
        // of title bar (bit of a magic number here but... at least they're tied together?)
        parentNode.position.y -= STEP_NODE_TITLE_HEIGHT / 2;
      }

      measuredTuplesByParentId[parentNode.id] = [
        parentNode,
        ...measuredChildNodes,
      ];
    });

    if (!debug) {
      // awkwardly the insertion order matters here for the step -> answer z-index
      // behavior of `react-flow`, so make sure steps and their answers are inserted
      // in clusters of associated tuples
      onNodesMeasured(
        idKeyedArrToObj(Object.values(measuredTuplesByParentId).flat(), "id")
      );
    }
    // pls do not add deps to this effect dependency array
  }, [parentNodeWidths, parentNodesMeasured]); // eslint-disable-line

  return (
    <StyledContainer>
      {nodesToMeasure.tuples.flatMap(([parentNode, ...childNodes]) => {
        const parentNodeWidth = parentNodeWidths[parentNode.id];

        const ParentNodeComponent = getComponentByNodeType(
          nodesToMeasure.context,
          parentNode.type as AllNodeTypes
        );

        return [
          <ParentNodeComponent
            {...dummyNodeProps}
            data={parentNode.data}
            key={parentNode.id}
            measuringProps={{
              innerRef: refsById[parentNode.id],
            }}
          />,
          childNodes.map((node) => {
            const ChildNodeComponent = getComponentByNodeType(
              nodesToMeasure.context,
              node.type as AllNodeTypes
            );

            return (
              <ChildNodeComponent
                {...dummyNodeProps}
                data={node.data}
                key={node.id}
                measuringProps={{
                  innerRef: refsById[node.id],
                  style: parentNodeWidth
                    ? { width: parentNodeWidth - childNodeGutterSize * 2 }
                    : undefined,
                }}
              />
            );
          }),
        ];
      })}
    </StyledContainer>
  );
};

export const useNodeMeasuringContainer = (
  nodes: Node[],
  setNodes: (nextNodes: Node[]) => void,
  edges?: Edge[],
  setEdges?: (nextEdges: Edge[]) => void
) => {
  const [nodesToMeasure, setNodesToMeasure] =
    useState<NodeTuplesToMeasure | null>(null);

  // when nodes have been measured & positioned, add them to ReactFlow nodes
  const handleNodesMeasured = (measuredNodes: Record<string, Node>) => {
    setNodesToMeasure(null);

    // overwrite existing nodes while removing them from measuredNodes
    const nextNodes = nodes.map((node) => {
      if (node.id in measuredNodes) {
        const measuredNode = measuredNodes[node.id];
        delete measuredNodes[node.id];
        return measuredNode;
      } else {
        return node;
      }
    });

    // return updated existing along with any remaining new nodes
    setNodes(nextNodes.concat(Object.values(measuredNodes)));
    edges && setEdges && setEdges(edges);
  };

  return {
    nodesToMeasure,
    setNodesToMeasure,
    onNodesMeasured: handleNodesMeasured,
  };
};

const getComponentByNodeType = (
  context: FlowNodeRenderContext,
  nodeType: AllNodeTypes
) => {
  let component;

  if (context === FlowNodeRenderContext.CallRoutingEditor) {
    switch (nodeType) {
      case "startFlowNode":
        component = StartFlowNode;
        break;
      case "blockNode":
        component = CallRoutingNodeComponents.BlockNode;
        break;
      case "textInputNode":
        component = CallRoutingNodeComponents.TextInputNode;
        break;
      case "dropdownNode":
        component = CallRoutingNodeComponents.DropdownNode;
        break;
      case "branchingNode":
        component = CallRoutingNodeComponents.BranchingNode;
        break;
      case "conditionNode":
        component = CallRoutingNodeComponents.ConditionNode;
        break;
    }
  }

  if (context === FlowNodeRenderContext.SnapshotEditor) {
    switch (nodeType) {
      case "startFlowNode":
        component = StartFlowNode;
        break;
      case "stepFlowNode":
        component = SnapshotStepFlowNode;
        break;
      case "answerFlowNode":
        component = SnapshotAnswerFlowNode;
        break;
    }
  }

  if (context === FlowNodeRenderContext.TemplateEditor) {
    switch (nodeType) {
      case "startFlowNode":
        component = StartFlowNode;
        break;
      case "stepFlowNode":
        component = TemplateStepFlowNode;
        break;
      case "answerFlowNode":
        component = TemplateAnswerFlowNode;
        break;
    }
  }

  if (!component)
    throw new Error("Missing node component for this type of node");
  return component;
};
