import {
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from "@apollo/client";

import { usePagination } from "src/hooks/usePagination";
import { Optional } from "src/types";
import {
  DefaultResponse,
  GetVoicemailsInput,
  MutationDeleteVoicemailArgs,
  MutationUpdateVoicemailArgs,
  MutationUpdateVoicemailByCallArgs,
  QuerySignedVoicemailRecordingUrlArgs,
  QueryVoicemailsArgs,
  SignedUrlResponse,
  SubscriptionCreatedVoicemailArgs,
  SubscriptionDeletedVoicemailArgs,
  Voicemail,
  VoicemailResponse,
  VoicemailsResponse,
} from "../schemaTypes";
import {
  DELETE_VOICEMAIL,
  UPDATE_VOICEMAIL,
  UPDATE_VOICEMAIL_BY_CALL,
} from "./mutations";
import { GET_SIGNED_VOICEMAIL_RECORDING_URL, GET_VOICEMAILS } from "./queries";
import {
  CREATED_VOICEMAIL_EVENT,
  DELETED_VOICEMAIL_EVENT,
  UPDATED_VOICEMAIL_EVENT,
} from "./subscriptions";

export const usePaginatedQueryVoicemails = (input: GetVoicemailsInput) =>
  usePagination<
    QueryVoicemailsArgs,
    { response: VoicemailsResponse },
    Voicemail
  >({ document: GET_VOICEMAILS, variables: { input } });

export const useMutationUpdateVoicemail = () =>
  useMutation<
    { updateVoicemail: VoicemailResponse },
    MutationUpdateVoicemailArgs
  >(UPDATE_VOICEMAIL, {
    refetchQueries: [GET_VOICEMAILS],
  });

export const useMutationUpdateVoicemailByCall = () =>
  useMutation<
    { updateVoicemailByCall: VoicemailResponse },
    MutationUpdateVoicemailByCallArgs
  >(UPDATE_VOICEMAIL_BY_CALL, {
    refetchQueries: [GET_VOICEMAILS],
  });

export const useMutationDeleteVoicemail = () =>
  useMutation<
    { deleteVoicemail: DefaultResponse },
    MutationDeleteVoicemailArgs
  >(DELETE_VOICEMAIL, {
    refetchQueries: [GET_VOICEMAILS],
  });

export const useUpdatedVoicemailSubscription = (organizationId: string) =>
  useSubscription<{ updatedVoicemail: Voicemail }>(UPDATED_VOICEMAIL_EVENT, {
    variables: { organizationId },

    onData(options) {
      if (options.data?.data?.updatedVoicemail)
        options.client.refetchQueries({ include: [GET_VOICEMAILS] });
    },
  });

export const useCreatedVoicemailSubscription = (organizationId: string) =>
  useSubscription<
    { createdVoicemail: Voicemail },
    SubscriptionCreatedVoicemailArgs
  >(CREATED_VOICEMAIL_EVENT, {
    variables: { organizationId },

    onData(options) {
      if (options.data.data?.createdVoicemail)
        options.client.refetchQueries({
          include: [GET_VOICEMAILS],
        });
    },
  });

export const useDeletedVoicemailSubscription = (organizationId: string) =>
  useSubscription<
    { deletedVoicemail: Voicemail },
    SubscriptionDeletedVoicemailArgs
  >(DELETED_VOICEMAIL_EVENT, {
    variables: { organizationId },

    onData(options) {
      if (options.data.data?.deletedVoicemail)
        options.client.refetchQueries({ include: [GET_VOICEMAILS] });
    },
  });

export const useLazyQueryGetSignedVoicemailUrl = () =>
  useLazyQuery<{
    signedVoicemailRecordingUrl: SignedUrlResponse;
  }>(GET_SIGNED_VOICEMAIL_RECORDING_URL, { fetchPolicy: "network-only" });

export const useQuerySignedVoicemailUrl = (call?: string) =>
  useQuery<
    {
      signedVoicemailRecordingUrl: SignedUrlResponse;
    },
    Optional<QuerySignedVoicemailRecordingUrlArgs, "call">
  >(GET_SIGNED_VOICEMAIL_RECORDING_URL, {
    variables: { call },
    skip: !call,
    fetchPolicy: "network-only",
  });
