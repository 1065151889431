import { Viewport } from "react-flow-renderer";
import { JourneyStage, JourneyStagePositions } from "src/graphql";
import { GrabbableJourneyStage } from "./JourneyStageToolbar";

export type XDims = {
  width: number;
  x: number;
};

export const orderedGrabbableStages = [
  JourneyStage.Contemplation,
  JourneyStage.Preparation,
  JourneyStage.Action,
  JourneyStage.Maintenance,
] as const;

export const orderedStages = [
  JourneyStage.Precontemplation,
  JourneyStage.Contemplation,
  JourneyStage.Preparation,
  JourneyStage.Action,
  JourneyStage.Maintenance,
] as const;

export const clientXToToolbarX = (clientX: number, toolbarXDims: XDims) =>
  Math.max(0, Math.min(clientX - toolbarXDims.x, toolbarXDims.width));

export const templateXToToolbarX = (templateX: number, viewport: Viewport) =>
  templateX * viewport.zoom + viewport.x;

export const toolbarXToTemplateX = (toolbarX: number, viewport: Viewport) =>
  Math.floor((toolbarX - viewport.x) / viewport.zoom);

export const MIN_STAGE_WIDTH = 30;
export const clampXBetweenNeighbors = (
  stage: JourneyStage,
  stageX: number,
  stagePositions: Record<JourneyStage, number>
) => {
  const stageIndex = orderedGrabbableStages.findIndex((el) => stage === el);
  const prevStage =
    stageIndex === 0 ? null : orderedGrabbableStages[stageIndex - 1];
  const nextStage =
    stageIndex === orderedGrabbableStages.length - 1
      ? null
      : orderedGrabbableStages[stageIndex + 1];

  const lowerBound = prevStage
    ? stagePositions[prevStage] + MIN_STAGE_WIDTH / 2
    : -Infinity;
  const upperBound = nextStage
    ? stagePositions[nextStage] - MIN_STAGE_WIDTH / 2
    : Infinity;

  return Math.max(Math.min(stageX, upperBound), lowerBound);
};

// when moving a slider, checks for collisions and moves all necessary adjacent
// sliders to accommodate desired position
export const getNextStagePositions = (
  nextPos: number,
  grabbedStage: GrabbableJourneyStage,
  prevStagePositions: JourneyStagePositions
) => {
  const prevPos = prevStagePositions[grabbedStage];
  const grabbedStageIndex = orderedGrabbableStages.indexOf(grabbedStage);

  const nextStagePositions: JourneyStagePositions = {
    ...prevStagePositions,
    [grabbedStage]: nextPos,
  };

  if (nextPos > prevPos) {
    // moving right
    for (
      let i = grabbedStageIndex + 1;
      i <= orderedGrabbableStages.length;
      i++
    ) {
      const stageToLeft = orderedGrabbableStages[i - 1];
      const stageToPush = orderedGrabbableStages[i];

      // move neighbor if necessary
      if (
        nextStagePositions[stageToPush] - nextStagePositions[stageToLeft] <
        MIN_STAGE_WIDTH
      ) {
        nextStagePositions[stageToPush] =
          nextStagePositions[stageToLeft] + MIN_STAGE_WIDTH;
      } else {
        // escape hatch for iteration once we don't need to move neighbor
        i = orderedGrabbableStages.length + 1;
      }
    }
  } else {
    // moving left
    for (let i = grabbedStageIndex - 1; i >= 0; i--) {
      const stageToRight = orderedGrabbableStages[i + 1];
      const stageToPush = orderedGrabbableStages[i];

      // move neighbor if necessary
      if (
        nextStagePositions[stageToRight] - nextStagePositions[stageToPush] <
        MIN_STAGE_WIDTH
      ) {
        nextStagePositions[stageToPush] =
          nextStagePositions[stageToRight] - MIN_STAGE_WIDTH;
      } else {
        // escape hatch for iteration once we don't need to move neighbor
        i = -1;
      }
    }
  }

  return nextStagePositions;
};
