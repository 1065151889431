import { gql } from "@apollo/client";
import { ReferralTemplateFields } from "./types";

export const CREATE_REFERRAL_TEMPLATE = gql`
  mutation CreateReferralTemplate($input: ReferralTemplateInput!) {
    createReferralTemplate(input: $input) {
      success
      message
      data {
        ${ReferralTemplateFields}
      }
    }
  }
`;

export const UPDATE_REFERRAL_TEMPLATE = gql`
  mutation UpdateReferralTemplate($input: UpdateReferralTemplateInput!) {
    updateReferralTemplate(input: $input) {
      success
      message
      data {
        ${ReferralTemplateFields}
      }
    }
  }
`;

export const RETIRE_REFERRAL_TEMPLATE = gql`
  mutation RetireReferralTemplate($input: RetireReferralTemplateInput!) {
    retireReferralTemplate(input: $input) {
      success
      message
      data {
        ${ReferralTemplateFields}
      }
    }
  }
`;
