import React, { useEffect, useState } from "react";
import {
  Container,
  Paper,
  Text,
  Button,
  Group,
  Flex,
  LoadingOverlay,
} from "@mantine/core";
import {
  NotificationChannel,
  NotificationCode,
  NotificationSettings as NotificationSettingsGraphQL,
} from "src/graphql";
import {
  useMutationUpdateNotificationSettings,
  useQueryNotificationSettings,
} from "src/graphql/Notification";
import toast from "src/libs/toast";
import { IconCheck } from "@tabler/icons-react";

const NotificationCodeToLabel = {
  [NotificationCode.Gcbau1]:
    "When a goal is created by another user for a member that is assigned to you",
  [NotificationCode.Gnc1]:
    "When a goal is not completed for certain amount of time",
  [NotificationCode.Naa1]:
    "When a new activity is assigned to you by another user",
  [NotificationCode.Nma1]:
    "When a new member is assigned to you by another user",
  [NotificationCode.Saas1]: "Scheduled activities for the day",
  [NotificationCode.Neutral]: "Organization Notifications",
  [NotificationCode.OrganizationNotification]: "Organization Notifications",
};

export const NotificationSettings = () => {
  const {
    data: notificationSettingsData,
    loading: notificationSettingsLoading,
  } = useQueryNotificationSettings();

  const [
    mutationUpdateNotificationSettings,
    { loading: mutationUpdateNotificationSettingsLoading },
  ] = useMutationUpdateNotificationSettings();

  const [notificationSettings, setNotificationSettings] = useState<
    NotificationSettingsGraphQL | undefined
  >();

  useEffect(() => {
    const data = notificationSettingsData?.notificationSettings?.data;
    if (data) {
      const { __typename: GCBAU1Typename, ...GCBAU1 } = data.GCBAU1;
      const { __typename: GNC1Typename, ...GNC1 } = data.GNC1;
      const { __typename: NAA1Typename, ...NAA1 } = data.NAA1;
      const { __typename: NMA1Typename, ...NMA1 } = data.NMA1;
      const { __typename: NeutralTypename, ...Neutral } = data.Neutral;
      const { __typename: SAAS1Typename, ...SAAS1 } = data.SAAS1;
      const {
        __typename: OrganizationNotificationTypename,
        ...OrganizationNotification
      } = data.OrganizationNotification;

      const res: NotificationSettingsGraphQL = {
        GCBAU1,
        GNC1,
        NAA1,
        NMA1,
        Neutral,
        SAAS1,
        OrganizationNotification,
      };

      setNotificationSettings(res);
    }
  }, [notificationSettingsData?.notificationSettings.data]);

  const handleChannelChange = (
    notificationCode: NotificationCode,
    channel: NotificationChannel
  ) => {
    setNotificationSettings((prevSettings) => {
      if (prevSettings) {
        return {
          ...prevSettings,
          [notificationCode]: {
            ...prevSettings[notificationCode],
            [channel]: !prevSettings[notificationCode][channel],
          },
        };
      }

      return prevSettings;
    });
  };

  const updateSettings = async () => {
    try {
      if (notificationSettings) {
        const response = await mutationUpdateNotificationSettings({
          variables: { notificationSettingsInput: notificationSettings },
        });

        if (response.data?.updateNotificationSettings.success) {
          toast.success("Notification Settings update successfully");
          return;
        }
      }
    } catch (e) {}
    toast.error("Notification Settings failed to update");
  };

  const renderNotifications = () => {
    if (!notificationSettings) {
      return [];
    }
    return Object.keys(notificationSettings).map((code) => {
      if (code === NotificationCode.Neutral) {
        return null;
      }

      return (
        <Paper
          key={code}
          shadow="xs"
          withBorder
          style={{ marginBottom: "1rem" }}
        >
          <Container p="md">
            <Text size="sm" weight={700}>
              {NotificationCodeToLabel[code as NotificationCode]}
            </Text>
            <Group align="center" style={{ marginTop: "1rem" }}>
              <Button
                leftIcon={
                  notificationSettings[code as NotificationCode].Email && (
                    <IconCheck color={"white"} size={15} />
                  )
                }
                size="xs"
                variant={
                  notificationSettings[code as NotificationCode].Email
                    ? "filled"
                    : "outline"
                }
                onClick={() =>
                  handleChannelChange(
                    code as NotificationCode,
                    NotificationChannel.Email
                  )
                }
              >
                Email
              </Button>
              <Button
                leftIcon={
                  notificationSettings[code as NotificationCode].App && (
                    <IconCheck color={"white"} size={15} />
                  )
                }
                size="xs"
                variant={
                  notificationSettings[code as NotificationCode].App
                    ? "filled"
                    : "outline"
                }
                onClick={() =>
                  handleChannelChange(
                    code as NotificationCode,
                    NotificationChannel.App
                  )
                }
              >
                App
              </Button>
            </Group>
          </Container>
        </Paper>
      );
    });
  };

  return (
    <>
      <LoadingOverlay
        visible={
          notificationSettingsLoading ||
          mutationUpdateNotificationSettingsLoading
        }
        overlayBlur={2}
      />
      <Container px={0}>{renderNotifications()}</Container>
      <Flex py="md" style={{ borderTop: "1px solid lightgrey" }}>
        <Button
          onClick={updateSettings}
          loading={
            notificationSettingsLoading ||
            mutationUpdateNotificationSettingsLoading
          }
          ml="auto"
        >
          Update Settings
        </Button>
      </Flex>
    </>
  );
};
