import { LockingReferenceFields } from "../Common/types";

export const EmailTemplateFieldsMeta = `
    _id
    organizationId
    recipientAddress
    subject
    lockingReferences {
       ${LockingReferenceFields}
    }
    createdAt
    updatedAt
    `;
export const EmilTemplateFields = `
    ${EmailTemplateFieldsMeta}
    body
`;
