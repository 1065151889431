import { Button, Stack, Text } from "@mantine/core";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import * as React from "react";
import { ArrowLeft, Upload } from "react-feather";
import toast from "src/libs/toast";

import {
  DataTableComponent,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "src/components";
import { useAuthContext } from "src/hooks";
import { SelectedFile } from "./SelectedFile";
import { DontTranslate } from "src/libs/localization/components/DontTranslate";
import {
  BulkCreateMemberError,
  useMutationBulkCreateMembers,
} from "src/graphql";

interface ModalProps {
  modalIsOpen: boolean;
  closeModal: () => void;
}

export const BulkUploadModal = ({ modalIsOpen, closeModal }: ModalProps) => {
  const { selectedOrganizationId } = useAuthContext();
  const [mutationBulkCreateMembers] = useMutationBulkCreateMembers();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [csv, setCsv] = React.useState<File>();
  const [insertions, setInsertions] = React.useState<BulkCreateMemberError[]>(
    []
  );

  React.useEffect(() => {
    return () => {
      setLoading(false);
      setCsv(undefined);
      setInsertions([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFileAdd = (file: File[]) => setCsv(file[0]);

  const onFileUpload = async () => {
    if (csv && selectedOrganizationId) {
      setLoading(true);

      try {
        const mutate = await mutationBulkCreateMembers({
          variables: {
            file: csv,
            organizationId: selectedOrganizationId,
          },
        });
        const res = mutate.data?.bulkCreateMembers;
        setLoading(false);
        setCsv(undefined);

        if (!res || !res.success) {
          setInsertions(res?.errors?.length ? res.errors : []);
          if (!res?.errors?.length) {
            toast.error(res?.message ?? "Something went wrong");
          }
          return;
        }

        if (res.success) {
          closeModal();
          toast.success(res.message ?? "Successully inserted");
          return;
        }
      } catch (error) {
        setLoading(false);
        toast.error(
          typeof error === "object" && error instanceof Error && error?.message
            ? error?.message || "Something went wrong"
            : "Something went wrong"
        );
      }
    }
  };

  const columns = [
    {
      name: "Row #",
      selector: (row: BulkCreateMemberError) => row.rowNumber ?? "",
      width: "10%",
    },
    {
      name: "Column",
      width: "20%",
      selector: (row: BulkCreateMemberError) => row.column ?? "",
      omit: !!insertions.filter((r) => !r.column).length,
    },
    {
      name: "Value",
      cell: (row: BulkCreateMemberError) =>
        row.value ? <DontTranslate>{row.value}</DontTranslate> : "",
      width: "20%",
      omit: !!insertions.filter((r) => !r.value).length,
    },
    {
      name: "Error",
      width: "50%",
      selector: (row: BulkCreateMemberError) => row.message,
    },
  ];

  return (
    <Modal opened={modalIsOpen} onClose={closeModal} size="xl">
      <ModalHeader>Bulk Member Upload</ModalHeader>

      <ModalBody>
        <Stack spacing="sm">
          {!!insertions?.length && (
            <>
              <Text color="red">Invalid entries</Text>

              <DataTableComponent
                columns={columns}
                noRowsPerPage
                data={insertions}
                initialized={!loading}
              />
            </>
          )}

          {!insertions?.length && !csv && (
            <Dropzone
              onDrop={onFileAdd}
              accept={[MIME_TYPES.csv]}
              maxSize={1_000_000}
            >
              <Stack spacing="sm" align="center">
                <Upload size={55} />

                <Text align="center">
                  <strong>Drag</strong> and <strong>Drop</strong> File <br />
                  or <strong>browse media on your device</strong>
                </Text>

                <Text>Csv of Max. Size 1MB</Text>
              </Stack>
            </Dropzone>
          )}

          {!!csv && <SelectedFile file={csv} clear={setCsv} />}
        </Stack>
      </ModalBody>

      <ModalFooter>
        <Button color="red" variant="outline" onClick={closeModal}>
          Cancel
        </Button>

        {insertions?.length !== 0 ? (
          <Button
            disabled={insertions?.length === 0}
            leftIcon={<ArrowLeft />}
            onClick={() => setInsertions([])}
          >
            Back and upload more
          </Button>
        ) : (
          <Button disabled={!csv} loading={loading} onClick={onFileUpload}>
            Upload
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};
