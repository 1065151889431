import { gql } from "@apollo/client";

export const CREATE_CALENDAR_EVENT = gql`
  mutation CreateCalendarEvent($input: CreateCalendarEventInput!) {
    createCalendarEvent(input: $input) {
      success
      message
    }
  }
`;

export const UPDATE_CALENDAR_EVENT = gql`
  mutation UpdateCalendarEvent($input: UpdateCalendarEventInput!) {
    updateCalendarEvent(input: $input) {
      success
      message
    }
  }
`;

export const REMOVE_CALENDAR_EVENT = gql`
  mutation RemoveCalendarEvent($input: RemoveCalendarEventInput!) {
    removeCalendarEvent(input: $input) {
      success
      message
    }
  }
`;
