export const certificationTemplateFields = `
  _id
  createdAt
  updatedAt
  name
  description
  deletedAt
  userId
  organizationId
`;

export const certificationFields = `
  _id
  updatedAt
  createdAt
  description
  certificationTemplateId
  isActive
  title
  userId
  organizationId
  fileName
  fileId
  revokedAt
`;
