import { gql } from "@apollo/client";
import { UserGroupFields } from "./types";

export const CREATE_USER_GROUP = gql`
  mutation CreateUserGroup($input: CreateUserGroupInput!) {
    createUserGroup(input: $input) {
      success
      message
      data {
        ${UserGroupFields}
      }
    }
  }
`;

export const UPDATE_USER_GROUP = gql`
  mutation UpdateUserGroup($input: UpdateUserGroupInput!) {
    updateUserGroup(input: $input) {
      success
      message
      data {
        ${UserGroupFields}
      }
    }
  }
`;

export const DELETE_USER_GROUP = gql`
  mutation DeleteUserGroup($input: DeleteUserGroupInput!) {
    deleteUserGroup(input: $input) {
      success
      message
      data {
        ${UserGroupFields}
      }
    }
  }
`;
