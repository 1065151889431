export const trainingFields = `
  _id
  updatedAt
  createdAt
  name
  userId
  organizationId
  documentationId
  fileName
  revokedAt
  issueDate
  expirationDate
  continuingEducationUnits
`;
