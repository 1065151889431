import { useResizeObserver } from "@mantine/hooks";
import React, { useLayoutEffect, useState } from "react";

export const useDetectOverflow = <T extends HTMLElement>(): [
  React.RefObject<T>,
  boolean
] => {
  const [contentOverflowing, setContentOverflowing] = useState(false);
  const [contentRef, contentRect] = useResizeObserver<T>();

  useLayoutEffect(() => {
    const contentElement = contentRef.current;
    if (!contentElement) return;

    const overflowing =
      contentElement.offsetHeight < contentElement.scrollHeight ||
      contentElement.offsetWidth < contentElement.scrollWidth;

    setContentOverflowing(overflowing);
  }, [contentRef, contentRect]);

  return [contentRef, contentOverflowing];
};
