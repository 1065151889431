import { useMutation } from "@apollo/client";

import {
  CreateCalendarEventResponse,
  MutationCreateCalendarEventArgs,
  MutationRemoveCalendarEventArgs,
  MutationUpdateCalendarEventArgs,
  RemoveCalendarEventResponse,
  UpdateCalendarEventResponse,
} from "../schemaTypes";

import {
  CREATE_CALENDAR_EVENT,
  REMOVE_CALENDAR_EVENT,
  UPDATE_CALENDAR_EVENT,
} from "./mutations";

export const useMutationCreateCalendarEvent = () =>
  useMutation<
    {
      createCalendarEvent: CreateCalendarEventResponse;
    },
    MutationCreateCalendarEventArgs
  >(CREATE_CALENDAR_EVENT);

export const useMutationUpdateCalendarEvent = () =>
  useMutation<
    {
      updateCalendarEvent: UpdateCalendarEventResponse;
    },
    MutationUpdateCalendarEventArgs
  >(UPDATE_CALENDAR_EVENT);

export const useMutationRemoveCalendarEvent = () =>
  useMutation<
    {
      removeCalendarEvent: RemoveCalendarEventResponse;
    },
    MutationRemoveCalendarEventArgs
  >(REMOVE_CALENDAR_EVENT);
