export const TextMessageFields = `
        _id
    createdAt
    organizationPhone
    participantPhone
    direction
    organizationId
    body
    status
    messageSid
    notificationSid
    attachments
    createdAt
`;
