import {
  Anchor,
  Image,
  List,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { PricingTable } from "src/components/pricing-table/PricingTable";

export default function SubscriptionPlans() {
  const theme = useMantineTheme();

  return (
    <Stack
      bg={theme.colors.green.at(0)}
      pos="relative"
      h="100vh"
      style={{ overflowY: "auto", zIndex: 100 }}
    >
      <Stack py="xl" align="center" justify="center" spacing={36}>
        <Stack align="center" spacing="xs" mt="xl">
          <Image src="/fav-logo.png" alt="Pear Suite Logo" width={48} />

          <Title align="center">Pear Suite</Title>
          <Text align="center">
            Join hundreds of community health workers, doulas, promotores, and
            their frontline public health workers
          </Text>

          <Text align="center" size="lg" mt="xs">
            Simple and Transparent Pricing
          </Text>

          <List size="sm">
            <Stack align="center" spacing={0}>
              <List.Item>No implementation fees</List.Item>
              <List.Item>
                Monthly Subscription (no annual or multi-year terms)
              </List.Item>
              <List.Item>Complimentary technical support</List.Item>
              <List.Item>Bulk pricing discount for 15+ users</List.Item>
            </Stack>
          </List>
        </Stack>

        <PricingTable />

        <Text size="sm" my="xl" align="center">
          For additional pricing details or questions, please contact us at{" "}
          <Anchor href="mailto:support@pearsuite.com">
            support@pearsuite.com
          </Anchor>{" "}
          for more information.
        </Text>
      </Stack>
    </Stack>
  );
}
