import { Modal, ModalHeader } from "src/components";
import { LockingReference } from "src/graphql";
import { WarnModalContent } from "./WarningModalContent";

type WarnLockingReferencesModalProps = {
  isOpen: boolean;
  lockedObject: string;
  activeReferences: LockingReference[];
  onConfirm: () => void;
  onRequestClose: () => void;
};

export const WarnLockingReferencesModal = ({
  isOpen,
  lockedObject,
  activeReferences,
  onConfirm,
  onRequestClose,
}: WarnLockingReferencesModalProps) => {
  return (
    <Modal opened={isOpen} onClose={onRequestClose}>
      <ModalHeader withSubHeader>Update {lockedObject}</ModalHeader>
      <WarnModalContent
        lockedObject={lockedObject}
        activeReferences={activeReferences}
        onConfirm={onConfirm}
        onCancel={onRequestClose}
      />
    </Modal>
  );
};
