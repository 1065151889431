import { gql } from "@apollo/client";
import { DataFields } from "./types";

export const CREATE_DATA = gql`
  mutation CreateData($input: CreateDataInput!) {
    createData(input: $input) {
      success
      message
      data {
        ${DataFields}
      }
    }
  }
`;
