import gql from "graphql-tag";
import { UserGroupFields } from "../Organization/types";

export const GET_USER_GROUP = gql`
  query GetUserGroup($input: GetUserGroupInput!) {
    userGroup(input: $input) {
      success
      message
      data {
        ${UserGroupFields}
      }
    }
  }
`;

export const GET_USER_GROUPS = gql`
  query GetUserGroups($organizationId: ID!) {
    userGroups(organizationId: $organizationId) {
      success
      message
      data {
        ${UserGroupFields}
      }
    }
  }
`;
