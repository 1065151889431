import styled from "styled-components";

export const EDIT_MASK_Z_INDEX = 50;
export const NodeEditMask = styled.div<{ isEnabled: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: ${({ isEnabled }) => (isEnabled ? "1" : "0")};
  pointer-events: ${({ isEnabled }) => (isEnabled ? "all" : "none")};
  transition: all 0.1s;
`;
