import { css } from "@emotion/css";
import { useDrag } from "react-dnd";

const containerClass = css`
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
`;

const containerClassDisabled = css`
  cursor: not-allowed;
`;

export enum DragType {
  Question = "Question",
  CallRoutingNode = "CallRoutingNode",
}

type DraggableProps = {
  type: DragType;
  dragData: Record<string, unknown>;
  /** @default false */
  disabled?: boolean;
  children: React.ReactNode;
};

export const Draggable = ({
  type,
  dragData,
  children,
  disabled = false,
}: DraggableProps) => {
  const [{ opacity }, dragRef] = useDrag(() => ({
    type,
    item: dragData,
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.5 : 1,
    }),
  }));

  return (
    <div
      className={disabled ? containerClassDisabled : containerClass}
      ref={disabled ? null : dragRef}
      style={{ opacity }}
    >
      {children}
    </div>
  );
};
