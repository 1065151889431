import { useEffect } from "react";
import styled from "styled-components";

const SplashContainer = styled.div`
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  background: rgb(232, 238, 193);
  background: linear-gradient(
    34deg,
    rgba(232, 238, 193, 1) 0%,
    rgba(62, 174, 131, 1) 71%,
    rgba(62, 174, 131, 1) 100%
  );
`;

const PearLogoContainer = styled.div`
  position: absolute;
  right: -40px;
  top: -180px;
`;

type AuthSplashProps = {
  children: React.ReactNode;
  readonly pretty?: boolean;
};

export const AuthSplash = ({ pretty = false, children }: AuthSplashProps) => {
  useEffect(() => {
    if (pretty) {
      document.body.style.margin = "0px";
    }
  }, [pretty]);

  return (
    <SplashContainer>
      <PearLogoContainer>
        <PearLogo
          size={2000}
          fill={"rgba(255,255,255,.1)"}
          transform={"rotate(30deg)"}
        />
      </PearLogoContainer>
      {children}
    </SplashContainer>
  );
};

type PearLogoProps = {
  stroke?: React.SVGAttributes<HTMLOrSVGElement>["stroke"];
  fill?: React.SVGAttributes<HTMLOrSVGElement>["fill"];
  size?: React.CSSProperties["height"];
  strokeWidth?: React.SVGAttributes<HTMLOrSVGElement>["strokeWidth"];
  transform?: React.CSSProperties["transform"];
};

export const PearLogo = ({
  stroke = "var(--color-pear-green)",
  strokeWidth = 0,
  fill = "var(--color-pear-green)",
  size = 100,
  transform = undefined,
}: PearLogoProps) => (
  <svg
    height={size}
    viewBox="0 0 76.377991 121.51263"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      transform,
    }}
  >
    <path
      style={{
        fill,
        stroke,
        strokeWidth,
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeOpacity: 1,
      }}
      d="m107.53028 166.39096 3.5675-6.76595 55.29623 35.05991s3.07543-6.21237 2.64487-16.73035c-.43056-10.51797-3.47523-14.63904-3.47523-14.63904s-1.4147-2.86015-6.3969-5.96634c-4.98219-3.10618-34.16803-21.71253-34.16803-21.71253s6.24313-4.12108 13.34737-.43056l18.14504 11.5021s-3.5675-12.39398-11.68664-18.57559c-8.11913-6.18162-18.91389-3.25996-18.91389-3.25996s-8.79573 1.19942-14.79282 13.65491l45.45486 29.03206s1.3455.83037 1.90676 3.13694c.56127 2.30657 1.26093 6.18162 1.24555 7.94999l-51.80562-33.19927s-1.8145 6.0586-5.62803 12.1787c-3.813539 6.12011-5.997095 10.0259-6.12011 13.1936l50.34479 32.44578s-11.1023 7.59632-26.35644 1.50697c0 0-12.86976-5.26246-15.61504-19.33082 0 0-7.437326-4.95822-9.438012-6.21952 0 0-2.261643 17.48425 12.786982 29.00993 15.04863 11.52569 30.44187 8.04251 30.44187 8.04251s15.34639-1.72224 24.29589-15.03885zM133.0256 119.24461s-7.25802-3.78277-8.05763-9.3493c-.79961-5.56653 3.13694-8.70347 3.13694-8.70347s1.99903-2.275819 4.92069-3.659762c0 0 3.41372 1.50696 6.08935 4.920692 2.67562 3.41372 1.87601 8.14988 1.87601 8.14988s-.95338 5.25899-7.96536 8.64196z"
      transform="translate(-93.834429 -96.386614)"
    />
    <path
      style={{
        fill,
        stroke,
        strokeWidth,
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeOpacity: 1,
      }}
      d="m107.53028 166.39096 3.5675-6.76595 55.29623 35.05991s3.07543-6.21237 2.64487-16.73035c-.43056-10.51797-3.47523-14.63904-3.47523-14.63904s-1.4147-2.86015-6.3969-5.96634c-4.98219-3.10618-34.16803-21.71253-34.16803-21.71253s6.24313-4.12108 13.34737-.43056l18.14504 11.5021s-3.5675-12.39398-11.68664-18.57559c-8.11913-6.18162-18.91389-3.25996-18.91389-3.25996s-8.79573 1.19942-14.79282 13.65491l45.45486 29.03206s1.3455.83037 1.90676 3.13694c.56127 2.30657 1.26093 6.18162 1.24555 7.94999l-51.80562-33.19927s-1.8145 6.0586-5.62803 12.1787c-3.813539 6.12011-5.997095 10.0259-6.12011 13.1936l50.34479 32.44578s-11.1023 7.59632-26.35644 1.50697c0 0-12.86976-5.26246-15.61504-19.33082 0 0-7.437326-4.95822-9.438012-6.21952 0 0-2.261643 17.48425 12.786982 29.00993 15.04863 11.52569 30.44187 8.04251 30.44187 8.04251s15.34639-1.72224 24.29589-15.03885zM133.0256 119.24461s-7.25802-3.78277-8.05763-9.3493c-.79961-5.56653 3.13694-8.70347 3.13694-8.70347s1.99903-2.275819 4.92069-3.659762c0 0 3.41372 1.50696 6.08935 4.920692 2.67562 3.41372 1.87601 8.14988 1.87601 8.14988s-.95338 5.25899-7.96536 8.64196z"
      transform="translate(-93.834429 -96.386614)"
    />
  </svg>
);
