import { useMutation, useQuery } from "@apollo/client";
import { GET_DATA_IDS_WITH_ANSWER_TYPE_BY_ORGANIZATION_ID } from "../DataPoint/queries";
import {
  DefaultResponse,
  GetPaginatedQuestionsInput,
  GetPaginatedQuestionsResponse,
  GetQuestionResponse,
  GetQuestionsResponse,
  MutationCreateQuestionArgs,
  MutationDeleteQuestionArgs,
  MutationUpdateQuestionArgs,
  QueryGetPaginatedQuestionsArgs,
  QueryGetQuestionsArgs,
  Question,
  QuestionType,
} from "../schemaTypes";
import { CREATE_QUESTION, DELETE_QUESTION, UPDATE_QUESTION } from "./mutations";
import { GET_PAGINATED_QUESTIONS, GET_QUESTIONS } from "./queries";
import { MongoSort, usePagination } from "src/hooks/usePagination";

export const useMutationCreateQuestion = (
  organizationId: string,
  questionType?: QuestionType
) =>
  useMutation<
    { createQuestion: GetQuestionResponse },
    MutationCreateQuestionArgs
  >(CREATE_QUESTION, {
    refetchQueries: [
      {
        query: GET_QUESTIONS,
        variables: {
          organizationId,
          questionType,
        },
      },
      {
        query: GET_QUESTIONS,
        variables: {
          organizationId,
        },
      },
      {
        query: GET_DATA_IDS_WITH_ANSWER_TYPE_BY_ORGANIZATION_ID,
        variables: {
          organizationId,
          includeDemographicOptions: false,
        },
      },
    ],
  });

export const useMutationUpdateQuestion = (
  organizationId: string,
  questionType?: QuestionType
) =>
  useMutation<
    { updateQuestion: GetQuestionResponse },
    MutationUpdateQuestionArgs
  >(UPDATE_QUESTION, {
    refetchQueries: [
      {
        query: GET_QUESTIONS,
        variables: {
          organizationId,
          questionType,
        },
      },
      {
        query: GET_QUESTIONS,
        variables: {
          organizationId,
        },
      },
    ],
  });

export const useQueryGetQuestions = (
  organizationId: string,
  questionType?: QuestionType
) =>
  useQuery<{ getQuestions: GetQuestionsResponse }, QueryGetQuestionsArgs>(
    GET_QUESTIONS,
    {
      variables: {
        organizationId,
        questionType,
      },
      fetchPolicy: "network-only",
    }
  );

export const usePaginatedQueryQuestions = (
  input: GetPaginatedQuestionsInput,
  options?: {
    defaultSort?: MongoSort;
  }
) =>
  usePagination<
    QueryGetPaginatedQuestionsArgs,
    { response: GetPaginatedQuestionsResponse },
    Question
  >({ document: GET_PAGINATED_QUESTIONS, variables: { input }, options });

export const useMutationDeleteQuestion = (
  organizationId: string,
  questionType?: QuestionType
) =>
  useMutation<{ deleteQuestion: DefaultResponse }, MutationDeleteQuestionArgs>(
    DELETE_QUESTION,
    {
      refetchQueries: [
        {
          query: GET_QUESTIONS,
          variables: {
            organizationId,
            questionType,
          },
        },
        {
          query: GET_QUESTIONS,
          variables: {
            organizationId,
          },
        },
      ],
    }
  );
