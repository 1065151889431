import { gql } from "@apollo/client";

import { ActivityFields } from "../Activity/types";

export const GET_SCHEDULE = gql`
  query Schedule($input: GetScheduleInput!) {
    schedule(input: $input) {
      success
      message
      data {
        activities {
          ${ActivityFields}
        }
        dateEvents {
          id
          title
          description
          scheduledAt
          endingAt
        }
      }
    }
  }
`;

export const GET_SCHEDULE_ONLY_ACTIVITIES = gql`
  query Schedule($input: GetScheduleInput!) {
    schedule(input: $input) {
      success
      message
      data {
        activities {
          ${ActivityFields}
        }
      }
    }
  }
`;

export const GET_SCHEDULE_WITH_PAST_DUE = gql`
  query Schedule($input: GetScheduleInput!) {
    schedule(input: $input) {
      success
      message
      data {
        pastDueActivities {
          ${ActivityFields}
        }
        activities {
          ${ActivityFields}
        }
        dateEvents {
          id
          title
          description
          scheduledAt
          endingAt
        }
      }
    }
  }
`;
