import { Formik, FormikProps } from "formik";
import log from "loglevel";
import { useRef, useState } from "react";
import toast from "src/libs/toast";
import * as Yup from "yup";

import { FormikSelect } from "src/components";
import { ActionInfo } from "src/components/flow-graph/util";
import { ActionType, CreateGoalData, GoalTemplate } from "src/graphql";
import { SelectOption } from "src/types";
import { ySelectOptionSchema } from "src/utils";
import { SubFormCommonProps } from "../AddActionModal";
import { GoalTemplatePreview } from "../GoalTemplatePreview";
import { Stack } from "@mantine/core";
import { GoalTemplateModal } from "src/pages/templates-goal/GoalTemplateModal";
import { BASE_CREATE_TEMPLATE_ID } from "src/pages/templates";

type CreateGoalActionFormProps = SubFormCommonProps & {
  goalTemplateOptions: SelectOption<string>[];
  goalTemplatesById: Record<string, GoalTemplate>;
};

export const CreateGoalActionForm = ({
  goalTemplateOptions,
  goalTemplatesById,
  innerRef,
  node,
  onCreateAction,
  onDirtyStateChange,
  onValidStateChange,
}: CreateGoalActionFormProps) => {
  const dirtyStateRef = useRef(false);
  const validStateRef = useRef(false);

  const initialValues: WrappedFormValues = {
    goalTemplateId: null,
  };

  const [createModalOpen, setCreateModalOpen] = useState(false);

  return (
    <>
      <Formik
        innerRef={innerRef as React.RefObject<FormikProps<WrappedFormValues>>}
        initialValues={initialValues}
        validationSchema={ValidationSchema}
        onSubmit={(formValues, { setSubmitting }) => {
          if (!node) return; // nothing renders without a node selected
          try {
            setSubmitting(true);
            const actionInfo = parseFormValues(formValues);
            log.log("formSubmit", actionInfo, formValues);
            onCreateAction(node, actionInfo);
            // eslint-disable-next-line
          } catch (err: any) {
            log.error(err.message);
            setSubmitting(false);
            toast.error("Failed to add action; please try again");
          }
        }}
      >
        {({ values, dirty, isValid }) => {
          if (onDirtyStateChange && dirty !== dirtyStateRef.current) {
            dirtyStateRef.current = dirty;
            requestAnimationFrame(() => onDirtyStateChange(dirty));
          }
          if (onValidStateChange && isValid !== validStateRef.current) {
            validStateRef.current = isValid;
            requestAnimationFrame(() => onValidStateChange(isValid));
          }

          return (
            <Stack mt="0.75em" spacing="1em">
              <FormikSelect
                name="goalTemplateId"
                label="Goal Template"
                options={goalTemplateOptions}
                creatable
                getCreateLabel={() => "+ Create New Goal Template"}
                onCreate={() => {
                  setCreateModalOpen(true);
                  return null;
                }}
              />

              {values.goalTemplateId && (
                <div>
                  <GoalTemplatePreview
                    template={goalTemplatesById[values.goalTemplateId.value]}
                  />
                </div>
              )}
            </Stack>
          );
        }}
      </Formik>

      <GoalTemplateModal
        onRequestClose={() => setCreateModalOpen(false)}
        afterUpdateSuccess={() => setCreateModalOpen(false)}
        selectedTemplateId={
          createModalOpen ? BASE_CREATE_TEMPLATE_ID : undefined
        }
      />
    </>
  );
};

type WrappedFormValues = Omit<CreateGoalData, "goalTemplateId"> & {
  goalTemplateId: SelectOption<string> | null;
};

const parseFormValues = (formValues: WrappedFormValues): ActionInfo => {
  if (!formValues.goalTemplateId) {
    throw new Error("Missing goal template ID - check schema validator");
  }

  return {
    actionType: ActionType.CreateGoalData,
    actionData: {
      goalTemplateId: formValues.goalTemplateId.value,
    },
  };
};

const ValidationSchema = Yup.object({
  goalTemplateId: ySelectOptionSchema(Yup.string().required()).required(),
});
