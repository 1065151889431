import { gql } from "@apollo/client";
import { ActivityFields } from "./types";

export const CREATE_ACTIVITY = gql`
  mutation CreateActivity($input: ActivityInput!) {
    createActivity(input: $input) {
      success
      message
      data {
        ${ActivityFields}
      }
    }
  }
`;

export const UPDATE_ACTIVITY = gql`
  mutation UpdateActivity($input: UpdateActivityInput!) {
    updateActivity(input: $input) {
      success
      message
      data {
        ${ActivityFields}
      }
    }
  }
`;

export const UPDATE_ACTIVITY_TASK_STATUS = gql`
  mutation UpdateActivityTaskStatus($input: UpdateActivityTaskStatusInput!) {
    updateActivityTaskStatus(input: $input) {
      success
      message
      data {
        ${ActivityFields}
      }
    }
  }
`;

export const ACTIVITY_PING = gql`
  mutation ActivityPing($input: ActivityPingInput!) {
    activityPing(input: $input) {
      success
      message
      data {
        ${ActivityFields}
      }
    }
  }
`;

export const DELETE_ACTIVITY = gql`
  mutation DeleteActivity($activityId: ID!) {
    deleteActivity(activityId: $activityId) {
      message
      success
    }
  }
`;

export const CREATE_GROUP_ACTIVITY = gql`
  mutation ($input: GroupActivityInput!) {
    createGroupActivities(input: $input) {
      success
      message
      data {
        activityId
        memberId
      }
    }
  }
`;

export const ADD_ACTIVITY_MEMBER = gql`
  mutation AddMemberToActivity($input: ActivityMemberInput!) {
    addMemberToActivity(input: $input) {
      success
      message
      data {
        ${ActivityFields}
      }
    }
  }
`;

export const UPDATE_ACTIVITY_MEMBER = gql`
  mutation UpdateActivityMember($input: ActivityMemberInput!) {
    updateActivityMember(input: $input) {
      success
      message
      data {
        ${ActivityFields}
      }
    }
  }
`;

export const REMOVE_ACTIVITY_MEMBER = gql`
  mutation RemoveMemberFromActivity($input: ActivityMemberInput!) {
    removeMemberFromActivity(input: $input) {
      success
      message
      data {
        ${ActivityFields}
      }
    }
  }
`;
