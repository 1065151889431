import { memo } from "react";
import {
  ActionIcon,
  Button,
  Group,
  NumberInput,
  Select,
  Stack,
  Textarea,
  useMantineTheme,
} from "@mantine/core";
import { MeasurableNodeProps } from "src/components/flow-graph/flow-nodes/shared";
import {
  CallRoutingEdge,
  CallRoutingNode,
  CallRoutingNodeType,
} from "src/graphql";
import { nodeTypeColors, nodeTypeLabels } from "./CallRoutingEditorToolbar";
import { Position } from "react-flow-renderer";
import { StyledEdgeHandle } from "src/components/flow-graph/flow-nodes/StyledEdgeHandle";
import { useCallRoutingEditorContext } from "./CallRoutingEditorContext";
import styled from "@emotion/styled";
import { STEP_NODE_TITLE_HEIGHT } from "src/components/flow-graph/flow-nodes";
import { Plus, X } from "react-feather";
import { Text } from "@mantine/core";

type NodeHandlesProps = {
  isInputNode?: boolean;
  isOutputNode?: boolean;
  handleEdgeOffset?: number;
};

const EdgeHandles = ({
  isInputNode = true,
  isOutputNode = true,
  handleEdgeOffset = 0,
}: NodeHandlesProps) => (
  <>
    {isInputNode && (
      <StyledEdgeHandle
        type={"target"}
        position={Position.Left}
        isConnectable={true}
        handleEdgeOffset={handleEdgeOffset}
      />
    )}
    {isOutputNode && (
      <StyledEdgeHandle
        type={"source"}
        position={Position.Right}
        isConnectable={true}
        handleEdgeOffset={handleEdgeOffset}
      />
    )}
  </>
);

const DeleteNodeButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  color: white;
  height: ${STEP_NODE_TITLE_HEIGHT}px;
  width: ${STEP_NODE_TITLE_HEIGHT}px;
  border-top-right-radius: 3px;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }

  &:active {
    background-color: transparent;
  }
`;

const SectionHeader = styled.div`
  background-color: rgba(0, 0, 0, 0.05);
  text-transform: uppercase;
  padding: 0px 3px;
  font-size: 12px;
`;

type NodeWrapperProps = {
  innerRef?: React.RefObject<HTMLDivElement>;
  children?: React.ReactNode;
  nodeType: CallRoutingNodeType;
  nodeId: string;
};

const NodeWrapper = ({
  innerRef,
  children,
  nodeType,
  nodeId,
}: NodeWrapperProps) => {
  const { onDeleteNodeGroup } = useCallRoutingEditorContext();
  const theme = useMantineTheme();
  const label = nodeTypeLabels[nodeType];
  const backgroundColor = theme.colors[nodeTypeColors[nodeType]][5];

  const isOutputNode =
    nodeType !== CallRoutingNodeType.Voicemail &&
    nodeType !== CallRoutingNodeType.Branching;

  return (
    <Stack
      spacing={0}
      align="stretch"
      style={{
        height: "100%",
        borderRadius: 3,
        boxShadow: "0 0 6px rgba(0, 0, 0, 0.16), 0 0 6px rgba(0, 0, 0, 0.23)",
        backgroundColor: "white",
        position: "relative",
      }}
      ref={innerRef}
    >
      {/* Title Header */}
      <Group
        align="center"
        position="apart"
        style={{
          backgroundColor,
          color: "white",
          position: "relative",

          paddingLeft: 15,
          paddingRight: 0,
          borderRadius: 3,
          height: STEP_NODE_TITLE_HEIGHT,
          ...(["Branching", "UserGroup"].includes(nodeType)
            ? {
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }
            : {}),
        }}
      >
        <Text weight="bold">{label}</Text>

        {/* Delete Btn */}
        <DeleteNodeButton
          className="nodrag"
          onClick={() => onDeleteNodeGroup(nodeId)}
        >
          <X size={14} />
        </DeleteNodeButton>
        <EdgeHandles isOutputNode={isOutputNode} />
      </Group>

      {children}
    </Stack>
  );
};

export const BlockNode = memo((props: MeasurableNodeProps<CallRoutingNode>) => {
  const { id, data, measuringProps } = props;

  return (
    <NodeWrapper
      innerRef={measuringProps?.innerRef}
      nodeType={data.type}
      nodeId={id}
    />
  );
});

export const TextInputNode = memo(
  ({ id, data, measuringProps }: MeasurableNodeProps<CallRoutingNode>) => {
    const { onUpdateVoicePrompt } = useCallRoutingEditorContext();

    return (
      <NodeWrapper
        innerRef={measuringProps?.innerRef}
        nodeType={data.type}
        nodeId={id}
      >
        <SectionHeader>Voice Prompt</SectionHeader>
        <Textarea
          className="nowheel nodrag"
          defaultValue={data.voicePromptContent || ""}
          styles={{ input: { height: 150, width: 250, borderRadius: 0 } }}
          onChange={(e) => onUpdateVoicePrompt(id, e.currentTarget.value)}
          placeholder={"Enter voice prompt..."}
        />
      </NodeWrapper>
    );
  }
);

export const DropdownNode = memo(
  ({ id, data, measuringProps }: MeasurableNodeProps<CallRoutingNode>) => {
    const { userGroups, onUpdateUserGroup } = useCallRoutingEditorContext();

    const userGroupOptions = userGroups.map((g) => ({
      value: g._id,
      label: g.title,
    }));

    return (
      <NodeWrapper
        innerRef={measuringProps?.innerRef}
        nodeType={data.type}
        nodeId={id}
      >
        <Select
          className="nodrag"
          styles={{
            input: { borderTopLeftRadius: 0, borderTopRightRadius: 0 },
          }}
          placeholder="Select group..."
          creatable={false}
          clearable={false}
          searchable={false}
          value={data.userGroupId ?? null}
          data={userGroupOptions}
          onChange={(nextValue) => {
            if (!nextValue) return;
            onUpdateUserGroup(id, nextValue);
          }}
        />
      </NodeWrapper>
    );
  }
);

export const CONDITION_NODE_GUTTER_HEIGHT = 5;

export const BranchingNode = memo(
  ({ id, data, measuringProps }: MeasurableNodeProps<CallRoutingNode>) => {
    const { onCreateBranchingCondition, onUpdateVoicePrompt } =
      useCallRoutingEditorContext();

    return (
      <NodeWrapper
        innerRef={measuringProps?.innerRef}
        nodeType={data.type}
        nodeId={id}
      >
        <SectionHeader>Voice Prompt</SectionHeader>
        <Textarea
          className="nowheel nodrag"
          defaultValue={data.voicePromptContent || ""}
          styles={{ input: { height: 150, width: 250, borderRadius: 0 } }}
          onChange={(e) => onUpdateVoicePrompt(id, e.currentTarget.value)}
          placeholder={"Enter voice prompt..."}
        />

        <SectionHeader style={{ marginBottom: ".3rem" }}>
          <Group position="apart" spacing={0} p={0}>
            Options
            <Button
              compact
              color="green"
              variant="subtle"
              onClick={() => onCreateBranchingCondition(id)}
            >
              <Plus size={16} />
              &nbsp;Option
            </Button>
          </Group>
        </SectionHeader>
      </NodeWrapper>
    );
  }
);

export const ConditionNode = memo(
  ({ data, measuringProps, id }: MeasurableNodeProps<CallRoutingEdge>) => {
    const {
      onUpdateBranchingCondition: handleUpdateBranchingCondition,
      onDeleteBranchingCondition: handleDeleteBranchingCondition,
    } = useCallRoutingEditorContext();

    return (
      <Group ref={measuringProps?.innerRef} className="nodrag" spacing={4}>
        <NumberInput
          size="xs"
          value={data.condition}
          min={0}
          max={9}
          onChange={(nextValue) =>
            nextValue !== "" && handleUpdateBranchingCondition(id, nextValue)
          }
          style={{ flexGrow: 1 }}
        />

        <ActionIcon
          color="red"
          onClick={() => handleDeleteBranchingCondition(id)}
        >
          <X size={18} />
        </ActionIcon>

        <EdgeHandles isInputNode={false} handleEdgeOffset={5} />
      </Group>
    );
  }
);
