import { gql } from "@apollo/client";
import { tagFields } from "./types";

export const GET_TAGS = gql`
  query GetTags($input: GetTagsInput!, $pagination: PaginationInput!) {
    response: tags(input: $input, pagination: $pagination) {
      success
      message
      data {
        total
        page
        data {
          ${tagFields}
        }
      }
    }
  }
`;
