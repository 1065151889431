import styled from "styled-components";

const StyledFormErrorNotice = styled.div`
  border: 1px solid rgba(255, 0, 0, 0.8);
  border-radius: 3px;
  color: rgba(255, 0, 0, 0.8);
  background-color: rgba(255, 0, 0, 0.1);
  padding: 5px;
`;

type FormErrorNoticeProps = {
  errorMessage: string;
};

export const FormErrorNotice = ({ errorMessage }: FormErrorNoticeProps) =>
  errorMessage ? (
    <StyledFormErrorNotice>{errorMessage}</StyledFormErrorNotice>
  ) : null;
