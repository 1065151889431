import { gql } from "@apollo/client";
import { GoalTemplateFields } from "./types";

export const GET_GOAL_TEMPLATES = gql`
  query GetGoalTemplates($organizationId: ID!) {
    goalTemplates(organizationId: $organizationId) {
      data {
        goalTemplate {
          ${GoalTemplateFields}
        }
        accessType
      }
      success
      message
    }
  }
`;
