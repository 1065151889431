import { Grid, Title, TextInput } from "@mantine/core";
import { FormikCountrySelect } from "src/components/input/FormikCountrySelect";
import { useFormikContext, Field } from "formik";
import { MemberFormValues } from "../MemberFormFormik";
import { FormikSelect } from "src/components";
import { SelectOption } from "src/types";

interface MemberProfileInfoProps {
  timezoneSelectOptions: SelectOption<string>[];
}
export const MemberContactDetailsSection = ({
  timezoneSelectOptions,
}: MemberProfileInfoProps) => {
  const { errors, touched } = useFormikContext<MemberFormValues>();

  return (
    <>
      <Title order={4} style={{ marginBottom: 20 }}>
        Contact Details
      </Title>
      <Grid gutter="md">
        <Grid.Col md={6} sm={12}>
          <Field
            as={TextInput}
            label="Address Line 1"
            name="contactInfo.address"
            type="text"
            placeholder="Address Line 1"
            variant="filled"
            radius="md"
            error={touched?.contactInfo?.address && errors.contactInfo?.address}
          />
        </Grid.Col>
        <Grid.Col md={6} sm={12}>
          <Field
            as={TextInput}
            label="Address Line 2"
            type="text"
            name="contactInfo.address2"
            placeholder="Address Line 2"
            variant="filled"
            radius="md"
            error={
              touched?.contactInfo?.address2 && errors.contactInfo?.address2
            }
          />
        </Grid.Col>
        <Grid.Col md={4} sm={6}>
          <Field
            as={TextInput}
            label="City"
            name="contactInfo.city"
            type="text"
            variant="filled"
            radius="md"
            error={touched?.contactInfo?.city && errors.contactInfo?.city}
          />
        </Grid.Col>
        <Grid.Col md={4} sm={6}>
          <Field
            as={TextInput}
            label="State"
            name="contactInfo.state"
            type="text"
            variant="filled"
            radius="md"
            error={touched?.contactInfo?.state && errors.contactInfo?.state}
          />
        </Grid.Col>
        <Grid.Col md={4} sm={6}>
          <Field
            as={TextInput}
            label="Zip Code"
            name="contactInfo.zip"
            type="text"
            placeholder="Enter Zip Code"
            variant="filled"
            radius="md"
            error={touched?.contactInfo?.zip && errors.contactInfo?.zip}
          />
        </Grid.Col>
        <Grid.Col md={4} sm={6}>
          <FormikCountrySelect
            label="Country"
            clearable={true}
            name="contactInfo.country"
          />
        </Grid.Col>
        <Grid.Col md={4} sm={6}>
          <FormikSelect
            label="Timezone"
            name="timezone"
            placeholder="Select Timezone"
            options={timezoneSelectOptions}
          />
        </Grid.Col>
      </Grid>
    </>
  );
};
