import { CSSObject, TextStylesParams } from "@mantine/core";
import { MantineThemeComponents } from "@mantine/styles/lib/theme/types/MantineTheme";

export const Text: MantineThemeComponents[string] = {
  styles: (
    _theme,
    _params: TextStylesParams,
    context
  ): Record<string, CSSObject> => {
    if (context.size === "sm" || context.size === "xs")
      return {
        root: {
          fontFamily: "Avenir-Roman, Avenir-Book, sans-serif",
          lineHeight: "20px",
        },
      };
    else
      return {
        root: {
          fontFamily: "Avenir-Roman, Avenir-Book, sans-serif",
          lineHeight: "24px",
        },
      };
  },

  variants: {
    caption: (theme) => ({
      root: {
        fontFamily: "Avenir-Roman, Avenir-Book, sans-serif",
        fontStyle: "normal",
        fontSize: "12px",
        lineHeight: "16px",
        color: theme.colors.black[6],
      },
    }),
  },
};
