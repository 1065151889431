import { useAuthContext } from "src/hooks";
import {
  GetUserGroupResponse,
  GetUserGroupsResponse,
  MutationCreateUserGroupArgs,
  MutationDeleteUserGroupArgs,
  MutationUpdateUserGroupArgs,
  QueryUserGroupArgs,
  QueryUserGroupsArgs,
} from "../schemaTypes";
import { useMutation, useQuery } from "@apollo/client";
import { GET_USER_GROUP, GET_USER_GROUPS } from "./queries";
import {
  CREATE_USER_GROUP,
  DELETE_USER_GROUP,
  UPDATE_USER_GROUP,
} from "./mutations";

export const useQueryUserGroup = (groupId: string) => {
  const { selectedOrganizationId } = useAuthContext();

  return useQuery<{ userGroup: GetUserGroupResponse }, QueryUserGroupArgs>(
    GET_USER_GROUP,
    {
      variables: {
        input: {
          organizationId: selectedOrganizationId,
          _id: groupId,
        },
      },
    }
  );
};

export const useQueryUserGroups = () => {
  const { selectedOrganizationId } = useAuthContext();

  return useQuery<{ userGroups: GetUserGroupsResponse }, QueryUserGroupsArgs>(
    GET_USER_GROUPS,
    {
      variables: {
        organizationId: selectedOrganizationId,
      },
    }
  );
};

export const useMutationCreateUserGroup = (organizationId: string) =>
  useMutation<
    { createUserGroup: GetUserGroupResponse },
    MutationCreateUserGroupArgs
  >(CREATE_USER_GROUP, {
    update: (cache, result) =>
      cache.updateQuery<{ userGroups: GetUserGroupsResponse }>(
        {
          query: GET_USER_GROUPS,
          variables: { organizationId },
        },
        (cached) => {
          if (!cached) return;

          const createdGroup = result.data?.createUserGroup.data;
          if (!createdGroup) return cached;

          return {
            userGroups: {
              ...cached.userGroups,
              data: [...(cached.userGroups.data ?? []), createdGroup],
            },
          };
        }
      ),
  });

export const useMutationUpdateUserGroup = (organizationId: string) =>
  useMutation<
    { updateUserGroup: GetUserGroupResponse },
    MutationUpdateUserGroupArgs
  >(UPDATE_USER_GROUP);

export const useMutationDeleteUserGroup = (organizationId: string) =>
  useMutation<
    { deleteUserGroup: GetUserGroupResponse },
    MutationDeleteUserGroupArgs
  >(DELETE_USER_GROUP, {
    update: (cache, result) =>
      cache.updateQuery<{ userGroups: GetUserGroupsResponse }>(
        {
          query: GET_USER_GROUPS,
          variables: { organizationId },
        },
        (cached) => {
          if (!cached) return;

          const deletedGroup = result.data?.deleteUserGroup.data;
          if (!deletedGroup) return cached;

          return {
            userGroups: {
              ...cached.userGroups,
              data: (cached.userGroups.data ?? []).filter(
                (g) => g._id !== deletedGroup._id
              ),
            },
          };
        }
      ),
  });
