import { gql } from "@apollo/client";

const statsFields = (otherFieldsInsideStats = "") => `
      {
        groupByTimeUnit
        data {
          resource
          granularData {
            value {
              type
              value
            }
            key
          }
        }
        ${otherFieldsInsideStats}
      }
      `;

export const GET_ACTIVITIES_STATS = gql`
  query ActivitiesStats($input: AnalyticsInput!) {
    activitiesStats(input: $input) {
      success
      message
      stats ${statsFields()}
    }
  }
`;

export const GET_GOALS_STATS = gql`
  query GoalsStats($input: AnalyticsInput!) {
    goalsStats(input: $input) {
      success
      message
      stats ${statsFields()}
    }
  }
`;

export const GET_CARE_PATHWAYS_STATS = gql`
  query CarePathwaysStats($input: AnalyticsInput!) {
    carePathwaysStats(input: $input) {
      success
      message
      stats ${statsFields(`
        carePathwayTemplateStatuses {
          carePathwayTemplate
          createdCount
          enrolledCount
          completedSuccessfulCount
          completedUnsuccessfulCount
        }
      `)}
    }
  }
`;

export const GET_EXTERNAL_RESOURCES_STATS = gql`
  query ExternalResourcesStats($input: AnalyticsInput!) {
    externalResourcesStats(input: $input) {
      success
      message
      stats ${statsFields(`
        externalResourcesAndReferralTemplates {
          externalResource
          referralTemplates {
            referralTemplate
            sentCount
            scheduledCount
            inProgressCount
            closedSuccessfullyCount
            closedUnsuccessfullySentCount
          }
        }
      `)}
    }
  }
`;

const demographicsStatsFields = (categoryString: string) => `
  ${categoryString} {
    value
    count
  }
`;

export const GET_DEMOGRAPHICS_STATS = gql`
  query DemographicsStats($input: AnalyticsInput!) {
    demographicsStats(input: $input) {
      success
      message
      stats {
        data {
          ${demographicsStatsFields("Ethnicity")}
          ${demographicsStatsFields("SexualOrientation")}
          ${demographicsStatsFields("Pronouns")}
          ${demographicsStatsFields("Sex")}
          ${demographicsStatsFields("Gender")}
          ${demographicsStatsFields("SpokenLanguage")}
          ${demographicsStatsFields("MedicareStatus")}
          ${demographicsStatsFields("MedicaidStatus")}
          ${demographicsStatsFields("AgeGroup")}
          ${demographicsStatsFields("Race")}
        }
      }
    }
  }
`;

export const GET_DATAPOINT_STATS = gql`
  query DataPointStats($input: AnalyticsInput!) {
    dataPointStats(input: $input) {
      success
      message
      stats {
        dataIdType
        groupByTimeUnit
        data {
          value {
            type
            value
          }
          key
        }
      }
    }
  }
`;

export const GET_SUMMARY_STATS = gql`
  query SummaryStats($input: AnalyticsInput!) {
    summaryStats(input: $input) {
      success
      message
      stats {
        totalMembers
        inactiveMembers
        activeMembers
        newMembers
        avgAgeOfMembers
        avgMembersPerUser
        livesImpacted
        engagementRate
        activationRate
        totalUsers
        activeUsers
        inactiveUsers
      }
    }
  }
`;

export const GET_CALL_CENTER_STATS = gql`
  query CallCenterStats($input: AnalyticsInput!) {
    callCenterStats(input: $input) {
      success
      message
      stats {
        totalInteractions
        connectionRate
        totalCallsMade
        totalCallsCompleted
        totalMissedCalls
        totalIncomingCalls
        totalAvgCallDuration
        totalAvgCallsPerHour
        totalVoiceMailLeft
        totalTextSent
        totalIncomingText
        totalSuccessfulTexts
        totalFailedTexts
        totalReadTexts
      }
    }
  }
`;

export const GET_ADDITIONAL_FILTERS = gql`
  query AdditionalFiltersOptions($input: AnalyticsInput!) {
    additionalFiltersOptions(input: $input) {
      success
      message
      data {
        filter
        options
      }
    }
  }
`;

export const GET_ALLOWED_CUSTOM_METRICS = gql`
  query GetAllowedCustomMetrics($organizationId: ID!) {
    allowedCustomMetrics(organizationId: $organizationId) {
      success
      message
      data
    }
  }
`;
