import { questionFields } from "../Question/types";
import { actionsFields } from "../Recommendations/types";

export const EventFields = `
  id
  title
  description
  startsAt
  endsAt
  questions {
    ${questionFields}
  }
  actionData {
    ${actionsFields}
  }
  eventTemplateId
  members {
    memberId
    memberDisplayName
    attended
    memberDataPoints {
      dataPointTemplateId
      answer
    }
  }
`;
