import { ActionIconStylesParams, CSSObject } from "@mantine/core";
import { MantineThemeComponents } from "@mantine/styles/lib/theme/types/MantineTheme";

export const ActionIcon: MantineThemeComponents[string] = {
  styles: (
    theme,
    params: ActionIconStylesParams,
    context
  ): Record<string, CSSObject> => {
    const color = params.color ?? "green";

    if (context.variant === "filled")
      return {
        root: {
          '&[data-disabled="true"]': { pointerEvents: "all" },
          "&:hover": { backgroundColor: theme.colors[color][4] },
          "&:active": { backgroundColor: theme.colors[color][5] },
        },
      };
    else
      return {
        root: {
          '&[data-disabled="true"]': { pointerEvents: "all" },
        },
      };
  },
};
