import { gql } from "@apollo/client";

export const GET_USER_INTEGRATIONS = gql`
  query GetUserIntegrations($input: UserIntegrationInput!) {
    getUserIntegrations(input: $input) {
      message
      success
      data {
        id
        with
      }
    }
  }
`;

export const START_GOOGLE_CONNECT_FLOW = gql`
  query StartGoogleConnectFlow($input: UserIntegrationInput!) {
    startGoogleConnectFlow(input: $input) {
      data
      message
      success
    }
  }
`;

export const START_MICROSOFT_CONNECT_FLOW = gql`
  query StartMicrosoftConnectFlow($input: UserIntegrationInput!) {
    startMicrosoftConnectFlow(input: $input) {
      data
      message
      success
    }
  }
`;
