import { FieldArray } from "formik";
import { Plus } from "react-feather";
import { Button } from "@mantine/core";
import { StyledErrorMessage } from "src/components";
import {
  AnswerType,
  ConditionItem,
  ConditionOperator,
  ConditionsGroupItem,
} from "src/graphql";
import { useAuthContext } from "src/hooks";
import { SelectOption } from "src/types";
import { ConditionsWrapper, makeBaseConditionItem } from "./ConditionsWrapper";
import { generateMongoObjectID } from "src/components/carepathways/utils";

type ConditionsGroupsProps = {
  conditionsGroups: ConditionsGroupItem[];
  readOnly?: boolean;
};

export const ConditionsGroups = ({
  conditionsGroups,
  readOnly,
}: ConditionsGroupsProps) => {
  const {
    userPerms: { atLeastOrgLead },
  } = useAuthContext();
  return (
    <div>
      <FieldArray name="conditionsGroups">
        {({ push, remove }) => (
          <>
            {conditionsGroups.map((conditionsGroupItem, index) => {
              return (
                <ConditionsWrapper
                  readOnly={readOnly}
                  key={`conditions-groups-${index}`}
                  index={index}
                  removeConditionGroup={() => {
                    remove(index);
                  }}
                  hasNextItem={conditionsGroups.length - 2 >= index}
                />
              );
            })}

            {atLeastOrgLead && (
              <Button
                disabled={readOnly}
                onClick={() => push(makeBaseGroupItem())}
                leftIcon={<Plus size={"18"} />}
              >
                Add New Condition Group
              </Button>
            )}
          </>
        )}
      </FieldArray>
      <StyledErrorMessage name="conditionsGroups" />
    </div>
  );
};

export const wrapConditionsGroupValues = (
  conditionsGroups: ConditionsGroupItem[],
  answerTypesByDataId: Record<string, AnswerType>
): WrappedConditionsGroupItem[] => {
  return conditionsGroups.map((conditionGroupItem) => {
    return {
      ...conditionGroupItem,
      conditions: conditionGroupItem.conditions.map((condition) => {
        const answerType = answerTypesByDataId[condition.dataId];

        return {
          ...condition,
          dataIdTemp: condition.dataId
            ? {
                label: `${condition.dataId} - ${answerType}`,
                value: condition.dataId,
              }
            : undefined,
          operatorTemp: condition.dataId
            ? {
                label: condition.operator,
                value: condition.operator,
              }
            : undefined,
          valueTextTemp: condition.value.length ? condition.value[0] : "",
          valueMultiTemp:
            condition.value.length > 0
              ? {
                  label: condition.value[0],
                  value: condition.value[0],
                }
              : undefined,
          valueMultiChoiceTemp: condition.value.map((value) => ({
            value,
            label: value,
          })),
        };
      }),
    };
  });
};

export const unwrapConditionsGroupValues = (
  conditionsGroups: WrappedConditionsGroupItem[]
): WrappedConditionsGroupItem[] => {
  return conditionsGroups.map((conditionGroupItem) => {
    return {
      ...conditionGroupItem,
      conditions: conditionGroupItem.conditions.map((condition) => {
        const unwrappedCondition: WrappedConditionItem = {
          ...condition,
        };

        delete unwrappedCondition["dataIdTemp"];
        delete unwrappedCondition["operatorTemp"];
        delete unwrappedCondition["valueTextTemp"];
        delete unwrappedCondition["valueMultiTemp"];
        delete unwrappedCondition["valueMultiChoiceTemp"];

        return unwrappedCondition;
      }),
    };
  });
};

export type WrappedConditionsGroupItem = ConditionsGroupItem & {
  conditions: WrappedConditionItem[];
};

export type WrappedConditionItem = ConditionItem & {
  dataIdTemp?: SelectOption<string>;
  operatorTemp?: SelectOption<string>;
  valueTextTemp?: string;
  valueMultiTemp?: SelectOption<string>;
  valueMultiChoiceTemp?: SelectOption<string>[];
};

export const makeBaseGroupItem = (): WrappedConditionsGroupItem => ({
  _id: generateMongoObjectID(),
  conditionOperator: ConditionOperator.And,
  conditions: [makeBaseConditionItem()],
});
