import { Button, Grid, Text, Group, Flex } from "@mantine/core";
import { FormikProps } from "formik";
import { useMemo, useRef, useEffect, useState } from "react";
import toast from "src/libs/toast";
import { DatePickerInput } from "@mantine/dates";
import { IconCalendar } from "@tabler/icons-react";

import {
  GoalTemplateFormValues,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalSubHeader,
} from "src/components";
import {
  GoalTemplateForm,
  makeBaseGoalTemplate,
  TemplateFormContext,
} from "src/components/form";
import { Goal, GoalTemplate, useMutationUpdateGoal } from "src/graphql";
import { useAuthContext } from "src/hooks";
import { SelectInterventionDropdown } from "./SelectInterventionDropdown";

interface UpdateGoalModalProps {
  userId: string;
  memberId: string;
  goal: Goal;

  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

export const UpdateGoalModal = ({
  memberId,
  goal,
  isOpen,
  setIsOpen,
}: UpdateGoalModalProps) => {
  const formikRef = useRef<FormikProps<GoalTemplateFormValues>>(null);
  const { selectedOrganizationId } = useAuthContext();

  const [mutationUpdateGoal, { loading: mutationLoading }] =
    useMutationUpdateGoal();

  const [selectedData, setSelectedData] = useState<any[]>([]); // eslint-disable-line @typescript-eslint/no-explicit-any

  const [selectedDomain, setSelectedDomain] = useState<string | null>(
    goal.domain?._id ?? null
  );
  const [selectedDueDate, setSelectedDueDate] = useState<Date | null>(
    goal.dueDate ? new Date(goal.dueDate) : null
  );

  useEffect(() => {
    if (
      goal?.activities?.length ||
      goal?.referrals?.length ||
      goal?.carePathways?.length
    ) {
      const activities = (goal.activities ?? []).map((activity) => ({
        ...activity,
        __typename: "Activity",
        _id: activity._id,
        title: activity?.title,
        status: activity?.status,
      }));

      const referrals = (goal.referrals ?? []).map((referral) => ({
        ...referral,
        __typename: "Referral",
        _id: referral._id,
        referralTemplateTitle: referral?.referralTemplateTitle,
        status: referral?.status,
      }));

      const carePathways = (goal.carePathways ?? []).map((carePathway) => ({
        ...carePathway,
        __typename: "CarePathway",
        _id: carePathway._id,
        name: carePathway?.carePathwayTemplate?.name ?? carePathway?.name ?? "",
        status: carePathway?.status,
      }));

      setSelectedData([...activities, ...referrals, ...carePathways]);
    }
  }, [goal]);

  const template: GoalTemplate = useMemo(() => {
    return {
      ...makeBaseGoalTemplate(selectedOrganizationId),

      _id: goal._id,
      name: goal.name,
      description: goal.description,
      actionItemDescriptions: goal.actionItems.map(
        (value) => value.description
      ),
      domainId: goal.domain?._id ?? undefined,
    };
  }, [goal, selectedOrganizationId]);

  useEffect(() => {
    setSelectedDomain(goal.domain?._id ?? null);
  }, [goal]);

  const handleSubmit = async (template: GoalTemplate) => {
    try {
      const { _id, lockingReferences, ...templateInput } = template;

      const response = await mutationUpdateGoal({
        variables: {
          input: {
            goalId: goal._id,
            memberId,
            domainId: selectedDomain ?? undefined,
            template: templateInput,
            activityIds: selectedData
              .filter((data) => data.__typename === "Activity")
              .map((data) => data._id),
            referralIds: selectedData
              .filter((data) => data.__typename === "Referral")
              .map((data) => data._id),
            carePathwayIds: selectedData
              .filter((data) => data.__typename === "CarePathway")
              .map((data) => data._id),
            dueDate: selectedDueDate?.toISOString() ?? undefined,
          },
        },
      });

      if (
        response.errors?.length ||
        response.data?.updateGoal?.success === false
      ) {
        toast.error("Oops! Something went wrong. Please try again.");
        return;
      }

      handleClose();
      toast.success("Successfully updated goal!");
    } catch {
      toast.error("Oops! Something went wrong. Please try again.");
    }
  };

  const handleClose = () => {
    formikRef.current?.resetForm();
    setSelectedData([]);
    setSelectedDomain(null);
    setSelectedDueDate(null);
    setIsOpen(false);
  };

  const isTemplateBased = !!goal.baseTemplateId && !!goal.domain?._id;

  return (
    <Modal opened={isOpen} onClose={handleClose} width="1200px">
      <ModalHeader withSubHeader>Update Goal</ModalHeader>
      <ModalSubHeader>
        <Flex justify="flex-end" align="center">
          <Group dir="column" spacing="md" align="flex-end">
            <DatePickerInput
              icon={<IconCalendar size="1.1rem" stroke={1.5} />}
              dropdownType="modal"
              placeholder="Select Date"
              value={new Date(goal.createdAt)}
              label="Assigned Date"
              disabled
            />
            <DatePickerInput
              icon={<IconCalendar size="1.1rem" stroke={1.5} />}
              dropdownType="modal"
              placeholder="Select Date"
              value={selectedDueDate}
              onChange={setSelectedDueDate}
              label="Due Date"
              clearable
            />
          </Group>
        </Flex>
      </ModalSubHeader>

      <ModalBody>
        <Grid>
          <Grid.Col span={6}>
            <GoalTemplateForm
              formContext={TemplateFormContext.CreateGoal}
              innerRef={formikRef}
              selectedTemplate={template}
              onSubmit={handleSubmit}
              selectedDomain={selectedDomain}
              setSelectedDomain={setSelectedDomain}
              isDomainEditable={!isTemplateBased}
            />{" "}
          </Grid.Col>
          <Grid.Col span={6}>
            <>
              <>
                <Text size="md" weight={600}>
                  Interventions
                </Text>
                <SelectInterventionDropdown
                  memberId={memberId}
                  selectedData={selectedData}
                  setSelectedData={setSelectedData}
                />
              </>
            </>
          </Grid.Col>
        </Grid>
      </ModalBody>

      <ModalFooter>
        <Button
          color="red"
          variant="outline"
          onClick={handleClose}
          disabled={mutationLoading}
        >
          Cancel
        </Button>

        <Button
          onClick={() => formikRef.current?.submitForm()}
          loading={mutationLoading}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};
