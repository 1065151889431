import { Plus, Check } from "react-feather";
import styled from "styled-components";
import { ActionType } from "src/graphql";

const badgeColorsByType: Record<ActionType, string> = {
  [ActionType.CreateGoalData]: "purple",
  [ActionType.UpdateGoalData]: "purple",
  [ActionType.ScheduleActivityData]: "var(--color-seafoam)",
  [ActionType.UpdateMemberData]: "var(--color-clean-yellow)",
  [ActionType.CreateReferral]: "var(--color-pear-green)",
  [ActionType.StartCarePathway]: "var(--color-clean-blue)",
  [ActionType.UpdateCarePathwayStatus]: "var(--color-clean-blue)",
  [ActionType.CreateConsent]: "var(--color-creamsicle)",
  [ActionType.AddMemberToGroup]: "var(--color-clean-lime)",
  [ActionType.RemoveMemberFromGroup]: "var(--color-clean-lime-muted)",
};

const StyledActionBadge = styled.div<{ actionType: ActionType }>`
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  height: 20px;
  padding: 0px 6px 0px 2px;
  background-color: ${(props) => badgeColorsByType[props.actionType]};
  color: white;
`;

type FlowActionBadgeProps = {
  actionType: ActionType;
};

export const FlowActionBadge = ({ actionType }: FlowActionBadgeProps) => {
  let Content: React.ReactNode;
  const size = 12;

  switch (actionType) {
    case ActionType.CreateGoalData:
      Content = (
        <>
          <Plus size={size} />
          &nbsp;Goal
        </>
      );
      break;
    case ActionType.UpdateGoalData:
      Content = (
        <>
          <Check size={size} />
          &nbsp;Goal
        </>
      );
      break;
    case ActionType.ScheduleActivityData:
      Content = (
        <>
          <Plus size={size} />
          &nbsp;Activity
        </>
      );
      break;

    case ActionType.UpdateMemberData:
      Content = (
        <>
          <Plus size={size} />
          &nbsp;Update Member
        </>
      );
      break;
    case ActionType.CreateReferral:
      Content = (
        <>
          <Plus size={size} />
          &nbsp;Create Referral
        </>
      );
      break;
    case ActionType.StartCarePathway:
      Content = (
        <>
          <Plus size={size} />
          &nbsp;Start Care Pathway
        </>
      );
      break;
    case ActionType.UpdateCarePathwayStatus:
      Content = (
        <>
          <Plus size={size} />
          &nbsp;Update Care Pathway Status
        </>
      );
      break;
    case ActionType.CreateConsent:
      Content = (
        <>
          <Plus size={size} />
          &nbsp;Collect Consent
        </>
      );
      break;
    case ActionType.AddMemberToGroup:
      Content = (
        <>
          <Plus size={size} />
          &nbsp;Add Member To Group
        </>
      );
      break;
    case ActionType.RemoveMemberFromGroup:
      Content = (
        <>
          <Plus size={size} />
          &nbsp;Remove Member From Group
        </>
      );
      break;
    default:
      break;
  }

  return (
    <StyledActionBadge actionType={actionType}>{Content}</StyledActionBadge>
  );
};
