import { useMemo } from "react";
import { MantineTheme, ThemeIcon, useMantineTheme } from "@mantine/core";
import { EnrollmentStatus } from "src/graphql";
import { Check, Clock, Icon, Minus, X } from "react-feather";

const getColorByStatus = (status: EnrollmentStatus, theme: MantineTheme) => {
  switch (status) {
    case EnrollmentStatus.ApplicationPending:
      return "orange";
    case EnrollmentStatus.Enrolled:
      return "green";
    case EnrollmentStatus.NotEnrolled:
      return "red";
    case EnrollmentStatus.Unknown:
      return "gray";
  }
};

const getIconByStatus = (status: EnrollmentStatus): Icon => {
  switch (status) {
    case EnrollmentStatus.ApplicationPending:
      return Clock;
    case EnrollmentStatus.Enrolled:
      return Check;
    case EnrollmentStatus.NotEnrolled:
      return X;
    case EnrollmentStatus.Unknown:
      return Minus;
  }
};

type EnrollmentStatusIconProps = {
  status?: EnrollmentStatus;
};

export const EnrollmentStatusIcon = ({
  status = EnrollmentStatus.Unknown,
}: EnrollmentStatusIconProps) => {
  const theme = useMantineTheme();

  const [color, Icon] = useMemo(
    () => [getColorByStatus(status, theme), getIconByStatus(status)],
    [status, theme]
  );

  return (
    <ThemeIcon radius="xl" size="sm" color={color}>
      <Icon size="12" />
    </ThemeIcon>
  );
};
