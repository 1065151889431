export const LockingReferenceFields = `
  referencingId
  referencingType
  isActive
`;

export const TitledLockingReferenceFields = `
  referencingId
  referencingType
  isActive
  title
`;
