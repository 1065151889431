import { gql } from "@apollo/client";
import { GoalTemplateFields } from "../GoalTemplate/types";
import { questionFields } from "../Question/types";
import { ActivityTemplateFields } from "../ActivityTemplate/types";
import { flowTemplateFields } from "./types";
import { ExternalResourceFields } from "../ExternalResource/types";
import { carePathwayTemplateMinimalFields } from "../CarePath/types";
import { consentTemplateFields } from "../Consent/types";

export const GET_FLOW_TEMPLATES = gql`
  query GetFlowTemplates($organizationId: ID!) {
    flowTemplates(organizationId: $organizationId) {
      success
      message
      data {
        flowTemplate {
          ${flowTemplateFields}
        }
      accessType
      }
    }
  }
`;

export const GET_FLOW_TEMPLATE = gql`
  query GetFlowTemplate($input: FlowTemplateIdAndOrgInput!) {
    flowTemplate(input: $input ) {
      success
      message
      data {
       ${flowTemplateFields}
      }
    }
  }
`;

export const GET_FLOW_TEMPLATE_FAMILY = gql`
  query GetFlowTemplateFamily($input: FlowTemplateFamilyIdAndOrgInput!) {
    flowTemplateFamily(input: $input) {
      success
      message
      data {
        currentVersion {
          ${flowTemplateFields}
        }
        priorVersions {
          ${flowTemplateFields}
        }
      }
    }
  }
`;

export const GET_FLOW_TEMPLATE_FAMILIES = gql`
  query GetFlowTemplateFamilies($organizationId: ID!) {
    flowTemplateFamilies(organizationId: $organizationId) {
      success
      message
      data {
        currentVersion {
            ${flowTemplateFields}
        }
        priorVersions {
          ${flowTemplateFields}
        }
      }
    }
  }
`;

export const GET_FLOW_BUILDER_DATA = gql`
  query GetFlowTemplateActionOptions($organizationId: ID!, $questionType: QuestionType) {
    goalTemplates(organizationId: $organizationId) {
      data {
        accessType
        goalTemplate {
            ${GoalTemplateFields}
        }
      }
      success
      message
    }
    flowTemplateFamilies(organizationId: $organizationId) {
      data {
        accessType
        flowTemplateFamily {
          currentVersion {
            ${flowTemplateFields}
          }
          priorVersions {
            ${flowTemplateFields}
          }
        }
      }
      success
      message
    }
    activityTemplates(organizationId: $organizationId) {
      data {
        accessType
        activityTemplate { 
          ${ActivityTemplateFields}
        }
      }
      success
      message
    }
    getQuestions(organizationId: $organizationId, questionType: $questionType) {
      data {
        ${questionFields}
      }
    }
    externalResources(organizationId: $organizationId) {
      data {
        ${ExternalResourceFields}
        
      }
    }
    carePathwayTemplatesByOrganizationId(organizationId: $organizationId) {
      data {
       ${carePathwayTemplateMinimalFields}
      }
    }
    consentTemplatesByOrganizationId(organizationId: $organizationId) {
      data {
        ${consentTemplateFields}
      }
    }
  }
`;
