export const notificationFields = `
_id
updatedAt
createdAt
renderedContent
timestamp
isRead
type
code
organizationId
resourceOrganizationId
templateContext { 
   ... on NMA1TemplateContext { 
    initiatingUserId
    memberId
   }
   ... on NAA1TemplateContext { 
    initiatingUserId
    activityId
    memberId
   }

   ... on SAAS1TemplateContext { 
    initiatingUserId
    activityId
    memberId
   }
   
   ... on GNC1TemplateContext { 
    initiatingUserId
    goalId
    memberId
   }

   ... on GCBAU1TemplateContext { 
    initiatingUserId
    goalId
    memberId
   }
   
   ... on NeutralTemplateContext { 
    initiatingUserId
   }

   ... on OrganizationNotificationTemplateContext { 
    initiatingUserId
   }
 }
`;

export const notificationsMetadataFields = `
unreadNotificationsCount
`;

export const notificationSettingsFields = `
Neutral { 
  App 
  Email
}
NMA1 { 
  App 
  Email
}

NAA1 { 
  App 
  Email
}

SAAS1 { 
  App 
  Email
}

GNC1 { 
  App 
  Email
}

GCBAU1 { 
  App 
  Email
}

OrganizationNotification { 
  App 
  Email
}
`;
