import { Group, TextInput, Button, MultiSelect } from "@mantine/core";
import { Search } from "react-feather";
import { SelectOption } from "src/types";

import { IconSortAscending, IconSortDescending } from "@tabler/icons-react";

interface NotesFiltersProps {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  selectedDomain: string[];
  setSelectedDomain: (domain: string[]) => void;
  selectedActivity: string[];
  setSelectedActivity: (activity: string[]) => void;
  sortOrder: "asc" | "desc";
  toggleSortOrder: () => void;
  activityOptions: SelectOption<string>[];
  domainOptions: SelectOption<string>[];
}
export const NotesFilters = ({
  searchQuery,
  setSearchQuery,
  selectedDomain,
  setSelectedDomain,
  selectedActivity,
  setSelectedActivity,
  sortOrder,
  toggleSortOrder,
  activityOptions,
  domainOptions,
}: NotesFiltersProps) => {
  return (
    <Group position="apart" mb="md">
      <Group spacing="xs">
        <TextInput
          placeholder="Search notes..."
          icon={<Search size={14} />}
          value={searchQuery}
          onChange={(event) => setSearchQuery(event.currentTarget.value)}
          sx={{ minWidth: 200 }}
        />
        <MultiSelect
          placeholder="Filter by domain"
          data={domainOptions}
          clearable
          onChange={setSelectedDomain}
          value={selectedDomain}
          sx={{ minWidth: 180 }}
        />
        <MultiSelect
          placeholder="Filter by activity"
          data={activityOptions}
          clearable
          onChange={setSelectedActivity}
          value={selectedActivity}
          sx={{ minWidth: 180 }}
        />
      </Group>
      <Group spacing="xs">
        <Button
          variant="outline"
          onClick={toggleSortOrder}
          leftIcon={
            sortOrder === "desc" ? (
              <IconSortDescending size={16} />
            ) : (
              <IconSortAscending size={16} />
            )
          }
        >
          Sort{" "}
          {sortOrder === "desc" ? "Reverse Chronologically" : "Chronologically"}
        </Button>
      </Group>
    </Group>
  );
};
