import * as React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useLocalStorageState = (defaultValue: any, key: string) => {
  const [value, setValue] = React.useState(() => {
    const localStorageValue = localStorage.getItem(key);

    return localStorageValue !== null
      ? JSON.parse(localStorageValue)
      : defaultValue;
  });

  React.useEffect(() => {
    if (value !== undefined && value !== null) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }, [key, value]);

  return [value, setValue];
};

const removeStorage = (key: string) => {
  localStorage.removeItem(key);
};

export { removeStorage, useLocalStorageState };
