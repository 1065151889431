import { Textarea, TextareaProps } from "@mantine/core";
import { useField } from "formik";
import {
  extractWrapperProps,
  FormikInputBaseProps,
  FormikInputWrapper,
} from "./FormikInputWrapper";

type FormikTextareaProps = Partial<TextareaProps> &
  FormikInputBaseProps<string>;

export const FormikTextarea = (props: FormikTextareaProps) => {
  const { className, style, disabled, ...rest } = props;

  const [field] = useField(rest);

  return (
    <FormikInputWrapper {...extractWrapperProps(props)}>
      <Textarea
        {...field}
        style={style}
        autosize={props.autosize}
        minLength={props.minLength}
        maxLength={props.maxLength}
        rows={props.rows}
        minRows={props.minRows}
        maxRows={props.maxRows}
        placeholder={rest.placeholder}
        disabled={disabled}
        className={className}
      />
    </FormikInputWrapper>
  );
};
