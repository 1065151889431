import { EnrollmentStatus, ProviderRecommendationStatus } from "src/graphql";

export const RecommendationStatusToColor = {
  [ProviderRecommendationStatus.Signed]: "green",
  [ProviderRecommendationStatus.Pending]: "blue",
  [ProviderRecommendationStatus.FailedDelivery]: "red",
  [ProviderRecommendationStatus.Rejected]: "orange",
};

export const RecommendationStatusToLabel = {
  [ProviderRecommendationStatus.Signed]: "Signed",
  [ProviderRecommendationStatus.Pending]: "Pending",
  [ProviderRecommendationStatus.FailedDelivery]: "Failed Delivery",
  [ProviderRecommendationStatus.Rejected]: "Rejected",
};

export const EnrollmentStatusToColor = {
  [EnrollmentStatus.Enrolled]: "green",
  [EnrollmentStatus.ApplicationPending]: "blue",
  [EnrollmentStatus.Unknown]: "red",
  [EnrollmentStatus.NotEnrolled]: "orange",
};

export const EnrollmentStatusToLabel = {
  [EnrollmentStatus.Enrolled]: "Enrolled",
  [EnrollmentStatus.ApplicationPending]: "Pending",
  [EnrollmentStatus.Unknown]: "Unknown",
  [EnrollmentStatus.NotEnrolled]: "Not Enrolled",
};
