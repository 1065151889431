import { Formik, Form } from "formik";
import { Button, Group, Stack, Grid, Checkbox } from "@mantine/core";
import { Note } from "src/graphql";
import { QuestionTooltip } from "../question-tooltip";
import { NoteFormFields, useNoteForm } from "./NoteForm";
import { IconCheck, IconX } from "@tabler/icons-react";

interface CreateNoteInlineFormProps {
  memberId?: string;
  activityId?: string;
  existingNote?: Note | null;
  onClose: () => void;
  multiMemberActivity?: boolean;
  organizationId: string;
  externalResourceId?: string;
  externalResourceContactId?: string;
  userId: string;
}

export const CreateNoteInlineForm = ({
  memberId,
  activityId,
  existingNote,
  onClose,
  multiMemberActivity = false,
  externalResourceId,
  externalResourceContactId,
  organizationId,
  userId,
}: CreateNoteInlineFormProps) => {
  const {
    formikRef,
    initialValues,
    handleSubmit,
    templateOptions,
    templatesById,
    templatesLoading,
    domainsData,
    submissionPending,
    createForAllMembersInActivity,
    setCreateForAllMembersInActivity,
  } = useNoteForm({
    organizationId,
    userId,
    activityId,
    memberId,
    existingNote,
    externalResourceId,
    externalResourceContactId,
    multiMemberActivity,
    afterUpdateSuccess: onClose,
    onClose,
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      innerRef={formikRef}
      enableReinitialize={true}
    >
      {({ values, handleChange, setFieldValue }) => (
        <Form>
          <Stack spacing="md" p="md">
            <NoteFormFields
              values={values}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              templateOptions={templateOptions}
              templatesById={templatesById}
              templatesLoading={templatesLoading}
              domainsData={(domainsData?.data ?? []).map((domain) => ({
                label: domain.title ?? "",
                value: domain._id ?? "",
              }))}
            />

            {/* Submit/Cancel buttons for the inline form */}
            <Grid w="100%" align="center">
              {multiMemberActivity && (
                <Grid.Col span={8}>
                  <Checkbox
                    checked={createForAllMembersInActivity}
                    onChange={(e) =>
                      setCreateForAllMembersInActivity(e.target.checked)
                    }
                    label={
                      <>
                        Create for all members of activity
                        <QuestionTooltip toolTipMessage="If checked, this will create a separate note for each member in the activity." />
                      </>
                    }
                  />
                </Grid.Col>
              )}
              <Grid.Col span={multiMemberActivity ? 4 : 12}>
                <Group position="right" spacing="xs">
                  {" "}
                  <Button
                    color="red"
                    variant="outline"
                    onClick={onClose}
                    disabled={submissionPending}
                    size="xs"
                  >
                    <IconX size={14} />
                  </Button>
                  <Button type="submit" loading={submissionPending} size="xs">
                    {" "}
                    <IconCheck size={14} />
                  </Button>
                </Group>
              </Grid.Col>
            </Grid>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
