import { useViewport, Viewport } from "react-flow-renderer";
import styled from "styled-components";
import { JourneyStage, JourneyStagePositions } from "src/graphql";
import {
  orderedStages,
  orderedGrabbableStages,
  templateXToToolbarX,
} from "./util";

const LabelTag = styled.div`
  margin: auto;
  max-width: 80%;
  whitespace: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px 5px;
  background-color: rgb(200, 200, 200);
  color: black;
  border-radius: 3px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: semi-bold;
`;

type StageLabelProps = {
  stage: JourneyStage;
  stagePositions: JourneyStagePositions;
};

export const StageLabel = ({ stage, stagePositions }: StageLabelProps) => {
  const viewport = useViewport();

  const width = getWidth(stagePositions, stage, viewport);
  const marginLeft = getMarginLeft(stage, stagePositions, viewport);

  return (
    <div
      style={{
        flexGrow: stage === JourneyStage.Maintenance ? 1 : 0,
        display: "flex",
        justifyContent: "center",
        marginLeft,
        width,
      }}
    >
      <LabelTag>{stage}</LabelTag>
    </div>
  );
};

const getMarginLeft = (
  stage: JourneyStage,
  stagePositions: JourneyStagePositions,
  viewport: Viewport
) => {
  if (stage !== orderedStages[0]) return;

  return Math.min(
    0,
    templateXToToolbarX(stagePositions[orderedGrabbableStages[0]], viewport)
  );
};

const getWidth = (
  stagePositions: JourneyStagePositions,
  stage: JourneyStage,
  viewport: Viewport
) => {
  const start =
    stage === JourneyStage.Precontemplation
      ? 0
      : templateXToToolbarX(stagePositions[stage], viewport);
  const stageIndex =
    stage === JourneyStage.Precontemplation
      ? -1
      : orderedGrabbableStages.indexOf(stage);
  const nextStage = orderedGrabbableStages[stageIndex + 1];
  if (!nextStage) return "auto";

  const end = templateXToToolbarX(stagePositions[nextStage], viewport);
  const width = end - start;

  return `${width}px`;
};
