/**
 * Type-safe utility to omit array of fields from object and narrow return type
 */
export const omitFields = <
  T extends Record<string, unknown>,
  K extends Array<keyof T> | ReadonlyArray<keyof T>
>(
  obj: T,
  fields: K
): Omit<T, typeof fields[number]> => {
  const intermediate = { ...obj };
  for (const field of fields) {
    delete intermediate[field];
  }
  return intermediate;
};
