import { Button, ButtonProps } from "@mantine/core";
import { PolymorphicComponentProps } from "@mantine/utils";
import { Plus } from "react-feather";

import { AuthContextType } from "src/providers";
import { useAuthContext } from "src/hooks";

type AddTemplateButtonProps = PolymorphicComponentProps<
  "button",
  ButtonProps
> & {
  text: string;
  permission: keyof AuthContextType["userPerms"];
};

export const AddTemplateButton = ({
  text,
  permission,
  ...props
}: AddTemplateButtonProps) => {
  const { userPerms } = useAuthContext();
  if (!userPerms[permission]) return null;
  return (
    <Button {...props} leftIcon={<Plus size="1em" />}>
      {text}
    </Button>
  );
};
