import { gql } from "@apollo/client";
import { trainingFields } from "./types";

export const GET_TRAININGS = gql`
  query GetTrainingsByUserId($userId: ID!) {
    trainingsByUserId(userId: $userId) {
      success
      message
      data {
        ${trainingFields}
      }
    }
  }
`;

export const GET_TRAINING = gql`
  query GetTraining($trainingId: ID!) {
    training(trainingId: $trainingId) {
      success
      message
      data {
        ${trainingFields}
      }
    }
  }
`;
