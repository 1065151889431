import { Disposition } from "src/graphql";

export const DispositionMessages: Partial<Record<Disposition, string>> = {
  CallCompleted: "Call Completed",
  LeftVoicemail: "Left Voicemail",
  BusyLine: "Busy Line",
  RequestedCallBack: "Requested Call Back",
  CallDropped: "Call Dropped",
  UnableToLeaveVoicemail: "Unable To Leave Voicemail",
  IncorrectOrDisconnectedNumber: "Incorrect or Disconnected Number",
  NotInterested: "Not Interested",
  DoNotCall: "Do Not Call",
};
