import { gql } from "@apollo/client";
import { PhoneCallFields } from "./types";

export const CREATED_PHONE_CALL_ENTRY_EVENT = gql`
  subscription OnCreatedPhoneCallEntry($organizationId: ID!) {
    createdPhoneCallEntry(organizationId: $organizationId) {
      ${PhoneCallFields}
    }
  }
`;

export const UPDATED_PHONE_CALL_ENTRY_EVENT = gql`
  subscription OnUpdatedPhoneCallEntry($organizationId: ID!) {
    updatedPhoneCallEntry(organizationId: $organizationId) {
      ${PhoneCallFields}
    }
  }
`;

export const DELETED_PHONE_CALL_ENTRY_EVENT = gql`
    subscription OnDeletedPhoneCallEntry($organizationId: ID!) {
        deletedPhoneCallEntry(organizationId: $organizationId) {
            ${PhoneCallFields}
        }
    }
`;
