import { Button, Group, Text } from "@mantine/core";
import { Edit } from "react-feather";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";

import {
  LoaderComponent,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalSubHeader,
  StyledLabel,
} from "src/components";
import {
  LockingReference,
  LockingReferenceType,
  TitledLockingReference,
  useQueryTitledReferences,
} from "src/graphql";
import { useAuthContext } from "src/hooks";

const basePathsByReferenceType: Record<LockingReferenceType, string> = {
  [LockingReferenceType.Activity]: "/activity",
  [LockingReferenceType.ActivityTemplate]: "/builder/activities",
  [LockingReferenceType.CarePathwayTemplate]: "/builder/carepathways",
  [LockingReferenceType.FlowTemplate]: "/builder/flows",
  [LockingReferenceType.Organization]: "/organization",
  [LockingReferenceType.Recommendation]: "/recommendations",
  [LockingReferenceType.CallRoutingConfiguration]: "/organization",
  [LockingReferenceType.ReferralTemplate]: "/external-resources",
  [LockingReferenceType.ConditionItem]: "/recommendations",
  [LockingReferenceType.Question]: "/data-management",
  [LockingReferenceType.ConsentTemplate]: "/organization",
  [LockingReferenceType.Calculation]: "/data-management",
  [LockingReferenceType.CalculationVariable]: "/data-management",
};

export const labelsByReferenceType: Record<LockingReferenceType, string> = {
  [LockingReferenceType.Activity]: "Activities",
  [LockingReferenceType.ActivityTemplate]: "Activity Templates",
  [LockingReferenceType.CarePathwayTemplate]: "Care Pathway Templates",
  [LockingReferenceType.FlowTemplate]: "Flow Templates",
  [LockingReferenceType.Organization]: "Organization Onboarding Survey",
  [LockingReferenceType.Recommendation]: "Recommendations",
  [LockingReferenceType.ReferralTemplate]: "Referral Templates",
  [LockingReferenceType.CallRoutingConfiguration]: "Call Routing Configuration",
  [LockingReferenceType.ConditionItem]: "Condition items",
  [LockingReferenceType.Question]: "Questions",
  [LockingReferenceType.ConsentTemplate]: "Consent Templates",
  [LockingReferenceType.Calculation]: "Calculations",
  [LockingReferenceType.CalculationVariable]: "Calculation Variables",
};

type ResolveLockingReferencesModalProps = {
  isOpen: boolean;
  activeReferences: LockingReference[];
  onRequestClose: () => void;
};

export const ResolveLockingReferencesModal = ({
  isOpen,
  activeReferences,
  onRequestClose,
}: ResolveLockingReferencesModalProps) => {
  const { selectedOrganizationId } = useAuthContext();

  const { loading, data } = useQueryTitledReferences(
    selectedOrganizationId,
    activeReferences
  );

  const byType = (data?.titledReferences.data ?? []).reduce((byType, ref) => {
    if (ref.referencingType in byType) {
      byType[ref.referencingType].push(ref);
    } else {
      byType[ref.referencingType] = [ref];
    }
    return byType;
  }, {} as Record<LockingReferenceType, TitledLockingReference[]>);

  return (
    <Modal opened={isOpen} onClose={onRequestClose}>
      <ModalHeader withSubHeader>Reference Conflicts</ModalHeader>

      {loading && <LoaderComponent />}

      {!loading && (
        <>
          <ModalSubHeader>
            This template is referenced by currently active templates. Please
            remove the references in the following documents (or retire these
            documents) to retire this template:
          </ModalSubHeader>

          <ModalBody>
            {Object.entries(byType).map(([referenceType, references]) => (
              <LockingReferenceList
                referenceType={referenceType as LockingReferenceType}
                references={references}
              />
            ))}
          </ModalBody>

          <ModalFooter>
            <Button color="red" onClick={onRequestClose}>
              Close
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

type LockingReferenceListProps = {
  references: TitledLockingReference[];
  referenceType: LockingReferenceType;
};

const LockingReferenceList = ({
  references,
  referenceType,
}: LockingReferenceListProps) => {
  const basePath = basePathsByReferenceType[referenceType];
  const label = labelsByReferenceType[referenceType];
  return (
    <>
      <Group spacing={0} position="apart" align="center">
        <StyledLabel>{label}:</StyledLabel>
        <Link style={{ marginBottom: 5 }} to={basePath}>
          <Text size="xs" color="blue">
            View All
          </Text>
        </Link>
      </Group>

      {references.map((ref) => (
        <LockingReferenceListItem
          key={ref.referencingId}
          reference={ref}
          basePath={basePath}
        />
      ))}
    </>
  );
};

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.05);

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

type LockingReferenceListItemProps = {
  reference: TitledLockingReference;
  basePath: string;
};

const LockingReferenceListItem = ({
  reference,
  basePath,
}: LockingReferenceListItemProps) => {
  const history = useHistory();

  return (
    <StyledContainer>
      {reference.title}

      {reference.referencingType !== LockingReferenceType.ReferralTemplate && (
        <Button
          size="xs"
          leftIcon={<Edit size={18} />}
          onClick={() => history.push(`${basePath}/${reference.referencingId}`)}
        >
          Edit
        </Button>
      )}
    </StyledContainer>
  );
};
