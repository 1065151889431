import { css } from "@emotion/css";
import { Group, Loader, Paper, Stack, Text, Tooltip } from "@mantine/core";
import dayjs from "dayjs";
import { AlertCircle, Check } from "react-feather";

import { TextMessageDirection, TextMessageStatus } from "src/graphql";

type MessageItemProps = {
  direction: TextMessageDirection;
  body: string;
  status: TextMessageStatus;
  createdAt: string;
};

enum UIMessageStatus {
  Sending,
  Failed,
  Successful,
}

const mapMessageBackendStatusToUIStatus = (
  status: TextMessageStatus
): UIMessageStatus => {
  switch (status) {
    case TextMessageStatus.PartiallyDelivered:
    case TextMessageStatus.Sending:
    case TextMessageStatus.Receiving:
    case TextMessageStatus.Scheduled:
    case TextMessageStatus.Queued:
      return UIMessageStatus.Sending;

    case TextMessageStatus.Accepted:
    case TextMessageStatus.Delivered:
    case TextMessageStatus.Received:
    case TextMessageStatus.Sent:
    case TextMessageStatus.Read:
      return UIMessageStatus.Successful;

    case TextMessageStatus.Undelivered:
    case TextMessageStatus.Failed:
    case TextMessageStatus.Canceled:
      return UIMessageStatus.Failed;
  }
};

const MessageStatus = (props: { status: TextMessageStatus }) => {
  const status = mapMessageBackendStatusToUIStatus(props.status);

  if (status === UIMessageStatus.Sending)
    return <Loader color="green" size="xs" />;

  if (status === UIMessageStatus.Failed)
    return (
      <Text color="red" pb={3}>
        <AlertCircle size={16} />
      </Text>
    );

  return (
    <Text color="green" pb={2}>
      <Check size={16} />
    </Text>
  );
};

export const MessageItem = ({
  body,
  direction,
  createdAt,
  status,
}: MessageItemProps) => {
  const parsedCreatedAt = dayjs(createdAt);
  const statusText = status
    .split("_")
    .map((string) => string[0].toUpperCase() + string.slice(1))
    .join(" ");

  if (direction === TextMessageDirection.Outgoing)
    return (
      <Stack
        data-testid="message-item"
        spacing={5}
        maw={311}
        align="flex-end"
        style={{ alignSelf: "end" }}
      >
        <Paper shadow="sm" withBorder p="xs" bg="green">
          <Text size="sm" color="black.0" style={{ wordBreak: "break-word" }}>
            {body}
          </Text>
        </Paper>

        <Tooltip
          position="bottom-start"
          label={`${parsedCreatedAt.format("LLL")}, ${statusText}`}
        >
          <Group spacing={5}>
            <MessageStatus status={status} />

            <Text
              variant="caption"
              size="xs"
              className={css`
                &:first-letter: {
                  text-transform: capitalize;
                }
              `}
            >
              {dayjs.duration(parsedCreatedAt.diff()).humanize(true)}
            </Text>
          </Group>
        </Tooltip>
      </Stack>
    );

  return (
    <Stack
      data-testid="message-item"
      spacing={5}
      maw={311}
      align="flex-start"
      style={{ alignSelf: "start" }}
    >
      <Paper shadow="sm" withBorder p="xs">
        <Text size="sm" style={{ wordBreak: "break-word" }}>
          {body}
        </Text>
      </Paper>

      <Tooltip position="bottom-start" label={parsedCreatedAt.format("LLL")}>
        <Text
          variant="caption"
          size="xs"
          className={css`
            &:first-letter: {
              text-transform: capitalize;
            }
          `}
        >
          {dayjs.duration(parsedCreatedAt.diff()).humanize(true)}
        </Text>
      </Tooltip>
    </Stack>
  );
};
