import { gql } from "@apollo/client";
import {
  notificationFields,
  notificationSettingsFields,
  notificationsMetadataFields,
} from "./types";

export const PAGINATED_NOTIFICATIONS = gql`
  query PaginatedNotifications(
    $showOnlyUnRead: Boolean!
    $page: Int!
  ) {
    paginatedNotifications(
      showOnlyUnRead: $showOnlyUnRead
      page: $page
    ) {
      success
      message
      data {
       ${notificationFields}
      }
    }
  }
`;

export const NOTIFICATIONS_METADATA = gql`
query NotificationsMetadata {
  notificationsMetadata {
    success
    message
    data {
     ${notificationsMetadataFields}
    }
  }
}
`;

export const NOTIFICATION_SETTINGS = gql`
query NotificationSettings {
  notificationSettings {
    success
    message
    data {
     ${notificationSettingsFields}
    }
  }
}
`;
