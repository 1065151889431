import * as Yup from "yup";
import { ConsentMethod, ConsentTemplate } from "src/graphql";
import { ySelectOptionSchema } from "./yup";
import { getEnumStringValues } from "./enum";

export const ConsentFormSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  description: Yup.string().nullable(true),
  method: ySelectOptionSchema(
    Yup.string().oneOf(getEnumStringValues(ConsentMethod))
  ).strict(true),
  retiredAt: Yup.string(),
  fileId: Yup.string(),
  phoneCallId: Yup.string(),
});

export type ConsentFormType = Yup.InferType<typeof ConsentFormSchema>;

export const getSelectedConsentMethods = (
  consentTemplates?: ConsentTemplate[],
  consentTemplateId?: string
) => {
  const consentMethods = [];
  if (consentTemplates && consentTemplateId) {
    const template = consentTemplates.find((t) => t._id === consentTemplateId);
    template?.consentMethods && consentMethods.push(...template.consentMethods);
  }
  return consentMethods;
};

export const isConsentFormValid = (values: ConsentFormType) => {
  if (!values.method) {
    return false;
  }

  const selectedMethod = values.method.value;

  if (
    selectedMethod === ConsentMethod.ChwAttestation ||
    (selectedMethod === ConsentMethod.DocumentRequired && values.fileId) ||
    (selectedMethod === ConsentMethod.CallRecordingRequired &&
      values.phoneCallId)
  ) {
    return true;
  }
  return false;
};

export const ConsentMethodLabel: Record<ConsentMethod, string> = {
  [ConsentMethod.CallRecordingRequired]: "Require Call Recording",
  [ConsentMethod.DocumentRequired]: "Require Document",
  [ConsentMethod.ChwAttestation]: "CHW Attestation",
};

export const makeMethodOptions = (
  methods: ConsentMethod[] = Object.values(ConsentMethod)
) =>
  methods.map((value) => ({
    label: ConsentMethodLabel[value],
    value: value,
  }));
