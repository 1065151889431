import { useState, useEffect } from "react";

import { MultiSelect, Box, Group } from "@mantine/core";
import { useAuthContext } from "src/hooks";
import {
  usePaginatedQueryReferralsByMember,
  usePaginatedQueryActivities,
  useQueryCarePathwaysByMemberId,
} from "src/graphql";

type SelectInterventionDropdownProps = {
  memberId: string;
  selectedData: any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
  setSelectedData: (data: any[]) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
};

export const SelectInterventionDropdown = ({
  memberId,
  selectedData,
  setSelectedData,
}: SelectInterventionDropdownProps) => {
  const { selectedOrganization } = useAuthContext();

  const { data: activitiesData, loading: loadingActivities } =
    usePaginatedQueryActivities({
      organizationId: selectedOrganization._id,
      memberId: memberId,
    });

  const { data: referralsData, loading: loadingReferrals } =
    usePaginatedQueryReferralsByMember({ memberId: memberId });

  const { data: carePathwaysData, loading: loadingCarePathways } =
    useQueryCarePathwaysByMemberId(memberId);
  const [selectedActivities, setSelectedActivities] = useState<string[]>(
    selectedData
      .filter((data) => data.__typename === "Activity")
      .map((data) => data._id) ?? []
  );
  const [selectedReferrals, setSelectedReferrals] = useState<string[]>(
    selectedData
      .filter((data) => data.__typename === "Referral")
      .map((data) => data._id) ?? []
  );
  const [selectedCarePathways, setSelectedCarePathways] = useState<string[]>(
    selectedData
      .filter((data) => data.__typename === "CarePathway")
      .map((data) => data._id) ?? []
  );

  useEffect(() => {
    const combinedSelectedData = [
      ...(activitiesData?.data ?? []).filter((activity) =>
        selectedActivities.includes(activity._id)
      ),
      ...(referralsData?.data ?? []).filter((referral) =>
        selectedReferrals.includes(referral._id)
      ),
      ...(carePathwaysData?.carePathwaysByMemberId?.data ?? []).filter(
        (carePathway) => selectedCarePathways.includes(carePathway._id)
      ),
    ];
    setSelectedData(combinedSelectedData);
  }, [
    selectedActivities,
    selectedReferrals,
    selectedCarePathways,
    setSelectedData,
    activitiesData,
    referralsData,
    carePathwaysData,
  ]);

  const handleActivitySelect = (selected: string[]) => {
    setSelectedActivities(selected);
  };

  const handleReferralSelect = (selected: string[]) => {
    setSelectedReferrals(selected);
  };

  const handleCarePathwaySelect = (selected: string[]) => {
    setSelectedCarePathways(selected);
  };

  const isLoading =
    loadingActivities || loadingReferrals || loadingCarePathways;

  return (
    <Box mb="md">
      <Group grow mb="sm">
        <MultiSelect
          label="Select Activities"
          data={
            activitiesData?.data?.map((item) => ({
              value: item._id,
              label: item.title,
            })) || []
          }
          onChange={handleActivitySelect}
          value={selectedActivities ?? []}
          disabled={isLoading}
          searchable
          nothingFound="No options"
          clearable
          placeholder="Select Activities..."
        />
      </Group>
      <Group grow mb="sm">
        <MultiSelect
          label="Select Referrals"
          data={
            referralsData?.data?.map((item) => ({
              value: item._id,
              label: item.referralTemplateTitle,
            })) || []
          }
          onChange={handleReferralSelect}
          value={selectedReferrals ?? []}
          disabled={isLoading}
          searchable
          nothingFound="No options"
          clearable
          placeholder="Select Referrals..."
        />
      </Group>
      <Group grow mb="sm">
        <MultiSelect
          label="Select Care Pathways"
          data={
            carePathwaysData?.carePathwaysByMemberId?.data
              ?.filter((i) => i.carePathwayTemplate?.name || i.name)
              .map((item) => ({
                value: item._id,
                label: item?.carePathwayTemplate?.name ?? item.name,
              })) || []
          }
          onChange={handleCarePathwaySelect}
          value={selectedCarePathways ?? []}
          disabled={isLoading}
          searchable
          nothingFound="No options"
          clearable
          placeholder="Select Care Pathways..."
        />
      </Group>
    </Box>
  );
};
