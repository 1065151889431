import { gql } from "@apollo/client";
import { ExternalContactFields, ExternalResourceFields } from "./types";

export const GET_EXTERNAL_RESOURCES = gql`
  query GetExternalResources($organizationId: ID!) {
    externalResources(organizationId: $organizationId) {
      success
      message
      data {
        ${ExternalResourceFields}
      }
    }
  }
`;

export const GET_EXTERNAL_RESOURCE_CONTACTS = gql`
  query GetExternalResourceContacts($input: GetExternalResourceContactsInput!, $pagination: PaginationInput!) {
    response: externalResourceContacts(input: $input, pagination: $pagination) {
      success
      message
      data {
        total
        page
        data {
          ${ExternalContactFields}
        }
      }
    }
  }
`;
