import { gql } from "@apollo/client";

export const CARE_PATHWAYS_BY_MEMBER_ID = gql`
  query CarePathwaysByMemberId($memberId: ID!, $episodeOfCareId: String) {
    carePathwaysByMemberId(
      memberId: $memberId
      episodeOfCareId: $episodeOfCareId
    ) {
      success
      message
      data {
        _id
        createdAt
        name
        isDefault
        completedPercentage
        carePathwayTemplateId
        status
        completedAt
        carePathwayTemplate {
          name
        }
      }
    }
  }
`;
