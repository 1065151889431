import { Dictionary } from "src/types";

export const JWT_ERRORS_SET: Dictionary = {
  "jwt malformed": "Invalid invitation url",
  "jwt expired": "Invitation expired",
  "invalid signature": "Password already reset using this url",
};

export const LOGIN_ERRORS = {
  TOO_MANY_ATTEMPTS:
    "Access to this account has been temporarily disabled due to many failed login attempts. Please try again later.",
  INCORRECT_PASSWORD: "Your email or password is incorrect. Please try again.",
};

export const MFA_ERRORS = {
  INVALID_CODE: "The code you have entered is incorrect. Please try again.",
  SESSION_EXPIRED:
    "Unfortunately, your session has expired. Please get a new code and try again",
  GENERIC_ERROR: "Unknown error occurred. Please try again.",
};
