export const PSFileFields = `
  _id
  title
  realFileName
  description
  organizationId
  memberId
  tags {
    _id
    label
    dataId
  }
  createdAt
  mimetype
  encoding
`;
