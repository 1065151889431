import { FormikInput, FormikSelect } from "src/components";
import {
  Consent,
  ConsentMethod,
  ConsentTemplate,
  PhoneCallIdentifierType,
  useLazyQueryPhoneCall,
  usePaginatedQueryMemberFiles,
  usePaginatedQueryPhoneCalls,
} from "src/graphql";
import { Form, Formik, FormikProps } from "formik";
import { useEffect, useMemo, useRef, useState } from "react";
import { formatDuration, parseDate } from "src/utils";
import { FormikDateTimePickerInput } from "../input/FormikDateTimePickerInput";
import {
  ConsentFormSchema,
  ConsentFormType,
  ConsentMethodLabel,
  makeMethodOptions,
  isConsentFormValid,
} from "src/utils/consent";
import { Text, Button, Stack } from "@mantine/core";
import useTwilio from "src/hooks/useTwilio";
import toast from "src/libs/toast";
import { useAuthContext } from "src/hooks";

export const NO_CONSENT_TEMPLATE_ID = "no_template";

interface ConsentModalProps {
  consent?: Consent;
  organizationId: string;
  memberId: string;

  selectedTemplate?: ConsentTemplate;
  onSubmit: (values: ConsentFormType) => void;
  /**
   * Disables form inputs and updating
   * @default false
   */
  readOnly?: boolean;
  /**
   * Exposes Formik's instance API, e.g. functions like ref.current.submitForm()
   */
  innerRef: React.RefObject<FormikProps<ConsentFormType>>;
  /**
   * Optional callback to subscribe to Formik's "dirty" state
   */
  onDirtyStateChange?: (dirty: boolean) => void;
  /**
   * Optional callback to subscribe to Formik's "isValid" state
   */
  onValidStateChange?: (dirty: boolean) => void;
}

export const ConsentForm = ({
  readOnly,
  onSubmit,
  innerRef,
  consent,
  organizationId,
  memberId,
  selectedTemplate,
  onDirtyStateChange,
  onValidStateChange,
}: ConsentModalProps) => {
  const dirtyStateRef = useRef(false);
  const validStateRef = useRef(false);

  const {
    widget,
    isCallInProgress,
    isIncomingCallInProgress,
    incomingCall,
    conferenceFriendlyName,
  } = useTwilio();
  const { selectedOrganizationId } = useAuthContext();

  const [lazyQueryPhoneCall, { loading: queryPhoneCallBySid }] =
    useLazyQueryPhoneCall();

  const [currentCallId, setCurrentCallId] = useState<string | undefined>();

  // reset form state whenever template changes
  useEffect(() => {
    dirtyStateRef.current = false;
    validStateRef.current = false;
    onDirtyStateChange?.(false);
    onValidStateChange?.(false);
    innerRef?.current?.resetForm();
  }, [
    selectedTemplate,
    innerRef,
    dirtyStateRef,
    validStateRef,
    onDirtyStateChange,
    onValidStateChange,
  ]);

  const {
    data: files,
    setSearchTerm: setFileSearchTerm,
    loading: filesLoading,
  } = usePaginatedQueryMemberFiles({
    organizationId,
    memberId,
  });
  const fileOptions = useMemo(
    () =>
      (files?.data ?? []).map((file) => ({
        label: file.realFileName,
        value: file._id,
      })),
    [files]
  );

  const {
    data: phoneCalls,
    loading: phoneCallLoading,
    setSearchTerm: setPhoneCallSearchTerm,
  } = usePaginatedQueryPhoneCalls({
    organizationId,
    memberId,
  });
  const phoneCallOptions = useMemo(
    () =>
      (phoneCalls?.data ?? [])
        .filter((call) => call.initiatingCallSid)
        .map((call) => ({
          label: `${parseDate(call.createdAt)} (${formatDuration(
            call.duration
          )})`,
          value: call._id,
        })),
    [phoneCalls]
  );

  const useCurrentCallForConsentClicked = async () => {
    if (currentCallId) {
      setCurrentCallId(undefined);
      return;
    }
    try {
      const response = await lazyQueryPhoneCall({
        variables: {
          input: {
            identifierType: PhoneCallIdentifierType.ConferenceFriendlyName,
            identifier: conferenceFriendlyName,
            organizationId:
              (isIncomingCallInProgress
                ? incomingCall?.organization._id
                : selectedOrganizationId) ?? "",
          },
        },
      });

      const callId = response.data?.phoneCall.data?._id;
      if (!callId) {
        throw new Error();
      }

      setCurrentCallId(callId);
      innerRef.current?.setFieldValue("phoneCallId", callId);
    } catch (e) {
      toast.error("Issue while fetching call information");
    }
  };

  const isMemberInCurrentCall = useMemo(() => {
    return (
      Boolean(
        widget.members?.filter((memberInfo) => memberInfo._id === memberId)
          .length
      ) &&
      (isCallInProgress || isIncomingCallInProgress)
    );
  }, [isCallInProgress, isIncomingCallInProgress, memberId, widget.members]);

  const initialValues = useMemo(() => {
    if (consent) {
      return {
        name: consent.name,
        description: consent.description,
        retiredAt: consent.retiredAt,
        ...(consent.fileId ? { fileId: consent.fileId } : {}),
        ...(consent.phoneCallId ? { phoneCallId: consent.phoneCallId } : {}),
        method: consent.consentMethod
          ? {
              value: consent.consentMethod,
              label: ConsentMethodLabel[consent.consentMethod],
            }
          : undefined,
      };
    }
    if (selectedTemplate) {
      return {
        name: selectedTemplate.name,
        method:
          selectedTemplate.consentMethods &&
          selectedTemplate.consentMethods.length === 1
            ? {
                value: selectedTemplate.consentMethods[0],
                label: ConsentMethodLabel[selectedTemplate.consentMethods[0]],
              }
            : undefined,
      };
    }
    return {};
  }, [consent, selectedTemplate]);

  return (
    <Formik
      initialValues={initialValues as ConsentFormType}
      validationSchema={ConsentFormSchema}
      onSubmit={(values) => {
        !readOnly && onSubmit(values);
      }}
      validateOnMount
      innerRef={innerRef}
      enableReinitialize
    >
      {({ values, setFieldValue, isValid, dirty, errors }) => {
        if (onDirtyStateChange && dirty !== dirtyStateRef.current) {
          dirtyStateRef.current = dirty;
          requestAnimationFrame(() => onDirtyStateChange(dirty));
        }
        const isFormValid = isValid && isConsentFormValid(values);

        if (onValidStateChange && isFormValid !== validStateRef.current) {
          validStateRef.current = isFormValid;
          requestAnimationFrame(() => onValidStateChange(isFormValid));
        }
        return (
          <Form noValidate>
            <Stack>
              <FormikInput
                type="text"
                name="name"
                label="Name"
                required
                disabled={Boolean(selectedTemplate)}
              />
              <FormikSelect
                name="method"
                label="Consent Method"
                placeholder="Select consent method..."
                options={makeMethodOptions(selectedTemplate?.consentMethods)}
                disabled={Boolean(consent)}
                required
              />
              <FormikInput type="text" name="description" label="Description" />
              <FormikDateTimePickerInput
                clearable={false}
                label="Expiry Date"
                name="retiredAt"
              />
              {values.method?.value === ConsentMethod.DocumentRequired && (
                <FormikSelect
                  name="fileId"
                  label="File"
                  placeholder="Select file"
                  isLoading={filesLoading}
                  options={fileOptions}
                  onSearchChange={setFileSearchTerm}
                  searchable
                  onChangeOverride={(option) =>
                    setFieldValue("fileId", option?.value ?? undefined)
                  }
                  disabled={Boolean(consent)}
                />
              )}
              {values.method?.value === ConsentMethod.CallRecordingRequired && (
                <>
                  <FormikSelect
                    name="phoneCallId"
                    label="Call Recording"
                    placeholder="Select call recording"
                    isLoading={phoneCallLoading}
                    options={phoneCallOptions}
                    onSearchChange={setPhoneCallSearchTerm}
                    searchable
                    onChangeOverride={(option) =>
                      setFieldValue("phoneCallId", option?.value ?? undefined)
                    }
                    disabled={Boolean(consent) || Boolean(currentCallId)}
                  />
                  {isMemberInCurrentCall && (
                    <Stack>
                      <Text size="sm" align="center">
                        OR
                      </Text>
                      <Button
                        onClick={useCurrentCallForConsentClicked}
                        variant="light"
                        loading={queryPhoneCallBySid}
                      >
                        {currentCallId
                          ? "Remove current active call from consent"
                          : "Use current active call for consent"}
                      </Button>
                    </Stack>
                  )}
                </>
              )}
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
};
