import { LockingReferenceFields } from "../Common/types";
import { NoteFields } from "../Note/types";

export const ExternalResourceContactFields = `
  _id
  externalResourceId
  name
  description
  phone
  email
  notes {
    ${NoteFields}
  }
  isActive
  lockingReferences {
    ${LockingReferenceFields}
  }
  accessType
`;

export const ExternalResourceFields = `
  _id
  organizationId
  title
  shortDescription
  longDescription
  contacts {
    ${ExternalResourceContactFields}
  }
  notes {
    ${NoteFields}
  }
  isActive
  lockingReferences {
    ${LockingReferenceFields}
  }
  address
  address2
  city
  state
  zip
  country
  accessType
`;

export const ExternalContactFields = `
  _id
  externalResourceId
  externalResourceName
  name
  email
  phone
`;
