import { Button, Grid, Group, Stack } from "@mantine/core";
import dayjs from "dayjs";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { useCallback, useMemo } from "react";

//utils
import {
  omitFields,
  unwrapSelectOption,
  unwrapSelectOptionsArray,
  wrapSelectOption,
  wrapSelectOptionsArray,
} from "src/utils";
import { MembersSchema } from "src/validation";

// custom component & constants
import {
  AutoFocusErroredField,
  FormikInput,
  FormikMultiSelect,
  FormikSelect,
} from "src/components";
import { SectionHeader } from "src/components/display/SectionHeader";
import {
  languageSelectOptions,
  raceSelectOptions,
  timezoneSelectOptions,
} from "src/constants/member";
import { MemberProfileInformationSection } from "./onboarding-form-sections/MemberProfileInformationSection";

// Types
import {
  COUNTRIES,
  FormikCountrySelect,
} from "src/components/input/FormikCountrySelect";
import {
  ContactInfo,
  EducationLevel,
  EmploymentStatus,
  EnrollmentStatus,
  Ethnicity,
  Gender,
  Integration,
  Language,
  MaritalStatus,
  Member,
  MemberProfileSettings,
  Organization,
  PregnancyStatus,
  Pronouns,
  Sex,
  SexualOrientation,
} from "src/graphql";
import { SelectOption } from "src/types";
import { FormikStateSelect } from "src/components/input/FormikStateSelect";

type MemberFormProps = {
  member?: Member;
  onSubmit: (values: MemberValues, reset: () => void) => Promise<void>;
  organization: Organization;
  loading?: boolean;
  submitLabel?: string;
  innerRef?: React.RefObject<FormikProps<MemberFormValues>>;
  hideSubmit?: boolean;
};
export function useEHRIntegrations(organization: Organization): boolean {
  return useMemo(() => {
    const integrations: Integration[] =
      organization.activatedIntegrations ?? [];
    return (
      integrations.includes(Integration.RedoxNoteSending) ||
      integrations.includes(Integration.RedoxPatientSync)
    );
  }, [organization.activatedIntegrations]);
}

export function useInsuranceCompanyOptions(
  organization: Organization
): SelectOption<string>[] {
  return useMemo(() => {
    return organization.billingInsuranceConfigurations.map((i) =>
      wrapSelectOption(i.insurance)
    );
  }, [organization.billingInsuranceConfigurations]);
}

export function useMemberFormInitialValues(
  member: Member | undefined,
  organization: Organization
) {
  return useMemo(() => {
    return wrapFormValues(
      member ?? makeBaseMember(organization._id),
      organization
    );
  }, [member, organization]);
}

export const MemberFormFormik = ({
  member,
  onSubmit,
  organization,
  loading = false,
  submitLabel = "Submit",
  innerRef,
  hideSubmit,
}: MemberFormProps) => {
  const groupOptions = (organization.groups ?? []).map((group) => ({
    value: group._id,
    label: group.title,
  }));

  const memberProfileSettings: MemberProfileSettings =
    organization?.memberProfileSettings ?? {};
  const insuranceCompanyOptions = useInsuranceCompanyOptions(organization);
  const ehrIntegrationsEnabled = useEHRIntegrations(organization);
  const initValues = useMemberFormInitialValues(member, organization);

  const onFormSubmit = useCallback(
    async (
      values: MemberFormValues,
      { resetForm, setSubmitting }: FormikHelpers<MemberFormValues>
    ) => {
      setSubmitting(true);
      const unwrapped = unwrapFormValues(values);
      await onSubmit(unwrapped, resetForm);
      setSubmitting(false);
    },
    [onSubmit]
  );

  const hideMemberProfile =
    memberProfileSettings.memberPreferredNameHidden &&
    memberProfileSettings.memberDOBHidden &&
    memberProfileSettings.memberSexHidden &&
    memberProfileSettings.memberGenderHidden &&
    memberProfileSettings.memberPronounsHidden &&
    memberProfileSettings.memberRaceHidden &&
    memberProfileSettings.memberEthnicityHidden &&
    memberProfileSettings.memberLanguagesHidden &&
    memberProfileSettings.memberSexualOrientationHidden &&
    memberProfileSettings.memberPregnancyHidden;

  return (
    <Formik
      initialValues={initValues}
      validationSchema={MembersSchema}
      validateOnChange={true}
      enableReinitialize={true}
      onSubmit={onFormSubmit}
      {...(innerRef ? { innerRef } : {})}
    >
      {({ handleSubmit, setValues, values }) => {
        return (
          <form onSubmit={handleSubmit}>
            {/**
             * Basic Information (REQUIRED)
             */}
            <Stack spacing={30}>
              <fieldset>
                <SectionHeader>Basic Info</SectionHeader>
                <Grid px={10}>
                  <Grid.Col md={4} sm={6}>
                    <FormikInput
                      label="First Name"
                      name="firstName"
                      type="text"
                      placeholder="Enter First Name"
                      required
                    />
                  </Grid.Col>
                  <Grid.Col md={4} sm={6}>
                    <FormikInput
                      type="text"
                      name="lastName"
                      label="Last Name"
                      placeholder="Enter Last name"
                      required
                    />
                  </Grid.Col>
                  <Grid.Col md={4} sm={6}>
                    <FormikInput
                      type="tel"
                      name="contactInfo.phone"
                      label="Phone"
                      placeholder="Enter Phone"
                      required
                    />
                  </Grid.Col>

                  <Grid.Col md={4} sm={6}>
                    <FormikInput
                      type="email"
                      name="contactInfo.email"
                      label="Email"
                      placeholder="Enter Email"
                    />
                  </Grid.Col>
                  {!memberProfileSettings.memberPhoneticHidden && (
                    <Grid.Col md={4} sm={6}>
                      <FormikInput
                        type="text"
                        name="phonetic"
                        label="Name Pronunciation"
                        placeholder="Enter Name Pronunciation"
                      />
                    </Grid.Col>
                  )}
                  {!memberProfileSettings.memberSocialSecurityNumberHidden && (
                    <Grid.Col md={4} sm={6}>
                      <FormikInput
                        min={9}
                        type="text"
                        name="socialSecurityNumber"
                        label="Social Security Number"
                        placeholder="Enter SSN"
                      />
                    </Grid.Col>
                  )}
                </Grid>
              </fieldset>

              {/**
               * Extended Profile Info
               */}
              {!hideMemberProfile && (
                <fieldset>
                  <SectionHeader>Profile</SectionHeader>
                  <MemberProfileInformationSection
                    memberProfileSettings={memberProfileSettings}
                    raceSelectOptions={raceSelectOptions}
                    ethnicitySelectOptions={ethnicitySelectOptions}
                    shouldShowTitle={false}
                    sexSelectOptions={sexSelectOptions}
                    genderSelectOptions={genderSelectOptions}
                    pronounsSelectOptions={pronounsSelectOptions}
                    sexualOrientationSelectOptions={
                      sexualOrientationSelectOptions
                    }
                    pregnancyStatusSelectOptions={pregnancyStatusSelectOptions}
                    languageSelectOptions={languageSelectOptions}
                    employmentStatusSelectOptions={
                      employmentStatusSelectOptions
                    }
                    maritalStatusSelectOptions={maritalStatusSelectOptions}
                    highestLevelOfEducationSelectOptions={
                      highestLevelOfEducationSelectOptions
                    }
                  />
                </fieldset>
              )}

              {/**
               * Insurance Information
               */}
              <fieldset>
                <SectionHeader>Insurance Information</SectionHeader>
                <Grid px={10}>
                  {!memberProfileSettings.memberPrimaryInsuranceHidden && (
                    <>
                      <Grid.Col md={3} sm={6}>
                        <FormikSelect
                          name="primaryInsuranceCompany"
                          label="Primary Insurance Company"
                          placeholder="Enter Insurance Provider..."
                          options={insuranceCompanyOptions}
                        />
                      </Grid.Col>
                      <Grid.Col md={3} sm={6}>
                        <FormikInput
                          name="primaryCIN"
                          type="text"
                          label="Primary Client Identification Number (CIN)"
                          placeholder="Enter CIN..."
                        />
                      </Grid.Col>
                    </>
                  )}

                  {!memberProfileSettings.memberSecondaryInsuranceHidden && (
                    <>
                      <Grid.Col md={3} sm={6}>
                        <FormikSelect
                          name="secondaryInsuranceCompany"
                          label="Secondary Insurance Company"
                          placeholder="Enter Insurance Provider..."
                          options={insuranceCompanyOptions}
                        />
                      </Grid.Col>
                      <Grid.Col md={3} sm={6}>
                        <FormikInput
                          name="secondaryCIN"
                          type="text"
                          label="Secondary Client Identification Number (CIN)"
                          placeholder="Enter CIN..."
                        />
                      </Grid.Col>
                    </>
                  )}

                  <Grid.Col md={3} sm={6}>
                    <FormikSelect
                      name="medicaidStatus"
                      label="Medicaid Status"
                      options={enrollmentStatusSelectOptions}
                    />
                  </Grid.Col>
                  <Grid.Col md={3} sm={6}>
                    <FormikSelect
                      name="medicareStatus"
                      label="Medicare Status"
                      options={enrollmentStatusSelectOptions}
                    />
                  </Grid.Col>
                </Grid>
              </fieldset>

              {/**
               * Organization & Groups
               */}
              {!memberProfileSettings.memberOrgGroupsHidden && (
                <fieldset>
                  <SectionHeader>Organization &amp; Groups</SectionHeader>
                  <Grid px={10}>
                    <Grid.Col md={4}>
                      <FormikInput
                        type="text"
                        name="organization"
                        label="Organization"
                        value={organization.title}
                        disabled
                      />
                    </Grid.Col>
                    <Grid.Col md={8}>
                      <FormikMultiSelect
                        name="contactInfo.groups"
                        label="Groups"
                        options={groupOptions}
                      />
                    </Grid.Col>
                  </Grid>
                </fieldset>
              )}

              {/**
               * Contact Details
               */}

              {!memberProfileSettings.memberAddressHidden && (
                <fieldset>
                  <SectionHeader>Contact Details</SectionHeader>
                  <Grid px={10}>
                    <Grid.Col md={12}>
                      <FormikInput
                        type="text"
                        name="contactInfo.address"
                        label="Address Line 1"
                        placeholder="Address Line 1"
                      />
                    </Grid.Col>
                  </Grid>
                  <Grid px={10}>
                    <Grid.Col md={12}>
                      <FormikInput
                        type="text"
                        name="contactInfo.address2"
                        label="Address Line 2"
                        placeholder="Address Line 2"
                      />
                    </Grid.Col>
                  </Grid>
                  <Grid px={10}>
                    <Grid.Col md={4}>
                      <FormikCountrySelect
                        name="contactInfo.country"
                        label="Country"
                        clearable={true}
                      />
                    </Grid.Col>
                    <Grid.Col md={4}>
                      {values.contactInfo.country?.value === "US" ? (
                        <FormikStateSelect
                          name="contactInfo.state"
                          label="State"
                          clearable={true}
                        />
                      ) : (
                        <FormikInput
                          name="contactInfo.state"
                          label="State"
                          type="text"
                        />
                      )}
                    </Grid.Col>
                    <Grid.Col md={4}>
                      <FormikInput
                        name="contactInfo.city"
                        label="City"
                        type="text"
                      />
                    </Grid.Col>
                  </Grid>
                  <Grid px={10}>
                    <Grid.Col md={6}>
                      <FormikInput
                        type="text"
                        name="contactInfo.zip"
                        label="Zip Code"
                        placeholder="Enter Zip Code"
                      />
                    </Grid.Col>

                    <Grid.Col md={6}>
                      <FormikSelect
                        name="timezone"
                        label="Timezone"
                        options={timezoneSelectOptions}
                      />
                    </Grid.Col>
                  </Grid>
                </fieldset>
              )}

              {ehrIntegrationsEnabled && (
                <fieldset>
                  <SectionHeader>EHR Details</SectionHeader>
                  <Grid px={10}>
                    <Grid.Col md={6}>
                      <FormikInput
                        type="text"
                        name="ehrID"
                        label="EHR ID"
                        placeholder="Enter EHR ID"
                      />
                    </Grid.Col>

                    <Grid.Col md={6}>
                      <FormikSelect
                        type="text"
                        name="ehrIDType"
                        label="EHR ID Type"
                        placeholder="Select EHR ID Type"
                        options={ehrIDTypeOptions}
                      />
                    </Grid.Col>
                  </Grid>
                </fieldset>
              )}

              {!hideSubmit && (
                <Group position="right">
                  <Button
                    onClick={() => handleSubmit()}
                    loading={loading}
                    data-testid={"member-submit-button"}
                  >
                    {submitLabel}
                  </Button>
                </Group>
              )}
              <AutoFocusErroredField />
            </Stack>
          </form>
        );
      }}
    </Formik>
  );
};

// Base member used for a blank form, when creating a new member
export const BASE_MEMBER_ID = "new-member";
export const makeBaseMember = (organization: string): Member => ({
  _id: BASE_MEMBER_ID,
  createdAt: dayjs().toISOString(),
  updatedAt: dayjs().toISOString(),
  firstName: "",
  lastName: "",
  phonetic: "",
  nickName: "",
  dob: "",
  spokenLanguages: [],
  race: [],
  timezone: "",
  contactInfo: {
    organizations: [organization],
    address2: "",
    address: "",
    groups: [],
    email: "",
    phone: "",
    zip: "",
  },
  linkedContacts: [],
  overriddenProcedureCodeLimits: [],
});

/**
 * Types & Codecs for wrapping and unwrapping form values
 */

// fields to be omitted from wrapped value
export const formOmittedFields = [
  "_id",
  "createdAt",
  "updatedAt",
  "assignedUserIds",
  "status",
  "reasonForDeactivation",
  "diagnosisCodes",
  "familyMembers",
  "lastEhrSync",
  "insuranceCoverages",
  "lastCoverageUpdate",
  "overriddenProcedureCodeLimits",
] as const;
export type FormOmittedFields = typeof formOmittedFields[number];

// fields from Member to wrap with SelectOption
export type FormRemappedFields =
  | "spokenLanguages"
  | "race"
  | "timezone"
  | "contactInfo"
  | "sex"
  | "gender"
  | "sexualOrientation"
  | "pronouns"
  | "medicareStatus"
  | "medicaidStatus"
  | "primaryInsuranceCompany"
  | "secondaryInsuranceCompany"
  | "primaryCIN"
  | "secondaryCIN"
  | "ehrIDType"
  | "pregnancyStatusEnum"
  | "ethnicity"
  | "maritalStatus"
  | "employmentStatus"
  | "highestLevelOfEducation";

// fields from Member.ContactInfo to wrap with SelectOption
export type ContactRemappedFields =
  | "groups"
  | "country"
  | "state"
  | "city"
  | "organizations";

// Mapping Member type to type with form-wrapped values
export type MemberFormValues = Omit<
  Member,
  FormOmittedFields | FormRemappedFields
> & {
  spokenLanguages: SelectOption<Language>[];
  race: SelectOption<string>[];
  timezone: SelectOption<string> | undefined;
  sex: SelectOption<Sex> | undefined;
  gender: SelectOption<Gender>[] | undefined;
  sexualOrientation: SelectOption<SexualOrientation>[];
  pronouns: SelectOption<Pronouns>[] | undefined;
  medicaidStatus: SelectOption<EnrollmentStatus> | undefined;
  medicareStatus: SelectOption<EnrollmentStatus> | undefined;
  primaryInsuranceCompany: SelectOption<string> | undefined;
  secondaryInsuranceCompany: SelectOption<string> | undefined;
  ehrIDType: SelectOption<string> | undefined;
  contactInfo: Omit<Member["contactInfo"], ContactRemappedFields> & {
    organization: string | undefined;
    groups: SelectOption<string>[];
    country: SelectOption<string> | undefined;
    state: SelectOption<string> | string | undefined;
    city: string | undefined;
  };
  pregnancyStatusEnum: SelectOption<PregnancyStatus> | undefined;
  ethnicity: SelectOption<Ethnicity> | undefined;
  maritalStatus: SelectOption<MaritalStatus> | undefined;
  employmentStatus: SelectOption<EmploymentStatus> | undefined;
  highestLevelOfEducation: SelectOption<EducationLevel> | undefined;
  organization: string | undefined;
};

export const maritalStatusSelectOptions: SelectOption<MaritalStatus>[] =
  Object.values(MaritalStatus).map((value) => ({ label: value, value }));

export const sexSelectOptions: SelectOption<Sex>[] = Object.values(Sex).map(
  (value) => ({ label: value, value })
);

export const employmentStatusLabels: Record<EmploymentStatus, string> = {
  [EmploymentStatus.Unemployed]: "Unemployed",
  [EmploymentStatus.EmployedPartTime]: "Employed - Part Time",
  [EmploymentStatus.EmployedFullTime]: "Employed - Full Time",
  [EmploymentStatus.Disabled]: "Disabled",
  [EmploymentStatus.Retired]: "Retired",
};

export const employmentStatusSelectOptions: SelectOption<EmploymentStatus>[] =
  Object.entries(employmentStatusLabels).map(([value, label]) => ({
    value: value as EmploymentStatus,
    label,
  }));

export const highestLevelOfEducationLabels: Record<EducationLevel, string> = {
  [EducationLevel.NoEducation]: "No Education",
  [EducationLevel.HighSchoolEquivalent]: "High School or Equivalent",
  [EducationLevel.SomeCollegeNoDegree]: "Some College No Degree",
  [EducationLevel.AssociateDegree]: "Associate's Degree",
  [EducationLevel.BachelorDegree]: "Bachelor's Degree",
  [EducationLevel.MasterDegree]: "Master's Degree",
  [EducationLevel.DoctoralDegree]: "Doctoral Degree",
};

export const highestLevelOfEducationSelectOptions: SelectOption<EducationLevel>[] =
  Object.entries(highestLevelOfEducationLabels).map(([value, label]) => ({
    value: value as EducationLevel,
    label,
  }));

export const genderLabels: Record<Gender, string> = {
  [Gender.Agender]: "Agender",
  [Gender.DoNotIdentifyWithLabels]: "Do Not Identify With Labels",
  [Gender.Woman]: "Woman",
  [Gender.Genderfluid]: "Genderfluid",
  [Gender.Genderqueer]: "Genderqueer",
  [Gender.IntersexDsd]: "Intersex / DSD",
  [Gender.Man]: "Man",
  [Gender.Nonbinary]: "Nonbinary",
  [Gender.NotListed]: "Not Listed",
  [Gender.PreferNotToDisclose]: "Prefer Not To Disclose",
  [Gender.Transgender]: "Transgender",
  [Gender.Cisgender]: "Cisgender",
};
export const genderSelectOptions: SelectOption<Gender>[] = Object.entries(
  genderLabels
).map(([value, label]) => ({
  value: value as Gender,
  label,
}));

export const pronounsLabels: Record<Pronouns, string> = {
  [Pronouns.HeHim]: "He/Him/His",
  [Pronouns.SheHer]: "She/Her/Hers",
  [Pronouns.TheyThem]: "They/Them/Theirs",
  [Pronouns.NotListed]: "Not Listed",
  [Pronouns.PreferNotToDisclose]: "Prefer Not To Disclose",
};
export const pronounsSelectOptions: SelectOption<Pronouns>[] = Object.entries(
  pronounsLabels
).map(([value, label]) => ({
  value: value as Pronouns,
  label,
}));

export const sexuaOrientationLabels: Record<SexualOrientation, string> = {
  [SexualOrientation.Asexual]: "Asexual",
  [SexualOrientation.Bisexual]: "Bisexual",
  [SexualOrientation.Gay]: "Gay",
  [SexualOrientation.NotListed]: "Not Listed",
  [SexualOrientation.HeterosexualOrStraight]: "Heterosexual/Straight",
  [SexualOrientation.Lesbian]: "Lesbian",
  [SexualOrientation.Pansexual]: "Pansexual",
  [SexualOrientation.PreferNotToDisclose]: "Prefer not to disclose",
  [SexualOrientation.Queer]: "Queer",
  [SexualOrientation.DoNotIdentifyWithLabels]: "Do Not Identify With Labels",
};

export const sexualOrientationSelectOptions: SelectOption<SexualOrientation>[] =
  Object.entries(sexuaOrientationLabels).map(([value, label]) => ({
    value: value as SexualOrientation,
    label,
  }));

export const ethnicityLabels: Record<Ethnicity, string> = {
  [Ethnicity.Hispanic]: "Hispanic, Latino, or of Spanish Origin",
  [Ethnicity.NonHispanic]: "Not Hispanic, Latino, or of Spanish Origin",
  [Ethnicity.NotListed]: "Not Listed",
};

export const ethnicitySelectOptions: SelectOption<Ethnicity>[] = Object.entries(
  ethnicityLabels
).map(([value, label]) => ({
  value: value as Ethnicity,
  label,
}));

export const pregnancyStatusSelectOptions: SelectOption<PregnancyStatus>[] =
  Object.values(PregnancyStatus).map((value) => {
    if (value === PregnancyStatus.NotPregnant) {
      return { label: "Not Pregnant", value };
    }
    return { label: value, value };
  });

export const enrollmentStatusLabels: Record<EnrollmentStatus, string> = {
  [EnrollmentStatus.Enrolled]: "Enrolled",
  [EnrollmentStatus.NotEnrolled]: "Not Enrolled",
  [EnrollmentStatus.ApplicationPending]: "Application Pending",
  [EnrollmentStatus.Unknown]: "Unknown",
};
export const enrollmentStatusSelectOptions: SelectOption<EnrollmentStatus>[] =
  Object.entries(enrollmentStatusLabels).map(([value, label]) => ({
    value: value as EnrollmentStatus,
    label,
  }));

export const ehrIDTypeOptions: SelectOption<string>[] = [
  "NIST",
  "EHRID",
  "MR",
  "MRN",
  "EPI",
].map((value) => ({
  label: value,
  value,
}));

// Wraps form values in w/e format required by inputs
export const wrapFormValues = (
  member: Member,
  organization: Organization
): MemberFormValues => {
  const insuranceDetails = member.insuranceDetails?.find(
    (x) => x.organizationId === organization._id
  );

  const formValues: MemberFormValues = {
    ...omitFields(member, formOmittedFields),
    organization: organization.title,
    dob: member.dob || "",
    socialSecurityNumber: member.socialSecurityNumber ?? "",
    spokenLanguages: wrapSelectOptionsArray(member.spokenLanguages ?? []),
    race: wrapSelectOptionsArray(member.race ?? []),
    timezone: timezoneSelectOptions.find((el) => el.value === member.timezone),
    gender: genderSelectOptions.filter((opt) =>
      member.gender?.includes(opt.value)
    ),
    sex: member.sex ? { label: member.sex, value: member.sex } : undefined,
    sexualOrientation: sexualOrientationSelectOptions.filter((opt) =>
      member.sexualOrientation?.includes(opt.value)
    ),
    ethnicity: member.ethnicity
      ? ethnicitySelectOptions.find((opt) => opt.value === member.ethnicity)
      : undefined,
    pronouns: pronounsSelectOptions.filter((opt) =>
      member.pronouns?.includes(opt.value)
    ),
    contactInfo: {
      ...member.contactInfo,
      organization: organization._id,
      groups:
        member.contactInfo.groups?.map((el) => ({
          value: el._id,
          label: el.title,
        })) ?? [],
      city: member.contactInfo.city,
      state: member.contactInfo.state,
      country: member.contactInfo.country
        ? {
            label:
              member.contactInfo.country in COUNTRIES
                ? COUNTRIES[
                    member.contactInfo.country as keyof typeof COUNTRIES
                  ]
                : member.contactInfo.country,
            value: member.contactInfo.country,
          }
        : undefined,
    },
    medicaidStatus: enrollmentStatusSelectOptions.find(
      (opt) => opt.value === member.medicaidStatus
    ),
    medicareStatus: enrollmentStatusSelectOptions.find(
      (opt) => opt.value === member.medicareStatus
    ),
    primaryInsuranceCompany: insuranceDetails?.primaryInsuranceCompany
      ? {
          label: insuranceDetails?.primaryInsuranceCompany.insurance,
          value: insuranceDetails?.primaryInsuranceCompany.insurance,
        }
      : undefined,
    secondaryInsuranceCompany: insuranceDetails?.secondaryInsuranceCompany
      ? {
          label: insuranceDetails?.secondaryInsuranceCompany.insurance,
          value: insuranceDetails?.secondaryInsuranceCompany.insurance,
        }
      : undefined,
    ehrIDType: member.ehrIDType
      ? { label: member.ehrIDType, value: member.ehrIDType }
      : undefined,
    pregnancyStatusEnum: member.pregnancyStatusEnum
      ? pregnancyStatusSelectOptions.find(
          (opt) => opt.value === member.pregnancyStatusEnum
        )
      : undefined,
    maritalStatus: member.maritalStatus
      ? { label: member.maritalStatus, value: member.maritalStatus }
      : undefined,
    highestLevelOfEducation: member.highestLevelOfEducation
      ? {
          label: member.highestLevelOfEducation,
          value: member.highestLevelOfEducation,
        }
      : undefined,
    employmentStatus: member.employmentStatus
      ? { label: member.employmentStatus, value: member.employmentStatus }
      : undefined,
  };

  return formValues;
};

type MemberContactInfoValues = Omit<ContactInfo, "groups" | "organizations"> & {
  groups: string[];
  organization: string | undefined;
  organizations: string[];
};

export type MemberValues = Omit<
  Member,
  | FormOmittedFields
  | "contactInfo"
  | "primaryInsuranceCompany"
  | "secondaryInsuranceCompany"
> & {
  contactInfo: MemberContactInfoValues;
  primaryInsuranceCompany: string | undefined;
  secondaryInsuranceCompany: string | undefined;
  organization: string | undefined;
};

// Unwraps form values from input-accepted types back to base data, minus omitted fields
export const unwrapFormValues = (
  formValues: MemberFormValues
): MemberValues => {
  const member: MemberValues = {
    ...formValues,

    firstName: formValues.firstName.trim(),
    lastName: formValues.lastName.trim(),
    nickName: formValues.nickName?.trim(),
    socialSecurityNumber: formValues.socialSecurityNumber ?? undefined,
    linkedContacts: formValues.linkedContacts,
    ethnicity: formValues.ethnicity
      ? unwrapSelectOption(formValues.ethnicity)
      : undefined,
    race: unwrapSelectOptionsArray(formValues.race),
    spokenLanguages: unwrapSelectOptionsArray(formValues.spokenLanguages),
    timezone: formValues.timezone
      ? unwrapSelectOption(formValues.timezone)
      : undefined,
    sex: formValues.sex ? unwrapSelectOption(formValues.sex) : undefined,
    gender: formValues.gender
      ? unwrapSelectOptionsArray(formValues.gender)
      : undefined,
    sexualOrientation: unwrapSelectOptionsArray(formValues.sexualOrientation),
    pronouns: formValues.pronouns
      ? unwrapSelectOptionsArray(formValues.pronouns)
      : undefined,
    medicaidStatus: formValues.medicaidStatus
      ? unwrapSelectOption(formValues.medicaidStatus)
      : undefined,
    medicareStatus: formValues.medicareStatus
      ? unwrapSelectOption(formValues.medicareStatus)
      : undefined,
    primaryInsuranceCompany: formValues.primaryInsuranceCompany
      ? unwrapSelectOption(formValues.primaryInsuranceCompany)
      : undefined,
    secondaryInsuranceCompany: formValues.secondaryInsuranceCompany
      ? unwrapSelectOption(formValues.secondaryInsuranceCompany)
      : undefined,
    ehrIDType: formValues.ehrIDType
      ? unwrapSelectOption(formValues.ehrIDType)
      : undefined,
    contactInfo: {
      ...formValues.contactInfo,
      organizations: formValues.contactInfo.organization
        ? [formValues.contactInfo.organization]
        : [],
      country: formValues.contactInfo.country?.value?.trim(),

      state:
        typeof formValues.contactInfo.state === "string"
          ? formValues.contactInfo.state?.trim()
          : formValues.contactInfo.state
          ? unwrapSelectOption(formValues.contactInfo.state)
          : undefined,

      city: formValues.contactInfo.city?.trim(),

      email: formValues.contactInfo.email?.trim(),
      address: formValues.contactInfo.address?.trim(),
      address2: formValues.contactInfo.address2?.trim(),
      zip: formValues.contactInfo.zip?.trim(),

      // org value is ensured by MemberFormSchema
      groups: formValues.contactInfo.groups.map(unwrapSelectOption),
    },
    //TODO: consider enums/ labels
    pregnancyStatusEnum: formValues.pregnancyStatusEnum
      ? unwrapSelectOption(formValues.pregnancyStatusEnum)
      : undefined,
    maritalStatus: formValues.maritalStatus
      ? unwrapSelectOption(formValues.maritalStatus)
      : undefined,
    employmentStatus: formValues.employmentStatus
      ? unwrapSelectOption(formValues.employmentStatus)
      : undefined,
    highestLevelOfEducation: formValues.highestLevelOfEducation
      ? unwrapSelectOption(formValues.highestLevelOfEducation)
      : undefined,
    peopleInHousehold:
      typeof formValues.peopleInHousehold === "string"
        ? 0
        : formValues.peopleInHousehold,
    childrenInHousehold:
      typeof formValues.childrenInHousehold === "string"
        ? 0
        : formValues.childrenInHousehold,
  };
  return member;
};
