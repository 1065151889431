import { Grid, Title, TextInput } from "@mantine/core";
import { Field } from "formik";
import { FormikSelect } from "src/components";
import { MemberProfileSettings } from "src/graphql";
import { SelectOption } from "src/types";

interface InsuranceInformationFormSectionProps {
  insuranceCompanyOptions: SelectOption<string>[];
  enrollmentStatusSelectOptions: SelectOption<string>[];
  memberProfileSettings: MemberProfileSettings;
}
export const InsuranceInformationFormSection = ({
  insuranceCompanyOptions,
  enrollmentStatusSelectOptions,
  memberProfileSettings,
}: InsuranceInformationFormSectionProps) => {
  return (
    <>
      <Title order={2} style={{ marginBottom: 20 }}>
        Insurance Information
      </Title>
      <Grid gutter="md">
        {!memberProfileSettings.memberPrimaryInsuranceHidden && (
          <>
            <Grid.Col md={6} sm={12}>
              <FormikSelect
                name="primaryInsuranceCompany"
                label="Primary Insurance Company"
                placeholder="Enter Insurance Provider..."
                options={insuranceCompanyOptions}
              />
            </Grid.Col>
            <Grid.Col md={6} sm={12}>
              <Field
                as={TextInput}
                name="primaryCIN"
                type="text"
                label="Primary Client Identification Number (CIN)"
                placeholder="Enter CIN..."
                variant="filled"
                radius="md"
              />
            </Grid.Col>
          </>
        )}

        {!memberProfileSettings.memberSecondaryInsuranceHidden && (
          <>
            <Grid.Col md={6} sm={12}>
              <FormikSelect
                name="secondaryInsuranceCompany"
                label="Secondary Insurance Company"
                placeholder="Enter Insurance Provider..."
                options={insuranceCompanyOptions}
              />
            </Grid.Col>
            <Grid.Col md={6} sm={12}>
              <Field
                as={TextInput}
                name="secondaryCIN"
                type="text"
                label="Secondary Client Identification Number (CIN)"
                placeholder="Enter CIN..."
                variant="filled"
                radius="md"
              />
            </Grid.Col>
          </>
        )}

        <Grid.Col md={6} sm={12}>
          <FormikSelect
            name="medicaidStatus"
            label="Medicaid Status"
            options={enrollmentStatusSelectOptions}
          />
        </Grid.Col>
        <Grid.Col md={6} sm={12}>
          <FormikSelect
            name="medicareStatus"
            label="Medicare Status"
            options={enrollmentStatusSelectOptions}
          />
        </Grid.Col>
      </Grid>
    </>
  );
};
