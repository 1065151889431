import { Checkbox, CloseButton, Tooltip } from "@mantine/core";
import { memo, useState } from "react";
import { ChevronLeft, ChevronRight, Plus } from "react-feather";
import { Position } from "react-flow-renderer";
import styled from "styled-components";

import { OverflowFadeMask, StyledLabel } from "src/components";
import { AnswerType } from "src/graphql";
import { useDetectOverflow } from "src/hooks";
import { useFlowBuilderContext } from "../template-editor/context";
import { StepNodeData } from "../util";
import { FlowActionItem } from "./FlowActionItem";
import {
  AddActionButton,
  MeasurableNodeProps,
  NodeOverlayContainer,
} from "./shared";
import { StyledEdgeHandle } from "./StyledEdgeHandle";

export const STEP_NODE_TITLE_HEIGHT = 34;

const StepContainer = styled.div<{ readOnly: boolean }>`
  width: 300px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: white;
  height: 100%;
  border-radius: 3px;
  overflow: hidden;
  cursor: ${({ readOnly }) => (readOnly ? "default" : "inherit")};
`;

const HeaderContainer = styled.div`
  height: ${STEP_NODE_TITLE_HEIGHT}px;
  background-color: var(--color-pear-green);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleContainer = styled.div`
  padding: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0px;
  color: white;
`;

const MultiToggleContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.03);
  display: flex;
  align-items: center;
  padding: 5px;
  gap: 5px;
  width: 100%;
`;

const QuestionContentWrapper = styled.div`
  position: relative;
  max-height: 300px;
  overflow: hidden;
`;

const QuestionContent = styled.div`
  padding: 5px;
  margin-bottom: 4px;
  max-height: 100%;
  overflow-y: auto;

  & > *:first-child {
    margin-top: 0px;
  }
  & > *:last-child {
    margin-bottom: 0px;
  }
`;

const QuestionActionsContent = styled.div`
  padding: 5px;
`;

const AddQuestionActionButton = styled(AddActionButton)`
  ${QuestionContentWrapper}:hover & {
    opacity: 1;
  }
`;

const SectionHeader = styled.div`
  background-color: rgba(0, 0, 0, 0.05);
  text-transform: uppercase;
  padding: 0px 3px;
  font-size: 12px;
`;

export const TemplateStepFlowNode = memo(
  ({
    data,
    id,
    measuringProps,
    xPos,
    dragging,
  }: MeasurableNodeProps<StepNodeData>) => {
    const { question, isMultiOutput, isInputNode } = data;
    const {
      readOnly,
      nodesSupportActions,
      grabbedStage,
      stagePositions,
      onToggleMultiOutput,
      onToggleActionModal,
      onDeleteStepNode,
      onDeleteAction,
    } = useFlowBuilderContext();
    const [overflowBottomedOut, setOverflowBottomedOut] = useState(false);

    const [titleElementRef, titleElementOverflowing] =
      useDetectOverflow<HTMLDivElement>();
    const [questionElementRef, questionElementOverflowing] =
      useDetectOverflow<HTMLDivElement>();

    const isSingleOutputType = (
      [
        AnswerType.Number,
        AnswerType.Text,
        AnswerType.Date,
        AnswerType.MultiChoice,
        AnswerType.ReadOnlyText,
      ] as string[]
    ).includes(question.answerType);

    const handleOverflowScroll = (e: React.UIEvent<HTMLDivElement>) => {
      const bottomedOut =
        e.currentTarget.scrollHeight - e.currentTarget.scrollTop ===
        e.currentTarget.clientHeight;
      if (bottomedOut !== overflowBottomedOut)
        setOverflowBottomedOut(bottomedOut);
    };

    return (
      <StepContainer
        ref={measuringProps?.innerRef}
        className={readOnly ? "nodrag" : undefined}
        readOnly={readOnly}
      >
        {/* Overlay for stage position indication, when thumbs grabbed */}
        {grabbedStage !== null && (
          <NodeOverlayContainer>
            {stagePositions[grabbedStage] >= xPos ? (
              <ChevronLeft size={100} color="var(--color-pear-green)" />
            ) : (
              <ChevronRight size={100} color="var(--DANGER)" />
            )}
          </NodeOverlayContainer>
        )}

        <HeaderContainer>
          <div style={{ minWidth: 0 }}>
            <Tooltip
              label={`${question.dataId ?? "-"} | ${question.questionTitle}`}
              multiline
              width={300}
              withArrow
              disabled={!titleElementOverflowing}
              color="green"
              opened={dragging ? false : undefined}
              position="top-start"
              withinPortal
            >
              <TitleContainer ref={titleElementRef}>
                {question.dataId && (
                  <>
                    <strong>{question.dataId}</strong>
                    &nbsp;|&nbsp;
                  </>
                )}
                {question.questionTitle}
              </TitleContainer>
            </Tooltip>
          </div>
          {!readOnly && (
            <CloseButton
              color="green"
              title="Remove Step"
              onClick={() => onDeleteStepNode(id)}
              variant="filled"
              h={STEP_NODE_TITLE_HEIGHT}
              w={STEP_NODE_TITLE_HEIGHT}
            />
          )}
        </HeaderContainer>

        {!isSingleOutputType && (
          <MultiToggleContainer>
            <Checkbox
              size="xs"
              disabled={readOnly}
              checked={isMultiOutput}
              onChange={() => onToggleMultiOutput(id)}
            />

            <StyledLabel mb={0}>Multi-Output</StyledLabel>
          </MultiToggleContainer>
        )}

        <QuestionContentWrapper>
          <QuestionContent
            ref={questionElementRef}
            className={questionElementOverflowing ? "nowheel" : undefined}
            onScroll={
              questionElementOverflowing ? handleOverflowScroll : undefined
            }
            dangerouslySetInnerHTML={{ __html: question.questionText }}
          />
          {!readOnly &&
            nodesSupportActions &&
            question.answerType !== AnswerType.ReadOnlyText && (
              <AddQuestionActionButton
                className={questionElementOverflowing ? "nowheel" : undefined}
                onClick={() => onToggleActionModal(id)}
                // this margin pulls the button inside the scollbar element when rendered
                style={{ marginRight: questionElementOverflowing ? 14 : 0 }}
              >
                <Plus size={12} />
                <small>&nbsp;Question Action</small>
              </AddQuestionActionButton>
            )}
          <OverflowFadeMask
            $visible={questionElementOverflowing && !overflowBottomedOut}
          />
        </QuestionContentWrapper>

        {!isInputNode && (
          <StyledEdgeHandle
            type={"target"}
            position={Position.Left}
            isConnectable={!readOnly}
          />
        )}
        {!isMultiOutput && (
          <StyledEdgeHandle
            type={"source"}
            position={Position.Right}
            isConnectable={!readOnly}
          />
        )}

        {nodesSupportActions && (
          <div style={{ marginBottom: 4 }}>
            <SectionHeader>Question Actions</SectionHeader>
            <QuestionActionsContent>
              <div style={{ fontSize: 12 }}>
                {!data.actions.length && (
                  <div
                    style={{ color: "var(--color-content-primary-disabled)" }}
                  >
                    {"<none configured>"}
                  </div>
                )}
                {!!data.actions.length &&
                  data.actions.map((actionInfo, i) => {
                    return (
                      <FlowActionItem
                        actionInfo={actionInfo}
                        key={JSON.stringify(actionInfo)}
                        onDeleteAction={(info) => onDeleteAction(id, info)}
                        isLastAction={i === data.actions.length - 1}
                      />
                    );
                  })}
              </div>
            </QuestionActionsContent>
          </div>
        )}
        <SectionHeader style={{ marginBottom: "0.25rem" }}>
          Answers
        </SectionHeader>
      </StepContainer>
    );
  }
);
