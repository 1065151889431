import { Group, ActionIcon, Tooltip } from "@mantine/core";
import { Edit, Trash2, Eye } from "react-feather";

import { TableColumn } from "react-data-table-component";

import { CertificationTemplate, Certification } from "src/graphql";
import { DontTranslate } from "src/libs/localization/components/DontTranslate";
import { OverflowTooltipCell } from "../table";

type ActionsCellProps = {
  onEdit: () => void;
  onDelete: () => void;
  onViewFile?: () => void;
};

export const ActionsCell = ({
  onEdit,
  onDelete,
  onViewFile,
}: ActionsCellProps) => {
  return (
    <Group spacing="xs">
      {onViewFile && (
        <Tooltip label="View File">
          <ActionIcon onClick={onViewFile} color="gray">
            <Eye size="16" />
          </ActionIcon>
        </Tooltip>
      )}

      <Tooltip label="Edit file">
        <ActionIcon onClick={onEdit} color="blue">
          <Edit size="16" />
        </ActionIcon>
      </Tooltip>

      <Tooltip label="Delete file">
        <ActionIcon onClick={onDelete} color="red">
          <Trash2 size="16" />
        </ActionIcon>
      </Tooltip>
    </Group>
  );
};

type CertificationTemplateTableColumnProps = {
  onEdit: (certificationTemplate: CertificationTemplate) => void;
  onDelete: (certificationTemplate: CertificationTemplate) => void;
};

export const getCertificationTemplateTableColumns = ({
  onEdit,
  onDelete,
}: CertificationTemplateTableColumnProps) => {
  const columns: TableColumn<CertificationTemplate>[] = [
    {
      name: "Name",
      cell: (row: CertificationTemplate) => (
        <DontTranslate>{row.name ?? ""}</DontTranslate>
      ),
    },
    {
      name: "Action",
      center: true,
      cell: (row: CertificationTemplate) => (
        <ActionsCell
          onEdit={() => onEdit(row)}
          onDelete={() => onDelete(row)}
        />
      ),
      width: "20%",
    },
  ];
  return columns;
};

type CertificationTableColumnProps = {
  onEdit: (certification: Certification) => void;
  onDelete: (certification: Certification) => void;
  onViewFile: (psFileId: string) => void;
};

export const getCertificationTableColumns = ({
  onEdit,
  onDelete,
  onViewFile,
}: CertificationTableColumnProps) => {
  const columns: TableColumn<Certification>[] = [
    {
      name: "Title",
      cell: (row: Certification) => (
        <OverflowTooltipCell>
          <DontTranslate>{row.title}</DontTranslate>
        </OverflowTooltipCell>
      ),
    },
    {
      name: "File Name",
      cell: (row: Certification) => (
        <OverflowTooltipCell>
          <DontTranslate>{row.fileName ?? "NA"}</DontTranslate>
        </OverflowTooltipCell>
      ),
    },
    {
      name: "Action",
      center: true,
      cell: (row: Certification) => {
        const fileId = row.fileId;

        return (
          <ActionsCell
            onEdit={() => onEdit(row)}
            onDelete={() => onDelete(row)}
            onViewFile={fileId ? () => onViewFile(fileId) : undefined}
          />
        );
      },
      width: "20%",
    },
  ];
  return columns;
};
