import { Stack } from "@mantine/core";
import { useAuthContext } from "src/hooks";
import { NotesContainer } from "../notes/NotesContainer";

type MemberNotesProps = {
  memberId: string;
  multiMemberActivity?: boolean;
  activityId?: string;
  hideTitle?: boolean;
  style?: React.CSSProperties;
  readonly?: boolean;
  episodeOfCareId?: string;
  noHorizontalPadding?: boolean;
  showFilters: boolean;
  inlineEdit?: boolean;
};

export const MemberNotes = ({
  hideTitle = false,
  memberId,
  activityId,
  style,
  multiMemberActivity,
  readonly = false,
  episodeOfCareId,
  noHorizontalPadding,
  showFilters = true,
  inlineEdit = false,
}: MemberNotesProps) => {
  const { memberPageReadOnly } = useAuthContext();

  return (
    <Stack spacing={0} style={style}>
      <NotesContainer
        listOptions={{
          memberId,
          activityId: activityId ?? undefined,
          episodeOfCareId: episodeOfCareId ?? undefined,
        }}
        createOptions={{ memberId, activityId }}
        multiMemberActivity={multiMemberActivity}
        readonly={readonly}
        showFilters={showFilters}
        isMemberNotesContainer={true}
        activityId={activityId}
        memberPageReadOnly={memberPageReadOnly}
        hideTitle={hideTitle}
        noHorizontalPadding={noHorizontalPadding}
        inlineEdit={inlineEdit}
      />
    </Stack>
  );
};
