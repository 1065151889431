import { useMutation } from "@apollo/client";
import { usePagination } from "src/hooks/usePagination";
import {
  QueryTagsArgs,
  GetTagResponse,
  Tag,
  GetTagsResponse,
  MutationCreateTagArgs,
  MutationUpdateTagArgs,
  MutationDeleteTagArgs,
} from "../schemaTypes";
import { GET_TAGS } from "./queries";
import { CREATE_TAG, DELETE_TAG, UPDATE_TAG } from "./mutations";

export const usePaginatedQueryTags = (organizationId: string, pageSize = 20) =>
  usePagination<QueryTagsArgs, { response: GetTagsResponse }, Tag>({
    document: GET_TAGS,
    variables: { input: { organizationId } },
    options: {
      size: pageSize,
      defaultSort: {
        field: "label",
        direction: 1,
      },
    },
  });

export const useMutationCreateTag = () =>
  useMutation<{ createTag: GetTagResponse }, MutationCreateTagArgs>(
    CREATE_TAG,
    {
      refetchQueries: [GET_TAGS],
    }
  );

export const useMutationUpdateTag = () =>
  useMutation<{ updateTag: GetTagResponse }, MutationUpdateTagArgs>(
    UPDATE_TAG,
    {
      refetchQueries: [GET_TAGS],
    }
  );

export const useMutationDeleteTag = () =>
  useMutation<{ deleteTag: GetTagResponse }, MutationDeleteTagArgs>(
    DELETE_TAG,
    {
      refetchQueries: [GET_TAGS],
    }
  );
