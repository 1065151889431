import { Stack } from "@mantine/core";

import { LoaderComponent } from "src/components/loader";
import { Organization, TextMessageDirection } from "src/graphql";
import { useInfiniteQueryTextMessages } from "src/graphql/TextMessage";
import { MessageItem } from "./item";

interface MessageListProps {
  height?: number | string;
  organization: Organization;
  participantPhone: string;
}

export const MessageList = ({
  height = 250,
  organization,
  participantPhone,
}: MessageListProps) => {
  const { data, completed, lastElementRef } =
    useInfiniteQueryTextMessages<HTMLDivElement>({
      participantPhone,
      organizationId: organization._id,
    });

  return (
    <Stack
      px="md"
      h={height}
      style={{ flexDirection: "column-reverse", overflowY: "scroll" }}
    >
      {data.map((message) => {
        return (
          <MessageItem
            key={message._id}
            direction={message.direction || TextMessageDirection.Incoming}
            body={message.body}
            createdAt={message.createdAt}
            status={message.status}
          />
        );
      })}

      {!completed && <LoaderComponent ref={lastElementRef} key="loader" />}
    </Stack>
  );
};
