export const tagFields = `
  _id
  createdAt
  updatedAt
  label
  dataId
  organizationId
`;

export const taggedEntityFields = `
  _id
  createdAt
  updatedAt
  tag {
    ${tagFields}
  }
`;
