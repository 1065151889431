import { gql } from "@apollo/client";
import { EHRNoteTemplateFields } from "./types";

export const GET_EHR_NOTE_TEMPLATES = gql`
  query EHRNoteTemplates($organizationId: ID!) {
    ehrNoteTemplates(organizationId: $organizationId) {
      success
      message
      data {
        ${EHRNoteTemplateFields}
      }
    }
  }
`;
