import { createContext, useContext } from "react";
import { UserGroup } from "src/graphql";

export type CallRoutingEditorContextValues = {
  userGroups: UserGroup[];
  readOnly: boolean;
  onDeleteNodeGroup: (nodeId: string) => void;
  onCreateBranchingCondition: (nodeId: string) => void;
  onUpdateBranchingCondition: (nodeId: string, nextCondition: number) => void;
  onDeleteBranchingCondition: (nodeId: string) => void;
  onUpdateVoicePrompt: (nodeId: string, nextPrompt: string) => void;
  onUpdateUserGroup: (nodeId: string, nextUserGroupId: string) => void;
};

export const CallRoutingEditorContext =
  createContext<CallRoutingEditorContextValues>(
    {} as CallRoutingEditorContextValues
  );

export const useCallRoutingEditorContext = () =>
  useContext(CallRoutingEditorContext);
