import {
  ActionIcon,
  Button,
  Group,
  Stack,
  Tabs,
  Text,
  Tooltip,
} from "@mantine/core";
import { Mic, MicOff, PhoneCall, PhoneOff, Plus } from "react-feather";

import Messages from "src/components/twilio/messages";
import { useAuthContext } from "src/hooks";
import useTwilio from "src/hooks/useTwilio";
import { formatPhoneNumber } from "src/utils/phone";
import { NumberPad } from "./number-pad";
import { useMutationAddParticipantToConferenceCall } from "src/graphql/Twilio/hooks";
import { useEffect, useState } from "react";
import toast from "src/libs/toast";
import { AddParticipantModal } from "./AddParticipantModal";

export enum WidgetTab {
  Sms = "sms",
  Phone = "phone",
}

export const TwilioWidgetTabs = () => {
  const {
    widget,
    isCallInProgress,
    isSettingDevice,
    makeOutgoingCall,
    endOutgoingCall,
    endIncomingCall,
    conferenceFriendlyName,
    incomingCall,
    call,
    isIncomingCallInProgress,
    micState,
  } = useTwilio();

  const { selectedOrganization } = useAuthContext();

  const [addParticipantToConference] =
    useMutationAddParticipantToConferenceCall();

  const [addParticipantModalOpen, setAddParticipantModalOpen] = useState(false);
  const [isMuted, setIsMuted] = useState(call?.isMuted() || false);

  useEffect(() => {
    call?.on("mute", (isMuted: boolean) => {
      setIsMuted(isMuted);
    });
  }, [call]);

  useEffect(() => {
    if (!call && !incomingCall) {
      setIsMuted(false);
    }
  }, [incomingCall, call]);

  const members =
    widget.members?.map((member) => member.firstName).join(", ") || "Unknown";

  const handleAddParticipantToConference = async (
    userId?: string,
    externalNumber?: string
  ) => {
    const addParticipantResult = await addParticipantToConference({
      variables: {
        input: {
          organizationId: selectedOrganization._id,
          conferenceFriendlyName:
            (isIncomingCallInProgress
              ? incomingCall?.conferenceFriendlyName
              : conferenceFriendlyName) ?? "",
          internalUserId: userId,
          externalPhoneNumber: externalNumber,
        },
      },
    });
    if (!addParticipantResult.data?.addParticipantToConferenceCall.success) {
      toast.error(
        addParticipantResult.data?.addParticipantToConferenceCall.message ??
          "Experienced an issue adding this participant, please try again. "
      );
    }
  };

  return (
    <Stack spacing={0}>
      <Stack p="md" spacing={5}>
        <Group style={{ justifyContent: "space-between" }}>
          <Group>
            {widget.phoneNumber && (
              <Text size="sm">{formatPhoneNumber(widget.phoneNumber)}</Text>
            )}

            <Tooltip label={members} position="top-start">
              <Text
                variant="caption"
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {members}
              </Text>
            </Tooltip>
          </Group>
          <Group>
            {(isCallInProgress || isIncomingCallInProgress) && isMuted && (
              <ActionIcon onClick={() => call?.mute(false)}>
                <Tooltip label="Mic is off" position="top-start">
                  <MicOff size={18} />
                </Tooltip>
              </ActionIcon>
            )}
            {(isCallInProgress || isIncomingCallInProgress) && !isMuted && (
              <ActionIcon onClick={() => call?.mute(true)}>
                <Tooltip label="Mic is on" position="top-start">
                  <Mic size={18} />
                </Tooltip>
              </ActionIcon>
            )}
            {(isCallInProgress || isIncomingCallInProgress) && (
              <ActionIcon onClick={() => setAddParticipantModalOpen(true)}>
                <Tooltip label="Add participants" position="top-start">
                  <Plus size={18} />
                </Tooltip>
              </ActionIcon>
            )}
          </Group>
        </Group>
      </Stack>

      <Tabs value={widget.tab} onTabChange={widget.setTab}>
        <Stack>
          <Tabs.List>
            <Tabs.Tab value={WidgetTab.Phone}>Phone</Tabs.Tab>
            <Tabs.Tab value={WidgetTab.Sms}>Texting</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value={WidgetTab.Phone} mih={300}>
            <Stack px="md">
              {incomingCall && (
                <Button
                  color="red"
                  fullWidth
                  onClick={() => {
                    endIncomingCall();
                  }}
                >
                  <PhoneOff size={18} />
                </Button>
              )}

              {!incomingCall && !isCallInProgress && micState !== "denied" && (
                <Button
                  fullWidth
                  disabled={isSettingDevice}
                  onClick={makeOutgoingCall}
                >
                  <PhoneCall size={18} />
                </Button>
              )}
              {!incomingCall && !isCallInProgress && micState === "denied" && (
                <>
                  <p>
                    Access to your microphone is currently disabled. For
                    information on how to change this setting, please visit{" "}
                    <a
                      href="https://support.google.com/chrome/answer/2693767?hl=en&co=GENIE.Platform%3DDesktop"
                      target="_blank"
                      rel="noreferrer"
                    >
                      this help article
                    </a>
                    .
                  </p>
                  <p>
                    Once this setting has been changed, please reload this page.
                  </p>
                </>
              )}

              {!incomingCall && isCallInProgress && (
                <Button
                  color="red"
                  fullWidth
                  onClick={() => {
                    endOutgoingCall();
                  }}
                >
                  <PhoneOff size={18} />
                </Button>
              )}

              <NumberPad />
            </Stack>
          </Tabs.Panel>

          <Tabs.Panel value={WidgetTab.Sms}>
            <Messages
              organization={selectedOrganization}
              participantPhone={widget.phoneNumber || ""}
            />
          </Tabs.Panel>
        </Stack>
      </Tabs>
      <AddParticipantModal
        open={addParticipantModalOpen}
        onClose={() => setAddParticipantModalOpen(false)}
        onSubmit={handleAddParticipantToConference}
      />
    </Stack>
  );
};
