import styled from "styled-components";

// using styled-components .attrs syntax here to inline frequently changing styles,
// in observance of this warning: https://github.com/styled-components/styled-components/issues/1212
//
// This component is the vertical line that drops down from handles in the top-toolbar for Journey type flows,
// which is used to defined Journey stage boundaries on the graph

type DropLineProps = {
  xPos: number;
  isCurrent: boolean;
  isVisible: boolean;
};

export const DropLine = styled.div.attrs<DropLineProps>(
  ({ xPos, isCurrent, isVisible }) => ({
    style: {
      borderLeft: isCurrent
        ? `2px solid var(--color-pear-green)`
        : isVisible
        ? `1px dashed var(--color-content-primary-disabled)`
        : `none`,
      left: xPos,
    },
  })
)<DropLineProps>`
  position: absolute;
  top: 100%;
  height: 100vh;
  width: 0px;
  z-index: 99;
`;
