import { gql } from "@apollo/client";
import { questionFields } from "./types";

export const CREATE_QUESTION = gql`
  mutation CreateQuestion($input: QuestionInput!) {
    createQuestion(input: $input) {
      success
      message
      data {
        ${questionFields}
      }
    }
  }
`;

export const UPDATE_QUESTION = gql`
  mutation UpdateQuestion($input: UpdateQuestionInput!) {
    updateQuestion(input: $input) {
      success
      message
      data {
        ${questionFields}
      }
    }
  }
`;

export const DELETE_QUESTION = gql`
  mutation DeleteQuestion($input: DeleteQuestionInput!) {
    deleteQuestion(input: $input) {
      success
      message
      data {
        ${questionFields}
      }
    }
  }
`;
