import { Group, MantineColor, Spoiler, SpoilerProps } from "@mantine/core";
import { ChevronDown, ChevronUp } from "react-feather";

type StyledSpoilerProps = Partial<SpoilerProps> & {
  fadeColor?: MantineColor;
};

export const StyledSpoiler = ({
  fadeColor,
  children,
  maxHeight,
  showLabel,
  hideLabel,
  styles,
  ...rest
}: StyledSpoilerProps) => (
  <Spoiler
    styles={(theme) => ({
      control: {
        paddingTop: theme.spacing.xs,
      },
      content: {},
      ...styles,
    })}
    maxHeight={maxHeight ?? 80}
    showLabel={
      showLabel ?? (
        <Group align="center" spacing="xs" fz="xs">
          SHOW MORE
          <ChevronDown size="1.2em" />
        </Group>
      )
    }
    hideLabel={
      hideLabel ?? (
        <Group align="center" spacing="xs" fz="xs">
          SHOW LESS
          <ChevronUp size="1.2em" />
        </Group>
      )
    }
    {...rest}
  >
    {children}
  </Spoiler>
);
