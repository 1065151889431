import * as Yup from "yup";
import { ySelectOptionSchema } from "src/utils";
import { UserRole } from "src/graphql";
import { SelectOption } from "src/types";

export const UserFormSchema = Yup.object({
  name: Yup.string().required(),
  email: Yup.string().required(),
  role: ySelectOptionSchema(
    Yup.mixed<UserRole>().oneOf(Object.values(UserRole))
  ).required("User Role Required"),
  organizationIds: Yup.array(ySelectOptionSchema(Yup.string().required())).when(
    "role",
    {
      is: (role: SelectOption<UserRole>) => role.value !== UserRole.SuperAdmin,
      then: (schema) => schema.min(1, "Organization is required"),
    }
  ),
  spokenLanguages: Yup.array(ySelectOptionSchema(Yup.string().required())),
  location: Yup.string(),
});
