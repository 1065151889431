import { gql } from "@apollo/client";
import { PSFileFields } from "./types";

export const GET_MEMBER_FILES = gql`
  query GetMemberFiles($input: GetFilesInput!, $pagination: PaginationInput!) {
    response: psFiles(input: $input, pagination: $pagination) {
      success
      message
      data {
        total
        page
        data {
          ${PSFileFields}
        }
      }
    }
  }
`;

export const GET_FILE_URL = gql`
  query GetFileURL(
    $id: ID!
    $dataImportTemplateId: ID
    $dataExportTemplateId: ID
  ) {
    securePsFileUrl(
      id: $id
      dataImportTemplateId: $dataImportTemplateId
      dataExportTemplateId: $dataExportTemplateId
    ) {
      success
      message
      data
    }
  }
`;

export const CHECK_FILE_NAME = gql`
  query CheckFileName($input: CheckFileNameInput!) {
    checkFileName(input: $input) {
      success
      message
      data
    }
  }
`;
