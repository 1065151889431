import { gql } from "@apollo/client";
import { textMessageThreadFields } from "./types";

export const CREATED_OR_UPDATED_TEXT_MESSAGE_THREAD_EVENT = gql`
  subscription OnCreatedOrUpdatedTextMessageThread($organizationId: ID!) {
    createdOrUpdatedTextMessageThread(organizationId: $organizationId) {
      ${textMessageThreadFields}
    }
  }
`;
