import { Text, TextProps } from "@mantine/core";

type StyledLabelProps = {
  required?: boolean;
  strong?: boolean;
} & TextProps;

export const StyledLabel = ({
  required,
  children,
  strong,
  ...props
}: StyledLabelProps) => (
  <Text
    component="label"
    weight={strong ? 600 : 400}
    size={12}
    mb={5}
    {...props}
  >
    {children}{" "}
    {required && <span style={{ color: "red", marginLeft: -1.5 }}>*</span>}
  </Text>
);
