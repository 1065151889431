import { Switch, Route } from "react-router-dom";
import { ReferralStatusComponent } from "./ReferralStatus";

const ReferralStatusRoute = () => (
  <Switch>
    <Route exact path="/referral-status/:referralId">
      <ReferralStatusComponent />
    </Route>
  </Switch>
);

export default ReferralStatusRoute;
