import { NodeProps } from "react-flow-renderer";
import styled from "styled-components";

export type MeasurableNodeProps<T> = NodeProps<T> & {
  /**
   * These props should be passed when a node component is rendered within the
   * <NodeMeasuringContainer /> component
   */
  measuringProps?: {
    innerRef?: React.RefObject<HTMLDivElement>;
    style?: React.CSSProperties;
  };
};

export const AddActionButton = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 6px;
  right: 5px;
  padding: 0px 5px 1px 2px;
  background-color: var(--color-pear-green);
  opacity: 0;
  cursor: pointer;
  border-radius: 3px;
  color: white;

  &:hover {
    background-color: var(--color-pear-green-highlight);
  }

  &:active {
    background-color: var(--color-pear-green);
  }
`;

// used to display something on top of a muted node, e.g. arrows for stage layout
export const NodeOverlayContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 90;
  pointer-events: none;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const getBgColor = (hasUnsavedChanges: boolean) =>
  hasUnsavedChanges ? "var(--color-clean-yellow)" : "var(--color-pear-green)";
export const getBgHighlightColor = (hasUnsavedChanges: boolean) =>
  hasUnsavedChanges
    ? "var(--color-clean-yellow-highlight)"
    : "var(--color-pear-green-highlight)";
export const getBgMutedColor = (hasUnsavedChanges: boolean) =>
  hasUnsavedChanges
    ? "var(--color-clean-yellow-muted)"
    : "var(--color-pear-green-muted)";
