import { Button } from "@mantine/core";
import { Check, X } from "react-feather";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "src/components";

type CommitApprovalModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  onApproveCommit: () => void;
};

export const CommitApprovalModal = ({
  isOpen,
  onRequestClose,
  onApproveCommit,
}: CommitApprovalModalProps) => (
  <Modal opened={isOpen} onClose={onRequestClose}>
    <ModalHeader>Approve Breaking Changes?</ModalHeader>

    <ModalBody>
      The requested change to this question's answer will change the path of the
      Flow, invalidating some previously given answers.
      <br />
      <br />
      Is this ok?
    </ModalBody>

    <ModalFooter>
      <Button
        color="red"
        variant="outline"
        onClick={onRequestClose}
        leftIcon={<X size="18" />}
      >
        Cancel
      </Button>

      <Button onClick={onApproveCommit} leftIcon={<Check size="18" />}>
        Approve
      </Button>
    </ModalFooter>
  </Modal>
);
