import { gql } from "@apollo/client";
import { NoteTemplateFields } from "./types";

export const CREATE_NOTE_TEMPLATE = gql`
  mutation CreateNoteTemplate($input: CreateNoteTemplateInput!) {
    createNoteTemplate(input: $input) {
      success
      message
      data {
        ${NoteTemplateFields}
      }
    }
  }
`;

export const UPDATE_NOTE_TEMPLATE = gql`
  mutation UpdateNoteTemplate($input: UpdateNoteTemplateInput!) {
    updateNoteTemplate(input: $input) {
      success
      message
      data {
        ${NoteTemplateFields}
      }
    }
  }
`;

export const DELETE_NOTE_TEMPLATE = gql`
  mutation DeleteNoteTemplate($input: GetNoteTemplateInput!) {
    deleteNoteTemplate(input: $input) {
      success
      message
    }
  }
`;
