import { gql } from "@apollo/client";

export const DELETE_CARE_PATHWAY = gql`
  mutation DeleteCarePathway(
    $carePathwayId: ID!
    $userId: ID!
    $memberId: ID!
    $organizationId: ID!
  ) {
    deleteCarePathway(
      carePathwayId: $carePathwayId
      userId: $userId
      memberId: $memberId
      organizationId: $organizationId
    ) {
      success
      message
    }
  }
`;
