import { gql } from "@apollo/client";
import { consentFields, consentTemplateFields } from "./types";

export const GET_CONSENT_TEMPLATES = gql`
  query GetConsentTemplates($organizationId: ID!, $showDeleted: Boolean) {
    consentTemplatesByOrganizationId(organizationId: $organizationId, showDeleted: $showDeleted) {
      success
      message
      data {
        ${consentTemplateFields}
      }
    }
  }
`;

export const GET_CONSENTS = gql`
  query GetConsentsByMemberId($organizationId: ID!, $memberId: ID!, $showDeleted: Boolean, $episodeOfCareId: String) {
    consentsByMemberId(organizationId: $organizationId, memberId: $memberId, showDeleted: $showDeleted, episodeOfCareId: $episodeOfCareId) {
      success
      message
      data {
        ${consentFields}
      }
    }
  }
`;
