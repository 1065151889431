import { Formik, FormikProps } from "formik";
import log from "loglevel";
import { useRef } from "react";
import toast from "src/libs/toast";
import * as Yup from "yup";

import { FormikSelect } from "src/components";
import { ActionInfo } from "src/components/flow-graph/util";
import { ActionType, ConsentTemplate, CreateConsentData } from "src/graphql";
import { SelectOption } from "src/types";
import { ySelectOptionSchema } from "src/utils";
import { SubFormCommonProps } from "../AddActionModal";
import { Stack } from "@mantine/core";
import { Disclaimer } from "../shared";

type CreateConsentActionFormProps = SubFormCommonProps & {
  consentTemplateOptions: SelectOption<string>[];
  consentTemplatesById: Record<string, ConsentTemplate>;
};

export const CreateConsentActionForm = ({
  consentTemplateOptions,
  consentTemplatesById,
  innerRef,
  node,
  onCreateAction,
  onDirtyStateChange,
  onValidStateChange,
}: CreateConsentActionFormProps) => {
  const dirtyStateRef = useRef(false);
  const validStateRef = useRef(false);

  const initialValues: WrappedFormValues = {
    consentTemplateId: null,
  };

  return (
    <Formik
      innerRef={innerRef as React.RefObject<FormikProps<WrappedFormValues>>}
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={(formValues, { setSubmitting }) => {
        if (!node) return; // nothing renders without a node selected
        try {
          setSubmitting(true);
          const actionInfo = parseFormValues(formValues);
          log.log("formSubmit", actionInfo, formValues);
          onCreateAction(node, actionInfo);
          // eslint-disable-next-line
        } catch (err: any) {
          log.error(err.message);
          setSubmitting(false);
          toast.error("Failed to add action; please try again");
        }
      }}
    >
      {({ values, dirty, isValid }) => {
        if (onDirtyStateChange && dirty !== dirtyStateRef.current) {
          dirtyStateRef.current = dirty;
          requestAnimationFrame(() => onDirtyStateChange(dirty));
        }
        if (onValidStateChange && isValid !== validStateRef.current) {
          validStateRef.current = isValid;
          requestAnimationFrame(() => onValidStateChange(isValid));
        }

        return (
          <Stack mt="0.75em" spacing="1em">
            <FormikSelect
              name="consentTemplateId"
              label="Consent Template"
              options={consentTemplateOptions}
            />

            {values.consentTemplateId && (
              <Disclaimer>
                Note: The <strong>Collect Consent</strong> action will prompt
                the user to create a consent for the member matching this
                template. If consent for the member already exists, the Flow
                will notify the user and continue transparently.
              </Disclaimer>
            )}
          </Stack>
        );
      }}
    </Formik>
  );
};

type WrappedFormValues = Omit<CreateConsentData, "consentTemplateId"> & {
  consentTemplateId: SelectOption<string> | null;
};

const parseFormValues = (formValues: WrappedFormValues): ActionInfo => {
  if (!formValues.consentTemplateId) {
    throw new Error("Missing consent template ID - check schema validator");
  }

  return {
    actionType: ActionType.CreateConsent,
    actionData: {
      consentTemplateId: formValues.consentTemplateId.value,
    },
  };
};

const ValidationSchema = Yup.object({
  consentTemplateId: ySelectOptionSchema(Yup.string().required()).required(),
});
