import { CSSObject, SwitchStylesParams } from "@mantine/core";
import { MantineThemeComponents } from "@mantine/styles/lib/theme/types/MantineTheme";

export const Switch: MantineThemeComponents[string] = {
  styles: (
    _theme,
    _params: SwitchStylesParams,
    _context
  ): Record<string, CSSObject> => {
    return { track: { cursor: "pointer" } };
  },
};
