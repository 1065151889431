import { LockingReferenceFields } from "../Common/types";

export const carePathwayTemplateMinimalFields = `
_id
name
isDefault
updatedAt
createdAt
organizationId
completedPercentage
relatedMemberCarePathwayId
relatedMemberCarePathwayCreatedAt
status
userId
lockedByUse
steps {
  actions {
    _id
    scheduleType
    date
    interval
    type
    resourceType
    resourceId
  }
}
lockingReferences {
  ${LockingReferenceFields}
}
accessType
`;

export const carePathwayFields = `
_id
carePathwayTemplateId
memberId
inProgressStepIds
completedStepIds
completedActionIds
parentCarePathwayTemplateId
parentCarePathwayTemplateActionId
scheduledDate
status
name
carePathwayTemplate {
  name
}
actionActivities {
  actionId
  activities {
    activityId
    date
  }
}
`;

export const StepsFields = `_id
isMultiOutput
position {
  x
  y
}
activityTemplateDetails {
  activityTemplateId
  title
  activities {
    _id
    createdAt
    activityTemplateId
    carePathwayId
    duration
    date
    status
    userId
    tasks {
      status
      type
    }
  }
}
nextStepsConditions {
  _id
  conditionsGroup {
   _id
   conditionOperator
   conditions {
    _id
    operator
    conditionOperator
    value
    dataId
    }
  }
}

targetSteps {
  _id
  sourceHandle
  targetStepId
  targetHandle
  conditionsGroupItemId
}
actions {
  _id
  scheduleType
  date
  interval
  type
  resourceType
  resourceId
  assignedUserType
  assignedUserTypeId
}`;

export const carePathwayTemplateFields = `
_id
name
firstStepId
isDefault
updatedAt
createdAt
organizationId  
userId
steps { 
  ${StepsFields}
}
lockedByUse
additionalEdges {
  id
  source
  target
  sourceHandle
  targetHandle
}
additionalSteps {
  ${StepsFields}
}
lockingReferences {
  ${LockingReferenceFields}
}
accessType
`;
