import { gql } from "@apollo/client";
import { textMessageThreadFields } from "./types";

export const GET_TEXT_MESSAGE_THREAD = gql`
    query GetTextMessageThread($organizationId: ID!, $participantPhone: String!) {
        textMessageThread(organizationId: $organizationId, participantPhone: $participantPhone) {
            success
            data {
                ${textMessageThreadFields}
            }
        }
    }
`;

export const GET_TEXT_MESSAGE_THREADS = gql`
    query GetTextMessageThreads($input: GetTextMessageThreadsInput!, $pagination: PaginationInput!) {
        response: textMessageThreads(input: $input, pagination: $pagination) {
            success
            data {
                total
                page
                unread
                data {
                    ${textMessageThreadFields}
                }
            }
        }
    }
`;
