import { gql } from "@apollo/client";
import { voicemailFields } from "./types";

export const UPDATED_VOICEMAIL_EVENT = gql`
  subscription OnUpdatedVoicemail($organizationId: ID!) {
    updatedVoicemail(organizationId: $organizationId) {
      ${voicemailFields}
    }
  }
`;

export const CREATED_VOICEMAIL_EVENT = gql`
  subscription OnCreatedVoicemail($organizationId: ID!) {
    createdVoicemail(organizationId: $organizationId) {
      ${voicemailFields}
    }
  }
`;
export const DELETED_VOICEMAIL_EVENT = gql`
  subscription OnDeletedVoicemail($organizationId: ID!) {
    deletedVoicemail(organizationId: $organizationId) {
      ${voicemailFields}
    }
  }
`;
