export const consentTemplateFields = `
  _id
  createdAt
  updatedAt
  name
  dataId
  description
  consentMethods
  deletedAt
  userId
  organizationId
  accessType
`;

export const consentFields = `
  _id
  updatedAt
  createdAt
  description
  consentTemplateId
  isActive
  revokedAt
  retiredAt
  fileId
  phoneCallId
  userId
  memberId
  organizationId
  name
  consentMethod
  fileName
  phoneCallSid
`;
