import { Language, MemberStatus } from "src/graphql";
import { SelectOption } from "src/types";

export const MAX_FILES = 1;
export const FILE_TYPES = "text/csv";
export const MAX_SIZE = 30000000; // 30MB

export const raceSelectOptions: SelectOption<string>[] = [
  {
    value: "Alaska Native",
    label: "Alaska Native",
  },
  { value: "Asian / Asian American", label: "Asian / Asian American" },
  { value: "Black / African American", label: "Black / African American" },
  { value: "Caucasian / White", label: "Caucasian / White" },
  {
    value: "Hispanic / Latino / Spanish",
    label: "Hispanic / Latino / Spanish",
  },
  {
    value: "Native American/Indigenous American/American Indian",
    label: "Native American/Indigenous American/American Indian",
  },
  {
    value: "Native Hawaiian",
    label: "Native Hawaiian",
  },
  {
    value: "Non-Hawaiian Pacific Islander",
    label: "Non-Hawaiian Pacific Islander",
  },

  { value: "Not Listed", label: "Not Listed" },
  { value: "Prefer Not To Disclose", label: "Prefer Not To Disclose" },
];

export const languageSelectOptions: SelectOption<Language>[] = Object.values(
  Language
).map((l) => {
  return { value: l, label: l.toString() };
});

export const timezoneSelectOptions: SelectOption<string>[] = [
  { value: "HST", label: "Hawaii Standard Time	GMT-10:00" },
  { value: "AST", label: "Alaska Standard Time	GMT-9:00" },
  { value: "PST", label: "Pacific Standard Time	GMT-8:00" },
  { value: "PNT", label: "Phoenix Standard Time	GMT-7:00" },
  { value: "MST", label: "Mountain Standard Time	GMT-7:00" },
  { value: "CST", label: "Central Standard Time	GMT-6:00" },
  { value: "EST", label: "Eastern Standard Time	GMT-5:00" },
  { value: "GMT", label: "Greenwich Mean Time" },
  { value: "UTC", label: "Universal Coordinated Time" },
  { value: "ECT", label: "European Central Time" },
  { value: "EET", label: "Eastern European Time" },
  { value: "ART", label: "(Arabic) Egypt Standard Time" },
  { value: "EAT", label: "Eastern African Time	GMT+3:00" },
  { value: "MET", label: "Middle East Time	GMT+3:30" },
  { value: "NET", label: "Near East Time	GMT+4:00" },
  { value: "PLT", label: "Pakistan Lahore Time	GMT+5:00" },
  { value: "IST", label: "India Standard Time	GMT+5:30" },
  { value: "BST", label: "Bangladesh Standard Time	GMT+6:00" },
  { value: "VST", label: "Vietnam Standard Time	GMT+7:00" },
  { value: "CTT", label: "China Taiwan Time	GMT+8:00" },
  { value: "JST", label: "Japan Standard Time	GMT+9:00" },
  { value: "ACT", label: "Australia Central Time	GMT+9:30" },
  { value: "AET", label: "Australia Eastern Time	GMT+10:00" },
  { value: "SST", label: "Solomon Standard Time	GMT+11:00" },
  { value: "NST", label: "New Zealand Standard Time	GMT+12:00" },
  { value: "MIT", label: "Midway Islands Time	GMT-11:00" },
  { value: "IET", label: "Indiana Eastern Standard Time	GMT-5:00" },
  { value: "PRT", label: "Puerto Rico and US Virgin Islands Time	GMT-4:00" },
  { value: "CNT", label: "Canada Newfoundland Time	GMT-3:30" },
  { value: "AGT", label: "Argentina Standard Time	GMT-3:00" },
  { value: "BET", label: "Brazil Eastern Time	GMT-3:00" },
  { value: "CAT", label: "Central African Time" },
];

export const MemberStatusText: Record<MemberStatus, string> = {
  [MemberStatus.ClosedSuccessful]: "Closed - Successful",
  [MemberStatus.ClosedUnsuccessful]: "Closed - Unsuccessful",
  [MemberStatus.Declined]: "Declined",
  [MemberStatus.Active]: "Active",
};

export const StatusOptions: SelectOption<MemberStatus>[] = [
  { value: MemberStatus.ClosedSuccessful, label: "Closed - Successful" },
  { value: MemberStatus.ClosedUnsuccessful, label: "Closed - Unsuccessful" },
  { value: MemberStatus.Declined, label: "Declined" },
];

export const DeactivationReasons: SelectOption<string>[] = [
  { value: "completed", label: "Successfully Completed" },
  { value: "unreachable", label: "Unable to Make Contact" },
  { value: "declinedAll", label: "Declined All Services" },
  { value: "declinedFurther", label: "Declined Further Services" },
  { value: "notEligible", label: "Not Eligible" },
  { value: "lostToFollowUp", label: "Lost to Follow-Up" },
  { value: "movedOutOfArea", label: "Moved Out of Area" },
  { value: "transferred", label: "Transferred to Another Program" },
  { value: "noLongerEligible", label: "No Longer Eligible For Services" },
  { value: "deceased", label: "Deceased" },
  { value: "other", label: "Other" },
];
