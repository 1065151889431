import gql from "graphql-tag";
import { signatureRequestFields } from "./types";

export const CREATE_SIGNATURE_REQUEST = gql`
    mutation CreateSignatureRequest($input: SignatureRequestInput!) {
        createSignatureRequest(input: $input) {
            success
            message
            data {
                ${signatureRequestFields}
            }
        }
    }`;
