import { Formik } from "formik";
import toast from "src/libs/toast";
import { FormikInput } from "src/components";
import { Button, Stack } from "@mantine/core";
import { useLazyQueryRequestPasswordReset } from "src/graphql";

const initialValues = { email: "" };

export const RequestPasswordResetForm = () => {
  const [queryForgotPassword, { loading: isSubmitting }] =
    useLazyQueryRequestPasswordReset();

  const handleSubmit = async (email: string) => {
    try {
      const res = await queryForgotPassword({ variables: { email } });
      toast.success(
        res.data?.requestPasswordReset?.message || "Request submitted"
      );
    } catch {
      toast.error(`Couldn't send email! Something went wrong`);
    }
  };

  return (
    <>
      <p style={{ textAlign: "center", marginBottom: "25px" }}>
        Enter your email below to request a password reset link.
      </p>
      <p style={{ textAlign: "center", marginBottom: "25px" }}>
        If we have a matching email, you should see a reset link in your inbox
        shortly!
      </p>
      <Formik
        initialValues={initialValues}
        validateOnChange={true}
        enableReinitialize={true}
        onSubmit={(values) => handleSubmit(values.email)}
      >
        {({ isValid, handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Stack spacing="sm">
                <FormikInput
                  type="email"
                  name="email"
                  label="Email"
                  required
                  autoFocus
                />

                <Button
                  mt="sm"
                  loading={isSubmitting}
                  disabled={!isValid}
                  type="submit"
                >
                  Send Reset Email
                </Button>
              </Stack>
            </form>
          );
        }}
      </Formik>
    </>
  );
};
