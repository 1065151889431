import { SelectOption } from "src/types";

/**
 * Wraps a T into a SelectOption<T>
 */
export const wrapSelectOption = <T extends string | number>(
  val: T
): SelectOption<T> => ({
  label: `${val}`,
  value: val,
});

/**
 * Unwraps a SelectOption<T> back into it's original T
 */
export const unwrapSelectOption = <T extends string | number>(
  opt: SelectOption<T>
): T => opt.value;

/**
 * Wraps an array of T into a SelectOption<T> array
 */
export const wrapSelectOptionsArray = <T extends string | number>(
  opts: T[]
): SelectOption<T>[] => opts?.filter((opt) => !!opt).map(wrapSelectOption);

/**
 * Unwraps a SelectOption<T>[] back into it's original T[]
 */
export const unwrapSelectOptionsArray = <T extends string | number>(
  opts: SelectOption<T>[]
): T[] => opts.map(unwrapSelectOption);

/**
 * Returns a function that checks if a value is not equal to the provided value
 */
export const isNot =
  <T>(value: T) =>
  <V>(compare: T | V): compare is Exclude<V, T> =>
    value !== compare;
