import { memberIdAndNameFields } from "../Members/types";

export const textMessageThreadFields = `
_id
organizationId
organizationPhone
participantPhone
members {
    ${memberIdAndNameFields}
}
read
updatedAt
`;
