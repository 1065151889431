import { ArrowLeft, Save } from "react-feather";
import { Prompt, useHistory } from "react-router-dom";
import { Badge, Button, Group, TextInput } from "@mantine/core";
import React from "react";

export const BUILDER_HEADER_HEIGHT = 59;

type CarePathwaysBuilderHeaderProps = {
  title: string;
  hasChanges: boolean;
  isSubmitting: boolean;
  readOnly: boolean;
  onChangeTitle: (nextTitle: string) => void;
  onSubmit: () => void;
  placeholder?: string;
  isDefaultCarePathwayTemplate?: boolean;
};

export const CarePathwaysBuilderHeader = ({
  title,
  hasChanges,
  isSubmitting,
  readOnly,
  onChangeTitle,
  onSubmit,
  placeholder,
  isDefaultCarePathwayTemplate,
}: CarePathwaysBuilderHeaderProps) => {
  const history = useHistory();

  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeTitle(e.currentTarget.value);
  };

  return (
    <Group
      h={BUILDER_HEADER_HEIGHT}
      p={10}
      style={{ borderBottom: `${BUILDER_HEADER_HEIGHT}px`, gap: "10px" }}
    >
      <Button
        leftIcon={<ArrowLeft size="18" />}
        onClick={() => history.goBack()}
      >
        Back
      </Button>

      <TextInput
        style={{ flexGrow: 1 }}
        type="text"
        name="title"
        value={title}
        onChange={handleChangeTitle}
        placeholder={placeholder ?? "Enter Template Title..."}
        disabled={readOnly}
        required
      />

      {isDefaultCarePathwayTemplate && (
        <Badge h="90%" variant="dot">
          Default Care Pathway
        </Badge>
      )}

      {!readOnly && (
        <Button
          onClick={onSubmit}
          loading={isSubmitting}
          disabled={!hasChanges || !title}
          leftIcon={<Save size="18" />}
        >
          Save Changes
        </Button>
      )}

      <Prompt
        when={hasChanges}
        message="You have unsaved changes, are you sure you want to leave?"
      />
    </Group>
  );
};
