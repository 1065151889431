import { gql } from "@apollo/client";
import { ReferralTemplateFields } from "./types";

export const GET_REFERRAL_TEMPLATE = gql`
  query GetReferralTemplate($input: GetReferralTemplateInput!) {
    referralTemplate(input: $input) {
      success
      message
      data {
        ${ReferralTemplateFields}
      }
    }
  }
`;

export const GET_REFERRAL_TEMPLATES = gql`
  query GetReferralTemplates($input: GetReferralTemplatesInput!) {
    referralTemplates(input: $input) {
      success
      message
      data {
        ${ReferralTemplateFields}
      }
    }
  }
`;
