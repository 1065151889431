import { XYCoord } from "react-dnd";
import { Node, Edge, Position, ReactFlowInstance } from "react-flow-renderer";
import toast from "src/libs/toast";
import {
  Action,
  ActionData,
  ActionInput,
  ActionType,
  Answer,
  AnswerType,
  CreateGoalData,
  FlowStep,
  FlowStepInput,
  FlowTemplate,
  NextQuestionInput,
  Question,
  ScheduleActivityData,
  UpdateGoalData,
  UpdateMemberData,
  XyCoord,
  CreateReferralData,
  ReferralType,
  ActionDataInput,
  CallRoutingEdge,
  CallRoutingNode,
  StartCarePathwayData,
  UpdateCarePathwayStatusData,
  CreateConsentData,
  AddOrRemoveFromGroupData,
} from "src/graphql";
import { arrayToKeyedObj } from "src/utils";
import { MissingInputError } from "./template-editor/errors";
import { OrderedStepNodeTuple } from "./NodeMeasuringContainer";
import { FlowBuilderData } from "./hooks";
import { getBgColor } from "./flow-nodes/shared";

export const START_NODE_ID = "startNodeId";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type StartNodeData = any;
export type PearStartNode = Omit<Node<StartNodeData>, "type"> & {
  type: "startFlowNode";
};

export type CreateGoalAction = {
  actionType: typeof ActionType.CreateGoalData;
  actionData: CreateGoalData;
};

export type UpdateGoalAction = {
  actionType: typeof ActionType.UpdateGoalData;
  actionData: UpdateGoalData;
};

export type ScheduleActivityAction = {
  actionType: typeof ActionType.ScheduleActivityData;
  actionData: ScheduleActivityData;
};

export type UpdateMemberAction = {
  actionType: typeof ActionType.UpdateMemberData;
  actionData: UpdateMemberData;
};

export type CreateReferralAction = {
  actionType: typeof ActionType.CreateReferral;
  actionData: CreateReferralData;
};

export type StartCarePathwayAction = {
  actionType: typeof ActionType.StartCarePathway;
  actionData: StartCarePathwayData;
};

export type UpdateCarePathwayStatusAction = {
  actionType: typeof ActionType.UpdateCarePathwayStatus;
  actionData: UpdateCarePathwayStatusData;
};

export type CreateConsentAction = {
  actionType: typeof ActionType.CreateConsent;
  actionData: CreateConsentData;
};

export type AddMemberToGroupAction = {
  actionType: typeof ActionType.AddMemberToGroup;
  actionData: AddOrRemoveFromGroupData;
};

export type RemoveMemberFromGroupAction = {
  actionType: typeof ActionType.RemoveMemberFromGroup;
  actionData: AddOrRemoveFromGroupData;
};

export type ActionInfo =
  | CreateGoalAction
  | UpdateGoalAction
  | ScheduleActivityAction
  | UpdateMemberAction
  | CreateReferralAction
  | StartCarePathwayAction
  | UpdateCarePathwayStatusAction
  | CreateConsentAction
  | AddMemberToGroupAction
  | RemoveMemberFromGroupAction;

// STEP NODE TYPE
export type StepNodeData = {
  isInputNode: boolean;
  isMultiOutput: boolean;
  step: FlowStep;
  question: Question;
  actions: ActionInfo[];
  // used in flow snapshot to indicate node has an answer
  visited?: boolean;
  // used in flow snapshot to indicate node has unsaved changes
  hasUnsavedChanges?: boolean;
  // used in flow snapshot to indicate position in flow answers array
  flowAnswerIndex?: number;
};

// ANSWER NODE TYPE
export type AnswerNodeData = {
  answerValue: string;
  answerType: AnswerType;
  isOutputNode: boolean;
  actions: ActionInfo[];
  parentStepId: string;
  questionId: string;
  // used in flow snapshot to indicate node is selected answer
  visited?: boolean;
  // used in flow snapshot to indicate position in flow answers array
  flowAnswerIndex?: number;
  // used in flow snapshot to indicate node has unsaved changes
  hasUnsavedChanges?: boolean;
};

// EDGE NODE TYPE
export type EdgeNodeData = {
  // used in flow snapshot to indicate node has an answer
  visited?: boolean;
};

export type PearEdge = Edge<EdgeNodeData>;
export type PearNode =
  | Node<AnswerNodeData>
  | Node<StepNodeData>
  | PearStartNode;
export type PearNodesData = StepNodeData | AnswerNodeData;
export type FlowEditorNodeType =
  | "stepFlowNode"
  | "answerFlowNode"
  | "startFlowNode";

export const isStartNode = (node: Node<PearNodesData>): node is PearStartNode =>
  node.type === "startFlowNode";
export const isStepFlowNode = (
  node: Node<PearNodesData>
): node is Node<StepNodeData> => node.type === "stepFlowNode";
export const isAnswerFlowNode = (
  node: Node<PearNodesData>
): node is Node<AnswerNodeData> => node.type === "answerFlowNode";

export const parseTemplateForFlow = (
  template: FlowTemplate,
  questionsById: Record<string, Question>
): [PearStartNode, OrderedStepNodeTuple[], Edge[]] => {
  // setup
  const edges: Edge[] = [];
  const tuplesByStepId: Record<string, OrderedStepNodeTuple> = {};
  const stepsById: Record<string, FlowStep> = arrayToKeyedObj(
    template.steps ?? [],
    "stepId"
  );

  // recursive walker utility
  const parseStepNode = (step: FlowStep): Node<StepNodeData> => {
    // create nodes for step and answers
    const newNodes = stepToNodes(step, questionsById[step.questionId]);

    // separate parent node from potential answer nodes
    const [currentStepNode, ...answerNodes] = newNodes;
    tuplesByStepId[currentStepNode.id] = newNodes;

    /** NODES */
    // get the next steps by their ID references
    const nextSteps = (step.next ?? []).map(
      (nextStep) => stepsById[nextStep.nextStepId]
    );
    // walk all previously unwalked next nodes
    const nextStepNodes = nextSteps
      .filter((nextStep) => !!nextStep)
      .flatMap((nextStep) =>
        nextStep.stepId in tuplesByStepId
          ? [tuplesByStepId[nextStep.stepId][0]]
          : parseStepNode(nextStep)
      );

    /** EDGES */
    const isMultiOutput =
      !!step.next?.length &&
      step.next.every((next) => next.response !== "DEFAULT");

    const newEdges: Edge[] = [];
    if (isMultiOutput) {
      answerNodes.forEach((answerNode) => {
        // match answer nodes to their next step nodes, if defined
        const answerTargetId =
          step.next?.find(
            (next) => next.response === answerNode.data.answerValue
          )?.nextStepId ?? "";

        const answerTargetNode = nextStepNodes.find(
          (node) => node.id === answerTargetId
        );

        if (answerTargetNode)
          newEdges.push(edgeFromNodes(answerNode, answerTargetNode));
      });
    } else {
      const nextStep = nextStepNodes[0];
      if (nextStep)
        newEdges.push(edgeFromNodes(currentStepNode, nextStepNodes[0]));
    }

    edges.push(...newEdges);

    // return parent step node so previous invocation can create edge between
    // previous parent step node or child answer nodes
    return currentStepNode;
  };

  // grab first step and start parsing
  const entryStep = stepsById[template.entryStepId] ?? null;
  let entryNode: Node<StepNodeData> | null = null;
  if (entryStep) {
    entryNode = parseStepNode(entryStep);
  }

  // create our entry node and edge
  const startNode: PearStartNode = {
    type: "startFlowNode",
    id: START_NODE_ID,
    position: template.startNodePosition,
    data: undefined,
  };
  let entryEdge: Edge | null = null;
  if (entryNode) {
    entryEdge = edgeFromNodes(startNode, entryNode);
    edges.push(entryEdge);
  }

  return [startNode, Object.values(tuplesByStepId), edges];
};

export const stepToNodes = (
  step: FlowStep,
  question: Question,
  isInput = false
): OrderedStepNodeTuple => {
  const nextQuestions = step.next ?? [];
  const isMultiOutput = nextQuestions.some((q) => q.response !== "DEFAULT");
  const actions = step.actions ?? [];

  const stepNode: Node<StepNodeData> = {
    type: "stepFlowNode",
    data: {
      isInputNode: isInput,
      step,
      question,
      actions: (
        actions.find((action) => action.response === "DEFAULT")?.action ?? []
      ).map(actionDataToActionInfo),
      isMultiOutput,
    },
    id: step.stepId,
    position: step.nodePosition,
    targetPosition: Position.Left,
    sourcePosition: Position.Right,
  };

  const answerNodes: Node<AnswerNodeData>[] = questionToAnswerNodes(
    question,
    stepNode.id,
    actions,
    isMultiOutput
  );

  return [stepNode, ...answerNodes];
};

/**
 * Maps an ActionData object into ActionInfo type for easier consumption in
 * Flow Builder UI
 */
export const actionDataToActionInfo = (actionData: ActionData): ActionInfo => {
  // make sure that only one action type key is set
  let setKeyCount = 0;
  for (const [key, value] of Object.entries(actionData)) {
    if (key !== "__typename" && value !== null) {
      setKeyCount++;
    }
  }

  if (setKeyCount > 1) {
    throw new Error(
      "Unparseable action encountered: Action has more than one action type key set"
    );
  }

  if (setKeyCount === 0) {
    throw new Error(
      "Unparseable action encountered: Action has no action type keys set"
    );
  }

  if (actionData.createGoal) {
    return {
      actionType: ActionType.CreateGoalData,
      actionData: actionData.createGoal,
    };
  }
  if (actionData.scheduleActivity) {
    return {
      actionType: ActionType.ScheduleActivityData,
      actionData: actionData.scheduleActivity,
    };
  }
  if (actionData.updateGoal) {
    return {
      actionType: ActionType.UpdateGoalData,
      actionData: actionData.updateGoal,
    };
  }
  if (actionData.updateMember) {
    return {
      actionType: ActionType.UpdateMemberData,
      actionData: actionData.updateMember,
    };
  }
  if (actionData.createReferral) {
    return {
      actionType: ActionType.CreateReferral,
      actionData: actionData.createReferral,
    };
  }
  if (actionData.startCarePathway) {
    return {
      actionType: ActionType.StartCarePathway,
      actionData: actionData.startCarePathway,
    };
  }
  if (actionData.updateCarePathwayStatus) {
    return {
      actionType: ActionType.UpdateCarePathwayStatus,
      actionData: actionData.updateCarePathwayStatus,
    };
  }
  if (actionData.createConsent) {
    return {
      actionType: ActionType.CreateConsent,
      actionData: actionData.createConsent,
    };
  }
  if (actionData.addMemberToGroup) {
    return {
      actionType: ActionType.AddMemberToGroup,
      actionData: actionData.addMemberToGroup,
    };
  }
  if (actionData.removeMemberFromGroup) {
    return {
      actionType: ActionType.RemoveMemberFromGroup,
      actionData: actionData.removeMemberFromGroup,
    };
  }

  // couldn't parse a known action type
  throw new Error(
    "Unparseable action encountered: Action has no type keys set that parser recognizes"
  );
};

export const actionInfoToActionData = (actionInfo: ActionInfo): ActionData => {
  let actionData: ActionData;

  switch (actionInfo.actionType) {
    case ActionType.CreateGoalData: {
      actionData = { createGoal: actionInfo.actionData };
      break;
    }
    case ActionType.UpdateGoalData:
      actionData = { updateGoal: actionInfo.actionData };
      break;
    case ActionType.ScheduleActivityData:
      actionData = { scheduleActivity: actionInfo.actionData };
      break;
    case ActionType.UpdateMemberData:
      actionData = { updateMember: actionInfo.actionData };
      break;
    case ActionType.CreateReferral:
      actionData = { createReferral: actionInfo.actionData };
      break;
    case ActionType.StartCarePathway:
      actionData = { startCarePathway: actionInfo.actionData };
      break;
    case ActionType.UpdateCarePathwayStatus:
      actionData = { updateCarePathwayStatus: actionInfo.actionData };
      break;
    case ActionType.CreateConsent:
      actionData = { createConsent: actionInfo.actionData };
      break;
    case ActionType.AddMemberToGroup:
      actionData = { addMemberToGroup: actionInfo.actionData };
      break;
    case ActionType.RemoveMemberFromGroup:
      actionData = { removeMemberFromGroup: actionInfo.actionData };
      break;
  }

  return actionData;
};

export const READ_ONLY_ACKNOWLEDGED_VALUE = "ack";
export const READ_ONLY_NOT_ACKNOWLEDGED_VALUE = "not-ack";

export const questionToAnswerNodes = (
  question: Question,
  parentStepId: string,
  stepActions: Action[],
  isOutputNode: boolean
): Node<AnswerNodeData>[] => {
  let answerOptions: string[];

  switch (question.answerType) {
    case AnswerType.Boolean:
      answerOptions = ["True", "False"];
      break;
    case AnswerType.Number:
    case AnswerType.Text:
    case AnswerType.Date:
      answerOptions = [""];
      break;
    case AnswerType.ReadOnlyText:
      answerOptions = [READ_ONLY_NOT_ACKNOWLEDGED_VALUE];
      break;
    case AnswerType.Multi:
    case AnswerType.MultiChoice:
    default:
      answerOptions = question.answerOptions ?? [];
      break;
  }

  return answerOptions.map((answerValue, i) => {
    const answerActions: ActionInfo[] = (
      stepActions.find((action) => action.response === answerValue)?.action ??
      []
    ).map(actionDataToActionInfo);

    return {
      type: "answerFlowNode",
      id: `${parentStepId}.answer.${i}`,
      data: {
        actions: answerActions,
        answerType: question.answerType,
        answerValue: answerValue,
        parentStepId,
        questionId: question._id,
        isOutputNode,
      },
      position: { x: 0, y: 0 }, // Measured relative to associated StepNode pre-render
      parentNode: parentStepId,
      draggable: false,
    };
  });
};

/**
 * Creates an edge from two nodes (preferred variant of this fn, see below)
 */
const edgeFromNodes = (
  source: Node<PearNodesData>,
  target: Node<StepNodeData>
): Edge => ({
  id: `reactflow__edge-${source.id}-${target.id}`,
  source: source.id,
  target: target.id,
});

/**
 * Creates an edge from two node IDs
 * NOTE: Only use this variant if the context makes it expensive or complicated to
 *       grab a direct reference to the target node; doesn't enforce node types for target
 */
export const edgeFromNodeIds = (sourceId: string, targetId: string): Edge => ({
  id: `reactflow__edge-${sourceId}-${targetId}`,
  source: sourceId,
  target: targetId,
});

// Creates a new set of unconnected nodes from a question which is dragged and dropped
// into the FlowFlowEditor
export const nodesFromQuestionDrop = (
  question: Question,
  coords: XYCoord
): [Node<StepNodeData>, ...Array<Node<AnswerNodeData>>] => {
  const stepId = Date.now().toString();

  // make parent node
  const stepNode: Node<StepNodeData> = {
    type: "stepFlowNode",
    data: {
      isInputNode: false,
      actions: [],
      step: {
        nodePosition: {
          x: coords.x,
          y: coords.y,
        },
        stepId,
        questionId: question._id,
      },
      question,
      isMultiOutput: false,
    },
    targetPosition: Position.Left,
    sourcePosition: Position.Right,
    position: { x: coords.x, y: coords.y },
    id: stepId,
  };

  const answerNodes = questionToAnswerNodes(question, stepNode.id, [], false);

  return [stepNode, ...answerNodes];
};

/**
 * Finds and returns all related Parent and Child nodes
 * from a search node and a list of all nodes.
 */
export const findParentOrChildNodeGroup = <T, K>(
  searchNode: Node,
  allNodes: Node[]
): [Node<T>, ...Node<K>[]] => {
  const parentNodeId =
    "parentNode" in searchNode ? searchNode.parentNode : searchNode.id;

  let parentNode: Node<T> | null = null;
  const childNodes: Node<K>[] = [];

  allNodes.forEach((node) => {
    if (node.id === parentNodeId) {
      parentNode = node;
    }
    if (node.parentNode === parentNodeId) {
      childNodes.push(node);
    }
  });
  if (!parentNode) throw new Error("Node grouping is missing parent node.");

  return [parentNode, ...childNodes];
};

// flow-editor specific
export const findStepOrAnswerNodeGroup = (
  searchNode: Node<PearNodesData>,
  allNodes: Node<PearNodesData>[]
) =>
  findParentOrChildNodeGroup<StepNodeData, AnswerNodeData>(
    searchNode,
    allNodes
  );

// call-routing-editor specific
export const findBlockOrConditionNodeGroup = (
  searchNode: Node<CallRoutingNode | CallRoutingEdge>,
  allNodes: Node<CallRoutingNode | CallRoutingEdge>[]
) =>
  findParentOrChildNodeGroup<CallRoutingNode, CallRoutingEdge>(
    searchNode,
    allNodes
  );

/**
 * Finds and returns all edges associated with an array of Nodes
 */
export const findEdgesForNodeGroup = (
  nodeGroup: Node[],
  edges: Edge[]
): Edge[] => {
  const nodeGroupIdSet = new Set(nodeGroup.map((node) => node.id));
  return edges.filter((edge) => {
    return nodeGroupIdSet.has(edge.source) || nodeGroupIdSet.has(edge.target);
  });
};

export const findAndRemoveNodeGroup = <T>(
  nodeId: string,
  nodes: Node<T>[],
  edges: Edge[]
) => {
  // grab target node
  const parentNode = findNodeById(nodes, nodeId);

  // find ids for associated node grouping & edges
  const nodeGroup = findParentOrChildNodeGroup(parentNode, nodes);
  const nodeGroupEdges = findEdgesForNodeGroup(nodeGroup, edges);
  const nodeIdSet = new Set(nodeGroup.map((node) => node.id));
  const edgeIdSet = new Set(nodeGroupEdges.map((edge) => edge.id));

  // remove nodes & edges
  const nextNodes = nodes.filter((node) => !nodeIdSet.has(node.id));
  const nextEdges = edges.filter((edge) => !edgeIdSet.has(edge.id));

  return { nextNodes, nextEdges };
};

/**
 * Finds and returns a node by it's ID. Errors if node missing.
 */
export const findNodeById = <T>(nodes: Node<T>[], nodeId: string): Node<T> => {
  const node = nodes.find((node) => node.id === nodeId);
  if (!node) {
    // should never happen?
    toast.error("Something went wrong...");
    throw new Error("Could not find node corresponding with passed ID");
  }
  return node;
};

/**
 * Finds and returns a step node by it's ID. Errors if node is missing, or if
 * found node is not a Node<StepNodeData>.
 */
export const findStepNodeById = (
  nodes: Node<PearNodesData>[],
  nodeId: string
): Node<StepNodeData> => {
  const node = findNodeById(nodes, nodeId);
  if (!isStepFlowNode(node)) {
    toast.error("Something went wrong...");
    throw new Error(
      "Found a node that is not a StepNode in a function where only StepNodes are valid results"
    );
  }
  return node;
};

/**
 * Finds and returns a Step node from a given questionId, if it exists.
 */
export const findNodeByQuestionId = (
  nodes: Node<PearNodesData>[],
  questionId: string
): Node<StepNodeData> | undefined =>
  nodes.find(
    (node) => isStepFlowNode(node) && node.data.question._id === questionId
  ) as Node<StepNodeData>;

/**
 * Resolves title of an action from it's ActionInfo and available options
 */
export const getActionTitle = (
  options: FlowBuilderData["actionOptionsByTypeById"],
  info: ActionInfo
) => {
  let title: string | undefined = "";

  switch (info?.actionType) {
    case ActionType.CreateGoalData:
    case ActionType.UpdateGoalData:
      title =
        options[ActionType.CreateGoalData][info.actionData.goalTemplateId]
          ?.name;
      break;
    case ActionType.ScheduleActivityData:
      title =
        options[ActionType.ScheduleActivityData][
          info.actionData.activityTemplateId
        ]?.title;
      break;
    case ActionType.CreateReferral:
      title =
        options[ActionType.CreateReferral][info.actionData.externalResourceId]
          ?.title;
      break;
    case ActionType.StartCarePathway:
      title =
        options[ActionType.StartCarePathway][
          info.actionData.carePathwayTemplateId
        ]?.name;
      break;
    case ActionType.UpdateCarePathwayStatus:
      title =
        options[ActionType.UpdateCarePathwayStatus][
          info.actionData.carePathwayTemplateId
        ]?.name;
      break;
    case ActionType.CreateConsent:
      title =
        options[ActionType.CreateConsent][info.actionData.consentTemplateId]
          ?.name;
      break;
    case ActionType.AddMemberToGroup:
      title =
        options[ActionType.AddMemberToGroup][info.actionData.groupId]?.title;
      break;
    case ActionType.RemoveMemberFromGroup:
      title =
        options[ActionType.RemoveMemberFromGroup][info.actionData.groupId]
          ?.title;
      break;
  }

  return title ?? "<title unavailable>";
};

export const getActionTitleFromActionData = (
  options: FlowBuilderData["actionOptionsByTypeById"],
  data: ActionData
) => {
  let title: string | undefined = "";

  if (data.createGoal?.goalTemplateId) {
    title =
      options[ActionType.CreateGoalData][data.createGoal?.goalTemplateId]?.name;
  }

  if (data.scheduleActivity?.activityTemplateId) {
    title =
      options[ActionType.ScheduleActivityData][
        data.scheduleActivity?.activityTemplateId
      ]?.title;
  }

  if (data.updateGoal?.goalTemplateId) {
    title =
      options[ActionType.CreateGoalData][data.updateGoal?.goalTemplateId]?.name;
  }

  if (data.updateMember?.field) {
    title = data.updateMember.field;
  }

  if (data.createReferral?.externalResourceId) {
    title =
      options[ActionType.CreateReferral][
        data.createReferral?.externalResourceId
      ]?.title;
  }

  if (data.createConsent?.consentTemplateId) {
    title =
      options[ActionType.CreateConsent][data.createConsent?.consentTemplateId]
        ?.name;
  }

  if (data.startCarePathway?.carePathwayTemplateId) {
    title =
      options[ActionType.StartCarePathway][
        data.startCarePathway?.carePathwayTemplateId
      ]?.name;
  }

  if (data.updateCarePathwayStatus?.carePathwayTemplateId) {
    title =
      options[ActionType.UpdateCarePathwayStatus][
        data.updateCarePathwayStatus?.carePathwayTemplateId
      ]?.name;
  }

  if (data.addMemberToGroup?.groupId) {
    title =
      options[ActionType.AddMemberToGroup][data.addMemberToGroup?.groupId]
        ?.title;
  }

  if (data.removeMemberFromGroup?.groupId) {
    title =
      options[ActionType.RemoveMemberFromGroup][
        data.removeMemberFromGroup?.groupId
      ]?.title;
  }

  return title ?? "<title unavailable>";
};

type GroupedNodesForStep = {
  stepNode: Node<StepNodeData>;
  answerNodesById?: Record<string, Node<AnswerNodeData>>;
  edges: Edge[];
};

/**
 * Serializes a set of nodes and edges into our `FlowStepInput` data structs
 */
export const serializeFlowNodes = (
  nodes: Node<PearNodesData>[],
  edges: Edge[]
) => {
  const edgeTargetIdSet = new Set(edges.map((edge) => edge.target));
  const groupedNodesByStepId: Record<string, Partial<GroupedNodesForStep>> = {};
  const answerNodesById: Record<string, Node<AnswerNodeData>> = {};
  let startNodePosition: XyCoord | null = null;
  let entryNodeId: string | null = null;

  // walk and group nodes
  for (const node of nodes) {
    if (isStartNode(node)) {
      startNodePosition = roundPosition(node.position);
    }

    if (isStepFlowNode(node)) {
      // validate that all step nodes have at least one edge pointing to them
      if (!edgeTargetIdSet.has(node.id)) {
        throw new MissingInputError(node);
      }

      if (node.id in groupedNodesByStepId) {
        groupedNodesByStepId[node.id].stepNode = node;
      } else {
        groupedNodesByStepId[node.id] = { stepNode: node };
      }
    }

    if (isAnswerFlowNode(node)) {
      const stepNodeId = node.data.parentStepId;
      answerNodesById[node.id] = node;
      if (stepNodeId in groupedNodesByStepId) {
        const existing = groupedNodesByStepId[stepNodeId].answerNodesById ?? {};
        groupedNodesByStepId[stepNodeId].answerNodesById = {
          ...existing,
          [node.id]: node,
        };
      } else {
        groupedNodesByStepId[stepNodeId].answerNodesById = { [node.id]: node };
      }
    }
  }

  // walk and group edges
  for (const edge of edges) {
    if (edge.source === START_NODE_ID) {
      entryNodeId = edge.target;
      continue;
    }

    const stepNodeId =
      edge.source in groupedNodesByStepId
        ? edge.source
        : answerNodesById[edge.source].data.parentStepId;

    const existing = groupedNodesByStepId[stepNodeId].edges ?? [];
    groupedNodesByStepId[stepNodeId].edges = existing.concat(edge);
  }

  const flowStepInputs = Object.values(
    groupedNodesByStepId as Record<string, GroupedNodesForStep>
  ).map(makeFlowStepInputFromGroup);

  if (!entryNodeId) {
    throw new Error("Template is missing a connection to the `start` node");
  }

  if (!startNodePosition) {
    throw new Error("Template is missing start node");
  }

  // return `FlowStepInput`s from grouped nodes
  return {
    entryNodeId,
    startNodePosition,
    flowStepInputs,
  };
};

const makeFlowStepInputFromGroup = (
  group: GroupedNodesForStep
): FlowStepInput => {
  const { stepNode, answerNodesById, edges } = group as GroupedNodesForStep;
  const actions: ActionInput[] = [];
  const next: NextQuestionInput[] = [];

  // get actions from step
  if (stepNode.data.actions.length) {
    actions.push({
      response: "DEFAULT",
      action: stepNode.data.actions.map(
        actionInfoToActionData
      ) as ActionDataInput[],
    });
  }

  // get actions from answers
  Object.values(answerNodesById || {}).forEach((node) => {
    if (node.data.actions.length) {
      actions.push({
        response: node.data.answerValue,
        action: node.data.actions.map(
          actionInfoToActionData
        ) as ActionDataInput[],
      });
    }
  });

  // get next from edges
  if (edges) {
    edges.forEach((edge) => {
      if (edge.source === stepNode.id) {
        next.push({
          nextStepId: edge.target,
          response: "DEFAULT",
        });
      } else {
        next.push({
          nextStepId: edge.target,
          response: answerNodesById
            ? answerNodesById[edge.source].data.answerValue
            : "DEFAULT",
        });
      }
    });
  }

  return {
    stepId: stepNode.id,
    questionId: stepNode.data.question._id,
    nodePosition: roundPosition(stepNode.position),
    actions,
    next,
  };
};

/**
 * Checks whether a step node has an answer type which requires User Input
 */
export const isUserInputType = (
  node: Node<StepNodeData> | Node<AnswerNodeData>
) =>
  (
    [
      AnswerType.Date,
      AnswerType.Number,
      AnswerType.Text,
      AnswerType.Date,
    ] as string[]
  ).includes(
    isStepFlowNode(node) ? node.data.question.answerType : node.data.answerType
  );

// Maps a flow's progress snapshot onto parsed flow template nodes' data property
export const augmentNodesWithFlowProgress = (
  template: FlowTemplate,
  nodeTuples: OrderedStepNodeTuple[],
  edges: PearEdge[],
  answers: Answer[]
): [OrderedStepNodeTuple[], PearEdge[], Answer[]] => {
  const mappedAnswers: Answer[] = [];
  const tuplesByStepId = nodeTuples.reduce(
    (byId, tuple) => ({ ...byId, [tuple[0].id]: tuple }),
    {} as Record<string, OrderedStepNodeTuple>
  );
  const edgesBySourceId = edges.reduce(
    (byId, edge) => ({
      ...byId,
      [edge.source]: edge,
    }),
    {} as Record<string, PearEdge>
  );

  // mark start edge visited
  edgesBySourceId[START_NODE_ID] = {
    ...edgesBySourceId[START_NODE_ID],
    data: {
      visited: true,
    },
    style: {
      stroke: "var(--color-pear-green)",
      strokeWidth: 2,
    },
  };

  let currentTupleStepId = tuplesByStepId[template.entryStepId][0].id;
  for (const currentAnswer of Object.values(answers)) {
    const mutableCurrentAnswer = copyFrozen(currentAnswer);
    const [currentStepNode, ...currentAnswerNodes] =
      tuplesByStepId[currentTupleStepId];
    const isMultiOutput = currentStepNode.data.isMultiOutput;

    // get next step id
    let nextTupleStepId: string | undefined;
    if (isMultiOutput) {
      nextTupleStepId =
        currentStepNode.data.step.next?.find(
          (next) => next.response === mutableCurrentAnswer.answer
        )?.nextStepId ?? "";
    } else {
      nextTupleStepId = currentStepNode.data.step.next?.[0]?.nextStepId ?? "";
    }

    // mark step as visited
    currentStepNode.data.visited = true;
    // mark answer as visited
    if (isMultiOutput) {
      const questionAnswerIndex = currentAnswerNodes.findIndex(
        (node) => node.data.answerValue === mutableCurrentAnswer.answer
      );

      if (questionAnswerIndex === -1 || questionAnswerIndex === undefined)
        throw new Error(
          "Unparseable Flow: missing corresponding next in template step for given answer"
        );
      currentAnswerNodes[questionAnswerIndex].data.visited = true;
    } else {
      switch (currentStepNode.data.question.answerType) {
        case AnswerType.MultiChoice: {
          // parse and mark each selected answer
          try {
            const answers: string[] = JSON.parse(currentAnswer.answer);
            currentAnswerNodes.forEach((node, i) => {
              if (answers.includes(node.data.answerValue))
                currentAnswerNodes[i].data.visited = true;
            });
          } catch (e) {
            throw new Error(
              "Unparseable flow: Answer provided for question in a format which JSON.Parse() could not parse."
            );
          }
          break;
        }
        case AnswerType.Multi: {
          // find corresponding answer and mark
          const answerIndex = currentAnswerNodes.findIndex(
            (node) => node.data.answerValue === mutableCurrentAnswer.answer
          );

          if (answerIndex === -1 || answerIndex === undefined)
            throw new Error(
              "Unparseable Flow: missing corresponding next in template step for given answer"
            );
          currentAnswerNodes[answerIndex].data.visited = true;
          break;
        }
        case AnswerType.ReadOnlyText: {
          // both node and returned Answer value need setting;
          currentAnswerNodes[0].data.answerValue = READ_ONLY_ACKNOWLEDGED_VALUE;
          mutableCurrentAnswer.answer = READ_ONLY_ACKNOWLEDGED_VALUE;
          break;
        }
        default: {
          // for all others, mark first
          currentAnswerNodes[0].data.visited = true;
        }
      }
    }

    // if answer is a User Input type, populate node with answer value;
    if (isUserInputType(currentStepNode)) {
      currentAnswerNodes[0].data.answerValue = mutableCurrentAnswer.answer;
    }

    // if we have a next, mark edge as visited
    if (nextTupleStepId) {
      let edgeSourceId;

      if (isMultiOutput) {
        edgeSourceId =
          currentAnswerNodes.find(
            (node) => node.data.answerValue === mutableCurrentAnswer.answer
          )?.id ?? "";
      } else {
        edgeSourceId = currentTupleStepId;
      }

      if (!edgeSourceId)
        throw new Error("Unparseable Flow: Failed to map answer to an edge");
      edgesBySourceId[edgeSourceId] = markEdgeVisited(
        edgesBySourceId[edgeSourceId]
      );
    }

    // map back to return tuples with flowAnswerIndex appended
    currentStepNode.data.flowAnswerIndex = currentAnswer.flowAnswerIndex;
    currentAnswerNodes.forEach(
      (node) => (node.data.flowAnswerIndex = currentAnswer.flowAnswerIndex)
    );
    tuplesByStepId[currentTupleStepId] = [
      currentStepNode,
      ...currentAnswerNodes,
    ];

    currentTupleStepId = nextTupleStepId ?? "";
    mappedAnswers.push(mutableCurrentAnswer);
  }

  return [
    Object.values(tuplesByStepId),
    Object.values(edgesBySourceId),
    mappedAnswers,
  ];
};

export const markEdgeVisited = (
  edge: PearEdge,
  hasUnsavedChanges?: boolean
) => ({
  ...edge,
  data: {
    ...edge.data,
    visited: true,
  },
  style: {
    stroke: getBgColor(!!hasUnsavedChanges),
    strokeWidth: 2,
  },
});

export const markEdgeUnvisited = (edge: PearEdge) => ({
  ...edge,
  data: {
    ...edge.data,
    visited: false,
  },
  style: undefined,
});

// for User input types in the Snapshot Editor, validates that there is a value
// present before attempting to commit an update.
export const validateAnswerUpdate = (
  nextAnswerJSON: string,
  answerType: AnswerType
): { message: string; isValid: boolean } => {
  let message = "";
  let isValid = true;

  switch (answerType) {
    case AnswerType.MultiChoice:
      try {
        const parsed = JSON.parse(nextAnswerJSON) as string[];
        if (!Array.isArray(parsed) || !parsed.length) {
          isValid = false;
          message =
            "Must select at least one value for a multiple-choice question.";
        }
      } catch {
        isValid = false;
        message =
          "Must select at least one value for a multiple-choice question.";
      }
      break;
    case AnswerType.Date:
    case AnswerType.Number:
    case AnswerType.Text:
      if (!nextAnswerJSON || nextAnswerJSON.trim().length < 1) {
        isValid = false;
        message = "Answer cannot be updated with an empty value.";
      }
      break;
    case AnswerType.ReadOnlyText:
      if (nextAnswerJSON !== READ_ONLY_ACKNOWLEDGED_VALUE) {
        isValid = false;
        message = "Read Only answers must be marked acknowledged.";
      }
      break;
    default:
      break;
  }

  return { message, isValid };
};

// For Flow Snapshot Editor,
// updates an answer in a given node group, returning a new copy
export const updateNodeGroupAnswer = (
  prevNodeGroup: OrderedStepNodeTuple,
  nextAnswerJSON: string
): OrderedStepNodeTuple => {
  const answerType = prevNodeGroup[0].data.question.answerType;
  // make a mutable copy
  const mutableNodeGroup = JSON.parse(
    JSON.stringify(prevNodeGroup)
  ) as OrderedStepNodeTuple;
  const [nextStepNode] = mutableNodeGroup;
  let [, ...nextAnswerNodes] = mutableNodeGroup;

  switch (answerType) {
    // if input type, update answer node value;
    case AnswerType.Date:
    case AnswerType.Text:
    case AnswerType.Number: {
      nextAnswerNodes[0].data.answerValue = nextAnswerJSON;
      break;
    }
    // if select multiple type, toggle answerValue;
    case AnswerType.MultiChoice: {
      const valueSet = new Set(JSON.parse(nextAnswerJSON) as string[]);
      nextAnswerNodes = nextAnswerNodes.map((node) => {
        node.data.visited = valueSet.has(node.data.answerValue);
        return node;
      });
      break;
    }
    // if select one type, toggle current answer & target answer;
    case AnswerType.Boolean:
    case AnswerType.Multi: {
      nextAnswerNodes = nextAnswerNodes.map((node) => {
        if (node.data.visited) {
          node.data.visited = false;
        }
        if (node.data.answerValue === nextAnswerJSON) {
          node.data.visited = true;
        }
        return node;
      });
      break;
    }
    // if read only, do nothing
    case AnswerType.ReadOnlyText: {
      break;
    }
  }

  return [nextStepNode, ...nextAnswerNodes];
};

// Checks if a given updated answer in a Node Tuple group will change
// the output path from that group
export const willFlowPathChangeWithUpdate = (
  targetTuple: OrderedStepNodeTuple,
  edges: PearEdge[],
  nextAnswerJSON: string
) => {
  const [targetStepNode, ...targetAnswerNodes] = targetTuple;

  // READ ONLY is the only type of node that can be toggled from "answer" to "no answer"
  // this esc hatch handles that case
  if (
    targetStepNode.data.question.answerType === AnswerType.ReadOnlyText &&
    targetStepNode.data.visited &&
    nextAnswerJSON !== READ_ONLY_ACKNOWLEDGED_VALUE
  ) {
    return true;
  }

  const nodeGroupEdges = findEdgesForNodeGroup(targetTuple, edges);
  const prevAnswerNode = targetAnswerNodes.find((node) => !!node.data.visited);
  const nextAnswerNode = targetAnswerNodes.find(
    (node) => node.data.answerValue === nextAnswerJSON
  );
  // check if nodes have different targets for their corresponding edges
  const prevAnswerEdge = nodeGroupEdges.find(
    (edge) => edge.source === prevAnswerNode?.id
  );
  const nextAnswerEdge = nodeGroupEdges.find(
    (edge) => edge.source === nextAnswerNode?.id
  );

  return prevAnswerEdge?.target !== nextAnswerEdge?.target;
};

// used in Flow Snapshot Editor to prune previously given answers,
// when a change to an answer changes the path of the graph.
export const pruneOrphanedNodes = (
  nodes: Node<PearNodesData>[],
  currentAnswers: Answer[],
  changedNodeTuple: OrderedStepNodeTuple
) => {
  const changeIndex = changedNodeTuple[0].data.flowAnswerIndex ?? 0;

  // find all nodes with answers that are past the updated node
  const droppedAnswerQuestionIdSet = new Set(
    currentAnswers.slice(changeIndex + 1).map((a) => a.questionId)
  );
  const mutableNodes = JSON.parse(
    JSON.stringify(nodes)
  ) as Node<PearNodesData>[];

  const prunedNodeTuples = mutableNodes.reduce((byId, curr) => {
    if (
      isStepFlowNode(curr) &&
      droppedAnswerQuestionIdSet.has(curr.data.question._id)
    ) {
      curr.data.visited = false;
      curr.data.flowAnswerIndex = undefined;
      if (curr.id in byId) {
        byId[curr.id][0] = curr;
      } else {
        byId[curr.id] = [curr];
      }
    }
    if (
      isAnswerFlowNode(curr) &&
      droppedAnswerQuestionIdSet.has(curr.data.questionId)
    ) {
      curr.data.visited = false;

      // UserInput and ReadOnly types need their answerValues cleared
      if (isUserInputType(curr)) {
        curr.data.answerValue = "";
      }
      if (curr.data.answerType === AnswerType.ReadOnlyText) {
        curr.data.answerValue = READ_ONLY_NOT_ACKNOWLEDGED_VALUE;
      }

      // add to map
      if (curr.data.parentStepId in byId) {
        byId[curr.data.parentStepId].push(curr);
      } else {
        const tuple = [];
        tuple[1] = curr;
        byId[curr.data.parentStepId] = tuple as OrderedStepNodeTuple;
      }
    }
    return byId;
  }, {} as Record<string, OrderedStepNodeTuple>);

  const prunedNodeIds = new Set(
    Object.values(prunedNodeTuples).flatMap((t) => t.map((n) => n.id))
  );
  const prunedWorkingAnswers = currentAnswers.slice(0, changeIndex + 1);

  return { prunedNodeTuples, prunedNodeIds, prunedWorkingAnswers };
};

export const updateEdgesForGraphRedirect = (
  edges: PearEdge[],
  prunedNodeIds: Set<string>,
  prechangeCurrentStepId: string,
  nextCurrentSourceId: string,
  nextCurrentTargetId: string
) =>
  edges.map((edge) => {
    let nextEdge = { ...edge };
    if (
      prunedNodeIds.has(nextEdge.source) ||
      prunedNodeIds.has(nextEdge.target) ||
      nextEdge.target === prechangeCurrentStepId
    ) {
      nextEdge = markEdgeUnvisited(nextEdge);
    }
    if (
      nextEdge.source === nextCurrentSourceId &&
      nextEdge.target === nextCurrentTargetId
    ) {
      nextEdge = markEdgeVisited(nextEdge, true);
    }
    return nextEdge;
  });

export type IndividualActionData =
  | CreateGoalData
  | UpdateGoalData
  | ScheduleActivityData
  | CreateReferralData
  | StartCarePathwayData
  | UpdateCarePathwayStatusData
  | CreateConsentData;

enum ChangeType {
  NoChange,
  AddedAnswer,
  ChangedAnswer,
}

/**
 * For use in Flow Snapshot Editor;
 *
 * When Flow has been edited, and is requested to be saved,
 * this function runs a comparison of previous and next answers for the Flow,
 * collecting any actions that are configured for any newly-given answers which
 * ought to be now administered, which need any data collected from user
 * (e.g. scheduling activities).
 */
export const gatherActionsToPerform = (
  selectedTemplate: FlowTemplate,
  questionsById: Record<string, Question>,
  prevAnswers: Answer[],
  nextAnswers: Answer[]
) => {
  // before submitting, check if we need to collect more info for new actions
  // (e.g. scheduling activities)

  // perform any previously unperformed actions from the nextAnswers arr
  const orderedChangedOrAddedAnswers = nextAnswers.map((nextAnswer, i) => {
    if (nextAnswer.questionId !== prevAnswers[i]?.questionId)
      return ChangeType.AddedAnswer;
    if (nextAnswer.answer !== prevAnswers[i]?.answer)
      return ChangeType.ChangedAnswer;
    return ChangeType.NoChange;
  });

  const stepsByStepId = arrayToKeyedObj(selectedTemplate.steps ?? [], "stepId");

  const actionsToCollectDataFor = orderedChangedOrAddedAnswers.reduce(
    (gatheredActions, changeType, i) => {
      if (changeType === ChangeType.NoChange) return gatheredActions;

      const { questionId, stepId } = nextAnswers[i];
      const stepActions = stepsByStepId[stepId].actions ?? [];
      const answerType = questionsById[questionId].answerType;
      const nextAnswerValue = nextAnswers[i].answer;

      // for multi-choice, we need to match answers to nodes marked visited;
      const parsedAnswers =
        answerType === AnswerType.MultiChoice
          ? (JSON.parse(nextAnswerValue) as string[])
          : [nextAnswerValue];

      // check step for global action
      const defaultActions = stepActions.filter(
        (action) => action.response === "DEFAULT"
      );
      if (defaultActions.length && changeType === ChangeType.AddedAnswer) {
        gatheredActions = gatheredActions.concat(defaultActions);
      }

      // check answers for actions
      const answerActions: Action[] = [];
      switch (answerType) {
        // single-answer types, always take first answer value
        case AnswerType.Boolean:
        case AnswerType.Date:
        case AnswerType.Number:
        case AnswerType.ReadOnlyText:
        case AnswerType.Text: {
          const action = stepActions.find(
            (action) => action.response !== "DEFAULT"
          );
          if (action) answerActions.push(action);
          break;
        }
        // select-one types, match answer value
        case AnswerType.Multi: {
          const action = stepActions.find(
            (action) => action.response === parsedAnswers[0]
          );
          if (action) answerActions.push(action);
          break;
        }
        // select-many types, need to diff previous answers
        case AnswerType.MultiChoice: {
          const previouslySelected = new Set(
            prevAnswers[i]
              ? (JSON.parse(prevAnswers[i].answer) as string[])
              : []
          );
          const actions = stepActions.filter(
            (action) =>
              parsedAnswers.includes(action.response) &&
              !previouslySelected.has(action.response)
          );
          if (actions.length) answerActions.push(...actions);
          break;
        }
      }

      gatheredActions = gatheredActions.concat(answerActions);
      return gatheredActions;
    },
    [] as Action[]
  );

  return actionsToCollectDataFor;
};

export const flattenActions = (action?: Action) =>
  action
    ? ((action.action ?? []).flatMap((sub) =>
        Object.values(sub).filter((v) => !!v && v !== "ActionData")
      ) as IndividualActionData[])
    : [];

// deep unfreezes an object frozen with .freeze() (react-flow-editor freezes node data)
export const copyFrozen = <T>(obj: T): T => JSON.parse(JSON.stringify(obj));

export const toggleMultiChoiceAnswer = (
  prevAnswerJSON: string,
  toggleValue: string
) => {
  try {
    let parsedValue = prevAnswerJSON
      ? (JSON.parse(prevAnswerJSON) as string[])
      : [];
    const toggleValueIndex = parsedValue.findIndex(
      (value) => value === toggleValue
    );

    if (toggleValueIndex === -1) {
      parsedValue.push(toggleValue);
    } else {
      parsedValue = parsedValue.filter((value) => value !== toggleValue);
    }
    return JSON.stringify(parsedValue);
  } catch {
    throw new Error(
      "Couldn't parse previous answer JSON - likely a coding error"
    );
  }
};

export const getActionsNeedingInput = (actions: IndividualActionData[]) =>
  actions.filter((action) => {
    if (action.__typename === "ScheduleActivityData") return true;
    if (action.__typename === "StartCarePathwayData") return true;
    if (action.__typename === "CreateReferralData") {
      if (action.type === ReferralType.Activity) {
        return true;
      }
    }
    return false;
  });

/**
 * Scrolls the viewport of the passed ReactFlow instance to the coordinates
 * specified on the pass Node
 */
export const scrollToNode = (
  node: Node<unknown>,
  reactFlowInstance: ReactFlowInstance
) => {
  const centerX = node.position.x + (node.width ?? 1) / 2;
  const centerY = node.position.y + (node.height ?? 1) / 2;
  reactFlowInstance.setCenter(centerX, centerY, { zoom: 1, duration: 400 });
};

export const roundPosition = (coords: XYCoord) => ({
  x: Math.round(coords.x),
  y: Math.round(coords.y),
});
