import { useEffect, useRef, useState } from "react";
import { useMutationExchangeOneTimeIdToken } from "src/graphql";

/**
 * Hook which exchanges a one time ID token for a 10 minute one time session cookie
 */

export enum OneTimeSessionState {
  Loading = 0,
  Expired,
  Active,
}

export const useOneTimeSession = (oneTimeIdToken: string | null) => {
  const [sessionState, setSessionState] = useState(OneTimeSessionState.Loading);
  const sessionExpiredTimerRef = useRef<number | null>(null);

  const [
    mutationExchangeToken,
    {
      loading: sessionLoading,
      data: sessionTokenResponse,
      called: sessionExchangeCalled,
    },
  ] = useMutationExchangeOneTimeIdToken();

  // validate && exchange one time token for 10m session token (sets oneTimeSessionToken cookie)
  useEffect(() => {
    if (oneTimeIdToken) {
      mutationExchangeToken({
        variables: { oneTimeIdToken },
      });

      sessionExpiredTimerRef.current = window.setTimeout(
        () => setSessionState(OneTimeSessionState.Expired),
        1000 * 60 * 60
      );

      return () => {
        sessionExpiredTimerRef.current !== null &&
          window.clearTimeout(sessionExpiredTimerRef.current);
      };
    }
  }, [oneTimeIdToken, mutationExchangeToken]);

  // set session state once exchange request has completed
  useEffect(() => {
    if (sessionExchangeCalled && !sessionLoading) {
      setSessionState(
        sessionTokenResponse?.exchangeOneTimeIdToken.success
          ? OneTimeSessionState.Active
          : OneTimeSessionState.Expired
      );
    }
  }, [sessionLoading, sessionExchangeCalled, sessionTokenResponse]);

  return {
    sessionState,
    setSessionExpired: () => setSessionState(OneTimeSessionState.Expired),
  };
};
