type SVGProps = React.HTMLProps<SVGSVGElement>;

type SliderThumbProps = {
  xPos: number;
  isDragging: boolean;
  /**
   * @default 20
   */
  size?: number;
  /**
   * @default 1
   */
  radius?: number;
  /**
   * @default "var(--color-pear-green)"
   */
  fill?: React.CSSProperties["color"];
  /**
   * @default "rgba(0, 0, 0, .5)"
   */
  stroke?: React.CSSProperties["color"];
  /**
   * @default false
   */
  readOnly?: boolean;
  onMouseDown: Exclude<SVGProps["onMouseDown"], undefined>;
  onMouseUp: Exclude<SVGProps["onMouseUp"], undefined>;
};

export const SliderThumb = ({
  isDragging,
  size = 20,
  radius = 1,
  fill = "var(--color-pear-green)",
  stroke = "rgba(0, 0, 0, .5)",
  xPos,
  readOnly = false,
  onMouseDown,
  onMouseUp,
}: SliderThumbProps) => {
  const viewboxWidth = 100;
  const ratio = viewboxWidth / size;
  const strokeWidth = radius * 2 * ratio;
  const offset = radius * ratio;
  const pathCoordinates = `50 ${140 - offset}, ${offset} ${
    100 - offset
  }, ${offset} ${offset}, ${100 - offset} ${offset}, ${100 - offset} ${
    100 - offset
  }`;

  return (
    <svg
      style={{
        position: "absolute",
        top: -2,
        left: xPos - size / 2,
        cursor: isDragging ? "grabbing" : "grab",
        zIndex: 100,
        pointerEvents: readOnly ? "none" : undefined,
      }}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      width={size}
      height={(size * 140) / 100}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
      viewBox={`0 0 ${viewboxWidth} 140`}
      filter="drop-shadow"
    >
      <defs>
        {/* Gradient for primary polygon fill */}
        <linearGradient id="thumb-gradient">
          <stop offset="0%" stopColor="rgb(245, 245, 245)"></stop>
          <stop offset="5%" stopColor="rgb(245, 245, 245)"></stop>

          <stop offset="5%" stopColor="rgb(230,230,230)"></stop>
          <stop offset="23%" stopColor="rgb(210, 210, 210)"></stop>

          <stop offset="23%" stopColor="rgb(210, 210, 210)"></stop>
          <stop offset="77%" stopColor="rgb(190, 190, 190)"></stop>

          <stop offset="77%" stopColor="rgb(190, 190, 190)"></stop>
          <stop offset="95%" stopColor="rgb(170, 170, 170)"></stop>

          <stop offset="95%" stopColor="rgb(155, 155, 155)"></stop>
          <stop offset="100%" stopColor="rgb(155, 155, 155)"></stop>
        </linearGradient>

        {/* clipPath masks second polygon to shape defined by polygon path (no stroke width) */}
        <clipPath id="thumb-clip">
          <polygon points={pathCoordinates} />
        </clipPath>
      </defs>

      {/* Polygon for Outline */}
      <polygon
        stroke={isDragging ? "var(--color-pear-green)" : stroke}
        fill={fill}
        points={pathCoordinates}
      />

      {/* Second polygon utilizing clip-path with fill for stroke color -
      effectively narrows outline */}
      <polygon
        fill={"url(#thumb-gradient)"}
        points={pathCoordinates}
        clipPath="url(#thumb-clip)"
      />
    </svg>
  );
};
