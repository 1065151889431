import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { createTheme } from "react-data-table-component";
import { theme } from "./theme";

import { BrowserRouter as Router } from "react-router-dom";

// Styles
import "./App.css";

// Providers
import { ApolloProvider } from "@apollo/client";
import { ResetOrSignupLinkTrap } from "./components";
import { AuthProvider } from "./providers/AuthProvider";
import TwilioProvider from "./providers/TwilioProvider";

// Client
import { client } from "./graphql";

// Routes Container
import { MainContainer } from "src/components";

import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import localizedFormat from "dayjs/plugin/localizedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(duration);
dayjs.extend(relativeTime);

createTheme(
  "pear",
  {
    highlightOnHover: {
      default: "red",
    },
  },
  "default"
);

const App = () => {
  return (
    <ApolloProvider client={client}>
      <Router>
        <MantineProvider withGlobalStyles withNormalizeCSS theme={theme}>
          <Notifications position="bottom-center" />
          <ResetOrSignupLinkTrap>
            <AuthProvider>
              <TwilioProvider>
                <MainContainer />
              </TwilioProvider>
            </AuthProvider>
          </ResetOrSignupLinkTrap>
        </MantineProvider>
      </Router>
    </ApolloProvider>
  );
};

export default App;
