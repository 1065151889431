import { ConditionalStyles, TableColumn } from "react-data-table-component";

import {
  ExternalContact,
  usePaginatedQueryExternalContacts,
} from "src/graphql";
import {
  DataTableComponent,
  OverflowTooltipCell,
  TableContainer,
  TableHeader,
} from "../table";
import { DontTranslate } from "src/libs/localization/components/DontTranslate";

interface MembersCardProps {
  organizationId: string;
  onSelect?: (member: ExternalContact) => void;
  selectedId?: string;
}

const ExternalContactsTable = ({
  organizationId,
  onSelect,
  selectedId,
}: MembersCardProps) => {
  const { dataTableProperties, setSearchTerm } =
    usePaginatedQueryExternalContacts(organizationId, true);

  // Table columns
  const columns: TableColumn<ExternalContact>[] = [
    {
      name: "Name",
      sortable: true,
      sortField: "name",
      cell: (contact) => (
        <OverflowTooltipCell>
          <DontTranslate>{contact.name}</DontTranslate>
        </OverflowTooltipCell>
      ),
    },
    {
      name: "External Resource",
      sortable: true,
      sortField: "resource",
      cell: (contact) => (
        <OverflowTooltipCell>
          {contact.externalResourceName}
        </OverflowTooltipCell>
      ),
    },
    {
      name: "Phone",
      sortField: "phone",
      sortable: true,
      cell: (contact) => (
        <OverflowTooltipCell>{contact.phone}</OverflowTooltipCell>
      ),
    },
  ];

  const conditionalRowStyles: ConditionalStyles<ExternalContact>[] = [
    {
      when: (row) => row._id === selectedId,
      style: { backgroundColor: "var(--color-light-grey)" },
    },
  ];

  return (
    <TableContainer>
      <TableHeader
        placeholder="By Name, Resource or Phone"
        setFilterText={(text) => setSearchTerm(text)}
        searchColumn={12}
      />

      <DataTableComponent
        noCardWrapper
        noRowsPerPage
        columns={columns}
        highlightOnHover={true}
        pointerOnHover={true}
        onRowClicked={onSelect}
        noDataText={"No contacts found"}
        conditionalRowStyles={conditionalRowStyles}
        {...dataTableProperties}
      />
    </TableContainer>
  );
};

export default ExternalContactsTable;
