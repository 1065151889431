import { useMutation, useQuery } from "@apollo/client";
import { GET_MEMBERS } from "../Members/queries";
import {
  DefaultResponse,
  GetAllMembersResponse,
  GetGroupResponse,
  GetGroupsResponse,
  MutationAddMemberToGroupArgs,
  MutationCreateGroupArgs,
  MutationDeleteGroupArgs,
  MutationRemoveMemberFromGroupArgs,
  MutationUpdateGroupArgs,
  QueryGroupMembersArgs,
  QueryGroupsArgs,
} from "../schemaTypes";
import {
  ADD_MEMBER_TO_GROUP,
  CREATE_MEMBER_GROUP,
  DELETE_MEMBER_GROUP,
  REMOVE_MEMBER_FROM_GROUP,
  UPDATE_MEMBER_GROUP,
} from "./mutation";
import { GET_GROUP_MEMBERS, GET_GROUPS } from "./queries";
import { GET_FLOW_TEMPLATES } from "../FlowTemplate/queries";
import { GET_RECOMMENDATIONS } from "../Recommendations/queries";

export const useQueryGetGroupMembers = (
  groupId: string,
  organizationId: string
) =>
  useQuery<{ groupMembers: GetAllMembersResponse }, QueryGroupMembersArgs>(
    GET_GROUP_MEMBERS,
    {
      variables: { groupId, organizationId },
      skip: !groupId,
    }
  );

export const useQueryGetGroups = (organizationId: string) =>
  useQuery<{ groups: GetGroupsResponse }, QueryGroupsArgs>(GET_GROUPS, {
    variables: { organizationId },
  });

export const useMutationAddMemberToGroup = (
  groupId: string,
  organizationId: string
) =>
  useMutation<
    { addMemberToGroup: DefaultResponse },
    MutationAddMemberToGroupArgs
  >(ADD_MEMBER_TO_GROUP, {
    refetchQueries: [
      {
        query: GET_GROUP_MEMBERS,
        variables: {
          groupId,
          organizationId,
        },
      },
      {
        query: GET_MEMBERS,
        variables: {
          input: {
            organizations: [organizationId],
          },
          pagination: {
            page: 1,
            size: 10,
          },
        },
      },
    ],
  });

export const useMutationRemoveMemberFromGroup = (
  groupId: string,
  organizationId: string
) =>
  useMutation<
    { removeMemberFromGroup: DefaultResponse },
    MutationRemoveMemberFromGroupArgs
  >(REMOVE_MEMBER_FROM_GROUP, {
    refetchQueries: [
      {
        query: GET_GROUP_MEMBERS,
        variables: {
          groupId,
          organizationId,
        },
      },
      {
        query: GET_MEMBERS,
        variables: {
          input: {
            organizations: [organizationId],
          },
          pagination: {
            page: 1,
            size: 10,
          },
        },
      },
    ],
  });

export const useMutationCreateMemberGroup = (organizationId: string) =>
  useMutation<{ createGroup: GetGroupResponse }, MutationCreateGroupArgs>(
    CREATE_MEMBER_GROUP,
    {
      refetchQueries: [
        {
          query: GET_GROUPS,
          variables: {
            organizationId,
          },
        },
      ],
    }
  );

export const useMutationUpdateMemberGroup = (organizationId: string) =>
  useMutation<{ updateGroup: DefaultResponse }, MutationUpdateGroupArgs>(
    UPDATE_MEMBER_GROUP,
    {
      refetchQueries: [
        {
          query: GET_GROUPS,
          variables: {
            organizationId,
          },
        },
      ],
    }
  );

export const useMutationDeleteMemberGroup = (organizationId: string) =>
  useMutation<{ deleteGroup: DefaultResponse }, MutationDeleteGroupArgs>(
    DELETE_MEMBER_GROUP,
    {
      refetchQueries: [
        {
          query: GET_GROUPS,
          variables: {
            organizationId,
          },
        },
        {
          query: GET_FLOW_TEMPLATES,
          variables: { organizationId: organizationId },
        },
        {
          query: GET_RECOMMENDATIONS,
          variables: { organizationId: organizationId },
        },
      ],
    }
  );
