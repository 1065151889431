import { gql } from "@apollo/client";
import { GroupFields } from "./types";

export const CREATE_MEMBER_GROUP = gql`
  mutation CreateMemberGroup($input: CreateGroupInput!) {
    createGroup(input: $input) {
      success
      message
      data {
        ${GroupFields}
      }
    }
  }
`;

export const DELETE_MEMBER_GROUP = gql`
  mutation DeleteMemberGroup($input: DeleteGroupInput!) {
    deleteGroup(input: $input) {
      success
      message
      data {
        ${GroupFields}
      }
    }
  }
`;

export const UPDATE_MEMBER_GROUP = gql`
  mutation UpdateMemberGroup($id: ID!, $input: UpdateGroupInput!, $organizationId: ID!) {
    updateGroup(id: $id, input: $input, organizationId: $organizationId) {
      success
      message
      data {
        ${GroupFields}
      }
    }
  }
`;

export const ADD_MEMBER_TO_GROUP = gql`
  mutation AddMemberToGroup(
    $memberId: ID!
    $groupId: ID!
    $organizationId: ID!
  ) {
    addMemberToGroup(
      memberId: $memberId
      groupId: $groupId
      organizationId: $organizationId
    ) {
      success
      message
    }
  }
`;

export const REMOVE_MEMBER_FROM_GROUP = gql`
  mutation RemoveMemberFromGroup(
    $memberId: ID!
    $groupId: ID!
    $organizationId: ID!
  ) {
    removeMemberFromGroup(
      memberId: $memberId
      groupId: $groupId
      organizationId: $organizationId
    ) {
      success
      message
    }
  }
`;
