import type { ReactNode } from "react";

type DontTranslateProps = {
  children?: ReactNode;
  style?: React.CSSProperties;
};
/**
 * This is a simple declarative span element abstraction with the `translate` attribute.
 */
export const DontTranslate = ({ children, style }: DontTranslateProps) => (
  <span translate="no" style={{ ...style }}>
    {children}
  </span>
);
