export const DataFields = `
  _id
  dataId
  questionText
  answer
  questionId
  activityId 
  activityTemplateTitle
  memberId
  dataPointTemplateId
  dataPointTemplateTitle
  organizationId
  createdAt
  updatedAt`;

export const DataPointIdWithAnswerTypeFields = `
  dataId
  questionTitle
  answerType
  answerOptions
  defaultOptions
  dataPointTemplateId
  historicalOptions`;
