import * as Yup from "yup";

import { EditableItem, EditableItemState } from "src/components";

export const EditableItemSchema: Yup.SchemaOf<EditableItem> = Yup.object({
  id: Yup.string().required(),
  content: Yup.string().required(),
  state: Yup.mixed<EditableItemState>().required(),
});

export const GoalTemplateFormSchema = Yup.object({
  name: Yup.string().required("Required"),
  actionItemDescriptions: Yup.array()
    .transform((_, orig) => Object.values(orig))
    .of(EditableItemSchema)
    .required("Required"),
  organizationId: Yup.string().required(),
  description: Yup.string(),
  domainId: Yup.string(),
});
