import { Flex, Stack } from "@mantine/core";
import { FieldHookConfig } from "formik";
import React from "react";

import { StyledErrorMessage } from "./StyledErrorMessage";
import { StyledLabel } from "./StyledLabel";
import { QuestionTooltip } from "../question-tooltip";
import { Box } from "@mantine/core";

export type FormikInputBaseProps<T> = FieldHookConfig<T> &
  Omit<FormikInputWrapperProps, "children">;

type FormikInputWrapperProps = {
  name: string;
  label?: string;
  required?: boolean;
  wrapperClassName?: string;
  /**
   * Whether or not to render error message as part of component; useful for e.g.
   * instances where you'd like to group inputs and render their error messages
   * somewhere besides the default place immediately below.
   * @default true
   */
  renderErrorMessage?: boolean;
  children: React.ReactNode;
  questionTooltip?: string;
};

export const extractWrapperProps = <
  T extends Omit<FormikInputWrapperProps, "children">
>(
  outerProps: T
): Omit<FormikInputWrapperProps, "children"> => ({
  name: outerProps.name,
  label: outerProps.label,
  required: outerProps.required,
  wrapperClassName: outerProps.wrapperClassName,
  renderErrorMessage: outerProps.renderErrorMessage,
  questionTooltip: outerProps.questionTooltip,
});

export const FormikInputWrapper = ({
  label,
  name,
  wrapperClassName,
  renderErrorMessage = true,
  children,
  required,
  questionTooltip,
}: FormikInputWrapperProps) => {
  return (
    <Stack spacing={0} className={wrapperClassName}>
      {label && (
        <Flex>
          <StyledLabel required={required}>{label} </StyledLabel>
          {questionTooltip && (
            <Box>
              <QuestionTooltip toolTipMessage={questionTooltip} />
            </Box>
          )}
        </Flex>
      )}
      {children}
      {renderErrorMessage && <StyledErrorMessage name={name} />}
    </Stack>
  );
};
