import { gql } from "@apollo/client";
import { EHRNoteTemplateFields } from "./types";

export const CREATE_EHR_NOTE_TEMPLATE = gql`
  mutation CreateEHRNoteTemplate($input: CreateEHRNoteTemplateInput!) {
    createEHRNoteTemplate(input: $input) {
      success
      message
      data {
        ${EHRNoteTemplateFields}
      }
    }
  }
`;

export const UPDATE_EHR_NOTE_TEMPLATE = gql`
  mutation UpdateEHRNoteTemplate($input: UpdateEHRNoteTemplateInput!) {
    updateEHRNoteTemplate(input: $input) {
      success
      message
      data {
        ${EHRNoteTemplateFields}
      }
    }
  }
`;

export const DELETE_EHR_NOTE_TEMPLATE = gql`
  mutation DeleteEHRNoteTemplate($input: DeleteEHRNoteTemplateInput!) {
    deleteEHRNoteTemplate(input: $input) {
      success
      message
    }
  }
`;
