import {
  Button,
  Card,
  Divider,
  Group,
  Indicator,
  List,
  SimpleGrid,
  Stack,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import {
  IconCircleCheck,
  IconCircleChevronsUp,
  IconCircleDashed,
} from "@tabler/icons-react";
import { CustomerPortalButton } from "src/components/stripe-buttons/stripe-buttons";
import { Organization, Plan } from "src/graphql";

export const checkIcon = (
  <ThemeIcon color="green" size={24} radius="xl">
    <IconCircleCheck size="1rem" />
  </ThemeIcon>
);

export const dashedIcon = (
  <ThemeIcon color="gray" size={24} radius="xl">
    <IconCircleDashed size="1rem" />
  </ThemeIcon>
);

const BasicPlanFeatures = [
  "Activity, Flow, & Goal Templates",
  "Recommendations & Calculations",
  "Analytics Dashboard",
  "User Management Tools",
  "Group Event Attendance",
  "Member Onboarding Survey",
  "External Resources & Referrals",
  "Access to Pear Suite's training library",
];

const PlusPlanFeatures = [
  "All Basic Plan Features and...",
  "Billing Toolset & Clearinghouse",
  "Subcontracting (15% fee)",
  "Call Center, SMS, & Email",
];

const ProPlanFeatures = [
  "All Plus Plan Features and...",
  "Program Design Consultation",
  "Expert Onboarding Assistance",
  "Pathways Community Hub",
  "Ongoing Optimization Sessions",
  "Same-Day Support (Business Hours)",
  "Assisted User Training",
];

type NonCustomPlan = Exclude<Plan, typeof Plan.Custom>;

export const planBestFitDescription = {
  [Plan.Basic]: "Best for small organizations with internal platform builders.",
  [Plan.Plus]:
    "Best for organizations and individuals providing billable services.",
  [Plan.Pro]:
    "Best for organizations looking for extra customization, ongoing support, and clinical oversight.",
};

export const planPricingPerMonthPerSeat = {
  [Plan.Basic]: 100,
  [Plan.Plus]: 150,
  [Plan.Pro]: 200,
};

export const planLinks = {
  [Plan.Basic]: process.env.REACT_APP_STRIPE_BASIC_PLAN_LINK,
  [Plan.Plus]: process.env.REACT_APP_STRIPE_PLUS_PLAN_LINK,
  [Plan.Pro]: process.env.REACT_APP_STRIPE_PRO_PLAN_LINK,
};

export const planValue = {
  [Plan.Basic]: 0,
  [Plan.Plus]: 1,
  [Plan.Pro]: 2,
};

export const featuresMap = {
  [Plan.Basic]: BasicPlanFeatures,
  [Plan.Plus]: PlusPlanFeatures,
  [Plan.Pro]: ProPlanFeatures,
};

export const recommendedPlan = Plan.Plus;

type PricingTableProps = {
  organization?: Organization;
};

export const PricingTable = ({ organization }: PricingTableProps) => (
  <SimpleGrid
    cols={3}
    maw={1100}
    breakpoints={[
      { maxWidth: "sm", cols: 1 },
      { maxWidth: "md", cols: 2 },
    ]}
  >
    {Object.entries(featuresMap).map(([plan, features]) => {
      const planAsPlan = plan as NonCustomPlan;

      const isCurrentPlan = plan === organization?.subscriptionPlan;
      const isRecommendedPlan = plan === recommendedPlan && !isCurrentPlan;
      const isIncluded = organization
        ? organization.subscriptionPlan === Plan.Custom ||
          planValue[organization.subscriptionPlan] >= planValue[planAsPlan]
        : false;

      return (
        <Indicator
          key={plan}
          inline
          color="orange"
          label="Most Popular"
          size={16}
          position="top-center"
          disabled={!isRecommendedPlan}
        >
          <Card
            withBorder
            padding="md"
            radius="md"
            mt={isRecommendedPlan || organization ? undefined : 50}
          >
            <Stack spacing="md">
              <Title order={3} size="h6">
                {plan} Plan
              </Title>

              <Stack spacing="xs">
                <Group align="flex-end" spacing={0}>
                  <Title>${planPricingPerMonthPerSeat[planAsPlan]}</Title>
                  <Text size="sm" pb={4}>
                    /month per user
                  </Text>
                </Group>

                <Text>{planBestFitDescription[planAsPlan]}</Text>
              </Stack>

              {organization ? (
                <CustomerPortalButton
                  organization={organization}
                  fullWidth
                  disabled={isIncluded}
                  variant={isRecommendedPlan ? "filled" : "outline"}
                  rightIcon={!isIncluded ? <IconCircleChevronsUp /> : undefined}
                >
                  {isIncluded && !isCurrentPlan && "Included in Plan"}
                  {isIncluded && isCurrentPlan && "Current Plan"}
                  {!isIncluded && `Upgrade to ${plan}`}
                </CustomerPortalButton>
              ) : (
                <Button
                  fullWidth
                  variant="filled"
                  component="a"
                  href={planLinks[planAsPlan]}
                >
                  Subscribe
                </Button>
              )}

              <Divider
                labelPosition="center"
                label={
                  <Text size="xs" color="dimmed" align="center">
                    FEATURES
                  </Text>
                }
              />

              <List
                spacing={4}
                size="sm"
                icon={isIncluded ? checkIcon : dashedIcon}
              >
                {features.map((feature) => (
                  <List.Item key={feature}>{feature}</List.Item>
                ))}
              </List>
            </Stack>
          </Card>
        </Indicator>
      );
    })}
  </SimpleGrid>
);
