import { useState } from "react";
import {
  getAuth,
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
} from "firebase/auth";
import { Formik } from "formik";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import * as Yup from "yup";
import { StyledLabel, StyledErrorMessage, FormikInput } from "../input";
import { Button, Stack, Text, Title } from "@mantine/core";
import { DefaultResponse } from "src/graphql";
import { AuthFlowState } from "./AuthFlowContainer";
import { AuthFormLink } from "./AuthFormLink";
import toast from "src/libs/toast";
import { useRecaptchaVerifier } from "src/hooks";

enum TwoFactorEnrollmentState {
  PhoneRequired,
  VerificationRequired,
  RecentLoginRequired,
  Complete,
}

type TwoFactorEnrollmentFormProps = {
  setAuthFlowState: (state: AuthFlowState) => void;
  userEmail?: string;
};

export const TwoFactorEnrollmentForm = ({
  setAuthFlowState,
  userEmail,
}: TwoFactorEnrollmentFormProps) => {
  const auth = getAuth();
  const { recaptchaVerifierRef, recaptchaContainerRef } = useRecaptchaVerifier(
    auth,
    userEmail
  );
  const [submittedPhone, setSubmittedPhone] = useState("");
  const [verificationId, setVerificationId] = useState("");
  const [twoFactorState, setTwoFactorState] = useState(
    TwoFactorEnrollmentState.PhoneRequired
  );

  const handleSignOut = () => {
    auth.signOut();
    setAuthFlowState(AuthFlowState.LoggedOut);
  };

  // handles resetting flow to enter a different number
  const handleResetFlow = () => {
    setSubmittedPhone("");
    setVerificationId("");
    setTwoFactorState(TwoFactorEnrollmentState.PhoneRequired);
  };

  // handles enrolling, sending SMS and setting verificationId for non-enrolled users
  const handleSubmitPhone = async (phoneNumber: string) => {
    if (!recaptchaVerifierRef.current) return { success: false }; // should never happen

    try {
      const firebaseUser = auth.currentUser;
      if (!firebaseUser) return { success: false, message: "Session Missing" };
      const mfaUser = multiFactor(firebaseUser);
      const phoneAuthProvider = new PhoneAuthProvider(auth);
      const session = await mfaUser.getSession();

      const phoneInfoOptions = {
        phoneNumber,
        session,
      };

      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        recaptchaVerifierRef.current
      );

      setVerificationId(verificationId);
      setSubmittedPhone(phoneNumber);
      setTwoFactorState(TwoFactorEnrollmentState.VerificationRequired);

      toast.success("Verification code sent!");

      return { success: true };
      // eslint-disable-next-line
    } catch (e: any) {
      // console.log({ ...e.customData });
      return { success: false, message: e.message };
    }
  };

  // handles verifying one-time code entered by user from SMS
  const handleVerifyPhone = async (verificationCode: string) => {
    try {
      const firebaseUser = auth.currentUser;
      if (!firebaseUser) return { success: false, message: "Session Missing" };
      const credential = PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );

      const mfaAssertion = PhoneMultiFactorGenerator.assertion(credential);
      const mfaUser = multiFactor(firebaseUser);
      await mfaUser.enroll(mfaAssertion);

      setAuthFlowState(AuthFlowState.Authenticated);
      return { success: true };
      // eslint-disable-next-line
    } catch (e: any) {
      // console.log("verifyError", e.message);
      return { success: false, message: e.code };
    }
  };

  return (
    <>
      {/* Collect phone number */}
      {twoFactorState === TwoFactorEnrollmentState.PhoneRequired && (
        <EnrollmentForm
          handleSignOut={handleSignOut}
          handleEnroll={handleSubmitPhone}
          setTwoFactorState={setTwoFactorState}
        />
      )}

      {/* Verify phone number */}
      {twoFactorState === TwoFactorEnrollmentState.VerificationRequired && (
        <VerificationForm
          submittedPhone={submittedPhone}
          handleReset={handleResetFlow}
          handleVerify={handleVerifyPhone}
          setTwoFactorState={setTwoFactorState}
          resendVerificationCode={() => handleSubmitPhone(submittedPhone)}
        />
      )}

      {/* sign out user if Firebase requires more recent signin */}
      {twoFactorState === TwoFactorEnrollmentState.RecentLoginRequired && (
        <>
          <Text mb="25px" align="center">
            This authentication session has expired; please sign in again to
            continue!
          </Text>

          <Button fullWidth onClick={handleSignOut}>
            Back To Login
          </Button>
        </>
      )}

      {twoFactorState === TwoFactorEnrollmentState.Complete && "Complete!"}

      <div ref={recaptchaContainerRef} />
    </>
  );
};

/**
 * Enrollment Form
 */

const initialEnrollmentValues = {
  phone: "",
};

const EnrollmentSchema = Yup.object({
  phone: Yup.string().required(),
});

type EnrollmentFormProps = {
  handleSignOut: () => void;
  handleEnroll: (phone: string) => Promise<DefaultResponse>;
  setTwoFactorState: (step: TwoFactorEnrollmentState) => void;
};

const EnrollmentForm = ({
  handleSignOut,
  handleEnroll,
  setTwoFactorState,
}: EnrollmentFormProps) => (
  <Stack>
    <Title order={5} align="center" mt="sm">
      Two-Factor Enrollment
    </Title>

    <Text align="center">
      An organization that you belong to has opted to require Two-Factor SMS
      authentication. Please enter your phone number below, including your
      country code.
    </Text>

    <Formik
      initialValues={initialEnrollmentValues}
      validationSchema={EnrollmentSchema}
      validateOnChange={true}
      enableReinitialize={true}
      onSubmit={async (values, { setSubmitting, setFieldError }) => {
        setSubmitting(true);
        const result = await handleEnroll(values.phone);
        setSubmitting(false);

        if (result.success) {
          setTwoFactorState(TwoFactorEnrollmentState.VerificationRequired);
        } else {
          if (result.message?.includes("auth/requires-recent-login")) {
            setTwoFactorState(TwoFactorEnrollmentState.RecentLoginRequired);
          }
          setFieldError("phone", result.message);
        }
      }}
    >
      {({ values, isValid, isSubmitting, setFieldValue, handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Stack spacing="sm">
              <div>
                <StyledLabel>Phone</StyledLabel>
                <PhoneInput
                  type="tel"
                  name="phone"
                  required
                  onChange={(value) => {
                    setFieldValue("phone", value);
                  }}
                  value={values.phone}
                  autoFocus
                />
                <StyledErrorMessage name={"phone"} />
              </div>

              <Button
                mt="sm"
                disabled={!isValid}
                loading={isSubmitting}
                type="submit"
              >
                Submit
              </Button>
              <AuthFormLink onClick={handleSignOut}>Sign Out</AuthFormLink>
            </Stack>
          </form>
        );
      }}
    </Formik>
  </Stack>
);

/**
 * Verification Form
 */

const initialVerificationValues = {
  verificationCode: "",
};

const VerificationSchema = Yup.object({
  verificationCode: Yup.string().required(),
});

type VerificationFormProps = {
  submittedPhone: string;
  handleReset: () => void;
  handleVerify: (verificationCode: string) => Promise<DefaultResponse>;
  setTwoFactorState: (step: TwoFactorEnrollmentState) => void;
  resendVerificationCode: () => void;
};

const VerificationForm = ({
  submittedPhone,
  handleReset,
  handleVerify,
  setTwoFactorState,
  resendVerificationCode,
}: VerificationFormProps) => (
  <Stack spacing="sm">
    <Title order={5} mt="sm" align="center">
      Two-Factor Verification
    </Title>

    <Text align="center">
      A one-time verification code has been sent via text message to&nbsp;
      <strong>{submittedPhone}</strong>
    </Text>

    <Formik
      initialValues={initialVerificationValues}
      validateOnChange={true}
      enableReinitialize={true}
      validationSchema={VerificationSchema}
      onSubmit={async (values, { setSubmitting, setFieldError }) => {
        setSubmitting(true);
        const result = await handleVerify(values.verificationCode);
        setSubmitting(false);
        if (result.success) {
          setTwoFactorState(TwoFactorEnrollmentState.Complete);
        } else {
          setFieldError("phone", result.message);
        }
      }}
    >
      {({ isValid, isSubmitting, handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Stack spacing="sm">
              <FormikInput
                label="Verification Code"
                type="text"
                name="verificationCode"
                required
                autoFocus
              />

              <AuthFormLink onClick={resendVerificationCode}>
                Resend Code
              </AuthFormLink>

              <AuthFormLink onClick={handleReset}>
                Use a Different Number
              </AuthFormLink>

              <Button
                mt="sm"
                disabled={!isValid}
                loading={isSubmitting}
                type="submit"
              >
                Submit
              </Button>
            </Stack>
          </form>
        );
      }}
    </Formik>
  </Stack>
);
