import { Button } from "@mantine/core";
import * as React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../modal";

interface DeleteModalProps {
  modalIsOpen: boolean;
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  deleteFunction: (val: string) => void;
  rowId: string;
  retire?: boolean;
  modalBodyText?: string;
}

export const DeleteModal = ({
  modalIsOpen,
  setModalIsOpen,
  deleteFunction,
  rowId,
  retire,
  modalBodyText,
}: DeleteModalProps) => {
  return (
    <Modal opened={modalIsOpen} onClose={() => setModalIsOpen(false)}>
      <ModalHeader>
        {retire ? "Confirm Deactivation" : "Confirm Deletion"}
      </ModalHeader>

      <ModalBody>
        {modalBodyText
          ? modalBodyText
          : retire
          ? "Are you sure you want to deactivate?"
          : "Are you sure you want to delete?"}
      </ModalBody>

      <ModalFooter>
        <Button variant="outline" onClick={() => setModalIsOpen(false)}>
          Cancel
        </Button>

        <Button
          color="red"
          onClick={() => {
            deleteFunction(rowId);
            setModalIsOpen(false);
          }}
        >
          {retire ? "Deactivate" : "Delete"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
