import { useState } from "react";
import { Formik } from "formik";
import {
  getAuth,
  signInWithEmailAndPassword,
  Auth as FirebaseAuth,
  AuthError,
  getMultiFactorResolver,
  MultiFactorError,
  MultiFactorResolver,
  setPersistence,
  browserSessionPersistence,
} from "firebase/auth";
import { FormikInput } from "src/components";
import { AuthFlowState } from "src/components/auth/AuthFlowContainer";
import { useEffect } from "react";
import { AuthFormLink } from "./AuthFormLink";
import { LOGIN_ERRORS } from "src/constants/errors";
import { Button, Stack, Text } from "@mantine/core";

const initialValues = {
  email: "",
  password: "",
};

type LoginFormProps = {
  setMfaResolver: (resolver: MultiFactorResolver) => void;
  setAuthFlowState: (state: AuthFlowState) => void;
  onUserAuthenticated: () => void;
  setUserEmail: (userEmail: string) => void;
};

const useSignInWithEmailAndPassword = (
  auth: FirebaseAuth,
  onUserAuthenticated: () => void
) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [signinError, setSigninError] = useState<AuthError | null>(null);

  const signIn = async (email: string, password: string) => {
    setIsSubmitting(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setTimeout(onUserAuthenticated, 0);
      // eslint-disable-next-line
    } catch (e: any) {
      // console.log("err", { ...e });
      setSigninError({ ...(e as AuthError) });
    } finally {
      setIsSubmitting(false);
    }
  };

  return { signIn, isSubmitting, signinError };
};

export const LoginForm = ({
  setAuthFlowState,
  setMfaResolver,
  onUserAuthenticated,
  setUserEmail,
}: LoginFormProps) => {
  const auth = getAuth();
  const { signIn, isSubmitting, signinError } = useSignInWithEmailAndPassword(
    auth,
    onUserAuthenticated
  );

  useEffect(() => {
    setPersistence(auth, browserSessionPersistence);
  }, [auth]);

  // redirect to 2fa if required after signin
  useEffect(() => {
    if (
      signinError &&
      signinError.code?.includes("auth/multi-factor-auth-required")
    ) {
      const mfaResolver = getMultiFactorResolver(
        auth,
        signinError as MultiFactorError
      );
      setMfaResolver(mfaResolver);
      setAuthFlowState(AuthFlowState.TwoFactorLoginRequired);
    }
  }, [signinError, auth, setAuthFlowState, setMfaResolver]);

  const getErrorText = (authError: string) => {
    if (authError === "auth/too-many-requests") {
      return LOGIN_ERRORS.TOO_MANY_ATTEMPTS;
    }
    return LOGIN_ERRORS.INCORRECT_PASSWORD;
  };

  return (
    <>
      {signinError && (
        <Text color="red" align="center">
          {getErrorText(signinError.code)}
        </Text>
      )}
      <Formik
        initialValues={initialValues}
        validateOnChange={true}
        enableReinitialize={true}
        onSubmit={async (values, { resetForm }) => {
          try {
            setUserEmail(values.email);
            await signIn(values.email, values.password);
          } catch (e) {
            // console.log(e);
          }
        }}
      >
        {({ isValid, handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Stack spacing="sm">
                <FormikInput
                  label="Email"
                  type="email"
                  name="email"
                  required
                  autoFocus
                />

                <FormikInput
                  label="Password"
                  type="password"
                  name="password"
                  required
                />

                <AuthFormLink to="/forgot-password">
                  Forgot Password?
                </AuthFormLink>

                <Button
                  loading={isSubmitting}
                  disabled={!isValid}
                  type="submit"
                >
                  Sign in
                </Button>
              </Stack>
            </form>
          );
        }}
      </Formik>
    </>
  );
};
