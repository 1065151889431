import {
  CloseButton,
  Group,
  GroupProps,
  Modal as MantineModal, // eslint-disable-line no-restricted-imports
  ModalProps,
  Stack,
  StackProps,
  Title,
} from "@mantine/core";
import { ReactNode, useLayoutEffect, useRef, useState } from "react";

export const Modal = ({
  title,
  width,
  ...props
}: ModalProps & { width?: string }) => {
  return (
    <MantineModal
      withCloseButton={false}
      size={width ?? "450px"}
      radius="8px"
      padding={0}
      centered
      {...props}
    >
      <MountFocusHelper>{props.children}</MountFocusHelper>
    </MantineModal>
  );
};

export const ModalHeader = ({
  children,
  onClose,
  withSubHeader,
}: {
  children: ReactNode;
  onClose?: () => void;
  withSubHeader?: boolean;
}) => (
  <Group
    p="24px"
    mb={withSubHeader ? 0 : "12px"}
    bg="green.6"
    align="center"
    position="apart"
  >
    <Title color="white" order={4}>
      {children}
    </Title>

    {onClose && (
      <CloseButton
        variant="transparent"
        size="lg"
        onClick={onClose}
        style={{ color: "white" }}
      />
    )}
  </Group>
);

export const ModalSubHeader = (props: StackProps) => (
  <Stack px="24px" py="16px" bg="black.1" spacing={0} {...props}>
    {props.children}
  </Stack>
);

export const ModalBody = ({
  noFooter,
  ...props
}: StackProps & { noFooter?: boolean }) => (
  <Stack px="24px" pt="12px" pb={noFooter ? "24px" : 0} spacing={0} {...props}>
    {props.children}
  </Stack>
);

export const ModalFooter = (props: GroupProps) => (
  <Group px="24px" h="82px" align="center" position="right" {...props}>
    {props.children}
  </Group>
);

/**
 * Helper component which prevents auto-focusing of Mantine Select components when they are
 * the first non-disabled, visible input. When these focus, they activate their dropdowns
 * and obscure the body of the modal which makes for (IMO) poor UX
 */
type MountFocusHelperProps = {
  children: React.ReactNode;
};
const MountFocusHelper = ({ children }: MountFocusHelperProps) => {
  const containerElementRef = useRef<HTMLDivElement>(null);
  const [shouldRenderTrap, setShouldRenderTrap] = useState(false);

  useLayoutEffect(() => {
    const inputs = containerElementRef.current?.querySelectorAll(
      `input:not([type="hidden"]):not([data-disabled="true"]`
    );
    if (inputs?.item(0)?.classList.contains("mantine-Select-input")) {
      setShouldRenderTrap(true);
    }
  }, []);

  return (
    <div ref={containerElementRef}>
      {shouldRenderTrap && <div tabIndex={0} />}
      {children}
    </div>
  );
};
