import { gql } from "@apollo/client";
import { TextMessageFields } from "./types";

export const TEXT_MESSAGE_STATUS_CHANGED = gql`
  fragment TextMessageFragment on TextMessage {
    ${TextMessageFields}
  }
  subscription OnCreatedOrUpdatedTextMessage($organizationId: ID!) {
    createdOrUpdatedTextMessage(organizationId: $organizationId) {
      ...TextMessageFragment
    }
  }
`;
