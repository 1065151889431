import { gql } from "@apollo/client";
import { ReferralFields } from "./types";

export const GET_REFERRAL = gql`
  query GetReferral($input: GetReferralInput!){
    response: referral(input: $input) {
      success
      message
      data {
        ${ReferralFields}
      }
    }
  }
`;

export const GET_REFERRALS_BY_MEMBER = gql`
  query GetReferralsByMember($input: GetReferralsByMemberInput!, $pagination: PaginationInput!){
    response: referralsByMember(input: $input, pagination: $pagination) {
      success
      message
      data {
        total
        page
        data {
          ${ReferralFields}
        }
      }
    }
  }
`;

export const GET_REFERRALS_BY_RESOURCE = gql`
  query GetReferralsByResource($input: GetReferralsByResourceInput!, $pagination: PaginationInput!) {
    response: referralsByResource(input: $input, pagination: $pagination) {
      success
      message
      data {
        total
        page
        data {
          ${ReferralFields}
        }
      }
    }
  }
`;
