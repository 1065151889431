import { Formik, Form } from "formik";
import {
  Button,
  Text,
  FileInput,
  Stack,
  UnstyledButton,
  Tooltip,
} from "@mantine/core";
import { FormikInput } from "../input";

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormikSelect,
  CertificationFormValues,
} from "src/components";
import { Certification } from "src/graphql";
export const NO_CERTIFICATION_TEMPLATE_ID = "no_template";

interface CertificationFormProps {
  handleSubmit: (values: CertificationFormValues) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  innerRef: any;
  certification: Certification | undefined;
  organizationId: string;
  templates: { value: string; label: string }[];
  userId: string;
  closeModal: () => void;
  modalIsOpen: boolean;
  handleUploadFile: (file: File, title: string) => void;
  uploadedFileId?: string;
  onViewFile?: (psFileId: string) => void;
}

export const CertificationForm = ({
  handleSubmit,
  innerRef,
  certification,
  organizationId,
  templates,
  userId,
  closeModal,
  modalIsOpen,
  handleUploadFile,
  uploadedFileId,
  onViewFile,
}: CertificationFormProps) => {
  const initialValues = {
    title: certification?.title ?? "",
    description: certification?.description ?? undefined,
    isActive: certification?.isActive ?? true,
    fileId: certification?.fileId ?? "",
    certificationTemplateId: certification?.certificationTemplateId ?? "",
    organizationId,
    userId,
  };

  return (
    <Modal
      opened={modalIsOpen}
      onClose={closeModal}
      title={certification ? "Edit Certification" : "Create Certification"}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit({
            ...values,
            organizationId,
            userId,
          });
          setSubmitting(false);
        }}
        innerRef={innerRef}
      >
        {({ isValid, dirty, setFieldValue }) => (
          <Form noValidate>
            <ModalHeader>
              {certification ? "Edit Certification" : "Create Certification"}
            </ModalHeader>

            <ModalBody spacing="md">
              <FormikSelect
                options={templates}
                name="certificationTemplateId"
                label="Select Certification Template"
                placeholder="Select a template"
                value={initialValues.certificationTemplateId}
                required
                onChangeOverride={(selectedTemplate) => {
                  setFieldValue(
                    "certificationTemplateId",
                    selectedTemplate?.value
                  );
                }}
              />
              <FormikInput type="text" name="title" label="Title" required />
              <FormikInput
                type="text"
                name="description"
                label="Description"
                placeholder="Enter a description (optional)"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFieldValue("description", e.target.value)
                }
              />

              <Stack spacing="xs">
                <FileInput
                  name="file"
                  label="File"
                  placeholder="Upload File"
                  required
                  onChange={(file: File) =>
                    handleUploadFile(file, "Certification File Name")
                  }
                />

                {certification?.fileName && uploadedFileId && onViewFile && (
                  <Tooltip
                    label={`View ${certification.fileName}`}
                    maw={300}
                    multiline
                  >
                    <UnstyledButton onClick={() => onViewFile(uploadedFileId)}>
                      <Text color="dimmed" size="sm" underline>
                        Click here to view uploaded file
                      </Text>
                    </UnstyledButton>
                  </Tooltip>
                )}
              </Stack>
            </ModalBody>

            <ModalFooter>
              <Button
                type="button"
                color="red"
                variant="outline"
                onClick={closeModal}
              >
                Cancel
              </Button>
              <Button type="submit" disabled={!dirty || !isValid}>
                {certification ? "Update" : "Create"}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
