import { useLazyQuery, useMutation } from "@apollo/client";
import { DELETE_FILE, UPDATE_FILE, UPLOAD_FILE } from "./mutations";
import {
  CheckFileNameResponse,
  GetFileResponse,
  GetFilesResponse,
  MutationDeletePsFileArgs,
  MutationUpdatePsFileArgs,
  MutationUploadPsFileArgs,
  PsFile,
  QueryCheckFileNameArgs,
  QueryPsFilesArgs,
  QuerySecurePsFileUrlArgs,
  SignedUrlResponse,
} from "../schemaTypes";
import { CHECK_FILE_NAME, GET_FILE_URL, GET_MEMBER_FILES } from "./queries";
import { usePagination } from "src/hooks/usePagination";

export const usePaginatedQueryMemberFiles = ({
  organizationId,
  memberId,
  pageSize = 20,
  episodeOfCareId,
}: {
  organizationId: string;
  memberId?: string;
  pageSize?: number;
  episodeOfCareId?: string;
}) =>
  usePagination<QueryPsFilesArgs, { response: GetFilesResponse }, PsFile>({
    document: GET_MEMBER_FILES,
    variables: { input: { organizationId, memberId, episodeOfCareId } },
    options: {
      size: pageSize,
    },
  });

export const useMutationUploadFile = () =>
  useMutation<
    {
      uploadPSFile: GetFileResponse;
    },
    MutationUploadPsFileArgs
  >(UPLOAD_FILE, {
    refetchQueries: [GET_MEMBER_FILES],
  });

export const useMutationUpdateFile = () =>
  useMutation<
    {
      updatePSFile: GetFileResponse;
    },
    MutationUpdatePsFileArgs
  >(UPDATE_FILE, {
    refetchQueries: [GET_MEMBER_FILES],
  });

export const useMutationDeleteFile = () =>
  useMutation<
    {
      deletePSFile: GetFileResponse;
    },
    MutationDeletePsFileArgs
  >(DELETE_FILE, {
    refetchQueries: [GET_MEMBER_FILES],
  });

export const useLazyQuerySecureFileURL = () =>
  useLazyQuery<
    { securePsFileUrl: SignedUrlResponse },
    QuerySecurePsFileUrlArgs
  >(GET_FILE_URL, {
    fetchPolicy: "no-cache",
  });

export const useLazyQueryCheckFileName = () =>
  useLazyQuery<
    { checkFileName: CheckFileNameResponse },
    QueryCheckFileNameArgs
  >(CHECK_FILE_NAME, {
    fetchPolicy: "no-cache",
  });
