import { CSSObject, TitleStylesParams } from "@mantine/core";
import { MantineThemeComponents } from "@mantine/styles/lib/theme/types/MantineTheme";

export const Title: MantineThemeComponents[string] = {
  styles: (
    _theme,
    params: TitleStylesParams,
    _context
  ): Record<string, CSSObject> => {
    if (!["h1", "h2", "h3"].includes(params.element))
      return { root: { fontFamily: "Avenir-Roman, Avenir-Book, sans-serif" } };
    else return {};
  },
};
