import styled from "styled-components";

export const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  > * {
    &:not(:first-child) {
      margin-left: 5px;
    }
  }
`;
