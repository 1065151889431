import { useMemo, useRef, useState } from "react";
import { Stack } from "@mantine/core";
import * as Yup from "yup";
import toast from "src/libs/toast";
import { Formik, FormikProps } from "formik";
import log from "loglevel";
import {
  ActionType,
  CreateReferralData,
  ReferralTemplate,
  useQueryReferralTemplates,
} from "src/graphql";
import { SelectOption } from "src/types";
import { ActionInfo } from "../../../util";
import { arrayToKeyedObj, ySelectOptionSchema } from "src/utils";
import { FormikSelect } from "src/components";
import { SubFormCommonProps } from "../AddActionModal";
import { useAuthContext } from "src/hooks";
import { ReferralTemplatePreview } from "../ReferralTemplatePreview";

type CreateReferralActionFormProps = SubFormCommonProps & {
  resourceOptions: SelectOption<string>[];
};

export const CreateReferralActionForm = ({
  resourceOptions,
  innerRef,
  node,
  onCreateAction,
  onDirtyStateChange,
  onValidStateChange,
}: CreateReferralActionFormProps) => {
  const { selectedOrganizationId } = useAuthContext();
  const [selectedResourceId, setSelectedResourceId] = useState<string>();

  const dirtyStateRef = useRef(false);
  const validStateRef = useRef(false);

  const { data: templatesResponse, loading: templatesLoading } =
    useQueryReferralTemplates(
      selectedOrganizationId,
      selectedResourceId,
      !selectedResourceId // skip param
    );

  const templateOptions = useMemo(
    () =>
      (templatesResponse?.referralTemplates.data ?? []).map((template) => ({
        label: template.title,
        value: template._id,
      })),
    [templatesResponse]
  );
  const templatesById = useMemo(
    () =>
      arrayToKeyedObj(templatesResponse?.referralTemplates.data ?? [], "_id"),
    [templatesResponse]
  );

  const initialValues: WrappedFormValues = {
    externalResourceId: null,
    referralTemplateId: null,
  };

  return (
    <Formik
      innerRef={innerRef as React.RefObject<FormikProps<WrappedFormValues>>}
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={(formValues, { setSubmitting }) => {
        if (!node) return; // nothing renders without a node selected
        try {
          setSubmitting(true);
          const actionInfo = parseFormValues(formValues, templatesById);
          onCreateAction(node, actionInfo);
          // eslint-disable-next-line
        } catch (err: any) {
          log.error(err.message);
          setSubmitting(false);
          toast.error("Failed to add action; please try again");
        }
      }}
    >
      {({ values, dirty, isValid, setFieldValue }) => {
        if (onDirtyStateChange && dirty !== dirtyStateRef.current) {
          dirtyStateRef.current = dirty;
          requestAnimationFrame(() => onDirtyStateChange(dirty));
        }
        if (onValidStateChange && isValid !== validStateRef.current) {
          validStateRef.current = isValid;
          requestAnimationFrame(() => onValidStateChange(isValid));
        }

        return (
          <Stack mt="0.75em" spacing="0.5em">
            <FormikSelect
              name="externalResourceId"
              label="External Resource"
              options={resourceOptions}
              onChangeOverride={(option) => {
                setFieldValue("externalResourceId", option, true);
                setSelectedResourceId(option?.value);
              }}
            />

            <FormikSelect
              name="referralTemplateId"
              label="Referral"
              options={templateOptions}
              isLoading={templatesLoading}
            />

            {values.externalResourceId && values.referralTemplateId && (
              <div>
                <ReferralTemplatePreview
                  referralTemplate={
                    templatesById[values.referralTemplateId.value]
                  }
                />
              </div>
            )}
          </Stack>
        );
      }}
    </Formik>
  );
};

type WrappedFormValues = Omit<
  CreateReferralData,
  "externalResourceId" | "referralTemplateId" | "type"
> & {
  externalResourceId: SelectOption<string> | null;
  referralTemplateId: SelectOption<string> | null;
};

const parseFormValues = (
  formValues: WrappedFormValues,
  templatesById: Record<string, ReferralTemplate>
): ActionInfo => {
  if (!formValues.externalResourceId) {
    throw new Error("Missing External Resource ID - check schema validator");
  }

  if (
    !formValues.referralTemplateId ||
    templatesById[formValues.referralTemplateId.value] === undefined
  ) {
    throw new Error("Invalid Referral ID");
  }

  return {
    actionType: ActionType.CreateReferral,
    actionData: {
      externalResourceId: formValues.externalResourceId.value,
      referralTemplateId: formValues.referralTemplateId.value,
      type: templatesById[formValues.referralTemplateId.value].type,
    },
  };
};

const ValidationSchema = Yup.object({
  externalResourceId: ySelectOptionSchema(Yup.string().required()).required(),
  referralTemplateId: ySelectOptionSchema(Yup.string().required()).required(),
});
