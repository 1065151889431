import { MultiSelectProps } from "@mantine/core";
import { MantineThemeComponents } from "@mantine/styles/lib/theme/types/MantineTheme";
import { IconChevronDown } from "@tabler/icons-react";

export const MultiSelect: MantineThemeComponents[string] = {
  defaultProps: (theme): Partial<MultiSelectProps> => ({
    searchable: true,
    withinPortal: true,
    rightSection: (
      <IconChevronDown
        size={18}
        style={{ pointerEvents: "none", color: theme.colors.gray[8] }}
        stroke={1}
      />
    ),
    styles: {
      rightSection: {
        pointerEvents: "none",
      },
    },
  }),
};
