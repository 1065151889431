import { Box, Tooltip } from "@mantine/core";
import { IconQuestionMark } from "@tabler/icons-react";

export function QuestionTooltip({
  toolTipMessage,
}: {
  toolTipMessage: string | React.ReactElement;
}) {
  return (
    <Box
      mx={4}
      display="inline-flex"
      style={{
        borderRadius: "8px",
        border: "1px solid rgba(0,0,0,.6)",
        alignItems: "center",
        boxShadow: "0 0 1px black",
      }}
    >
      <Tooltip label={toolTipMessage} opacity={1} multiline w={256} withArrow>
        <IconQuestionMark size={12} opacity={0.6} />
      </Tooltip>
    </Box>
  );
}
