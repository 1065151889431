import { gql } from "@apollo/client";
import { tagFields } from "./types";

export const CREATE_TAG = gql`
  mutation CreateTag($input: CreateTagInput!) {
    createTag(input: $input) {
      success
      message
      data {
        ${tagFields}
      }
    }
  }
`;

export const UPDATE_TAG = gql`
  mutation UpdateTag($input: UpdateTagInput!) {
    updateTag(input: $input) {
      success
      message
      data {
        ${tagFields}
      }
    }
  }
`;

export const DELETE_TAG = gql`
  mutation DeleteTag($id: ID!) {
    deleteTag(id: $id) {
      success
      message
      data {
        ${tagFields}
      }
    }
  }
`;
