import React from "react";
import {
  NumberInput as MantineNumberInput,
  Textarea,
  Button,
  Text,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { IconCalendar } from "@tabler/icons-react";

interface InputBaseProps {
  setCurrentAnswer: React.Dispatch<React.SetStateAction<string>>;
  currentAnswer: string;
}

interface DateInputProps extends InputBaseProps {
  name?: string;
}

interface InputOptionsProps {
  setCurrentAnswer: React.Dispatch<React.SetStateAction<string>>;
  currentAnswer: string;
  answerOptions: string[];
}

interface InputSelectMultiProps {
  setCurrentAnswer: React.Dispatch<React.SetStateAction<string>>;
  currentAnswer: string;
  answerOptions: string[];
}

export const BooleanInput = ({
  setCurrentAnswer,
  currentAnswer,
}: InputBaseProps) => {
  return (
    <Button.Group w="100%">
      <Button
        fullWidth
        variant={currentAnswer === "True" ? "filled" : "default"}
        onClick={() => setCurrentAnswer("True")}
      >
        True
      </Button>
      <Button
        fullWidth
        variant={currentAnswer === "False" ? "filled" : "default"}
        onClick={() => setCurrentAnswer("False")}
      >
        False
      </Button>
    </Button.Group>
  );
};

export const NumberInput = ({
  setCurrentAnswer,
  currentAnswer,
}: InputBaseProps) => {
  return (
    <MantineNumberInput
      type="number"
      value={currentAnswer ? parseInt(currentAnswer) : undefined}
      onChange={(nextValue) => setCurrentAnswer(nextValue?.toString() ?? "")}
    />
  );
};

export const TextInput = ({
  setCurrentAnswer,
  currentAnswer,
}: InputBaseProps) => {
  return (
    <Textarea
      value={currentAnswer}
      onChange={(e) => setCurrentAnswer(e.target.value)}
    />
  );
};

export const DateInput = ({
  setCurrentAnswer,
  name = "date",
}: DateInputProps) => {
  return (
    <DatePickerInput
      icon={<IconCalendar size="1.1rem" stroke={1.5} />}
      dropdownType="modal"
      placeholder="Pick date"
      name={name}
      clearable
      onChange={(d) => setCurrentAnswer(d?.toISOString() || "0")}
    />
  );
};

export const MultiInput = ({
  setCurrentAnswer,
  answerOptions,
  currentAnswer,
}: InputOptionsProps) => {
  return (
    <Button.Group orientation="vertical">
      {answerOptions.map((option) => (
        <Button
          key={option}
          fullWidth
          variant={currentAnswer === option ? "filled" : "default"}
          onClick={() => setCurrentAnswer(option)}
          style={{ height: "auto" }}
        >
          <Text
            style={{ textOverflow: "initial", whiteSpace: "normal" }}
            align="center"
            size="sm"
            py="xs"
          >
            {option}
          </Text>
        </Button>
      ))}
    </Button.Group>
  );
};

export const MultiChoiceInput = ({
  setCurrentAnswer,
  currentAnswer,
  answerOptions,
}: InputSelectMultiProps) => {
  let currentMultiAnswer: string[] = [];

  // i think this is illegal lol
  try {
    currentMultiAnswer = JSON.parse(currentAnswer);
  } catch {
    // does Nothing
  }

  const handleClickMulti = (answer: string) => {
    // If it is already within the answer set, remove it
    if (currentMultiAnswer.includes(answer)) {
      const tmp = new Set(currentMultiAnswer);
      tmp.delete(answer);

      // setCurrentMultiAnswer(tmp);

      setCurrentAnswer(JSON.stringify([...tmp.keys()]));
      // Otherwise add it to the set
    } else {
      const tmp = new Set(currentMultiAnswer);
      tmp.add(answer);

      //setCurrentMultiAnswer(tmp);
      setCurrentAnswer(JSON.stringify([...tmp.keys()]));
    }
  };

  return (
    <Button.Group orientation="vertical">
      {answerOptions.map((option) => (
        <Button
          key={option}
          fullWidth
          variant={currentMultiAnswer.includes(option) ? "filled" : "default"}
          onClick={() => handleClickMulti(option)}
          style={{ height: "auto" }}
        >
          <Text
            style={{ textOverflow: "initial", whiteSpace: "normal" }}
            align="center"
            size="sm"
            py="xs"
          >
            {option}
          </Text>
        </Button>
      ))}
    </Button.Group>
  );
};
