import toast from "src/libs/toast";
import { useContext } from "react";
import { AuthContext } from "src/providers";
import log from "loglevel";

const apiUrl = process.env.REACT_APP_API_URL;

export const useRequest = () => {
  const { firebaseUser } = useContext(AuthContext);

  return async (
    endpoint = "",
    params = {},
    parseAsJSON = true,
    showToast = true
  ) => {
    const token = await firebaseUser?.getIdToken();

    return fetch(`${apiUrl}${endpoint}`, {
      ...params,
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    })
      .then(async (res) => {
        if (res.status >= 200 && res.status < 400) {
          return parseAsJSON ? res.json() : res;

          /**
           * Code block to enable once content-type is added in the request from the backend
           */
          // const contentType = res.headers.get("content-type");
          // if (contentType && contentType.includes("application/json")) {
          //   return res.json().then((error = "") => {
          //     toast.error(error);
          //   });
          // }

          // // Return text if we are not dealing with a JSON response object
          // return res.text();
        } else {
          let message = res.statusText;

          const contentType = res.headers.get("content-type") as string | null;
          if (contentType && contentType.includes("application/json")) {
            const data = await res.json();
            message = data?.result?.message ?? message;
          }

          if (showToast) toast.error(message);
        }
      })
      .catch((e) => {
        log.error({ e });
        if (showToast) toast.error("Something went wrong!");
        else throw e;
      });
  };
};
