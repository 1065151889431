import { gql } from "@apollo/client";

export const UPDATE_EVENT_MEMBER_ATTENDANCE = gql`
  mutation UpdateEventMemberAttendance(
    $input: UpdateEventMemberAttendanceInput!
  ) {
    updateEventMemberAttendance(input: $input) {
      success
      message
      data {
        attended
        memberDisplayName
        memberId
      }
    }
  }
`;
