export const ReferralFields = `
  _id
  type
  contactType
  status
  organizationId
  memberId
  memberDisplayName
  referralTemplateId
  referralTemplateTitle
  externalResourceId
  activityId
  activityTitle
  activityDate
  userId
  userName
  content
  sendTo
  createdAt
  updatedAt
  isSentWithActionButtons
`;

export const ReferralFieldsWithAlias = `
_id
  type
  contactType
  referralStatus: status
  referralOrganizationId: organizationId
  memberId
  memberDisplayName
  referralTemplateId
  referralTemplateTitle
  externalResourceId
  activityId
  activityTitle
  activityDate
  userId
  userName
  content
  sendTo
  createdAt
  updatedAt
  isSentWithActionButtons
`;
