import { gql } from "@apollo/client";
import { flowTemplateFields } from "./types";

export const CREATE_FLOW_TEMPLATE = gql`
  mutation CreateFlowTemplate($input: CreateFlowTemplateInput!) {
    createFlowTemplate(input: $input) {
      success
      message
      data {
        ${flowTemplateFields}
      }
    }
  }
`;

export const RETIRE_FLOW_TEMPLATE_FAMILY = gql`
  mutation RetireFlowTemplateFamily($input: FlowTemplateFamilyIdAndOrgInput!) {
    retireFlowTemplateFamily(input: $input) {
      success
      message
      data {
        currentVersion {
          ${flowTemplateFields}
        }
        priorVersions {
          ${flowTemplateFields}
        }
      }
    }
  }
`;

export const UPDATE_FLOW_TEMPLATE = gql`
  mutation UpdateFlowTemplate($input: UpdateFlowTemplateInput!) {
    updateFlowTemplate(input: $input) {
      success
      message
      data {
        ${flowTemplateFields}
      }
    }
  }
`;

export const COPY_FLOW_TEMPLATE = gql`
  mutation CopyFlowTemplate($input: CopyFlowTemplateInput!) {
    copyFlowTemplate(input: $input) {
      success
      message
      data {
        ${flowTemplateFields}
      }
    }
  }
`;

export const EXPORT_FLOW_DATA = gql`
  mutation exportFlowTemplateData($input: ExportFlowTemplateInput!) {
    exportFlowTemplateData(input: $input) {
      success
      message
    }
  }
`;
