import styled from "styled-components";

export const PreviewContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 3px;
  font-size: 12px;
  overflow: hidden;
`;

export const PreviewHeader = styled.div`
  background-color: rgba(0, 0, 0, 0.05);
  text-transform: uppercase;
  padding: 0px 3px;
`;

export const PreviewList = styled.ul`
  margin-bottom: 0px;
`;

export const PreviewListItem = styled.li`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  list-style-position: inside;
  margin-left: -15px;
  padding-right: 5px;
`;

export const Disclaimer = styled.div`
  background-color: var(--DANGER-MUTED);
  color: var(--DANGER);
  border-radius: 3px;
  padding: 5px 7px;
  font-size: 12px;
`;

type PreviewListMoreProps = { count: number; limit?: number };
export const PreviewListMore = ({ count, limit = 3 }: PreviewListMoreProps) =>
  count > limit ? (
    <div style={{ marginLeft: "calc(2rem - 15px" }}>{`...and ${
      count - limit
    } more`}</div>
  ) : null;
