import { Switch, Route } from "react-router-dom";
import { Survey } from "./OnboardingSurvey";

const OnboardingRoute = () => (
  <Switch>
    <Route exact path="/onboarding-survey/:onboardingToken">
      <Survey />
    </Route>
  </Switch>
);

export default OnboardingRoute;
