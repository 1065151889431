import { carePathwayFields } from "../CarePath/types";
import { ActivityFields } from "../Activity/types";
import { ReferralFields } from "../Referral/types";

export const GoalFields = `
  _id
  memberId
  organizationId
  name
  memberName
  description
  dueDate
  createdAt
  status
  actionItems {
    id
    completed
    description
    orderIndex
  }
  baseTemplateId
  domain {
    _id
    title
    description
  }
  referrals {
    ${ReferralFields}
  }
  activities {
    ${ActivityFields}
  }
  carePathways {
    ${carePathwayFields}
  }
`;
