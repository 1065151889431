import { gql } from "@apollo/client";
import { TextMessageFields } from "./types";

export const GET_TEXT_MESSAGES = gql`
    query GetTextMessages($input: GetTextMessagesInput!, $pagination: PaginationInput!) {
        response: textMessages(input: $input, pagination: $pagination) {
            success
            data {
                total
                page
                data {
                    ${TextMessageFields}
                }
            }
        }
    }
`;
