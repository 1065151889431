import { Node } from "react-flow-renderer";
import { StepNodeIdentifyingToast } from "./toasts";
import { StepNodeData } from "../util";

export class FlowCodecError extends Error {
  jsxMessage?: JSX.Element;
  focusNodeId?: string;

  constructor(message: string, jsxMessage?: JSX.Element, focusNodeId?: string) {
    super(message);
    this.jsxMessage = jsxMessage;
    this.focusNodeId = focusNodeId;
  }
}

export class MissingInputError extends FlowCodecError {
  constructor(node: Node<StepNodeData>) {
    const toast = (
      <StepNodeIdentifyingToast
        node={node}
        message={"Question missing input connection:"}
      />
    );

    super("Question missing input connection", toast, node.id);
  }
}
