import { LockingReferenceFields } from "../Common/types";
import { ExternalResourceContactFields } from "../ExternalResource/types";

export const ReferralTemplateFields = `
  _id
  organizationId
  title
  description
  type
  contactType
  isActive
  defaultContactId
  defaultContact {
    ${ExternalResourceContactFields}
  }
  targetTemplateId
  targetTemplateName
  emailBody
  emailSubject
  SMSContent
  createdAt
  updatedAt
  consentTemplateId
  lockingReferences {
    ${LockingReferenceFields}
  }
  shouldSendActionButtons
  accessType
`;
