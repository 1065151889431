import { ActionInfo } from "src/components/flow-graph/util";
import { ActionData, ActionDataInput, ActionType } from "src/graphql";

export const getActionDataInput = (actionInfo: ActionInfo) => {
  const actionData = {} as ActionDataInput;

  switch (actionInfo.actionType) {
    case ActionType.CreateGoalData:
      actionData.createGoal = actionInfo.actionData;
      break;
    case ActionType.ScheduleActivityData:
      actionData.scheduleActivity = actionInfo.actionData;
      break;
    case ActionType.UpdateGoalData:
      actionData.updateGoal = actionInfo.actionData;
      break;
    case ActionType.UpdateMemberData:
      actionData.updateMember = actionInfo.actionData;
      break;
    case ActionType.CreateReferral:
      actionData.createReferral = actionInfo.actionData;
      break;
    case ActionType.CreateConsent:
      actionData.createConsent = actionInfo.actionData;
      break;
    case ActionType.StartCarePathway:
      actionData.startCarePathway = actionInfo.actionData;
      break;
    case ActionType.UpdateCarePathwayStatus:
      actionData.updateCarePathwayStatus = actionInfo.actionData;
      break;
    case ActionType.AddMemberToGroup:
      actionData.addMemberToGroup = actionInfo.actionData;
      break;
    case ActionType.RemoveMemberFromGroup:
      actionData.removeMemberFromGroup = actionInfo.actionData;
      break;
    default:
      break;
  }

  return actionData;
};

export const getActionInfos = (actionData: ActionDataInput) => {
  const actionInfos: ActionInfo[] = [];

  if (actionData.createGoal) {
    actionInfos.push({
      actionType: ActionType.CreateGoalData,
      actionData: actionData.createGoal,
    });
  }

  if (actionData.scheduleActivity) {
    actionInfos.push({
      actionType: ActionType.ScheduleActivityData,
      actionData: actionData.scheduleActivity,
    });
  }

  if (actionData.updateGoal) {
    actionInfos.push({
      actionType: ActionType.UpdateGoalData,
      actionData: actionData.updateGoal,
    });
  }

  if (actionData.updateMember) {
    actionInfos.push({
      actionType: ActionType.UpdateMemberData,
      actionData: actionData.updateMember,
    });
  }

  if (actionData.createReferral) {
    actionInfos.push({
      actionType: ActionType.CreateReferral,
      actionData: actionData.createReferral,
    });
  }

  if (actionData.createConsent) {
    actionInfos.push({
      actionType: ActionType.CreateConsent,
      actionData: actionData.createConsent,
    });
  }

  if (actionData.startCarePathway) {
    actionInfos.push({
      actionType: ActionType.StartCarePathway,
      actionData: actionData.startCarePathway,
    });
  }
  if (actionData.updateCarePathwayStatus) {
    actionInfos.push({
      actionType: ActionType.UpdateCarePathwayStatus,
      actionData: actionData.updateCarePathwayStatus,
    });
  }

  if (actionData.addMemberToGroup) {
    actionInfos.push({
      actionType: ActionType.AddMemberToGroup,
      actionData: actionData.addMemberToGroup,
    });
  }

  if (actionData.removeMemberFromGroup) {
    actionInfos.push({
      actionType: ActionType.RemoveMemberFromGroup,
      actionData: actionData.removeMemberFromGroup,
    });
  }

  return actionInfos;
};

export const extractIdFromActionData = (actionData: ActionData) => {
  return (
    actionData.addMemberToGroup?.id ||
    actionData.createConsent?.id ||
    actionData.createGoal?.id ||
    actionData.createReferral?.id ||
    actionData.removeMemberFromGroup?.id ||
    actionData.scheduleActivity?.id ||
    actionData.startCarePathway?.id ||
    actionData.updateGoal?.id ||
    actionData.updateMember?.id ||
    actionData.updateCarePathwayStatus?.id
  );
};
