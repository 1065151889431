import { SelectOption } from "src/types";
import * as Yup from "yup";

export const ySelectOptionSchema = <T extends Yup.AnySchema>(valueSchema: T) =>
  Yup.object({
    label: Yup.string().required(),
    value: valueSchema,
  });

const hasScriptTags = (value: string) => /<\s*script[(\s.*)|>]/.test(value);

/**
 * Yup schema for a string that does not contain script tags
 * @param message Custom error message
 */

export const yupNoScriptTag = (message?: string) =>
  Yup.string().test(
    "no-script",
    message || "No script tags allowed",
    (value) => !value || (!!value && !hasScriptTags(value))
  );

export const requiredWhenOptionEquals = <T>(equals: T, message?: string) => {
  return {
    is: (option?: SelectOption<T>) => option?.value === equals,
    then: (schema: Yup.AnySchema) => schema.required(message),
    otherwise: (schema: Yup.AnySchema) => schema.optional(),
  };
};
