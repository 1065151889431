import IncomingCallModal from "src/components/twilio/IncomingCallModal";
import { ContentContainer } from "./ContentContainer";
import { COLLAPSED_WIDTH, Sidebar } from "./Sidebar";
import { Masthead } from "./Masthead";
import SessionTimeout from "src/components/session-timeout";
import { SubscriptionContainer } from "../subscription-container/SubscriptionContainer";
import { TwilioPhoneWidget } from "../twilio/widget";
import { GlobalCallDispositionModal } from "../twilio/Disposition";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import { AppShell } from "@mantine/core";
import { UserCurrentActivityProvider } from "../user-current-activity";
import { OfflineModal } from "../offline-modal";

/**
 * Generally, we want to leave space at the bottom of our pages for the phone widget
 * to be able to scroll below the page content entirely; It's kinda easy to just do that
 * by default with the ContentContainer component as a general wrapper, by always adding
 * padding to the bottom of the view. Ideally we'd just remember to always put that padding
 * at the bottom of each of our views, but right now we dont, so that padding on the ContentContainer
 * component is useful.
 *
 * The routes which render ReactFlow graphs though, don't want that extra padding at the
 * bottom of the page - so these slugs should identify those routes for the ContentContainer
 * to skip adding that padding.
 */
const reactFlowRouteParts = ["/flows/", "/snapshot", "/carepathways/"];

export const MainContainer = () => {
  const location = useLocation();

  const isGraphRoute = useMemo(
    () =>
      reactFlowRouteParts.some((route) => location.pathname.includes(route)),
    [location]
  );

  return (
    <>
      <UserCurrentActivityProvider>
        <AppShell
          header={<Masthead />}
          navbar={<Sidebar />}
          styles={{
            main: {
              padding: 0,
              width: `calc(100vw - ${COLLAPSED_WIDTH}px)`,
              overflowX: "hidden",
            },
          }}
        >
          <ContentContainer omitBottomPadding={isGraphRoute} />
        </AppShell>
      </UserCurrentActivityProvider>

      {/* Modals and other global utils */}
      <SessionTimeout />
      <IncomingCallModal />
      <SubscriptionContainer />
      <TwilioPhoneWidget />
      <GlobalCallDispositionModal />
      <OfflineModal />
    </>
  );
};
