import { ButtonStylesParams, CSSObject } from "@mantine/core";
import { MantineThemeComponents } from "@mantine/styles/lib/theme/types/MantineTheme";

export const Button: MantineThemeComponents[string] = {
  styles: (
    theme,
    params: ButtonStylesParams,
    context
  ): Record<string, CSSObject> => {
    const color = params.color ?? "green";
    const primaryShade = theme.primaryShade as number;

    return {
      root: {
        ...(context.variant === "filled"
          ? {
              "&:hover": {
                backgroundColor: theme.colors[color][primaryShade - 1],
              },
              "&:active": {
                backgroundColor: theme.colors[color][primaryShade],
              },
            }
          : {}),

        ...(context.size === "sm" || context.size === "xs"
          ? {
              fontFamily: "Avenir-Roman, Avenir-Book, sans-serif",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "18px",
            }
          : {
              fontFamily: "Avenir-Roman, Avenir-Book, sans-serif",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "22px",
            }),
      },
    };
  },
};
