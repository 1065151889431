import gql from "graphql-tag";

export const GET_SIGNED_DOCUMENT = gql`
  query GetSignedDocument(
    $memberId: ID!
    $organizationIds: [ID!]!
    $providerRecommendationBatchId: ID!
    $signatureRequestId: ID
  ) {
    signedDocument(
      memberId: $memberId
      organizationIds: $organizationIds
      providerRecommendationBatchId: $providerRecommendationBatchId
      signatureRequestId: $signatureRequestId
    ) {
      success
      data {
        url
      }
      message
    }
  }
`;
