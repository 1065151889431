import { LockingReferenceFields } from "../Common/types";

export const EHRNoteTemplateFields = `
    id
    name
    contentType
    document
    documentType
    lockingReferences { ${LockingReferenceFields} }
    createdAt
    updatedAt
`;
