import { Stack } from "@mantine/core";

import { Organization } from "src/graphql";
import { MessageInputBox } from "./input-box";
import { MessageList } from "./list";

interface MessagesProps {
  height?: number | string;
  organization: Organization;
  participantPhone: string;
}

export const Messages = ({
  height,
  organization,
  participantPhone,
}: MessagesProps) => {
  return (
    <Stack spacing={0} h="100%">
      <MessageList
        height={height}
        organization={organization}
        participantPhone={participantPhone}
      />

      <MessageInputBox
        organization={organization}
        participantPhone={participantPhone}
      />
    </Stack>
  );
};

export default Messages;
