import { Button, TextInput } from "@mantine/core";
import { useMemo, useState } from "react";
import { Plus, Search } from "react-feather";
import toast from "src/libs/toast";
import { DeleteModal } from "src/components";
import { AnswerType, FlowType, Question, QuestionType } from "src/graphql";
import {
  useMutationDeleteQuestion,
  useQueryGetQuestions,
} from "src/graphql/Question/hooks";
import { useAuthContext } from "src/hooks";
import styled from "styled-components";
import { QuestionEditorModal } from "./QuestionEditorModal";
import { QuestionListEntry } from "./QuestionListEntry";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  height: 100%;
`;

const StyledHeader = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`;

const StyledQuestionList = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  & > *:not(:first-child) {
    margin-top: 10px;
  }

  & > *:last-child {
    margin-bottom: 100px;
  }
`;

export const BASE_QUESTION_ID = "newQuestionId";
export const makeBaseQuestion = (
  selectedOrganizationId: string,
  flowType: FlowType = FlowType.Script
): Question => ({
  _id: BASE_QUESTION_ID,
  answerOptions: [],
  answerType: AnswerType.Boolean,
  dataId: "",
  organizationId: selectedOrganizationId,
  questionText: "",
  questionTitle: "",
  lockingReferences: [],
  questionType:
    flowType === FlowType.Journey ? QuestionType.Step : QuestionType.Question,
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
});

const splitDataId = (dataId: string): [string, number] => {
  const [prefix] = dataId.match(/[^\d]+/) ?? [];
  const [numeric] = dataId.match(/\d+/) ?? [];
  return [prefix ?? "", parseInt(numeric ?? "0")];
};

const applyQuestionSort = (questionA: Question, questionB: Question) => {
  const [prefixA, numericA] = splitDataId(questionA.dataId);
  const [prefixB, numericB] = splitDataId(questionB.dataId);

  // sort by prefix, then number, then question title
  return (
    prefixA.localeCompare(prefixB) ||
    numericA - numericB ||
    questionA.questionTitle.localeCompare(questionB.questionTitle)
  );
};

const applyQuestionFilter = (
  question: Question,
  filterText: string,
  flowType: FlowType
) => {
  if (
    flowType === FlowType.Journey &&
    question.questionType === QuestionType.Question
  )
    return false;
  if (
    flowType !== FlowType.Journey &&
    question.questionType === QuestionType.Step
  )
    return false;

  return (
    `${question.dataId} ${question.questionTitle}`
      .toLowerCase()
      .includes(filterText) ||
    `${question.dataId} | ${question.questionTitle}`
      .toLowerCase()
      .includes(filterText) ||
    question.questionText.toLowerCase().includes(filterText)
  );
};

type QuestionListPaneProps = {
  flowType: FlowType;
  onUpdateQuestion: (question: Question) => void;
};

export const QuestionListPane = ({
  flowType,
  onUpdateQuestion,
}: QuestionListPaneProps) => {
  const { selectedOrganizationId } = useAuthContext();
  const [selectedQuestionId, setSelectedQuestionId] = useState<string>("");
  const [requestedDeleteQuestionId, setRequestedDeleteQuestionId] =
    useState("");
  const [filterText, setFilterText] = useState("");

  const questionType =
    flowType === FlowType.Journey ? QuestionType.Step : QuestionType.Question;

  const {
    data: getQuestionsResponse,
    loading: questionsLoading,
    refetch: refetchQuestions,
  } = useQueryGetQuestions(selectedOrganizationId, questionType);
  const [mutationDeleteQuestion] = useMutationDeleteQuestion(
    selectedOrganizationId,
    questionType
  );

  const handleConfirmDelete = async () => {
    try {
      const deleteResponse = await mutationDeleteQuestion({
        variables: {
          input: {
            _id: requestedDeleteQuestionId,
            organizationId: selectedOrganizationId,
          },
        },
      });

      if (
        deleteResponse.errors?.length ||
        deleteResponse.data?.deleteQuestion?.success === false
      ) {
        toast.error("Ooops! Something went wrong.");
      } else {
        toast.success("Question deleted!");
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      toast.error("Ooops! Somethign went wrong.");
    } finally {
      refetchQuestions();
      setRequestedDeleteQuestionId("");
    }
  };

  const sortedQuestions = useMemo(
    () =>
      [...(getQuestionsResponse?.getQuestions.data ?? [])].sort(
        applyQuestionSort
      ),
    [getQuestionsResponse?.getQuestions.data]
  );

  const questionsById: Record<string, Question> = useMemo(() => {
    if (questionsLoading) return {};
    return {
      [BASE_QUESTION_ID]: makeBaseQuestion(selectedOrganizationId, flowType),
      ...sortedQuestions
        .filter((q) =>
          applyQuestionFilter(q, filterText.toLowerCase(), flowType)
        )
        .reduce(
          (byId, question) => ({
            ...byId,
            [question._id]: question,
          }),
          {} as Record<string, Question>
        ),
    };
  }, [
    sortedQuestions,
    selectedOrganizationId,
    questionsLoading,
    filterText,
    flowType,
  ]);

  return (
    <StyledContainer>
      {/* List Header */}
      <StyledHeader>
        <strong>{flowType === FlowType.Journey ? "Steps" : "Questions"}</strong>
        <Button
          size="xs"
          leftIcon={<Plus size="18" />}
          onClick={() => setSelectedQuestionId(BASE_QUESTION_ID)}
        >
          Add
        </Button>
      </StyledHeader>

      {/* Question Search */}
      <TextInput
        placeholder={
          flowType === FlowType.Journey
            ? "Search steps... "
            : "Search questions..."
        }
        icon={<Search size={14} />}
        styles={{
          input: {
            border: "none",
            borderRadius: 0,
            borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
          },
        }}
        value={filterText}
        onChange={(e) => setFilterText(e.currentTarget.value)}
      />

      {/* No Questions Message */}
      {getQuestionsResponse?.getQuestions.data?.length === 0 && (
        <div style={{ textAlign: "center", padding: "20px 15px" }}>
          {questionType === QuestionType.Question
            ? "Add a Question to get started!"
            : "Add a Step to get started!"}
        </div>
      )}

      {/* Questions List */}
      <StyledQuestionList>
        {Object.values(questionsById).map((question, index) =>
          question._id === BASE_QUESTION_ID ? null : (
            <QuestionListEntry
              key={question._id}
              question={question}
              onRequestEdit={(questionId) => setSelectedQuestionId(questionId)}
              onRequestDelete={(questionId) =>
                setRequestedDeleteQuestionId(questionId)
              }
              isLast={index === Object.values(questionsById).length - 1}
            />
          )
        )}
      </StyledQuestionList>

      {selectedQuestionId && questionsById[selectedQuestionId] ? (
        <QuestionEditorModal
          selectedQuestion={questionsById[selectedQuestionId]}
          onUpdateQuestion={onUpdateQuestion}
          onRequestClose={() => setSelectedQuestionId("")}
        />
      ) : null}

      <DeleteModal
        modalIsOpen={!!requestedDeleteQuestionId}
        deleteFunction={handleConfirmDelete}
        setModalIsOpen={(open) => !open && setRequestedDeleteQuestionId("")}
        rowId=""
      />
    </StyledContainer>
  );
};
