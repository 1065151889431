import { Button, Group, TextInput } from "@mantine/core";
import { ArrowLeft, Save } from "react-feather";
import { useHistory } from "react-router-dom";

export const BUILDER_HEADER_HEIGHT = 59;

type FlowSnapshotHeaderProps = {
  title: string;
  isSubmitting: boolean;
  canSubmit: boolean;
  onRequestSubmit: () => void;
};

export const FlowSnapshotHeader = ({
  title,
  isSubmitting,
  canSubmit,
  onRequestSubmit,
}: FlowSnapshotHeaderProps) => {
  const history = useHistory();

  return (
    <Group
      h={BUILDER_HEADER_HEIGHT}
      p={10}
      style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.2)", gap: "10px" }}
    >
      <Button
        onClick={() => history.goBack()}
        leftIcon={<ArrowLeft size="18" />}
      >
        Back
      </Button>
      <TextInput value={title} disabled required style={{ flexGrow: 1 }} />
      <Button
        onClick={onRequestSubmit}
        loading={isSubmitting}
        disabled={!canSubmit}
        leftIcon={<Save size="18" />}
      >
        Save Changes
      </Button>
    </Group>
  );
};
