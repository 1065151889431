import dayjs from "dayjs";
import log from "loglevel";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import toast from "src/libs/toast";
import {
  Activity,
  ActivityStatus,
  useMutationActivityPing,
  useQueryUserCurrentActivity,
} from "src/graphql";
import { useAuthContext } from "src/hooks";
import { PostCompletionUpdateActivityModal } from "../post-completion-update-activity-modal";

const ACTIVITY_LAST_PING_TIMEOUT_IN_SECONDS = 60;

// Provider to share date across the tree without property drilling
type UserCurrentActivityStatusContextValue = {
  error?: string;
  activity?: Activity & { endActivity: () => void };
};

const UserCurrentActivityStatusContext =
  createContext<UserCurrentActivityStatusContextValue>({});

export const useUserCurrentActivtyStatus = () =>
  useContext(UserCurrentActivityStatusContext);

// Root component
type UserCurrentActivityProviderProps = {
  children: ReactNode;
};

export const UserCurrentActivityProvider = ({
  children,
}: UserCurrentActivityProviderProps) => {
  const { currentUser, selectedOrganization } = useAuthContext();

  const [endActivityModalIsOpened, setEndActivityModalIsOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const userCurrentActivityQuery = useQueryUserCurrentActivity({
    userId: currentUser._id,
    organizationId: selectedOrganization._id,
  });

  const activity = userCurrentActivityQuery?.data?.userCurrentActivity?.data;

  const activityTimedOut = useMemo(() => {
    // Time trackong not enabled
    if (selectedOrganization.timeTrackingEnabled === false) return false;

    if (!activity) return false;
    if (activity.status !== ActivityStatus.InProgress) return false;
    if (!activity.lastPing) return false;

    const lastPingSentBeforeTimeout =
      dayjs().diff(activity.lastPing, "seconds") >
      ACTIVITY_LAST_PING_TIMEOUT_IN_SECONDS;

    return lastPingSentBeforeTimeout;
  }, [activity, selectedOrganization.timeTrackingEnabled]);

  const activityContextValue =
    useMemo<UserCurrentActivityStatusContextValue>(() => {
      if (error) return { error };
      if (activity)
        return {
          activity: {
            ...activity,
            endActivity: () => setEndActivityModalIsOpen(true),
          },
        };
      return {};
    }, [activity, error]);

  const [activityPing] = useMutationActivityPing();

  // Activity ping loop
  useEffect(() => {
    const interval = setInterval(() => {
      if (activity?.status !== ActivityStatus.InProgress) return;

      activityPing({
        variables: {
          input: { activityId: activity._id },
        },
      })
        .then(() => {
          if (error) setError(null);
        })
        .catch((error) => {
          log.error(error);
          setError("Missed activity progress sync");
          toast.error("Missed activity progress sync");
        });
    }, 15_000);

    return () => clearInterval(interval);
  }, [activity, error, activityTimedOut, activityPing]);

  // Activity completed modal
  useEffect(() => {
    if (activityTimedOut) return setEndActivityModalIsOpen(true);
  }, [activity, activityTimedOut, setEndActivityModalIsOpen]);

  return (
    <UserCurrentActivityStatusContext.Provider value={activityContextValue}>
      {children}

      <PostCompletionUpdateActivityModal
        title="End Activity"
        message="Completed activity"
        showEditStatus={false}
        activity={activity ?? null}
        opened={endActivityModalIsOpened}
        onClose={() => setEndActivityModalIsOpen(false)}
      />
    </UserCurrentActivityStatusContext.Provider>
  );
};
