import {
  IconBuildingEstate,
  Icon as TablerIcon,
  IconBuildingBank,
} from "@tabler/icons-react";
import * as React from "react";
import {
  Home,
  UserPlus,
  Briefcase,
  UserCheck,
  Phone,
  Icon,
  Layers,
  Award,
  Copy,
  PieChart,
  Users,
  ExternalLink,
  Archive,
  Database,
  Tool,
  Lock,
} from "react-feather";

import { UserRole } from "src/graphql";
import { AuthContextType } from "./providers";

export type RouteConfig = {
  exact?: boolean;
  component: React.LazyExoticComponent<React.ComponentType<unknown>>;
};

export const AuthRoutes: Record<string, RouteConfig> = {
  "/members": {
    exact: false,
    component: React.lazy(() => import("./pages/members")),
  },
  "/users": {
    exact: true,
    component: React.lazy(() => import("./pages/users")),
  },
  "/settings/:subPage": {
    exact: true,
    component: React.lazy(() => import("./pages/user-settings")),
  },
  "/activity/:activityId": {
    exact: true,
    component: React.lazy(() => import("./pages/activity")),
  },
  "/dashboard": {
    exact: true,
    component: React.lazy(() => import("./pages/dashboard")),
  },
  "/organization": {
    exact: false,
    component: React.lazy(() => import("./pages/organization")),
  },
  "/call-center": {
    exact: true,
    component: React.lazy(() => import("./pages/call-center")),
  },
  "/recommendations": {
    exact: false,
    component: React.lazy(() => import("./pages/recommendations")),
  },
  "/carepathways/:templateId": {
    exact: false,
    component: React.lazy(
      () => import("./pages/templates-carepathway/EditCarePathWayView")
    ),
  },
  "/duplications": {
    exact: false,
    component: React.lazy(() => import("./pages/duplications")),
  },
  "/user-management": {
    exact: true,
    component: React.lazy(() => import("./pages/user-management")),
  },
  "/subcontractor-payers": {
    exact: true,
    component: React.lazy(() => import("./pages/subcontractor-payers")),
  },
  "/organizations-management": {
    exact: true,
    component: React.lazy(() => import("./pages/organizations-managment")),
  },
  "/review-members": {
    exact: true,
    component: React.lazy(() => import("./pages/review-members")),
  },
  "/external-resources": {
    exact: false,
    component: React.lazy(() => import("./pages/external-resources")),
  },
  "/billing/claims": {
    exact: true,
    component: React.lazy(() => import("./pages/billing/bills/index")),
  },
  "/billing/bundles": {
    exact: true,
    component: React.lazy(() => import("./pages/billing/batches/index")),
  },
  "/billing/matching": {
    exact: true,
    component: React.lazy(
      () => import("./pages/billing/payment-reconciliation/index")
    ),
  },
  "/billing/reports": {
    exact: true,
    component: React.lazy(
      () => import("./pages/billing/provider-reports/index")
    ),
  },
  "/power-dialer": {
    exact: true,
    component: React.lazy(() => import("./pages/power-dialer")),
  },
  "/data-management": {
    exact: true,
    component: React.lazy(() => import("./pages/data-management")),
  },
  "/data-management/calculations/:calculationId": {
    exact: true,
    component: React.lazy(() => import("./pages/calculation")),
  },
  "/data-management/import-templates/:dataImportTemplateId": {
    exact: true,
    component: React.lazy(() => import("./pages/data-import-template")),
  },
  "/data-management/export-templates/:dataExportTemplateId": {
    exact: true,
    component: React.lazy(() => import("./pages/data-export-template")),
  },
  "/analytics": {
    exact: true,
    component: React.lazy(() => import("./pages/analytics")),
  },
  "/templates/:templateTab": {
    exact: true,
    component: React.lazy(() => import("./pages/templates")),
  },
  "/builder": {
    exact: true,
    component: React.lazy(() => import("./pages/builder")),
  },
  "/builder/:builderType/:builderId/:templateId?": {
    exact: true,
    component: React.lazy(() => import("./pages/builder/builder")),
  },
  "/templates/events/:templateId": {
    exact: true,
    component: React.lazy(
      () => import("./pages/templates-event/EditEventTemplateView")
    ),
  },
  "/hub-control": {
    exact: true,
    component: React.lazy(() => import("./pages/hub-control")),
  },
  "*": {
    component: React.lazy(() => import("./pages/not-found")),
  },
};

type RouteDescription = {
  path: string;
  label: string;
  Icon: Icon | TablerIcon;
  omit?: (ctx: AuthContextType) => boolean;
};

type RouteGroup = {
  allowedRoles: UserRole[];
  children: RouteDescription[];
  omit?: (ctx: AuthContextType) => boolean;
};

export const DefaultRouteBasedOnRole = {
  [UserRole.AnalyticsViewer]: "/analytics",
  [UserRole.Admin]: "/dashboard",
  [UserRole.Chw]: "/dashboard",
  [UserRole.Lead]: "/dashboard",
  [UserRole.SuperAdmin]: "/dashboard",
  [UserRole.RecommendingProvider]: "/review-members",
} as const;

export const RouteStyleConfig = {
  "/analytics": {
    bgColor: "#F6F8FA",
  },
};

export const SidebarSections: RouteGroup[][] = [
  [
    {
      allowedRoles: [
        UserRole.SuperAdmin,
        UserRole.Admin,
        UserRole.Lead,
        UserRole.Chw,
        UserRole.RecommendingProvider,
      ],
      children: [
        {
          path: "/dashboard",
          label: "Dashboard",
          Icon: Home,
          omit: (ctx) => ctx.currentUser.role === UserRole.RecommendingProvider,
        },
        {
          path: "/members",
          label: "Members",
          Icon: UserCheck,
          omit: (ctx) => ctx.currentUser.role === UserRole.RecommendingProvider,
        },
        {
          path: "/call-center",
          label: "Call Center",
          Icon: Phone,
          omit({ selectedOrganization, currentUser }) {
            return (
              !selectedOrganization?.callerId ||
              currentUser.role === UserRole.RecommendingProvider
            );
          },
        },
      ],
    },
  ],
  [
    {
      allowedRoles: [UserRole.RecommendingProvider],
      children: [
        {
          path: "/review-members",
          label: "Review Members",
          Icon: UserCheck,
        },
      ],
    },
  ],
  [
    {
      allowedRoles: [
        UserRole.Chw,
        UserRole.SuperAdmin,
        UserRole.Admin,
        UserRole.Lead,
      ],
      children: [
        {
          path: "/builder",
          label: "Builder",
          Icon: Tool,
        },
        {
          path: "/templates/activities",
          label: "Templates",
          Icon: Layers,
        },
        {
          path: "/recommendations",
          label: "Recommendations",
          Icon: Award,
        },
        {
          path: "/external-resources",
          label: "External Resources",
          Icon: ExternalLink,
        },
      ],
    },
    {
      allowedRoles: [
        UserRole.Lead,
        UserRole.SuperAdmin,
        UserRole.Admin,
        UserRole.AnalyticsViewer,
      ],
      children: [
        {
          path: "/analytics",
          label: "Analytics",
          Icon: PieChart,
        },
      ],
    },
    {
      allowedRoles: [UserRole.Lead, UserRole.SuperAdmin, UserRole.Admin],
      children: [
        {
          path: "/user-management",
          label: "User Management",
          Icon: Users,
        },
      ],
    },
    {
      allowedRoles: [UserRole.Lead, UserRole.SuperAdmin, UserRole.Admin],
      children: [
        {
          path: "/data-management",
          label: "Data Management",
          Icon: Database,
        },
      ],
    },
  ],
  [
    {
      allowedRoles: [UserRole.SuperAdmin, UserRole.Admin],
      children: [
        {
          path: "/users",
          label: "Users",
          Icon: UserPlus,
        },
        {
          path: "/billing/claims",
          label: "Claims",
          Icon: Archive,
          omit: (ctx) => !ctx.selectedOrganization.billingEnabled,
        },
        {
          path: "/organization",
          label: "Organization",
          Icon: Briefcase,
        },
        {
          path: "/hub-control",
          label: "HUB Control",
          Icon: Lock,
          omit: (ctx) => !ctx.selectedOrganization.hubControlEnabled,
        },
      ],
    },
  ],
  [
    {
      allowedRoles: [UserRole.SuperAdmin],
      children: [
        {
          path: "/duplications",
          label: "Duplicate",
          Icon: Copy,
        },
        {
          path: "/organizations-management",
          label: "Organizations",
          Icon: IconBuildingEstate,
        },
        {
          path: "/subcontractor-payers",
          label: "Subcontractor Payers",
          Icon: IconBuildingBank,
        },
      ],
    },
  ],
];
