import { Checkbox, CheckboxProps } from "@mantine/core";
import { useField } from "formik";
import {
  FormikInputBaseProps,
  FormikInputWrapper,
  extractWrapperProps,
} from "./FormikInputWrapper";

type FormikCheckboxProps = FormikInputBaseProps<boolean> &
  CheckboxProps & {
    onChangeOverride?: (value: boolean) => void;
  };

export const FormikCheckbox = ({
  onChangeOverride,
  ...props
}: FormikCheckboxProps) => {
  const [field, , helpers] = useField(props);

  const onChange = onChangeOverride
    ? (e: React.ChangeEvent<HTMLInputElement>) =>
        onChangeOverride(e.currentTarget.checked)
    : (e: React.ChangeEvent<HTMLInputElement>) =>
        helpers.setValue(e.currentTarget.checked);

  return (
    <FormikInputWrapper {...extractWrapperProps(props)} label={undefined}>
      <Checkbox {...props} checked={Boolean(field.value)} onChange={onChange} />
    </FormikInputWrapper>
  );
};
