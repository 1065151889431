import { ActionIcon, Group, Text } from "@mantine/core";

type SelectedFileProps = {
  file: File;
  clear: (val: File | undefined) => void;
};

export const SelectedFile = ({ file, clear }: SelectedFileProps) => {
  const getFileSizeinKB = (size: number) => {
    return `${(size * 1e-3).toFixed(2)} KB`;
  };

  return (
    <Group align="center" position="apart" noWrap>
      <Text size="sm">
        {file.name} - {getFileSizeinKB(file.size)}
      </Text>
      <ActionIcon onClick={() => clear(undefined)}>&#x292B;</ActionIcon>
    </Group>
  );
};
