import { TableHeader } from "src/components";

type RecommendationsTableHeaderProps = {
  setFilterText: (nextText: string) => void;
};

export const RecommendationsTableHeader = ({
  setFilterText,
}: RecommendationsTableHeaderProps) => {
  return (
    <TableHeader
      placeholder="By Name"
      setFilterText={(nextText) => setFilterText(nextText)}
    />
  );
};
