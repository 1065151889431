import { gql } from "@apollo/client";
import { ActivityFields } from "./types";

export const GET_ACTIVITY = gql`
  query Activity($input: GetActivityInput!) {
    activity(input: $input) {
      success
      message
      data {
        ${ActivityFields}
      }
    }
  }
`;

export const GET_USER_CURRENT_ACTIVITY = gql`
  query UserCurrentActivity($input: GetUserCurrentActivityInput!) {
    userCurrentActivity(input: $input) {
      success
      message
      data {
        ${ActivityFields}
      }
    }
  }
`;

export const GET_ACTIVITIES = gql`
  query Activities($input: GetActivitiesInput!, $pagination: PaginationInput!){
    response: activities(input: $input, pagination: $pagination) {
      success
      message
      data {
        total
        page
        data {
          ${ActivityFields}
        }
      }
    }
  }
`;
