import {
  Menu,
  Paper,
  Button,
  Select,
  Flex,
  useMantineTheme,
} from "@mantine/core";
import { Trash2, Rewind, Edit2 } from "react-feather";
import { GoalStatus } from "src/graphql/schemaTypes";
import { IconDotsVertical } from "@tabler/icons-react";

type GoalDetailControlsProps = {
  goalStatus?: GoalStatus;
  deletePending: boolean;
  onRequestDelete: () => void;
  onRequestEdit: () => void;
  onStatusUpdate: (status: GoalStatus) => void;
  readOnly?: boolean;
};

export const GoalDetailControls = ({
  goalStatus,
  deletePending,
  onRequestDelete,
  onRequestEdit,
  onStatusUpdate,
  readOnly,
}: GoalDetailControlsProps) => {
  const theme = useMantineTheme();

  const statusOptions = [
    { value: "Active", label: "Active" },
    { value: "Unresolved", label: "Unresolved" },
    { value: "Resolved", label: "Resolved" },
    { value: "Completed", label: "Completed" },
  ];

  return (
    <Paper
      shadow="xs"
      pos="absolute"
      right={20}
      bottom={10}
      p={4}
      bg="white"
      style={{
        [theme.fn.smallerThan("sm")]: {
          width: "100%",
          left: 0,
          right: 0,
          bottom: 0,
          padding: "8px 16px",
          backgroundColor: theme.colors.gray[0],
        },
      }}
    >
      {(goalStatus === GoalStatus.Active ||
        goalStatus === GoalStatus.Unresolved) && (
        <>
          <Flex align="center" wrap="nowrap" justify="space-between">
            <Select
              data={statusOptions}
              placeholder="Update Status"
              value={goalStatus}
              onChange={onStatusUpdate}
              style={{ flexGrow: 1, marginRight: 10 }}
            />
            <div style={{ display: "flex", alignItems: "center" }}>
              {!readOnly && (
                <Menu withinPortal>
                  <Menu.Target>
                    <Button
                      compact
                      variant="subtle"
                      color="gray"
                      hidden={!theme.fn.smallerThan("sm")}
                    >
                      <IconDotsVertical size="1em" />
                    </Button>
                  </Menu.Target>

                  <Menu.Dropdown>
                    <Menu.Item
                      icon={<Edit2 size="1em" />}
                      color="blue"
                      onClick={onRequestEdit}
                    >
                      Edit
                    </Menu.Item>
                    {!deletePending && (
                      <Menu.Item
                        icon={<Trash2 size="1em" />}
                        color="red"
                        onClick={onRequestDelete}
                      >
                        Delete
                      </Menu.Item>
                    )}
                  </Menu.Dropdown>
                </Menu>
              )}
            </div>
          </Flex>
        </>
      )}

      {(goalStatus === GoalStatus.Resolved ||
        goalStatus === GoalStatus.Completed) && (
        <Button
          compact
          variant="subtle"
          color="orange"
          onClick={() => onStatusUpdate(GoalStatus.Active)}
        >
          <Rewind size="1em" />
          &nbsp;Restore
        </Button>
      )}
    </Paper>
  );
};
