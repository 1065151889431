import { useState } from "react";
import ReactFlow, {
  Node,
  Edge,
  Background,
  Controls,
} from "react-flow-renderer";
import {
  findStepNodeById,
  PearNodesData,
  updateNodeGroupAnswer,
  willFlowPathChangeWithUpdate,
  pruneOrphanedNodes,
  updateEdgesForGraphRedirect,
  PearEdge,
  markEdgeUnvisited,
  markEdgeVisited,
  isUserInputType,
  copyFrozen,
  toggleMultiChoiceAnswer,
  validateAnswerUpdate,
  findStepOrAnswerNodeGroup,
} from "../util";
import {
  FlowNodeRenderContext,
  NodeMeasuringContainer,
  NodeTuplesToMeasure,
  OrderedStepNodeTuple,
} from "../NodeMeasuringContainer";
import { snapshotEditorNodeTypes } from "../flow-nodes";
import { FlowSnapshotContext } from "./context";
import { Answer, AnswerType } from "src/graphql";
import { NodeEditMask } from "./NodeEditMask";
import { CommitApprovalModal } from "./CommitApprovalModal";
import toast from "src/libs/toast";

type FlowSnapshotEditorProps = {
  nodes: Node<PearNodesData>[];
  edges: Edge[];
  nodesToMeasure: NodeTuplesToMeasure | null;
  workingAnswers: Answer[];
  currentFlowStepId: string;
  currentStepHasChanged: boolean;
  setIsEditingNode: (isEditing: boolean) => void;
  setCurrentFlowStepId: (nextStepId: string) => void;
  setWorkingAnswers: (nextAnswers: Answer[]) => void;
  setEdges: (nextEdges: Edge[]) => void;
  setNodesToMeasure: (nodes: NodeTuplesToMeasure | null) => void;
  onNodesMeasured: (nodesById: Record<string, Node<PearNodesData>>) => void;
};

export const FlowSnapshotEditor = ({
  nodes,
  edges,
  nodesToMeasure,
  workingAnswers,
  currentFlowStepId,
  currentStepHasChanged,
  setIsEditingNode,
  setCurrentFlowStepId,
  setWorkingAnswers,
  setEdges,
  setNodesToMeasure,
  onNodesMeasured,
}: FlowSnapshotEditorProps) => {
  const [requestingCommitApproval, setRequestingCommitApproval] =
    useState(false);
  const [selectedEditNodeId, setSelectedEditNodeId] = useState("");
  const [pendingUncommittedAnswer, setPendingUncommittedAnswer] = useState("");
  const [cachedNodeTuple, setCachedNodeTuple] =
    useState<OrderedStepNodeTuple | null>(null);

  // selects a node for editing when "edit" btn clicked
  const handleRequestEditStep = (stepId: string) => {
    // check if editing may invalidate further answers
    const node = findStepNodeById(nodes, stepId);
    const nodeGroup = findStepOrAnswerNodeGroup(node, nodes);

    // cache original tuple in case changes are later discarded
    setCachedNodeTuple(nodeGroup);
    setIsEditingNode(true);
    setSelectedEditNodeId(stepId);

    const flowAnswerIndex = nodeGroup[0].data.flowAnswerIndex ?? -1;
    const existingAnswer = workingAnswers[flowAnswerIndex];
    if (existingAnswer) setPendingUncommittedAnswer(existingAnswer.answer);

    // for User Input types, we need to send the node group to be measured to make room
    // for textareas to render for editing.
    if (isUserInputType(node)) {
      setNodesToMeasure({
        tuples: [nodeGroup],
        context: FlowNodeRenderContext.SnapshotEditor,
      });
    }
  };

  // updates nodes in-place *during* editing (before committing or discarding changes
  // and exiting edit mode on a node)
  const handleUpdateAnswer = (parentNodeId: string, nextAnswerJSON: string) => {
    // get our node
    const prevStepNode = findStepNodeById(nodes, parentNodeId);

    // update our pending answer
    const nextPendingAnswer =
      prevStepNode.data.question.answerType === AnswerType.MultiChoice
        ? toggleMultiChoiceAnswer(
            pendingUncommittedAnswer ?? "[]",
            nextAnswerJSON
          )
        : nextAnswerJSON;
    setPendingUncommittedAnswer(nextPendingAnswer);

    // for UserInput types, rendering as value updates should be controlled at the node level with
    // local state, so all we need to do is report the changes to the input and store
    if (isUserInputType(prevStepNode)) {
      return;
    }

    // otherwise, update the node group and send to measurer for re-render
    const prevNodeGroup = findStepOrAnswerNodeGroup(prevStepNode, nodes);
    const nextNodeGroup = updateNodeGroupAnswer(
      prevNodeGroup,
      nextPendingAnswer
    );

    // send nodes off to measure
    setNodesToMeasure({
      tuples: [nextNodeGroup],
      context: FlowNodeRenderContext.SnapshotEditor,
    });
  };

  // verify with user if committing requested changes will redirect graph,
  // invalidating previously given answers
  const handleRequestCommitChanges = () => {
    if (!cachedNodeTuple) {
      throw new Error(
        "No cached node group tuple; believe this is a coding error."
      );
    }

    // validate the next answer before attempting to commit
    const { isValid, message } = validateAnswerUpdate(
      pendingUncommittedAnswer,
      cachedNodeTuple[0].data.question.answerType
    );
    if (!isValid) {
      toast.error(message);
      return;
    }

    // check if this change will invalidate previously given anwers
    const pathWillChange = willFlowPathChangeWithUpdate(
      cachedNodeTuple,
      edges,
      pendingUncommittedAnswer
    );
    const updateIndex = cachedNodeTuple[0].data.flowAnswerIndex;
    const changeWillDropNodes =
      pathWillChange &&
      updateIndex !== undefined &&
      updateIndex !== workingAnswers.length - 1;

    // if the new answer does not result in dropped previously answered nodes,
    // changes can be committed as path will not be changed.
    if (changeWillDropNodes) {
      setRequestingCommitApproval(true);
    } else {
      handleCommitChanges();
    }
  };

  const handleCommitChanges = () => {
    if (!cachedNodeTuple) {
      throw new Error(
        "No cached node group tuple; believe this is a coding error."
      );
    }

    // get our current changing node tuple, while setting it and it's child nodes
    // as having unsaved changes
    const nextStepNode = findStepNodeById(nodes, selectedEditNodeId);
    let nextWorkingAnswers = copyFrozen(workingAnswers);
    let [, ...nextAnswerNodes] = findStepOrAnswerNodeGroup(
      nextStepNode,
      nodes
    ).map((node) => {
      node.data.hasUnsavedChanges = true;
      return node;
    }) as OrderedStepNodeTuple;

    // check if graph will change paths
    const pathWillChange = willFlowPathChangeWithUpdate(
      cachedNodeTuple,
      edges,
      pendingUncommittedAnswer
    );

    // find the ids of the new answer node, prev output source, next output source
    // -- setup
    const [cachedStepNode, ...cachedAnswerNodes] = cachedNodeTuple;
    const isMultiOutput = cachedStepNode.data.isMultiOutput;
    const next = cachedStepNode.data.step.next?.find((next) =>
      isMultiOutput
        ? next.response === pendingUncommittedAnswer
        : next.response === "DEFAULT"
    );

    // update our current node's answer
    const nextAnswerIndex = nextStepNode.data.flowAnswerIndex;
    if (nextAnswerIndex !== undefined) {
      nextWorkingAnswers[nextAnswerIndex].answer = pendingUncommittedAnswer;
    } else {
      nextStepNode.data.flowAnswerIndex = workingAnswers.length;
      nextWorkingAnswers.push({
        flowAnswerIndex: nextStepNode.data.flowAnswerIndex,
        questionId: nextStepNode.data.question._id,
        answer: pendingUncommittedAnswer,
        submittedAt: new Date().toISOString(),
        stepId: cachedStepNode.id,
      });
    }

    // mark our edited node and answer as visited
    nextStepNode.data.visited = true;
    if (isMultiOutput) {
      nextAnswerNodes = nextAnswerNodes.map((node) => {
        if (node.data.answerValue === pendingUncommittedAnswer) {
          node.data.visited = true;
        }
        return node;
      });
    }
    // when the edit node is a UserInput or ReadOnly type, the state for the value is
    // managed in the component itself, rather than stored in nodes as the clicked
    // values change; so we need to set the node's value manually when committing to
    // the reported current value for these types.
    if (
      isUserInputType(nextStepNode) ||
      nextStepNode.data.question.answerType === AnswerType.ReadOnlyText
    ) {
      nextAnswerNodes[0].data.answerValue = pendingUncommittedAnswer;
      nextAnswerNodes[0].data.visited = !!pendingUncommittedAnswer;
    }

    // -- ids we want
    const nextAnswerNodeId =
      nextAnswerNodes.find(
        (answerNode) => answerNode.data.answerValue === pendingUncommittedAnswer
      )?.id ?? "";
    const currentOutputNodeId = isMultiOutput
      ? cachedAnswerNodes.find((node) => !!node.data.visited)?.id ?? ""
      : cachedStepNode.id;
    const nextOutputNodeId = isMultiOutput
      ? nextAnswerNodeId
      : cachedStepNode.id;

    // set up our return state
    const nodesToMeasure: NodeTuplesToMeasure = {
      tuples: [[nextStepNode, ...nextAnswerNodes]], // current updated node, maybe add dropped nodes
      context: FlowNodeRenderContext.SnapshotEditor,
    };
    let nextEdges: PearEdge[] = [...edges];

    // if path will change, prune nodes and edges orphaned by changed path
    if (pathWillChange) {
      const { prunedNodeTuples, prunedNodeIds, prunedWorkingAnswers } =
        pruneOrphanedNodes(nodes, nextWorkingAnswers, [
          nextStepNode,
          ...nextAnswerNodes,
        ]);
      nodesToMeasure.tuples.push(...Object.values(prunedNodeTuples));

      nextEdges = updateEdgesForGraphRedirect(
        edges,
        prunedNodeIds,
        currentFlowStepId,
        nextAnswerNodeId,
        next?.nextStepId ?? ""
      );

      nextWorkingAnswers = prunedWorkingAnswers;
      setPendingUncommittedAnswer("");
      setCurrentFlowStepId(next?.nextStepId ?? cachedStepNode.id);
    }

    // if path won't change, but output edge will change, swap marked output edges
    if (!pathWillChange && currentOutputNodeId !== nextOutputNodeId) {
      nextEdges = nextEdges.map((edge) => {
        if (edge.source === currentOutputNodeId) edge = markEdgeUnvisited(edge);
        if (edge.source === nextOutputNodeId)
          edge = markEdgeVisited(edge, true);
        return edge;
      });
    }

    // if path won't change, but this is the current end node for the flow's progress,
    // -- mark output edge visited
    // -- update currentFlowStepId
    if (!pathWillChange && nextStepNode.id === currentFlowStepId) {
      nextEdges = nextEdges.map((edge) => {
        if (edge.source === nextOutputNodeId) {
          edge = markEdgeVisited(edge, true);
          setCurrentFlowStepId(edge.target);
        }
        return edge;
      });
    }

    // clear ephemeral editing state
    setRequestingCommitApproval(false);
    setIsEditingNode(false);
    setSelectedEditNodeId("");
    setCachedNodeTuple(null);

    // set next state with results
    setWorkingAnswers(nextWorkingAnswers);
    setEdges(nextEdges);
    setNodesToMeasure(nodesToMeasure);
  };

  // discards uncommitted answers from an actively edited node
  const handleDiscardChanges = () => {
    setIsEditingNode(false);
    setSelectedEditNodeId("");
    setPendingUncommittedAnswer("");
    setRequestingCommitApproval(false);

    // if we have a cached original tuple, send if off to measure and overwrite
    // any discarded changes
    if (cachedNodeTuple) {
      setNodesToMeasure({
        tuples: [cachedNodeTuple],
        context: FlowNodeRenderContext.SnapshotEditor,
      });
      setCachedNodeTuple(null);
    }
  };

  return (
    <>
      <FlowSnapshotContext.Provider
        value={{
          selectedEditNodeId,
          currentStepId: currentFlowStepId,
          currentStepHasChanged,
          onUpdateAnswer: handleUpdateAnswer,
          onRequestEditStep: handleRequestEditStep,
          onRequestCommitChanges: handleRequestCommitChanges,
          onDiscardChanges: handleDiscardChanges,
        }}
      >
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            overflow: "hidden",
          }}
        >
          <ReactFlow
            nodes={nodes}
            edges={edges}
            nodeTypes={snapshotEditorNodeTypes}
          >
            <NodeEditMask isEnabled={!!selectedEditNodeId} />
            <Background />
            <Controls />
          </ReactFlow>
        </div>

        {!!nodesToMeasure && (
          <NodeMeasuringContainer
            nodesToMeasure={nodesToMeasure}
            onNodesMeasured={onNodesMeasured}
          />
        )}

        {requestingCommitApproval && (
          <CommitApprovalModal
            isOpen={requestingCommitApproval}
            onApproveCommit={handleCommitChanges}
            onRequestClose={handleDiscardChanges}
          />
        )}
      </FlowSnapshotContext.Provider>
    </>
  );
};
