import { useEffect, useRef, useState } from "react";
import { Viewport } from "react-flow-renderer";
import { JourneyStage, JourneyStagePositions } from "src/graphql";
import styled from "styled-components";
import { DropLine } from "./DropLine";
import { SliderThumb } from "./SliderThumb";
import { StageLabel } from "./StageLabel";
import {
  clientXToToolbarX,
  getNextStagePositions,
  orderedGrabbableStages,
  orderedStages,
  templateXToToolbarX,
  toolbarXToTemplateX,
  XDims,
} from "./util";

const rulerHeight = 18;

const OuterContainer = styled.div`
  position: absolute;
  top: -10px;
  padding-top: 10px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  pointer-events: none;
  z-index: 9;
`;

const RulerContainer = styled.div`
  display: flex;
  position: relative;
  height: ${rulerHeight}px;
  width: 100%;
  background-color: rgb(240, 240, 240);
  border-bottom: 1px solid rgb(200, 200, 200);
  pointer-events: all;
`;

const LargeTicks = styled.div`
  width: 100%;
  height: calc(100% - 6px);
  position: absolute;
  top: 3px;
  background: repeating-linear-gradient(
    90deg,
    transparent 0px,
    transparent 14px,
    rgba(0, 0, 0, 0.3) 14px,
    rgba(0, 0, 0, 0.3) 15px
  );
`;

const SmallTicks = styled.div`
  width: 100%;
  height: calc(100% - 12px);
  position: absolute;
  top: 6px;
  background: repeating-linear-gradient(
    90deg,
    transparent 0px,
    transparent 4px,
    rgba(0, 0, 0, 0.3) 4px,
    rgba(0, 0, 0, 0.3) 5px,
    transparent 5px,
    transparent 9px,
    rgba(0, 0, 0, 0.3) 9px,
    rgba(0, 0, 0, 0.3) 10px,
    transparent 10px,
    transparent 15px
  );
`;

const ContentContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  z-index: 10;
`;

export type GrabbableJourneyStage = Exclude<
  JourneyStage,
  typeof JourneyStage.Precontemplation
>;

type JourneyStageToolbarProps = {
  viewport: Viewport;
  stagePositions: JourneyStagePositions;
  onChangeStagePositions: (stages: JourneyStagePositions) => void;
  onGrabStagePosition: (stage: GrabbableJourneyStage) => void;
  onDropStagePosition: () => void;
  /**
   * @default false
   */
  readOnly?: boolean;
};

export const JourneyStageToolbar = ({
  viewport,
  stagePositions,
  readOnly,
  onGrabStagePosition,
  onDropStagePosition,
  onChangeStagePositions,
}: JourneyStageToolbarProps) => {
  const rulerContainerRef = useRef<HTMLDivElement>(null);
  const observerRef = useRef<ResizeObserver | null>(null);
  const [grabbedStage, setGrabbedStage] =
    useState<GrabbableJourneyStage | null>(null);
  const [toolbarXDims, setToolbarXDims] = useState<XDims>({
    width: 0,
    x: 0,
  });
  const moveHandlerRef = useRef<((e: MouseEvent) => void) | null>(null);

  // on render, measure toolbar and setup resize observer
  useEffect(() => {
    if (!rulerContainerRef.current) return;

    observerRef.current = new ResizeObserver(() => {
      if (!rulerContainerRef.current) return;
      const { width, x } = rulerContainerRef.current.getBoundingClientRect();
      setToolbarXDims({ width, x });
    });

    observerRef.current.observe(rulerContainerRef.current);
  }, []);

  const handleMouseMove = (e: MouseEvent, stage: GrabbableJourneyStage) => {
    const toolbarX = clientXToToolbarX(e.clientX, toolbarXDims);
    const templateX = toolbarXToTemplateX(toolbarX, viewport);
    const nextPositions = getNextStagePositions(
      templateX,
      stage,
      stagePositions
    );

    onChangeStagePositions(nextPositions);
  };

  const handleDragStart = (stage: GrabbableJourneyStage) => {
    setGrabbedStage(stage);
    onGrabStagePosition(stage);
    const moveHandler = (e: MouseEvent) => {
      handleMouseMove(e, stage);
    };
    moveHandlerRef.current = moveHandler;
    window.addEventListener("mousemove", moveHandler);
    window.addEventListener("mouseup", handleDragEnd);
  };

  const handleDragEnd = () => {
    setGrabbedStage(null);
    onDropStagePosition();
    if (moveHandlerRef.current) {
      window.removeEventListener("mousemove", moveHandlerRef.current);
    }
    window.removeEventListener("mouseup", handleDragEnd);
  };

  return (
    <OuterContainer>
      <RulerContainer ref={rulerContainerRef}>
        <LargeTicks />
        <SmallTicks />
        <ContentContainer>
          <div
            style={{
              position: "relative",
              flexGrow: 1,
              display: "flex",
            }}
          >
            {/* Stage Labels */}
            {orderedStages.map((stage) => (
              <StageLabel
                key={`${stage}-label`}
                stage={stage}
                stagePositions={stagePositions}
              />
            ))}

            {/* Slider Thumbs */}
            {orderedGrabbableStages.map((stage) => (
              <SliderThumb
                key={`${stage}-thumb`}
                onMouseDown={() => handleDragStart(stage)}
                onMouseUp={handleDragEnd}
                isDragging={grabbedStage === stage}
                xPos={templateXToToolbarX(stagePositions[stage], viewport)}
                readOnly={readOnly}
              />
            ))}
          </div>

          {/* Drop Lines for each thumb */}
          {orderedGrabbableStages.map((stage) => (
            <DropLine
              key={`${stage}-drop-line`}
              xPos={templateXToToolbarX(stagePositions[stage], viewport)}
              isCurrent={grabbedStage === stage}
              isVisible={grabbedStage !== null}
            />
          ))}
        </ContentContainer>
      </RulerContainer>
    </OuterContainer>
  );
};
