import styled from "@emotion/styled";

export const OverflowFadeMask = styled.div<{ $visible: boolean }>`
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 50px;
  width: 100%;
  pointer-events: none;
  background: linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.9) 80%
  );
  transition: opacity 0.3s;
  opacity: ${({ $visible }) => ($visible ? "1" : "0")};
`;
