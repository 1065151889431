import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { AnswerType, DataIdWithAnswerTypeResponse } from "src/graphql";
import { GET_DATA_IDS_WITH_ANSWER_TYPE_BY_ORGANIZATION_ID } from "src/graphql/DataPoint/queries";

export const useGetOrganizationDataPoints = (organizationId?: string) => {
  const {
    data: dataResponse,
    loading,
    error,
  } = useQuery<{
    dataIdsWithAnswerTypesByOrganizationId: DataIdWithAnswerTypeResponse;
  }>(GET_DATA_IDS_WITH_ANSWER_TYPE_BY_ORGANIZATION_ID, {
    variables: { organizationId, skip: !organizationId },
  });

  const orgDataPoints = useMemo(() => {
    const orgDps = dataResponse?.dataIdsWithAnswerTypesByOrganizationId?.data;
    const options: DataPointPlaceholder[] = [];

    if (!orgDps) return options;
    for (const option of orgDps) {
      options.push({
        label: option.dataId,
        value: option.dataId,
        questionTitle: option.questionTitle,
        answerType: option.answerType,
        group: "Data Points",
      });
    }

    return [
      ...memberProfileDataPoints,
      ...options.sort((a, b) =>
        (a.label as string)?.localeCompare(b.label as string)
      ),
    ];
  }, [dataResponse]);

  if (loading) return { loading: true, error, data: memberProfileDataPoints };

  return { loading: false, error, data: orgDataPoints };
};

const Groups = {
  Profile: "Member Profile",
  DataPoint: "Data Points",
} as const;

type DataPointPlaceholder = {
  label: string;
  value: string;
  group: typeof Groups[keyof typeof Groups];
  required?: boolean;
  answerType?: AnswerType;
  questionTitle?: string;
};

export const memberProfileDataPoints: DataPointPlaceholder[] = [
  { value: "D1", label: "First Name", group: Groups.Profile, required: true },
  { value: "D2", label: "Last Name", group: Groups.Profile, required: true },
  { value: "D3", label: "Nick Name", group: Groups.Profile },
  { value: "D4", label: "Gender", group: Groups.Profile },
  { value: "D5", label: "Date of Birth", group: Groups.Profile },
  { value: "D6", label: "Address 1", group: Groups.Profile },
  { value: "D53", label: "Address 2", group: Groups.Profile },
  { value: "D7", label: "Phone", group: Groups.Profile, required: true },
  { value: "D13", label: "Email", group: Groups.Profile },
  { value: "D60", label: "Zip Code", group: Groups.Profile },
  { value: "D25", label: "Race", group: Groups.Profile },
  { value: "D26", label: "Spoken Languages", group: Groups.Profile },
  { value: "D31", label: "Primary Insurance Company", group: Groups.Profile },
  { value: "D70", label: "Secondary Insurance Company", group: Groups.Profile },
  { value: "D44", label: "Ethnicity", group: Groups.Profile },
  { value: "D45", label: "Medicaid Status", group: Groups.Profile },
  { value: "D46", label: "Medicare Status", group: Groups.Profile },
  { value: "D47", label: "Pronouns", group: Groups.Profile },
  { value: "D50", label: "Sexual Orientation", group: Groups.Profile },
  { value: "D48", label: "Diagnosis Codes", group: Groups.Profile },
  { value: "D8", label: "Sex", group: Groups.Profile },
  { value: "D68", label: "Pregnancy Status", group: Groups.Profile },
];
