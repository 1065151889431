import { Text } from "@mantine/core";
import { Link } from "react-router-dom";

type AuthFormLinkProps = {
  children: React.ReactNode;
  to?: string;
  onClick?: () => void;
};

export const AuthFormLink = ({ children, to, onClick }: AuthFormLinkProps) => (
  <Text align="right" variant="link" color="blue">
    <Link onClick={onClick} to={to ?? "#"}>
      {children}
    </Link>
  </Text>
);
