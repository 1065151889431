import { Button, Group, TextInput } from "@mantine/core";
import React from "react";
import { ArrowLeft, Save } from "react-feather";
import { Prompt, useHistory } from "react-router-dom";

export const BUILDER_HEADER_HEIGHT = 59;

type FlowTemplateBuilderHeaderProps = {
  title: string;
  canSubmit: boolean;
  isSubmitting: boolean;
  readOnly: boolean;
  onChangeTitle: (nextTitle: string) => void;
  onSubmit: () => void;
  placeholder?: string;
  disabledMessage?: string;
};

export const FlowTemplateBuilderHeader = ({
  title,
  canSubmit,
  isSubmitting,
  readOnly,
  onChangeTitle,
  onSubmit,
  placeholder,
  disabledMessage,
}: FlowTemplateBuilderHeaderProps) => {
  const history = useHistory();

  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeTitle(e.currentTarget.value);
  };

  return (
    <Group
      h={BUILDER_HEADER_HEIGHT}
      p={10}
      style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.2)", gap: "10px" }}
    >
      <Button
        leftIcon={<ArrowLeft size="18" />}
        onClick={() => history.goBack()}
      >
        Back
      </Button>

      <TextInput
        name="title"
        value={title}
        onChange={handleChangeTitle}
        placeholder={placeholder ?? "Enter Template Title..."}
        disabled={readOnly}
        required
        style={{ flexGrow: 1 }}
      />

      {!readOnly && (
        <Button
          leftIcon={<Save size="18" />}
          onClick={onSubmit}
          loading={isSubmitting}
          disabled={!title || !canSubmit}
        >
          Save Changes
        </Button>
      )}

      <Prompt
        when={canSubmit}
        message="You have unsaved changes. Are you sure you want to leave?"
      />
    </Group>
  );
};
