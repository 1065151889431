import React from "react";
import ReactDOM from "react-dom";
import { FirebaseOptions, initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import log from "loglevel";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { cleanupSocketConnection } from "./graphql";
import "./validation/yup-extensions";
import { ErrorBoundary, initInstrumentation } from "./libs/instrumentation";

initInstrumentation();

log.enableAll();

// Initialize Firebase for auth
const firebaseConfig: FirebaseOptions = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
  // The value of `databaseURL` depends on the location of the database
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
};
initializeApp(firebaseConfig);

// Firebase Auth listener to ensure Apollo subscriptions close on sign out
getAuth().onAuthStateChanged((user) => !user && cleanupSocketConnection());

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
