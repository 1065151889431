import { useFormikContext } from "formik";
import { useEffect } from "react";
import { Prompt } from "react-router-dom";

export interface DirtyLeavePromptProps {
  hasUnsavedChanges: boolean;
  setHasUnsavedChanges: (value: boolean) => void;
}

export function DirtyLeavePrompt({
  hasUnsavedChanges,
  setHasUnsavedChanges,
}: DirtyLeavePromptProps) {
  const { dirty } = useFormikContext();

  useEffect(() => {
    setHasUnsavedChanges(dirty);
  }, [dirty, setHasUnsavedChanges]);

  return (
    <Prompt
      when={hasUnsavedChanges}
      message="Are you sure you want to leave? You have unsaved changes."
    />
  );
}
