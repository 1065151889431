import { MantineThemeOverride } from "@mantine/core";

import { colors } from "./colors";
import { ActionIcon } from "./components/action-icon";
import { Button } from "./components/button";
import { Tabs } from "./components/tabs";
import { Text } from "./components/text";
import { Title } from "./components/title";
import { Switch } from "./components/switch";
import { Select } from "./components/select";
import { MultiSelect } from "./components/multi-select";

export const theme: MantineThemeOverride = {
  fontFamily: "Avenir-Roman, Avenir-Book, sans-serif",

  fontSizes: {
    xs: "12px",
    sm: "14px",
    md: "16px",
  },

  headings: {
    fontFamily: "KeepCalm-Medium, sans-serif",
    fontWeight: "normal",

    sizes: {
      h1: { fontSize: "24px", lineHeight: "33px" },
      h2: { fontSize: "20px", lineHeight: "27px" },
      h3: { fontSize: "20px", lineHeight: "27px" },
      h4: { fontSize: "20px", lineHeight: "27px" },
    },
  },

  colors,
  primaryColor: "green",
  primaryShade: 5,

  loader: "dots",

  components: {
    ActionIcon,
    Button,
    Tabs,
    Title,
    Text,
    Switch,
    Select,
    MultiSelect,
  },

  globalStyles: () => ({
    fieldset: { border: "none", padding: 0 },
  }),
};
