import { gql } from "@apollo/client";
import { NoteTemplateFields } from "./types";

export const GET_NOTE_TEMPLATES = gql`
  query GetNoteTemplates($input: GetNoteTemplatesInput!) {
    noteTemplates(input: $input) {
      success
      message
      data {
        ${NoteTemplateFields}
      }
    }
  }
`;

export const GET_NOTE_TEMPLATE = gql`
  query GetNoteTemplate($input: GetNoteTemplateInput!) {
    noteTemplate(input: $input) {
      success
      message
      data {
        ${NoteTemplateFields}
      }
    }
  }
`;
