import { gql } from "@apollo/client";
import { TitledLockingReferenceFields } from "./types";

export const GET_TITLED_LOCKING_REFERENCES = gql`
  query GetTitledLockingReferences($input: LockingReferencesAndOrgIdInput!) {
    titledReferences(input: $input) {
      success
      message
      data {
        ${TitledLockingReferenceFields}
      }
    }
  }
`;
