import { useEffect, useState } from "react";
import toast from "src/libs/toast";
import { Button } from "@mantine/core";

import { MultiInput } from "src/components/flow-display/QuestionDisplayCards";
import { DispositionMessages } from "src/constants/phonecall";
import {
  Disposition,
  PhoneCallIdentifierType,
  useMutationUpdatePhoneCallEntry,
} from "src/graphql";
import { useAuthContext } from "src/hooks";
import useTwilio from "src/hooks/useTwilio";
import log from "loglevel";
import { Call as TwilioCall } from "@twilio/voice-sdk";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../modal";

const getDispositionEnumValue = (disposition: string) =>
  Object.entries(DispositionMessages)
    .find(([_, d]) => d === disposition)
    ?.at(0) as Disposition;

// Call disposition test scenarios
// Incoming, call not received - DON'T SHOW
// Incoming, after accept member declines - SHOW
// Incoming, after accept member's client declines - SHOW
// Outgoing, call not received - SHOW
// Outgoing, after accept member declines - SHOW
// Outgoing, after accept member's client declines - SHOW
type CallDispositionProps = {
  conferenceFriendlyName: string;
  callOrganizationId: string;
  onSaved: () => void;
};

const CallDisposition = ({
  conferenceFriendlyName,
  callOrganizationId,
  onSaved,
}: CallDispositionProps) => {
  const { currentUser } = useAuthContext();
  const [updatePhoneCallEntry] = useMutationUpdatePhoneCallEntry();
  const [disposition, setDisposition] = useState<string>("");
  const [buttonDisabled, setButtonDisabled] = useState(false);

  async function onSubmit() {
    if (disposition.length <= 0) return log.log("No disposition");

    const dispositionEnumValue = getDispositionEnumValue(disposition);
    if (!dispositionEnumValue) return log.log("No disposition enum found");

    setButtonDisabled(true);

    try {
      const { data } = await updatePhoneCallEntry({
        variables: {
          input: {
            identifier: conferenceFriendlyName,
            identifierType: PhoneCallIdentifierType.ConferenceFriendlyName,
            disposition: dispositionEnumValue,
            userId: currentUser._id,
            organizationId: callOrganizationId,
          },
        },
      });

      if (data?.updatePhoneCallEntry.success) {
        onSaved();
        toast.success("Saved disposition");
      } else throw new Error(data?.updatePhoneCallEntry.message);
    } catch (error) {
      log.error(error);
      toast.error("Unable to create disposition");
      setButtonDisabled(false);
    }
  }

  return (
    <>
      <ModalHeader>Disposition</ModalHeader>

      <ModalBody>
        <MultiInput
          answerOptions={Object.values(DispositionMessages)}
          currentAnswer={disposition}
          setCurrentAnswer={setDisposition}
        />
      </ModalBody>

      <ModalFooter>
        <Button
          disabled={buttonDisabled || disposition === ""}
          onClick={onSubmit}
        >
          Submit
        </Button>
      </ModalFooter>
    </>
  );
};

export const GlobalCallDispositionModal = () => {
  const { call, incomingCall, lastCallOrgId, conferenceFriendlyName } =
    useTwilio();
  const [
    dispositionConferenceFriendlyName,
    setDispositionConferenceFriendlyName,
  ] = useState<undefined | string>();

  useEffect(() => {
    // If the call variable is truthy, that means a call is happening
    // For incoming calls, we want to show disposition only when they were accepted
    // For outgoing calls, we always want to show the disposition
    // Once the calls disconnect, we take a note of their call sid
    if (call) {
      if (call.direction === TwilioCall.CallDirection.Incoming) {
        call.once("accept", () =>
          call.once(
            "disconnect",
            () =>
              incomingCall?.phoneCallId &&
              setDispositionConferenceFriendlyName(
                incomingCall.conferenceFriendlyName
              )
          )
        );
      } else {
        call.once("disconnect", () =>
          setDispositionConferenceFriendlyName(conferenceFriendlyName)
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [call, conferenceFriendlyName]);

  return dispositionConferenceFriendlyName && !incomingCall ? (
    <Modal
      opened={!!dispositionConferenceFriendlyName}
      onClose={() => setDispositionConferenceFriendlyName(undefined)}
      closeOnEscape={true}
      closeOnClickOutside={false}
    >
      <CallDisposition
        conferenceFriendlyName={dispositionConferenceFriendlyName}
        callOrganizationId={lastCallOrgId ?? ""}
        onSaved={() => setDispositionConferenceFriendlyName(undefined)}
      />
    </Modal>
  ) : null;
};
