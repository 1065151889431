import { Button, Group, Stack, Text } from "@mantine/core";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  ReferralStatus,
  useMutationUpdateReferralStatusPublic,
} from "src/graphql";
import toast from "src/libs/toast";

export const ReferralStatusComponent = () => {
  const { referralId } = useParams<{ referralId: string }>();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const [mutationUpdateReferralStatus, { loading }] =
    useMutationUpdateReferralStatusPublic();

  const updateReferralStatus = async (status: ReferralStatus) => {
    try {
      const result = await mutationUpdateReferralStatus({
        variables: {
          input: {
            referralId,
            status,
          },
        },
      });
      if (!result.data?.updateReferralStatusPublic?.success) {
        throw new Error("Something went wrong");
      }

      setShowSuccessMessage(true);
    } catch (e) {
      toast.error("Something went wrong!");
    }
  };

  return (
    <Stack h="100%" w="100%" align="center" justify={"center"}>
      <Stack
        spacing="lg"
        align="center"
        justify="center"
        bg="white"
        p="50px"
        style={{ borderRadius: "8px" }}
      >
        {!showSuccessMessage && (
          <Text align="center" fz="lg">
            Accept or Reject referral
          </Text>
        )}
        {showSuccessMessage && (
          <Text align="center" fz="lg" color="green">
            Thank you for your input
          </Text>
        )}
        {!showSuccessMessage && (
          <Group align="center">
            <Button
              disabled={loading}
              onClick={() => updateReferralStatus(ReferralStatus.Accepted)}
              variant="filled"
            >
              Accept
            </Button>
            <Button
              disabled={loading}
              onClick={() => updateReferralStatus(ReferralStatus.Rejected)}
              color="red"
            >
              Reject
            </Button>
          </Group>
        )}
      </Stack>
    </Stack>
  );
};
