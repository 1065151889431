import { TabsStylesParams } from "@mantine/core";
import { MantineThemeComponents } from "@mantine/styles/lib/theme/types/MantineTheme";

export const Tabs: MantineThemeComponents[string] = {
  defaultProps: { radius: 0 },

  styles: (theme, params: TabsStylesParams, context) => ({
    tabsList: { borderColor: theme.fn.themeColor("gray", 1) },

    // override for light gray themed pill-variant tabs
    ...(context.variant === "pills" && params.color === "gray"
      ? {
          tab: {
            "&[data-active]": {
              backgroundColor: theme.colors.gray[1],
              color: "black",

              "&:hover": { backgroundColor: theme.colors.gray[2] },
            },
          },
        }
      : {}),
  }),
};
