import { Text } from "@mantine/core";
import { ErrorMessage } from "formik";

type StyledErrorMessageProps = {
  name: string;
};

export const StyledErrorMessage = ({ name }: StyledErrorMessageProps) => (
  <div style={{ marginTop: "0.25em" }}>
    <ErrorMessage
      name={name}
      render={(msg) => {
        if (typeof msg === "string") {
          return (
            <Text color="red" size="sm">
              {msg}
            </Text>
          );
        }
        return "";
      }}
    />
  </div>
);
