import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CreateSignatureRequestResponse,
  GetSignedDocumentResponse,
  MutationCreateSignatureRequestArgs,
  QuerySignedDocumentArgs,
} from "../schemaTypes";
import { CREATE_SIGNATURE_REQUEST } from "./mutations";
import { GET_SIGNED_DOCUMENT } from "./queries";

export const useMutationCreateSignatureRequest = () =>
  useMutation<
    { createSignatureRequest: CreateSignatureRequestResponse },
    MutationCreateSignatureRequestArgs
  >(CREATE_SIGNATURE_REQUEST, {});

export const useLazyQuerySignedDocument = () =>
  useLazyQuery<
    { signedDocument: GetSignedDocumentResponse },
    QuerySignedDocumentArgs
  >(GET_SIGNED_DOCUMENT, {
    fetchPolicy: "no-cache",
  });
