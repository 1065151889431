import { gql } from "@apollo/client";

export const REQUEST_PASSWORD_RESET = gql`
  query RequestPasswordReset($email: String!) {
    requestPasswordReset(email: $email) {
      success
      message
    }
  }
`;

export const RESEND_SIGNUP_EMAIL = gql`
  query ResendSignupEmail($email: String!) {
    resendSignupEmail(email: $email) {
      success
      message
    }
  }
`;
