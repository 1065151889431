import { Stack, Box, Button, Group, Switch } from "@mantine/core";
import { useMemo, useState } from "react";
import { useToggle } from "@mantine/hooks";

import { CreateNoteInput, GetNotesInput, Note } from "src/graphql";
import { SectionHeader } from "src/components";

import { useAuthContext } from "../../hooks/useAuthContext";
import { CreateNoteModal } from "./CreateNoteModal";
import { Notes } from "src/hooks";
import { Plus } from "react-feather";
import { CreateNoteInlineForm } from "./CreateNoteInlineForm";

interface NotesContainerProps {
  // Functional options
  listOptions: Omit<GetNotesInput, "organizationId">;
  createOptions: Omit<
    CreateNoteInput,
    "content" | "authorId" | "organizationId"
  >;

  // Styling-related props
  noHorizontalPadding?: boolean;
  hideTitle?: boolean;

  // Behavior flags and configurations
  multiMemberActivity?: boolean;
  readonly?: boolean;
  showFilters?: boolean;
  hideAddButton?: boolean;
  isMemberNotesContainer?: boolean;
  memberPageReadOnly?: boolean;
  inlineEdit?: boolean;

  // State control
  notesEditorOpen?: boolean;
  setNotesEditorOpen?: (open: boolean) => void;

  activityId?: string; // Activity reference
}

export const NotesContainer = ({
  listOptions,
  createOptions,
  multiMemberActivity = false,
  readonly = false,
  showFilters = true,
  hideAddButton = false,
  isMemberNotesContainer = false,
  activityId,
  memberPageReadOnly,
  inlineEdit = false,
  noHorizontalPadding = false,
  hideTitle = false,
  notesEditorOpen,
  setNotesEditorOpen,
}: NotesContainerProps) => {
  const { selectedOrganizationId, currentUser } = useAuthContext();
  const [showEditor, setShowEditor] = useState(false);
  const [editingNote, setEditingNote] = useState<Note | null>(null);
  const [viewAllNotes, setViewAllNotes] = useToggle();

  const handleEditClick = (note: Note) => {
    setEditingNote(note);
    setShowEditor(true);
    if (!inlineEdit) {
      setNotesEditorOpen && setNotesEditorOpen(true);
    }
  };

  const handleAddNoteClickInternal = () => {
    setEditingNote(null);
    setShowEditor(true);
    if (!inlineEdit) {
      setNotesEditorOpen && setNotesEditorOpen(true); // Open the modal for note creation
    }
  };

  const closeEditor = () => {
    setEditingNote(null);
    setShowEditor(false);
    if (!inlineEdit) {
      setNotesEditorOpen && setNotesEditorOpen(false);
    }
  };

  const updatedListOptions = useMemo(() => {
    return {
      ...listOptions,
      activityId: viewAllNotes ? undefined : activityId, // Remove activityId if viewAllNotes is true
    };
  }, [listOptions, viewAllNotes, activityId]);

  return (
    <Stack spacing="xs">
      {/* Render the Notes component */}

      {!hideTitle && (
        <SectionHeader noGap>
          <Group position="apart">
            <span>{isMemberNotesContainer ? "Member Notes" : "Notes"}</span>
            {activityId && (
              <Switch
                ml="auto"
                label="View all"
                size="xs"
                checked={viewAllNotes}
                onChange={(e) => setViewAllNotes(e.currentTarget.checked)}
              />
            )}
            {/* Add Note Button in the Header */}
            {!memberPageReadOnly && !readonly && !hideAddButton && (
              <Button
                compact
                color="green"
                variant="subtle"
                onClick={handleAddNoteClickInternal}
                leftIcon={<Plus size={16} />}
                data-testid="create-note"
              >
                Add Note
              </Button>
            )}
          </Group>
        </SectionHeader>
      )}
      {/* Create/Edit Note Modal */}
      {inlineEdit
        ? showEditor && (
            <CreateNoteInlineForm
              memberId={createOptions.memberId}
              activityId={activityId}
              existingNote={editingNote}
              onClose={closeEditor}
              multiMemberActivity={multiMemberActivity}
              organizationId={selectedOrganizationId}
              userId={currentUser._id}
            />
          )
        : (notesEditorOpen || showEditor) && (
            <CreateNoteModal
              isOpen={notesEditorOpen ?? showEditor}
              organizationId={selectedOrganizationId}
              userId={currentUser._id}
              afterUpdateSuccess={closeEditor}
              onRequestClose={closeEditor}
              memberId={createOptions.memberId}
              existingNote={editingNote}
              activityId={editingNote?.activityId ?? listOptions.activityId}
              externalResourceId={createOptions.externalResourceId}
              externalResourceContactId={
                createOptions.externalResourceContactId
              }
              multiMemberActivity={multiMemberActivity}
            />
          )}
      <Box
        py="xs"
        px={noHorizontalPadding ? 0 : "xs"}
        h="100%"
        style={{ overflowY: "auto" }}
      >
        <Notes
          listOptions={updatedListOptions}
          createOptions={createOptions}
          multiMemberActivity={multiMemberActivity}
          readonly={readonly}
          showFilters={showFilters}
          handleEditClick={handleEditClick}
        />
      </Box>
    </Stack>
  );
};
