import { Button, Select } from "@mantine/core";
import toast from "src/libs/toast";

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalSubHeader,
} from "src/components";
import {
  Consent,
  ConsentMethod,
  ConsentTemplate,
  useMutationCreateOrUpdateConsent,
  useQueryConsentTemplates,
} from "src/graphql";
import { FormikProps } from "formik";
import { useMemo, useRef, useState } from "react";
import { ConsentFormType, isConsentFormValid } from "src/utils/consent";
import { ConsentForm, NO_CONSENT_TEMPLATE_ID } from "./ConsentForm";

interface ConsentModalProps {
  modalIsOpen: boolean;
  closeModal: () => void;
  onConsentCreate?: () => void;
  consent?: Consent;
  organizationId: string;
  memberId: string;
  consentTemplateId?: string;
}

export const ConsentModal = ({
  modalIsOpen,
  closeModal,
  onConsentCreate,
  consent,
  organizationId,
  memberId,
  consentTemplateId = noTemplateOption.value,
}: ConsentModalProps) => {
  const { data: consentTemplatesResponse, loading: consentsTemplatesLoading } =
    useQueryConsentTemplates(organizationId);
  const [selectedTemplateId, setSelectedTemplateId] =
    useState(consentTemplateId);
  const formikRef = useRef<FormikProps<ConsentFormType>>(null);
  const [submissionPending, setSubmissionPending] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);

  const consentTemplateOptions = useMemo(
    () => [
      ...(
        consentTemplatesResponse?.consentTemplatesByOrganizationId.data ?? []
      ).map((ct) => ({
        label: ct.name,
        value: ct._id,
        group: "Templates",
      })),
      noTemplateOption,
    ],
    [consentTemplatesResponse]
  );
  // prepare values for form/local state
  const templatesById = useMemo(
    () =>
      (
        consentTemplatesResponse?.consentTemplatesByOrganizationId.data ?? []
      ).reduce(
        (byId, template) => ({
          ...byId,
          [template._id]: template,
        }),
        {} as Record<string, ConsentTemplate>
      ),
    [consentTemplatesResponse]
  );

  const [mutationConsent] = useMutationCreateOrUpdateConsent();

  const handleClose = () => {
    formikRef.current?.resetForm();
    setSelectedTemplateId(noTemplateOption.value);
    closeModal();
  };

  const handleSubmit = async (values: ConsentFormType) => {
    /* validate consent method */
    if (!isConsentFormValid(values)) {
      return;
    }
    const { method, ...rest } = values;

    try {
      setSubmissionPending(true);
      const res = await mutationConsent({
        variables: {
          input: {
            ...rest,
            description: rest.description ?? undefined,
            consentMethod: values.method.value as ConsentMethod,
            _id: consent?._id,
            consentTemplateId:
              selectedTemplateId === noTemplateOption.value
                ? undefined
                : selectedTemplateId,
            isActive: true,
            organizationId,
            memberId,
          },
        },
      });
      if (
        !res.data?.createOrUpdateConsent?.success ||
        !res.data.createOrUpdateConsent.data
      )
        throw new Error(res.data?.createOrUpdateConsent.message);

      setSubmissionPending(false);
      onConsentCreate?.();
      handleClose();

      toast.success(res.data?.createOrUpdateConsent?.message ?? "Success!");
    } catch (error) {
      const defaultErrorMessage = "Failed! Something went wrong.";

      toast.error(
        error instanceof Error
          ? error.message ?? defaultErrorMessage
          : defaultErrorMessage
      );

      setSubmissionPending(false);
    }
  };

  return (
    <Modal opened={modalIsOpen} onClose={handleClose}>
      <ModalHeader withSubHeader>
        {consent ? "Edit" : "Create"} Consent
      </ModalHeader>
      {/* template select */}
      <ModalSubHeader>
        <Select
          label="Template"
          disabled={consentsTemplatesLoading || Boolean(consent)}
          defaultValue={
            consentTemplateId !== noTemplateOption.value
              ? consentTemplateId
              : consent?.consentTemplateId ?? noTemplateOption.value
          }
          data={consentTemplateOptions}
          onChange={(selected) => selected && setSelectedTemplateId(selected)}
        />
      </ModalSubHeader>

      <ModalBody spacing="md">
        <ConsentForm
          onSubmit={handleSubmit}
          innerRef={formikRef}
          consent={consent}
          selectedTemplate={templatesById[selectedTemplateId]}
          organizationId={organizationId}
          memberId={memberId}
          onValidStateChange={setCanSubmit}
        />
      </ModalBody>

      <ModalFooter>
        <Button
          type="button"
          color="red"
          variant="outline"
          onClick={handleClose}
        >
          Cancel
        </Button>

        <Button
          onClick={formikRef.current?.submitForm}
          disabled={!canSubmit}
          loading={submissionPending}
        >
          {consent ? "Update" : "Create"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const noTemplateOption = {
  label: "None",
  value: NO_CONSENT_TEMPLATE_ID,
};
