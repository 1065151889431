import { ActionIcon, Center, Dialog, Group } from "@mantine/core";
import { ArrowDownRight, ArrowLeft, Phone } from "react-feather";

import { useAuthContext } from "src/hooks";
import useTwilio from "src/hooks/useTwilio";
import { TwilioWidgetSelect } from "./select";
import { TwilioWidgetTabs } from "./tabs";

export const TwilioPhoneWidget = () => {
  const { selectedOrganization } = useAuthContext();

  const { widget, isCallInProgress, isIncomingCallInProgress } = useTwilio();

  const clearMember = () => {
    widget.setPhoneNumber(undefined);
  };

  if (!selectedOrganization.callerId) {
    return <></>;
  }

  return (
    <>
      <ActionIcon
        color="green"
        variant="filled"
        p="xl"
        radius="xl"
        pos="absolute"
        right={50}
        bottom={50}
        onClick={() => widget.expand(true)}
        style={{ zIndex: 199 }}
        data-testid="twilio-widget"
      >
        <Center>
          <Phone />
        </Center>
      </ActionIcon>

      <Dialog
        p={0}
        opened={widget.expanded}
        size="lg"
        style={{ overflow: "hidden" }}
        position={{ bottom: 50, right: 50 }}
      >
        <Group spacing="xs" p="xs" bg="green">
          {widget.phoneNumber &&
            !isCallInProgress &&
            !isIncomingCallInProgress && (
              <ActionIcon
                color="black.0"
                variant="transparent"
                size="sm"
                onClick={clearMember}
              >
                <ArrowLeft />
              </ActionIcon>
            )}

          <ActionIcon
            color="black.0"
            variant="transparent"
            size="sm"
            onClick={() => widget.expand(false)}
          >
            <ArrowDownRight />
          </ActionIcon>
        </Group>

        {widget.phoneNumber ? <TwilioWidgetTabs /> : <TwilioWidgetSelect />}
      </Dialog>
    </>
  );
};
