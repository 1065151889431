import { gql } from "@apollo/client";
import { certificationFields, certificationTemplateFields } from "./types";

export const GET_CERTIFICATION_TEMPLATES = gql`
  query GetCertificationTemplates($organizationId: ID!, $showDeleted: Boolean) {
    certificationTemplatesByOrganizationId(organizationId: $organizationId, showDeleted: $showDeleted) {
      success
      message
      data {
        ${certificationTemplateFields}
      }
    }
  }
`;

export const GET_CERTIFICATIONS = gql`
  query GetCertificationsByUserId($organizationId: ID!, $userId: ID!, $showDeleted: Boolean) {
    certificationsByUserId(organizationId: $organizationId, userId: $userId, showDeleted: $showDeleted) {
      success
      message
      data {
        ${certificationFields}
      }
    }
  }
`;
