import { Button, Stack, Text } from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { useState } from "react";

import { Select } from "@mantine/core";
import { ModalBody, ModalFooter, ModalHeader } from "src/components";

import { LoaderComponent, ModalSubHeader, StyledLabel } from "src/components";
import { ScheduleActivityInput, useQueryReferralTemplate } from "src/graphql";
import { useAuthContext } from "src/hooks";
import { SelectOption } from "src/types";

interface CreateReferralInputProps {
  templateId: string;
  assignableUserOptions: SelectOption<string>[];
  assignableUsersLoading: boolean;
  completeFunction: (scheduleInfo: ScheduleActivityInput) => void;
  onboardingToken?: string;
}

export const CreateReferralActionModalInput: React.FC<CreateReferralInputProps> =
  ({
    completeFunction,
    templateId,
    assignableUserOptions,
    assignableUsersLoading,
    onboardingToken,
  }) => {
    const { currentUser, selectedOrganizationId } = useAuthContext();
    const [dateTime, setDateTime] = useState<Date | null>(null);
    const [selectedUserId, setSelectedUserId] = useState<string | null>(
      onboardingToken ? null : currentUser?._id ?? null
    );
    const { data: referralTemplateResponse, loading: referralTemplateLoading } =
      useQueryReferralTemplate(
        selectedOrganizationId,
        templateId,
        onboardingToken
      );

    return (
      <>
        <ModalHeader withSubHeader>Create Referral</ModalHeader>

        {referralTemplateLoading && <LoaderComponent />}

        {!referralTemplateLoading && referralTemplateResponse && (
          <>
            <ModalSubHeader>
              {onboardingToken && (
                <Stack spacing="sm">
                  <Text>
                    Please select a time to schedule a call from our
                    organization regarding:
                  </Text>
                  <Text>
                    {referralTemplateResponse.referralTemplate.data?.title}
                  </Text>
                </Stack>
              )}

              {!onboardingToken &&
                `Please assign a user and select a time to schedule this member's activity for "${referralTemplateResponse.referralTemplate.data?.title}" referral.`}
            </ModalSubHeader>

            <ModalBody spacing="sm">
              {assignableUsersLoading && <LoaderComponent />}

              {!assignableUsersLoading && (
                <>
                  {/* Don't show user assignment in the member self-administered "onboarding" context */}
                  {!onboardingToken && (
                    <div>
                      <StyledLabel>Assigned User (optional)</StyledLabel>
                      <Select
                        data={assignableUserOptions}
                        value={selectedUserId}
                        disabled={assignableUserOptions.length <= 1}
                        onChange={setSelectedUserId}
                      />
                    </div>
                  )}

                  <div>
                    <StyledLabel>Time *</StyledLabel>
                    <DateTimePicker
                      value={dateTime}
                      onChange={setDateTime}
                      placeholder="Select a time"
                      popoverProps={{ withinPortal: true }}
                    />
                  </div>
                </>
              )}
            </ModalBody>
          </>
        )}

        <ModalFooter>
          <Button
            disabled={
              !dateTime || assignableUsersLoading || referralTemplateLoading
            }
            onClick={() => {
              // null case is impossible given the above check
              completeFunction({
                activityTime: dateTime?.toISOString() ?? "",
                activityTemplateId:
                  referralTemplateResponse?.referralTemplate.data
                    ?.targetTemplateId ?? "",
                userId: selectedUserId ?? undefined,
              });
              setDateTime(null);
            }}
          >
            Submit
          </Button>
        </ModalFooter>
      </>
    );
  };
