import { gql } from "@apollo/client";
import { GoalTemplateFields } from "./types";

export const CREATE_GOAL_TEMPLATE = gql`
  mutation CreateGoalTemplate ($input: GoalTemplateInput!) {
    createGoalTemplate(input: $input) {
      success
      message
      data {
        ${GoalTemplateFields}
      }
    }
  }
`;

export const UPDATE_GOAL_TEMPLATE = gql`
  mutation UpdateGoalTemplate ($input: UpdateGoalTemplateInput!) {
    updateGoalTemplate(input: $input) {
      success
      message
      data {
        ${GoalTemplateFields}
      }
    }
  }
`;

export const DELETE_GOAL_TEMPLATE = gql`
  mutation DeleteGoalTemplate ( $input: DeleteGoalTemplateInput!) {
    deleteGoalTemplate(input: $input) {
      success
      message
      data {
        ${GoalTemplateFields}
      }
    }
  }
`;
