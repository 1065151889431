import { ActionIcon, ActionIconProps } from "@mantine/core";
import { PolymorphicComponentProps } from "@mantine/utils";
import { useEffect } from "react";
import { Pause, Play } from "react-feather";

export enum PlayState {
  PLAYING,
  LOADING,
  PAUSED,
  ENDED,
}

type AudioButtonProps = Omit<
  PolymorphicComponentProps<"button", ActionIconProps>,
  "onClick"
> & {
  srcId: string;
  isSelected: boolean;
  playing: boolean;
  onTogglePlayState: () => void;
  onSelectKey: (srcId: string) => void;
};

type AudioElementProps = React.HTMLProps<HTMLAudioElement> & {
  onPlayStateChanged: (state: PlayState) => void;
  innerRef: React.RefObject<HTMLAudioElement>;
};

export const AudioElement = ({
  onPlayStateChanged,
  innerRef,
  ...rest
}: AudioElementProps) => {
  useEffect(() => {
    innerRef.current?.addEventListener("play", () =>
      onPlayStateChanged(PlayState.PLAYING)
    );
    innerRef.current?.addEventListener("pause", () =>
      onPlayStateChanged(PlayState.PAUSED)
    );
    innerRef.current?.addEventListener("ended", () =>
      onPlayStateChanged(PlayState.ENDED)
    );
    innerRef.current?.addEventListener("loadstart", () =>
      onPlayStateChanged(PlayState.LOADING)
    );
  }, [onPlayStateChanged, innerRef]);

  return <audio ref={innerRef} {...rest} />;
};

export const AudioButton = ({
  srcId,
  isSelected,
  playing,
  onTogglePlayState,
  onSelectKey,
  ...rest
}: AudioButtonProps) => {
  return (
    <ActionIcon
      {...rest}
      onClick={isSelected ? onTogglePlayState : () => onSelectKey(srcId)}
    >
      {!isSelected && <Play size={16} color="#3EAE83" fill="#3EAE83" />}

      {isSelected &&
        (playing ? (
          <Pause size={16} color="#3EAE83" fill={"#3EAE83"} />
        ) : (
          <Play size={16} color="#3EAE83" fill={"#3EAE83"} />
        ))}
    </ActionIcon>
  );
};
