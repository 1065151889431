import { useEffect, useState } from "react";
import {
  getAuth,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  MultiFactorResolver,
} from "firebase/auth";
import { Button, Stack, Title, Text, PinInput, Group } from "@mantine/core";
import { Formik, useField } from "formik";
import "react-phone-number-input/style.css";
import * as Yup from "yup";
import { DefaultResponse } from "src/graphql";
import { AuthFlowState } from "./AuthFlowContainer";
import { LoaderComponent } from "../loader";
import { AuthFormLink } from "./AuthFormLink";
import { useRecaptchaVerifier } from "src/hooks";
import { MFA_ERRORS } from "src/constants/errors";
import { FormikInputBaseProps } from "../input/FormikInputWrapper";

enum TwoFactorLoginState {
  SessionRequired,
  VerificationRequired,
  RecentLoginRequired,
  Complete,
}

type TwoFactorLoginFormProps = {
  mfaResolver: MultiFactorResolver | null;
  setAuthFlowState: (state: AuthFlowState) => void;
  onUserAuthenticated: () => void;
  userEmail?: string;
};

export const TwoFactorLoginForm = ({
  mfaResolver,
  setAuthFlowState,
  onUserAuthenticated,
  userEmail,
}: TwoFactorLoginFormProps) => {
  const auth = getAuth();
  const { recaptchaContainerRef, recaptchaVerifierRef } = useRecaptchaVerifier(
    auth,
    userEmail
  );
  const [verificationId, setVerificationId] = useState("");
  const [twoFactorState, setTwoFactorState] = useState(
    TwoFactorLoginState.SessionRequired
  );
  const [signinMfaError, setSigninMfaError] = useState<string | null>(null);

  const handleSignOut = () => {
    auth.signOut();
    setAuthFlowState(AuthFlowState.LoggedOut);
  };

  // handles sending SMS and setting verificationId
  useEffect(() => {
    if (!mfaResolver) {
      return setAuthFlowState(AuthFlowState.LoggedOut);
    }

    const getVerificationId = async () => {
      if (
        twoFactorState === TwoFactorLoginState.SessionRequired &&
        !verificationId
      ) {
        if (!recaptchaVerifierRef.current) return { success: false }; // should never happen

        const phoneAuthProvider = new PhoneAuthProvider(auth);
        const phoneInfoOptions = {
          // will need to be expanded if we ever offer more than 1 factor
          multiFactorHint: mfaResolver.hints[0],
          session: mfaResolver.session,
        };

        const verificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          recaptchaVerifierRef.current
        );

        setVerificationId(verificationId);
        setTwoFactorState(TwoFactorLoginState.VerificationRequired);
      }
    };

    getVerificationId();
  }, [
    auth,
    mfaResolver,
    twoFactorState,
    verificationId,
    setAuthFlowState,
    recaptchaVerifierRef,
  ]);
  const getMfaErrorText = (authError: string) => {
    if (authError === "auth/invalid-verification-code") {
      return MFA_ERRORS.INVALID_CODE;
    } else if (authError === "auth/session-expired") {
      return MFA_ERRORS.SESSION_EXPIRED;
    }
    return MFA_ERRORS.GENERIC_ERROR;
  };
  // handles verifying one-time code entered by user from SMS
  const handleVerifyMFA = async (verificationCode: string) => {
    if (!mfaResolver) {
      setAuthFlowState(AuthFlowState.LoggedOut);
      return { success: false };
    }

    try {
      const credential = PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );

      const mfaAssertion = PhoneMultiFactorGenerator.assertion(credential);
      await mfaResolver.resolveSignIn(mfaAssertion);
      setTimeout(onUserAuthenticated, 0);

      return { success: true };
      // eslint-disable-next-line
    } catch (e: any) {
      setSigninMfaError(e.code);
      //console.log(e.code);
      return { success: false, message: e.code };
    }
  };

  return (
    <>
      {signinMfaError !== null && (
        <Text color="red" align="center" mb="sm">
          {getMfaErrorText(signinMfaError)}
        </Text>
      )}
      {/* show loading while mfa session inits */}
      {twoFactorState === TwoFactorLoginState.SessionRequired && (
        <LoaderComponent />
      )}

      {/* once session established, show one-time SMS code verification form */}
      {twoFactorState === TwoFactorLoginState.VerificationRequired && (
        <VerificationForm
          handleSignOut={handleSignOut}
          handleVerify={handleVerifyMFA}
          setTwoFactorState={setTwoFactorState}
        />
      )}

      {/* sign out user if Firebase requires more recent signin */}
      {twoFactorState === TwoFactorLoginState.RecentLoginRequired && (
        <>
          <p style={{ textAlign: "center", marginBottom: "25px" }}>
            This authentication session has expired; please sign in again to
            continue!
          </p>
          <Button fullWidth onClick={handleSignOut}>
            Back To Login
          </Button>
        </>
      )}

      <div ref={recaptchaContainerRef} />
    </>
  );
};

/**
 * Verification Form
 */

const initialVerificationValues = {
  verificationCode: "",
};

const VerificationSchema = Yup.object({
  verificationCode: Yup.string().required(),
});

type VerificationFormProps = {
  handleSignOut: () => void;
  handleVerify: (verificationCode: string) => Promise<DefaultResponse>;
  setTwoFactorState: (step: TwoFactorLoginState) => void;
};

const VerificationForm = ({
  handleSignOut,
  handleVerify,
  setTwoFactorState,
}: VerificationFormProps) => (
  <Stack spacing="sm">
    <Title order={5} align="center">
      Two-Factor Verification
    </Title>

    <Text align="center" mb="lg">
      A one-time verification code has been sent to your registered two-factor
      phone number.
    </Text>

    <Formik
      initialValues={initialVerificationValues}
      validateOnChange={true}
      enableReinitialize={true}
      validationSchema={VerificationSchema}
      onSubmit={async (values, { setSubmitting, setFieldError }) => {
        setSubmitting(true);
        const result = await handleVerify(values.verificationCode);
        setSubmitting(false);
        if (result.success) {
          setTwoFactorState(TwoFactorLoginState.Complete);
        } else {
          setFieldError("verificationCode", result.message);
        }
      }}
    >
      {({ isValid, isSubmitting, handleSubmit, submitForm }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Stack spacing="sm">
              <Group position="center" mb="xs">
                <FormikPinInput
                  name="verificationCode"
                  onComplete={submitForm}
                />
              </Group>

              <Button disabled={!isValid} loading={isSubmitting} type="submit">
                Submit
              </Button>

              <AuthFormLink onClick={handleSignOut}>Sign Out</AuthFormLink>
            </Stack>
          </form>
        );
      }}
    </Formik>
  </Stack>
);

type FormikPinInputProps = FormikInputBaseProps<string> & {
  onComplete?: () => void;
};

const FormikPinInput = (props: FormikPinInputProps) => {
  const [field, , helpers] = useField(props);

  return (
    <PinInput
      autoFocus
      oneTimeCode
      inputType="text"
      type="number"
      length={6}
      value={field.value}
      onChange={(value) => helpers.setValue(value)}
      onComplete={props.onComplete}
    />
  );
};
