import { Dispatch, ReactNode } from "react";
import { AnswerType, Question } from "src/graphql/schemaTypes";
import { Button, Card, Stack, Text } from "@mantine/core";
import {
  BooleanInput,
  MultiChoiceInput,
  NumberInput,
  TextInput,
  MultiInput,
  DateInput,
} from "./QuestionDisplayCards";
import { LoaderComponent } from "../loader";
import { useMediaQuery } from "@mantine/hooks";

export type QuestionProps = {
  loading?: boolean;
  question: Question | undefined;
  answer: string;
  setAnswer: Dispatch<React.SetStateAction<string>>;
  onSubmit: () => void;
  submitLoading?: boolean;
  completedView?: ReactNode;
};

export const QuestionWidget = ({
  loading = false,
  question,
  answer,
  setAnswer,
  onSubmit,
  submitLoading = false,
  completedView,
}: QuestionProps) => {
  const isSmallScreen = useMediaQuery("(max-width: 768px)");

  const isSubmitDisabled =
    answer === "" && question?.answerType !== AnswerType.ReadOnlyText;

  const completedComponent = loading ? (
    <LoaderComponent />
  ) : (
    <Stack>
      <Text p="md">Flow Complete!</Text>
      {completedView}
    </Stack>
  );

  return (
    <Card
      m={isSmallScreen ? "20px 0" : "20px"}
      withBorder
      miw={isSmallScreen ? 300 : 400}
      maw="1100px"
      pt={0}
      style={{ overflowY: "unset", alignSelf: "center" }}
    >
      {question === undefined ? (
        completedComponent
      ) : (
        <>
          <Card.Section bg="#F7F7F7" withBorder style={{ borderTop: "none" }}>
            <Text
              ta="center"
              fz="20px"
              p="10px"
              fw={700}
              style={{ wordBreak: "break-word" }}
            >{`${question.questionTitle}`}</Text>
          </Card.Section>

          <Text
            ta="center"
            fz="16px"
            p="10px"
            mb="20px"
            style={{ wordBreak: "break-word" }}
            dangerouslySetInnerHTML={{ __html: question.questionText }}
          />

          {(() => {
            switch (question.answerType) {
              case AnswerType.Boolean:
                return (
                  <BooleanInput
                    setCurrentAnswer={setAnswer}
                    currentAnswer={answer}
                  />
                );
              case AnswerType.Number:
                return (
                  <NumberInput
                    setCurrentAnswer={setAnswer}
                    currentAnswer={answer}
                  />
                );
              case AnswerType.Text:
                return (
                  <TextInput
                    setCurrentAnswer={setAnswer}
                    currentAnswer={answer}
                  />
                );
              case AnswerType.MultiChoice:
                return (
                  <MultiChoiceInput
                    setCurrentAnswer={setAnswer}
                    answerOptions={question?.answerOptions ?? []}
                    currentAnswer={answer}
                  />
                );
              case AnswerType.Multi:
                return (
                  <MultiInput
                    setCurrentAnswer={setAnswer}
                    answerOptions={question?.answerOptions ?? []}
                    currentAnswer={answer}
                  />
                );
              case AnswerType.Date:
                return (
                  <DateInput
                    key={question._id}
                    setCurrentAnswer={setAnswer}
                    currentAnswer={answer}
                  />
                );
              case AnswerType.ReadOnlyText:
                return null;
              default:
                return <Text p="md">Nothing here</Text>;
            }
          })()}
          <Button
            display="block"
            miw={"120px"}
            mt="20px"
            ml="auto"
            mr="auto"
            onClick={onSubmit}
            disabled={isSubmitDisabled}
            loading={submitLoading}
          >
            Next
          </Button>
        </>
      )}
    </Card>
  );
};
