import { TableHeader } from "src/components";

type TemplatesTableHeaderProps = {
  placeholder?: string;
  setFilterText: (nextText: string) => void;
  actionButtons?: React.ReactElement[];
};

export const TemplatesTableHeader = ({
  setFilterText,
  placeholder = "By Name",
  actionButtons = [],
}: TemplatesTableHeaderProps) => {
  return (
    <TableHeader
      placeholder={placeholder}
      setFilterText={(nextText) => setFilterText(nextText)}
      actionButtons={actionButtons}
    />
  );
};
