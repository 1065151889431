import { Group, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { Check } from "react-feather";

type CustomStepperProps = {
  steps: string[];
  activeStep: number;
};

export const CustomStepper = ({ steps, activeStep }: CustomStepperProps) => {
  const isMobile = useMediaQuery("(max-width: 780px)");

  return (
    <Group position="apart" spacing="35px" noWrap mb="lg">
      {steps.map((step, index) => {
        const isActiveStep = activeStep === index;
        const isStepCompleted = index < activeStep;

        return (
          <Group key={step + index} spacing="xs" align="center">
            <div
              style={{
                border: `2px solid  ${
                  isActiveStep || isStepCompleted
                    ? "var(--color-pear-green)"
                    : "#f1f3f5"
                }`,
                backgroundColor: "#f1f3f5",
                width: "33px",
                height: "33px",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {isStepCompleted ? (
                <div
                  style={{
                    backgroundColor: "var(--color-pear-green)",
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                    borderColor: "#f1f3f5",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Check size="22px" strokeWidth="4" color="white" />
                </div>
              ) : (
                <Text fz="16px" fw={700} color="#495057">
                  {index + 1}
                </Text>
              )}
            </div>
            {!isMobile && (
              <Text
                size="sm"
                color="dimmed"
                align="left"
                style={{ lineHeight: 1 }}
              >
                {step}
              </Text>
            )}
          </Group>
        );
      })}
    </Group>
  );
};
