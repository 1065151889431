import React, { useState } from "react";
import styled from "styled-components";
import imagePearLogo from "src/assets/images/PearSuite-Horizontal.png";
import { AuthSplash } from "./AuthSplash";
import { Switch, Route } from "react-router-dom";
import { LoaderComponent } from "../loader";
import { LoginForm } from "./LoginForm";
import { SignupForm } from "./SignupForm";
import { Button, Stack, Text } from "@mantine/core";
import { User as FirebaseUser, MultiFactorResolver } from "firebase/auth";
import { RequestPasswordResetForm } from "./RequestResetPasswordForm";
import { ResetPasswordForm } from "./ResetPasswordForm";
import { TwoFactorEnrollmentForm } from "./TwoFactorEnrollmentForm";
import { TwoFactorLoginForm } from "./TwoFactorLoginForm";
import OnboardingRoute from "src/pages/onboarding";
import ReferralStatusRoute from "src/pages/referral-status";
import SubscriptionPlans from "src/pages/plans";

const StyledContainer = styled.div`
  background-color: white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  -webkit-box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.1);
`;

export const LogoContainer = styled.div`
  width: 100%;
  z-index: 1;
  padding: 15px 0px 10px;
  text-align: center;
  border-top: 20px solid var(--color-pear-green);
`;

const ContentContainer = styled.div`
  width: 320px;
  padding: 20px;
`;

export enum AuthFlowState {
  Loading = 0,
  LoggedOut,
  NoOrgs,
  Disabled,
  OfferSignout,
  UserDocNotFound,
  TwoFactorEnrollmentRequired,
  TwoFactorLoginRequired,
  Authenticated,
}

type AuthFlowContainerProps = {
  authFlowState: AuthFlowState;
  firebaseUser?: FirebaseUser | null;
  onSignOut: () => void;
  setAuthFlowState: (state: AuthFlowState) => void;
  onUserAuthenticated: () => void;
};

export const AuthFlowContainer = ({
  authFlowState,
  setAuthFlowState,
  onSignOut,
  onUserAuthenticated,
}: AuthFlowContainerProps) => {
  const [mfaResolver, setMfaResolver] = useState<MultiFactorResolver | null>(
    null
  );
  const [userEmail, setUserEmail] = useState<string | undefined>();

  return (
    <AuthSplash>
      <Switch>
        <Route path="/plans" component={SubscriptionPlans} />
        <Route path="/onboarding-survey" component={OnboardingRoute} />
        <Route path="/referral-status" component={ReferralStatusRoute} />
        <Route>
          <AuthStep>
            {/* loading firebase session state */}
            {authFlowState === AuthFlowState.Loading && <LoaderComponent />}

            {/* signed out */}
            {authFlowState === AuthFlowState.LoggedOut && (
              <Switch>
                <Route exact path="/login">
                  <LoginForm
                    setMfaResolver={setMfaResolver}
                    setAuthFlowState={setAuthFlowState}
                    onUserAuthenticated={onUserAuthenticated}
                    setUserEmail={setUserEmail}
                  />
                </Route>
                <Route exact path="/signup">
                  <SignupForm />
                </Route>
                <Route exact path="/forgot-password">
                  <RequestPasswordResetForm />
                </Route>
                <Route exact path="/reset">
                  <ResetPasswordForm />
                </Route>
              </Switch>
            )}

            {/* attempted sign in, not enrolled in 2fa, must enroll */}
            {authFlowState === AuthFlowState.TwoFactorEnrollmentRequired && (
              <TwoFactorEnrollmentForm
                setAuthFlowState={setAuthFlowState}
                userEmail={userEmail}
              />
            )}

            {/* attempted sign in, enrolled in 2fa, must complete 2fa */}
            {authFlowState === AuthFlowState.TwoFactorLoginRequired && (
              <TwoFactorLoginForm
                mfaResolver={mfaResolver}
                setAuthFlowState={setAuthFlowState}
                onUserAuthenticated={onUserAuthenticated}
                userEmail={userEmail}
              />
            )}

            {/* signed in and fetching pearUser state */}
            {authFlowState === AuthFlowState.Authenticated && (
              <LoaderComponent />
            )}

            {/* user state is fetched but disabled or has no orgs */}
            {authFlowState === AuthFlowState.NoOrgs && (
              <>
                <Text align="center">
                  This account is not attached to any organizations or has been
                  disabled. Please reach out to support if this is unexpected!
                </Text>
                <Button fullWidth onClick={onSignOut}>
                  Sign Out
                </Button>
              </>
            )}
          </AuthStep>
        </Route>
      </Switch>
    </AuthSplash>
  );
};

type AuthStepProps = {
  children: React.ReactNode;
};

export const AuthStep = ({ children }: AuthStepProps) => (
  <Stack h="100%" align="center" justify="center">
    <StyledContainer>
      <LogoContainer>
        <img src={imagePearLogo} height={50} alt="Pearsuite" />
      </LogoContainer>
      <ContentContainer>{children}</ContentContainer>
    </StyledContainer>
  </Stack>
);
