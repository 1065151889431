import { gql } from "@apollo/client";
import { notificationFields } from "./types";

export const NOTIFICATION_RECEIVED = gql`
  subscription notificationReceived(
    $organizationId: ID!
  ) {
    notificationReceived(
      organizationId: $organizationId
    ) {
      ${notificationFields}
    }
  }
`;
