import { gql } from "@apollo/client";
import { BillBatchFields, BillFields, ProviderReportFields } from "./types";

export const GET_BILLS = gql`
    query GetBills($input: GetBillsInput!, $pagination: PaginationInput!) {
        response: bills(input: $input, pagination: $pagination) {
            success
            message
            data {
                total
                page
                data {
                    ${BillFields}
                }

                aggregate {
                  status
                  count
                  amount
                }
            }
        }
    }
`;

export const GET_ORGANIZATION_BILL_BATCHES = gql`
  query GetOrganizationBillBatches(
    $input: GetOrganizationBillBatchesInput!
    $pagination: PaginationInput!
  ) {
    response: organizationBillBatches(input: $input, pagination: $pagination) {
      success
      message
      data {
        total
        page
        data {
          ${BillBatchFields}
        }
      }
    }
  }
`;

export const GET_BILL_SIGNED_URL = gql`
  query GetBillSignedUrl($input: GetBillSignedUrlInput!) {
    billSignedUrl(input: $input) {
      success
      message
      data
    }
  }
`;

export const GET_PROVIDER_REPORTS = gql`
query GetProviderReports($input: GetProviderReportsInput!, $pagination: PaginationInput!) {
    response: providerReports(input: $input, pagination: $pagination) {
        success
        message
        data {
            total
            page
            data {
                ${ProviderReportFields}
            }
        }
    }
}
`;
