import { Button, SimpleGrid, Text } from "@mantine/core";
import useTwilio from "src/hooks/useTwilio";

const NUMBER_PADS = [
  { main: "1" },
  { main: "2", sub: "A B C" },
  { main: "3", sub: "D E F" },

  { main: "4", sub: "G H I" },
  { main: "5", sub: "J K L" },
  { main: "6", sub: "M N O" },

  { main: "7", sub: "P Q R S" },
  { main: "8", sub: "T U V" },
  { main: "9", sub: "W X Y Z" },

  { main: "*" },
  { main: "0" },
  { main: "#" },
];

export const NumberPad = () => {
  const { call } = useTwilio();

  return !call ? null : (
    <SimpleGrid cols={3}>
      {NUMBER_PADS.map(({ main, sub }) => (
        <Button
          key={main}
          variant="light"
          onClick={() => call?.sendDigits(main)}
          leftIcon={<Text weight={600}>{main}</Text>}
        >
          <Text w="7ch">{sub}</Text>
        </Button>
      ))}
    </SimpleGrid>
  );
};
