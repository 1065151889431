import { gql } from "@apollo/client";
import { EmilTemplateFields } from "./types";

export const CREATE_EMAIL_TEMPLATE = gql`
    mutation CreateEmailTemplate($input: EmailTemplateInput!) {
        createEmailTemplate(input: $input) {
            success
            message
            data {
              ${EmilTemplateFields}
            }
        }
    }
`;

export const UPDATE_EMAIL_TEMPLATE = gql`
    mutation UpdateEmailTemplate($input: UpdateEmailTemplateInput!) {
        updateEmailTemplate(input: $input) {
            success
            message
            data {
              ${EmilTemplateFields}
            }
        }
    }
`;

export const COPY_EMAIL_TEMPLATE = gql`
    mutation CopyEmailTemplate($input: CopyEmailTemplateInput!) {
        copyEmailTemplate(input: $input) {
            success
            message
            data {
                ${EmilTemplateFields}
            }
        }
    }
`;

export const DELETE_EMAIL_TEMPLATE = gql`
    mutation DeleteEmailTemplate($input: EmailTemplateIdAndOrgInput!) {
        deleteEmailTemplate(input: $input) {
            success
            message
            data {
              ${EmilTemplateFields}
            }
        }
    }
`;
