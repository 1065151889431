import { Activity, Check, Clock, Icon, AlertOctagon } from "react-feather";
import { ActivityStatus } from "src/graphql";

export const activityStatusLabels: Record<ActivityStatus, string> = {
  [ActivityStatus.Complete]: "Complete",
  [ActivityStatus.Scheduled]: "Scheduled",
  [ActivityStatus.InProgress]: "In progress",
  [ActivityStatus.Failed]: "Failed",
};

export const activityStatusColors: Record<ActivityStatus, string> = {
  [ActivityStatus.Complete]: "green",
  [ActivityStatus.Scheduled]: "blue",
  [ActivityStatus.InProgress]: "orange",
  [ActivityStatus.Failed]: "red",
};

export const activityStatusIcons: Record<ActivityStatus, Icon> = {
  [ActivityStatus.Complete]: Check,
  [ActivityStatus.Scheduled]: Clock,
  [ActivityStatus.InProgress]: Activity,
  [ActivityStatus.Failed]: AlertOctagon,
};
