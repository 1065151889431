import { MantineThemeColors } from "@mantine/core";

export const colors: Partial<MantineThemeColors> = {
  green: [
    "#ECF7F3",
    "#D8EFE6",
    "#B2DFCD",
    "#8BCEB5",
    "#51b68f",
    "#3EAE83",
    "#328B69",
    "#25684F",
    "#194634",
    "#0C231A",
  ],

  durian: [
    "#F9FAEB",
    "#F0F3D4",
    "#E2E9AD",
    "#D2DD8A",
    "#B6C754",
    "#99AC36",
    "#778927",
    "#5B6922",
    "#495420",
    "#3F481F",
  ],

  red: [
    "#FDF3F3",
    "#FBE6E5",
    "#F8D1D0",
    "#F2B1AF",
    "#E88481",
    "#D9534F",
    "#C73F3B",
    "#A7322E",
    "#8A2D2A",
    "#742A28",
  ],

  yellow: [
    "#FFFFEA",
    "#FFFBC5",
    "#FFF885",
    "#FFEE46",
    "#FFDF1B",
    "#FFC107",
    "#E29400",
    "#BB6902",
    "#985108",
    "#7C420B",
  ],

  black: [
    "#FFFFFF",
    "#E9E9EA",
    "#BCBEBF",
    "#A6A8A9",
    "#909294",
    "#7A7C7F",
    "#646669",
    "#4D5154",
    "#373B3E",
    "#212529",
  ],
};
