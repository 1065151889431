import { useEffect, useMemo, useState } from "react";
import {
  ExecuteActionInput,
  ScheduleActivityInput,
  useQueryCarePathwayTemplateById,
} from "src/graphql";
import { SelectOption } from "src/types";
import { ScheduleActivityActionModalInput } from "./ScheduleActivityActionModalInput";

interface CreateCarePathwayInputProps {
  carePathwayTemplateId?: string;
  assignableUserOptions: SelectOption<string>[];
  assignableUsersLoading: boolean;
  completeFunction: (actionInfo: ExecuteActionInput) => void;
  onboardingToken?: string;
  organizationId: string;
}

export const CreateCarePathwayDataModalInput: React.FC<CreateCarePathwayInputProps> =
  ({
    completeFunction,
    carePathwayTemplateId,
    assignableUserOptions,
    assignableUsersLoading,
    onboardingToken,
    organizationId,
  }) => {
    const carePathwayTemplateQuery = useQueryCarePathwayTemplateById({
      carePathwayTemplateId: carePathwayTemplateId ?? "",
      carePathwayId: undefined,
      skip: carePathwayTemplateId === undefined,
      onboardingToken,
      organizationId,
    });

    const [actionData, setActionData] = useState<ScheduleActivityInput[]>([]);
    const [currentActivity, setCurrentActivity] = useState(0);

    const activitiesToSchedule = useMemo(() => {
      const carePathwayTemplate =
        carePathwayTemplateQuery.data?.carePathwayTemplateById?.data;

      return (
        carePathwayTemplate?.steps[0].actions
          .filter(
            (action) =>
              action.resourceType === "ACTIVITY_TEMPLATE" &&
              action.scheduleType === "SCHEDULED"
          )
          .map((action) => action.resourceId) ?? []
      );
    }, [carePathwayTemplateQuery.data?.carePathwayTemplateById?.data]);

    const completeSingleActivityScheduling = (
      scheduledActivityData: ScheduleActivityInput
    ) => {
      if (currentActivity === activitiesToSchedule.length - 1)
        return completeFunction({
          carePathwayTemplateId: carePathwayTemplateId ?? "",
          activities: [...actionData, scheduledActivityData],
        });

      setActionData((prev) => [...prev, scheduledActivityData]);
      setCurrentActivity((prev) => prev + 1);
    };

    useEffect(() => {
      if (
        activitiesToSchedule.length === 0 &&
        carePathwayTemplateQuery.loading === false
      )
        completeFunction({
          carePathwayTemplateId: carePathwayTemplateId ?? "",
          activities: [],
        });
    }, [
      activitiesToSchedule.length,
      carePathwayTemplateQuery.loading,
      carePathwayTemplateId,
      completeFunction,
    ]);

    if (carePathwayTemplateQuery.loading) return null;

    if (activitiesToSchedule[currentActivity])
      return (
        <ScheduleActivityActionModalInput
          completeFunction={completeSingleActivityScheduling}
          templateId={activitiesToSchedule[currentActivity]}
          onboardingToken={onboardingToken}
          assignableUserOptions={assignableUserOptions}
          assignableUsersLoading={assignableUsersLoading}
          relatedCarePathwayTemplateId={carePathwayTemplateId}
        />
      );

    return <></>;
  };
