import { LockingReferenceFields } from "../Common/types";

export const actionFields = `
  response
  action {
    createGoal {
      goalTemplateId
    }
    updateGoal {
      goalTemplateId
      status
    }
    scheduleActivity {
      activityTemplateId
    }
    updateMember {
      field
      value {
        constant
        input
      }
    }
    createReferral {
      externalResourceId
      referralTemplateId
      type
    }
    startCarePathway {
      carePathwayTemplateId
    }
    updateCarePathwayStatus {
      carePathwayTemplateId
      status
    }
    createConsent {
      consentTemplateId
    }
    addMemberToGroup{
      groupId
    }
    removeMemberFromGroup{
      groupId
    }
  }
`;

export const flowTemplateFields = `
  _id
  steps {
      questionId
      stepId
      next {
          response
          nextStepId
      }
      actions{
          ${actionFields}
      } 
      chapter
      nodePosition {
          x
          y
      }
  }
  chapters 
  organizationId
  updatedAt
  createdAt
  title
  entryStepId
  startNodePosition {
      x
      y
  }
  stagePositions {
    Contemplation
    Preparation
    Action
    Maintenance
  }
  lockingReferences {
    ${LockingReferenceFields}
  }
  lockedByUse
  flowType
  version
  familyId
`;
