import { ActivityFields } from "../Activity/types";
import { carePathwayFields } from "../CarePath/types";
import { GoalFields } from "../Goal/types";
import { ReferralFields } from "../Referral/types";
import { consentFields } from "../Consent/types";

export const FlowFields = `
  _id
  memberId
  flowTemplateId
  flowTemplateFamilyId
  flowType
  currentStep
  currentStage
  answers {
    flowAnswerIndex
    questionId
    answer
    submittedAt
    stepId
  }
  activityId
  completed
  createdAt
  updatedAt
`;

export const CompleteActionDataFields = `
  createdGoal {
    ${GoalFields}
  }
  scheduledActivity {
    ${ActivityFields}
  }
  createdFlow {
    ${FlowFields}
  }
  createdReferral {
    ${ReferralFields}
  }
  createdCarePathway {
    ${carePathwayFields}
  }
  createdConsent {
    success
    message
    consentTemplateId
    data { 
      ${consentFields}
    }
  }
`;
